export const asPercentage = (toIn: number, fromIn: number) => {
  let to = toIn;
  let from = fromIn;
  if (Number.isNaN(to)) {
    to = 0;
  }

  if (Number.isNaN(from)) {
    from = 0;
  }

  if (from === 0 && to === 0) {
    return 0;
  }

  if (from === 0) {
    return NaN;
  }

  if (to === 0) {
    return -100;
  }

  const change = to - from;
  let result = 100 * (change / from);
  if (from < 0 && to > 0) {
    result *= -1;
  }

  if (to < 0 && from < to) {
    result *= -1;
  }

  return result;
};
