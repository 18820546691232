import React from "react";
import "./Shimmer.scss";

export type ShimmerProps = {
  Sheight?: string;
  className?: string;
} & React.HTMLProps<HTMLSpanElement>;

export function ShimmerCell({ className, style }: ShimmerProps) {
  const classes =
    "loading-shimmer loading-shimmer-animation " + (className || "");
  return <span className={classes} style={style}></span>;
}

export function ShimmerBlock({ className }: ShimmerProps) {
  return (
    <ShimmerCell
      className={className}
      style={{
        height: "1em",
        width: "1em",
      }}
    />
  );
}

export function ShimmerCircle({ className }: ShimmerProps) {
  return (
    <ShimmerCell
      className={className}
      style={{
        height: "1em",
        width: "1em",
        borderRadius: "1em",
      }}
    />
  );
}
