import { Fragment, KeyboardEvent, MouseEvent, useCallback } from "react";
import {
  Button,
  makeStyles,
  mergeClasses,
  Persona,
  Table,
  TableBody,
  TableCell,
  TableCellActions,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableSelectionCell,
  Text,
  useTableSelection,
  TableRowId,
} from "@fluentui/react-components";
import { Edit16Regular, MoreHorizontal20Filled } from "@fluentui/react-icons";
import {
  useTableExpandingRows,
  useTableFeaturesExtended,
  useTableLockingRows,
} from "components/table";
import { Item, mockData } from "./mock";
import { columns } from "./columns";

const useExpandedRowStyles = makeStyles({
  childRow: {
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderBottomColor: "transparent",
    backgroundColor: "var(--gray-300)",
    "&:active": {
      backgroundColor: "var(--colorSubtleBackgroundHover)",
    },
  },
  parentRow: {
    borderBottomWidth: 0,
    backgroundColor: "var(--gray-300)",
    "&:hover": {
      cursor: "ns-resize",
    },
  },
});

const useCollapsedRowStyles = makeStyles({
  row: {
    "&:hover": {
      cursor: "ns-resize",
    },
  },
});

export function ExpandableExample() {
  const expandedRowStyles = useExpandedRowStyles();
  const collapsedRowStyles = useCollapsedRowStyles();

  const {
    getRows,
    selection: {
      allRowsSelected,
      someRowsSelected,
      toggleAllRows,
      toggleRow,
      isRowSelected,
    },
    expandingRows: { isRowExpanded, toggleRow: toggleExpandingRow },
    lockingRows: { isRowLocked },
  } = useTableFeaturesExtended(
    {
      columns,
      items: mockData,
    },
    [
      useTableSelection({
        selectionMode: "multiselect",
        defaultSelectedItems: new Set([0, 1]),
      }),
    ],
    [
      useTableExpandingRows({
        expandingMode: "single",
        defaultExpandedItems: new Set([2, 3]),
      }),
      useTableLockingRows({
        defaultLockedItems: new Set([1, 3]),
      }),
    ]
  );

  const rows = getRows((row) => {
    const selected = isRowSelected(row.rowId);
    const expanded = isRowExpanded(row.rowId);
    const locked = isRowLocked(row.rowId);

    return {
      ...row,
      onClick: (e: MouseEvent) => {
        toggleExpandingRow(e, row.rowId);
      },
      onKeyDown: (e: KeyboardEvent) => {
        if (e.key === " ") {
          e.preventDefault();
          toggleExpandingRow(e, row.rowId);
        }
      },
      selected,
      expanded,
      locked,
      appearance: selected ? ("brand" as const) : ("none" as const),
    };
  });

  const toggleAllKeydown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === " ") {
        toggleAllRows(e);
        e.preventDefault();
      }
    },
    [toggleAllRows]
  );

  const renderChildRows = (
    rowId: TableRowId,
    item: Item,
    expanded: boolean
  ) => {
    if (!expanded || !item.projects.length) {
      return null;
    }

    return (
      <>
        <TableRow
          key={`${rowId}-child-header`}
          className={expandedRowStyles.childRow}
        >
          <TableCell colSpan={4} />
          <TableCell>
            <Text weight="semibold">Project types</Text>
          </TableCell>
          <TableCell>
            <Text weight="semibold">Managers</Text>
          </TableCell>
          <TableCell colSpan={2} />
        </TableRow>
        {item.projects.map((p) => (
          <TableRow
            key={`${rowId}-${p.type}-${p.managerEmail}-child`}
            className={expandedRowStyles.childRow}
          >
            <TableCell colSpan={4} />
            <TableCell>{p.type}</TableCell>
            <TableCell>
              <Persona primaryText={p.managerEmail} />
            </TableCell>
            <TableCell>
              <Button icon={<Edit16Regular />} appearance="transparent" />
            </TableCell>
            <TableCell />
          </TableRow>
        ))}
      </>
    );
  };

  const renderHeaderRow = () => {
    return (
      <TableRow>
        <TableSelectionCell
          checked={allRowsSelected || (someRowsSelected && "mixed")}
          onClick={toggleAllRows}
          onKeyDown={toggleAllKeydown}
        />
        <TableHeaderCell>
          <Text weight="semibold">Customer Name</Text>
        </TableHeaderCell>
        <TableHeaderCell>
          <Text weight="semibold">Customer number</Text>
        </TableHeaderCell>
        <TableHeaderCell>
          <Text weight="semibold">Business unit</Text>
        </TableHeaderCell>
        <TableHeaderCell>
          <Text weight="semibold">Projects</Text>
        </TableHeaderCell>
        <TableHeaderCell>
          <Text weight="semibold">Sales person</Text>
        </TableHeaderCell>
        <TableHeaderCell>
          <Text weight="semibold">Client program</Text>
        </TableHeaderCell>
        <TableHeaderCell>
          <Text weight="semibold">Actions</Text>
        </TableHeaderCell>
      </TableRow>
    );
  };

  const renderClientProgram = (level: "low" | "medium" | "high") => {
    if (level === "low") {
      return "🟢";
    }

    if (level === "medium") {
      return "🟡";
    }

    if (level === "high") {
      return "🔴";
    }

    return "⚫";
  };

  return (
    <Table>
      <TableHeader>{renderHeaderRow()}</TableHeader>
      <TableBody>
        {rows.map(
          ({ item, selected, onClick, onKeyDown, locked, expanded, rowId }) => (
            <Fragment key={rowId}>
              <TableRow
                key={`${rowId}-parent`}
                onClick={onClick}
                onKeyDown={onKeyDown}
                className={mergeClasses(
                  expanded
                    ? expandedRowStyles.parentRow
                    : collapsedRowStyles.row
                )}
              >
                <TableSelectionCell
                  checked={selected}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleRow(e, rowId);
                  }}
                />
                <TableCell>
                  <TableCellLayout>{item.customerName}</TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout>{item.customerId}</TableCellLayout>
                </TableCell>
                <TableCell>{item.businessUnit}</TableCell>
                <TableCell>
                  {item.projects.length === 0 ? "0" : item.projects.length}
                </TableCell>
                <TableCell>
                  <TableCellLayout>
                    <Persona primaryText={item.salesPerson} />
                  </TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellLayout>
                    {renderClientProgram(item.clientProgram)}
                  </TableCellLayout>
                </TableCell>
                <TableCell>
                  <TableCellActions>
                    <Button
                      icon={<MoreHorizontal20Filled />}
                      appearance="transparent"
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("clicked more on rowId: ", rowId);
                      }}
                    />
                  </TableCellActions>
                </TableCell>
              </TableRow>
              {renderChildRows(rowId, item, expanded)}
            </Fragment>
          )
        )}
      </TableBody>
    </Table>
  );
}
