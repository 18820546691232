import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { useTranslation } from "hooks/use-translate";
import { ACCOUNTING_SERVICE_LINE } from "libs/constants";
import { BasicInfo } from "models/customerSettings/customerSettingsBasicInfo";
import { RootState } from "state";
import CustomerPageBasicInfoModal from "./customerPageBasicInfoModal";
import CustomerPageBasicInfoToggle from "./customerPageBasicInfoToggle";

type CustomerPageBasicInfoProps = {
  customerId: string;
};
export default function CustomerPageBasicInfo({
  customerId,
}: CustomerPageBasicInfoProps) {
  const { translate } = useTranslation();
  const basicInfo = useSelector(
    (state: RootState) => state.settings.basicInfo.data
  );
  const serviceLine = useSelector(
    (state: RootState) => state.settings.serviceLines.currentServiceLine
  );
  const isLoading = useSelector((state: RootState) => state.settings.isLoading);

  const [openModal, setOpenModal] = useState(false);
  const [currSetting, setCurrSetting] = useState<Partial<BasicInfo>>();
  const isGeneral = serviceLine?.serviceline === "General";
  const showAccounting =
    serviceLine?.serviceline === ACCOUNTING_SERVICE_LINE || isGeneral;
  const showPayroll = serviceLine?.serviceline === "Payroll" || isGeneral;

  const onToggle = (change: Partial<BasicInfo>) => {
    setCurrSetting(change);
    setOpenModal(true);
  };
  return !isLoading ? (
    <Row className="p-lg cs-content">
      {showAccounting && (
        <Col md={5} className="d-flex flex-column">
          <div className="d-flex pb-sm">
            <h1 className="sub-h1">
              {translate("ACCOUNTING")} {translate("SETTINGS")}
            </h1>
          </div>
          <div className="d-inline-block">
            <div className="toggle-wrapper">
              <CustomerPageBasicInfoToggle
                settingName="collective_agreement"
                basicInfo={basicInfo}
                onToggle={onToggle}
              />
              <CustomerPageBasicInfoToggle
                settingName="holiday_salary"
                basicInfo={basicInfo}
                onToggle={onToggle}
              />
              <CustomerPageBasicInfoToggle
                settingName="lease_cars"
                basicInfo={basicInfo}
                onToggle={onToggle}
              />
            </div>
          </div>
        </Col>
      )}
      {isGeneral && (
        <Col md={2} className="d-flex justify-content-center">
          <div className="d-flex">
            <div className="vertical-divider" />
          </div>
        </Col>
      )}

      {showPayroll && (
        <Col md={5} key="settings-Payroll" className="d-flex flex-column">
          <div className="d-flex pb-2">
            <h1 className="sub-h1">{translate("PAYROLL_SETTINGS")}</h1>
          </div>
          <div className="d-inline-block">
            <div className="toggle-wrapper">
              <CustomerPageBasicInfoToggle
                settingName="reporting_forum"
                basicInfo={basicInfo}
                onToggle={onToggle}
              />
            </div>
          </div>
        </Col>
      )}
      {currSetting && (
        <CustomerPageBasicInfoModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          currSetting={currSetting}
          customerId={customerId}
        />
      )}
    </Row>
  ) : null;
}
