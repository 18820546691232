import { TextButton } from "../textButton/textButton";

type AddButtonProps = { children?: any; onClick?: () => void };

export function AddButton({ children, onClick }: AddButtonProps) {
  const handleOnClick = () => {
    onClick?.();
  };

  return <TextButton onClick={handleOnClick}>{children}</TextButton>;
}
