import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Modal from "components/modal";
import { useTranslation } from "hooks/use-translate";
import { PowerOfAttorney } from "models/customerSettings/customerSettingsPowerofAttorney";
import { CustomerDetailsParams } from "routes";
import { RootState } from "state";
import {
  fetchPowerOfAttorneyCallerWrapper,
  updatePowerOfAttorneyCallerWrapper,
} from "state/customerSettings/customerSettingsThunk";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";

const EMPTY = "N/A";
const ACTIVE = "Active";

type CustomerPagePowerOfAttorneyUpdateStatusModalProps = {
  powerOfAttorneyObj: PowerOfAttorney;
  isOpen: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  updateStatusId: number;
  onOpenChange: (isOpen: boolean) => void;
};
export default function CustomerPagePowerOfAttorneyUpdateStatusModal({
  powerOfAttorneyObj,
  isOpen,
  onConfirm,
  onDismiss,
  updateStatusId,
  onOpenChange,
}: CustomerPagePowerOfAttorneyUpdateStatusModalProps) {
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const { customerId } = useParams<CustomerDetailsParams>();

  const powerOfattorneyStatuses = useSelector(
    (state: RootState) => state.settings.powerOfAttorney.statuses
  );
  const selectedStatus = powerOfattorneyStatuses.find(
    (e) => e.id === updateStatusId
  );

  const currServiceLine = useSelector(
    (state: RootState) => state.settings.serviceLines.currentServiceLine
  );

  const updatePoaObj = async () => {
    if (selectedStatus && powerOfAttorneyObj.id) {
      const updatedPoaObj = {
        ...powerOfAttorneyObj,
        status: { ...selectedStatus },
      };
      await dispatch(updatePowerOfAttorneyCallerWrapper(updatedPoaObj));
      if (customerId) {
        dispatch(
          fetchPowerOfAttorneyCallerWrapper({
            customerId,
            ...(currServiceLine && { serviceLineId: currServiceLine.id }),
          })
        );
      }

      onConfirm();
    }
  };

  const poaNotEmptyStatusActive =
    selectedStatus &&
    selectedStatus.title === ACTIVE &&
    powerOfAttorneyObj.powerofattorneysource.title !== EMPTY &&
    powerOfAttorneyObj.powerofattorneytype.title !== EMPTY;
  const poaStatusInactive = selectedStatus && selectedStatus.title !== ACTIVE;

  return (
    <Modal
      size="medium"
      isOpen={isOpen}
      onDismiss={onDismiss}
      header={<ModalHeader headerTitleText="UPDATE_STATUS" />}
      footer={
        (poaNotEmptyStatusActive || poaStatusInactive) && (
          <ModalFooter
            onCancel={() => onOpenChange(false)}
            onSave={() => {
              updatePoaObj();
              onDismiss();
            }}
            labelSubmit="YES"
            labelCancel="NO"
          />
        )
      }
    >
      {poaNotEmptyStatusActive || poaStatusInactive ? (
        <Row className="p-md">
          <Col>
            <Row className="px-sm">
              {translate("CHANGE_STATUS_TO", [
                selectedStatus.title === ACTIVE
                  ? translate("ACTIVE")
                  : translate("INACTIVE"),
              ])}
            </Row>
            <Row className="p-sm">
              <Col md={2} className="fw-bold">
                {translate("SOURCE")}:
              </Col>
              <Col>{powerOfAttorneyObj?.powerofattorneysource.title}</Col>
            </Row>
            <Row className="p-sm">
              <Col md={2} className="fw-bold">
                {translate("TYPE")}:
              </Col>
              <Col>{powerOfAttorneyObj?.powerofattorneytype.title}</Col>
            </Row>
            <Row className="p-sm">
              <Col md={2} className="fw-bold">
                {translate("HOLDER")}:
              </Col>
              <Col>{powerOfAttorneyObj?.employee_name}</Col>
            </Row>
            <Row className="p-sm">
              <Col md={2} className="fw-bold">
                {translate("COMMENT")}:
              </Col>
              <Col>{powerOfAttorneyObj?.comment}</Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row className="px-md"> {translate("NON_VALID_FIELDS")}</Row>
      )}
    </Modal>
  );
}
