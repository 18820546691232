import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import AppConfig from "app-config";
import { Dropdown } from "components/dropdown";
import { DropdownV9 } from "components/dropdown/DropdownV9";
import { PersonPicker } from "components/people";
import { TextArea } from "components/textarea";
import Switch from "components/switch";
import { createNewDealFromContext } from "helpers/BusinessOpportinityHelper";
import { useTranslation } from "hooks";
import { capitalize } from "libs/capitalize";
import { EKONOMI_BUSINESS_AREA } from "libs/constants";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { PersonDetails } from "models/profile";
import { INCLUDED_SOURCES } from "shared/dealConstants";
import { RootState } from "state";
import { createNewDeal, fetchBusinessOpportunities } from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import { availableTogglesMeta } from "views/offer/components/servicePicker/ServicePickerHelper";
import { CreateBusinessOpportunityContext } from "../CreateBusinessOpportinityContext";
import WizardSectionBusinessOpportunity from "../WizardSectionBusinessOpportinity";

enum DealResponsible {
  CURRENT_USER = "CurrentUser",
  SALES_TEAM = "SalesTeam",
  EMPLOYEE = "Employee",
}

export default function BussinessOpportunitiesDetails() {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { updateNewDeal, newDeal } = useContext(
    CreateBusinessOpportunityContext
  );

  const { data: companyInfoData } = useSelector(
    (state: RootState) => state.offers.companyInfo
  );

  const { isNewCustomer } = useSelector((state: RootState) => state.sales);

  const [dealResponsible, setDealResponsible] = useState<
    DealResponsible | undefined
  >(undefined);
  const [dealResponsibleEmployee, setDealResponsibleEmployee] = useState<
    PersonDetails | undefined
  >(undefined);

  const [creatingDeal, setCreatingDeal] = useState(false);

  const [dealAmount, setDealAmount] = useState<number>(0);

  const { currentUser } = useSelector((state: RootState) => state.users);
  const {
    dealOptions: { data: dealOptions },
    products: { data: products },
  } = useSelector((state: RootState) => state.sales);

  const dealSources = dealOptions.find((d) => d.name === "deal_source");
  const dealSourceOptions =
    dealSources?.options.filter((option) =>
      INCLUDED_SOURCES.includes(option.value)
    ) || [];
  const productDropdownOptions = products.map((p) => ({
    text: p.name,
    data: p,
    value: p.id,
  }));

  const dealsBusinessAreas = dealOptions.find(
    (d) => d.name === "affarsomrade_deal_"
  );

  const dealBusinessAreaLabel = dealsBusinessAreas?.options.find(
    // eslint-disable-next-line no-underscore-dangle
    (area) => area.value === newDeal.affarsomrade_deal_
  )?.label;

  // the products are filter by business area label
  // activity_category_label that is coming from HS contains the business area label
  const productOptionsByArea = productDropdownOptions.filter(
    (po) => po.data?.activity_category_label === dealBusinessAreaLabel
  );

  const [productOptions, setProductOptions] = useState(productOptionsByArea);

  const selectedProductOptions = newDeal.productIds || [];

  useEffect(() => {
    switch (dealResponsible) {
      case DealResponsible.EMPLOYEE: {
        if (dealResponsibleEmployee) {
          updateNewDeal({
            ...newDeal,
            assignee_email: dealResponsibleEmployee.userPrincipalName || "",
            assignee_firstname: dealResponsibleEmployee.givenName || "",
            assignee_lastname: dealResponsibleEmployee.surname || "",
            dealstage: AppConfig.FEATURES.DEALS.DEFAULT_DEAL_STAGE,
          });
        }
        break;
      }

      case DealResponsible.CURRENT_USER: {
        updateNewDeal({
          ...newDeal,
          assignee_email: currentUser.email || "",
          assignee_firstname: currentUser.firstName || "",
          assignee_lastname: currentUser.lastName || "",
          dealstage: AppConfig.FEATURES.DEALS.DEFAULT_DEAL_STAGE,
        });
        break;
      }
      case DealResponsible.SALES_TEAM:
      default: {
        updateNewDeal({
          ...newDeal,
          assignee_email: "",
          assignee_firstname: "",
          assignee_lastname: "",
          dealstage: AppConfig.FEATURES.DEALS.DEFAULT_CUSTOMER_DEAL_STAGE,
        });
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dealResponsible, dealResponsibleEmployee]);

  const postBusinessDetails = async () => {
    setCreatingDeal(true);

    try {
      const businessArea = dealsBusinessAreas?.options.find(
        // eslint-disable-next-line no-underscore-dangle
        (area) => area.value === newDeal.affarsomrade_deal_
      );
      const dealToCreate = createNewDealFromContext(
        {
          ...newDeal,
          affarsomrade_deal_: businessArea?.value || "",
          dealstage: newDeal.dealstage,
          dealname: `${newDeal.first_name} ${newDeal.last_name} | ${businessArea?.label}`,
          productIds: selectedProductOptions,
          supress_asignee_email: false,
          amount: dealAmount,
        },
        currentUser,
        companyInfoData,
        isNewCustomer || false,
        false
      );
      await dispatch(createNewDeal(dealToCreate));
    } catch (error) {
      return false;
    } finally {
      await dispatch(fetchBusinessOpportunities());
      setCreatingDeal(false);
    }

    return true;
  };

  const disableProductsToMatchPOGServices = (filteredProductIds: string[]) => {
    if (newDeal.affarsomrade_deal_ === EKONOMI_BUSINESS_AREA) {
      if (!filteredProductIds.length) {
        const mappedProductOptions = productOptions.map((product) => ({
          ...product,
          disabled: false,
        }));
        setProductOptions(mappedProductOptions);
      }
      filteredProductIds.forEach((pid) => {
        const currentProduct = productOptionsByArea.find(
          (product) => product.value === pid
        );

        if (currentProduct && currentProduct.data?.description) {
          const currentService =
            availableTogglesMeta[capitalize(currentProduct.data.description)];
          if (currentService) {
            const { isPackage } = currentService;
            const mappedProductOptions = productOptions.map((product) => {
              let isProductDisabled = false;
              if (
                product.data?.description &&
                product.data.description !== currentProduct.data!.description
              ) {
                const targetService =
                  availableTogglesMeta[capitalize(product.data.description)];
                isProductDisabled = isPackage
                  ? !!targetService
                  : targetService && targetService.isPackage;
              }
              return {
                ...product,
                disabled: isProductDisabled,
              };
            });
            setProductOptions(mappedProductOptions);
          }
        }
      });
    }
  };

  const handleOnProductSelectChange = (isSelected: boolean, value?: string) => {
    if (!value) {
      return;
    }
    const filteredProductIds = selectedProductOptions.find(
      (option) => option === value
    )
      ? selectedProductOptions.filter((option) => option !== value)
      : [...selectedProductOptions, value as string];
    const item = productOptions.find((option) => option.value === value);
    if (item) {
      const addOrSubtract = isSelected
        ? Number(item.data.price)
        : -Number(item.data.price);
      setDealAmount(Number(dealAmount || 0) + addOrSubtract);
    }

    disableProductsToMatchPOGServices(filteredProductIds);
    updateNewDeal({
      productIds: filteredProductIds,
    });
  };

  const handlePersonChange = (person: PersonDetails | undefined) => {
    if (!person) {
      return null;
    }

    setDealResponsible(DealResponsible.EMPLOYEE);
    setDealResponsibleEmployee(person);
  };

  const resetContextData = () => {
    updateNewDeal({
      deal_source: "",
      productIds: [],
      meddelande_salj: "",
      assignee_email: "",
      assignee_firstname: "",
      assignee_lastname: "",
      dealstage: "",
    });
  };

  return (
    <WizardSectionBusinessOpportunity
      subtitles={[translate("BO_BUSINESS_DETAILS_SUBHEADER")]}
      isNextDisabled={!newDeal.deal_source || !dealResponsible || creatingDeal}
      loadingStatus={
        creatingDeal ? LoadingStatusEnum.PENDING : LoadingStatusEnum.IDLE
      }
      onBeforeNext={() => postBusinessDetails()}
      onPrevious={resetContextData}
      nextLabel="DEALS_WIZARD_CREATE_BO"
      backLabel="DEALS_WIZARD_BACK_TO_BUSINESS_AREAS"
      backMessageTooltip={translate("CREATE.NEW.DEAL.BACK_TO_BUSINESS_AREAS")}
    >
      <Row className="">
        <Col className="mb-sm" lg={4}>
          <label className="fw-semibold">
            {translate("SOURCE_OF_REFFERENCE")} <b>*</b>
          </label>
          <Dropdown
            value={newDeal.deal_source}
            placeholder={translate("DROPDOWN_PLACEHOLDER")}
            options={dealSourceOptions}
            onChange={(value) =>
              updateNewDeal({ ...newDeal, deal_source: value })
            }
          />
        </Col>
        <Col className="mb-sm" lg={4}>
          <label className="fw-semibold">{translate("SERVICE")}</label>
          <DropdownV9
            placeholder={translate("SELECT_LIST_MULTIPLE")}
            multiselect
            onOptionSelect={(_, { optionValue }, isSelected) => {
              handleOnProductSelectChange(isSelected, optionValue);
            }}
            selectedOptions={selectedProductOptions}
            options={productOptions}
          />
        </Col>
      </Row>
      <Row className="pt-lg">
        <Col className="mb-sm" lg={5}>
          <label className="fw-semibold">{translate("INTERNAL_NOTE")}</label>
          <TextArea
            id="internal_note"
            rows={5}
            onChange={(e) => {
              updateNewDeal({ ...newDeal, meddelande_salj: e.target.value });
            }}
          />
        </Col>
      </Row>
      <Row className="pt-lg d-flex">
        <label className="fw-semibold">
          {translate("RESPONSIBLE")} <b>*</b>
        </label>
        <div className="d-flex pt-sm">
          <Switch
            checked={dealResponsible === DealResponsible.SALES_TEAM}
            disabled={
              !!dealResponsible &&
              dealResponsible !== DealResponsible.SALES_TEAM
            }
            label={translate("SENT_TO_SALES_TEAM")}
            onToggleMethod={(_, { checked }) => {
              setDealResponsible(
                checked ? DealResponsible.SALES_TEAM : undefined
              );
            }}
          />
          <div className="d-flex pl-lg pr-md">
            <div className="vertical-divider" />
          </div>
          <Switch
            checked={dealResponsible === DealResponsible.CURRENT_USER}
            disabled={
              !!dealResponsible &&
              dealResponsible !== DealResponsible.CURRENT_USER
            }
            label={translate("MAKE_ME_RESPONSIBLE")}
            onToggleMethod={(_, { checked }) => {
              setDealResponsible(
                checked ? DealResponsible.CURRENT_USER : undefined
              );
            }}
          />
          <div className="d-flex pl-lg pr-md">
            <div className="vertical-divider" />
          </div>
          <div className="w-35">
            <PersonPicker
              className="me-2"
              selectionMode="single"
              emptyPlaceholder={translate("SELECT_EMPLOYEE")}
              placeholder={translate("SEARCH_PERSON")}
              disabled={
                !!dealResponsible &&
                dealResponsible !== DealResponsible.EMPLOYEE
              }
              selectedUsers={
                dealResponsible === DealResponsible.EMPLOYEE &&
                newDeal.assignee_email
                  ? [{ id: newDeal.assignee_email, name: undefined }]
                  : []
              }
              onPersonChange={(_, __, person) => {
                handlePersonChange(person);
              }}
            />
          </div>
        </div>
      </Row>
    </WizardSectionBusinessOpportunity>
  );
}
