import { Info16Regular } from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";
import "./Card.scss";

export type CardProps = Omit<React.HTMLProps<HTMLDivElement>, "label"> & {
  title: string;
  imageUrl: string;
  body: JSX.Element;
  label?: JSX.Element;
  footer?: JSX.Element;
  hasInfoIcon?: boolean;
  onInfoIconClicked?: () => void;
  hoverOverlay?: JSX.Element;
};

export function Card({
  title,
  imageUrl,
  body,
  label,
  footer,
  hoverOverlay,
  onInfoIconClicked,
  hasInfoIcon = false,
  ...rest
}: CardProps) {
  return (
    <div className="card d-flex align-items-end" {...rest}>
      <Col>
        <Row className="card-image mx-0 px-0">
          {label && <div className="card-label">{label}</div>}
          <img src={imageUrl} className="card-image-top px-0" alt={title}></img>
          <div className="card-overlay px-0">{hoverOverlay}</div>
        </Row>

        <Row className="d-flex flex-column justify-content-between px-2">
          <div>
            <div className="d-flex">
              <h5 className="card-title flex-1">{title}</h5>
              {hasInfoIcon && (
                <Info16Regular className="ml-sm" onClick={onInfoIconClicked} />
              )}
            </div>

            <p className="card-text">{body}</p>
          </div>
        </Row>
      </Col>
      <Row className="d-flex align-items-end justify-content-end mb-2 mx-2">
        {footer}
      </Row>
    </div>
  );
}
