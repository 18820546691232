import { ReactElement, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  ChevronDown24Regular,
  ChevronUp24Regular,
} from "@fluentui/react-icons";

import TooltipV9 from "components/tooltip/TooltipV9";

export type MenuItemGroupProps = {
  items: MenuItemType[];
  header: string;
  collapsedMenu: boolean;
};

export type MenuItemType = {
  title: string;
  url?: string;
  children?: MenuItemType[];
  icon?: ReactElement;
  isExternalLink?: boolean;
};

export function MenuItemGroup({
  items,
  header,
  collapsedMenu,
}: MenuItemGroupProps) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  function getActiveLinkClassName(
    item: MenuItemType
  ): string | ((props: { isActive: boolean }) => string) | undefined {
    const activeMenuItemClass = `fw-bold ${
      collapsedMenu
        ? "selected active-direction"
        : "selected d-flex align-items-center"
    }`;

    if (
      item.children &&
      item.url &&
      item.url === `/${location.pathname.split("/")[1]}`.concat(location.search)
    ) {
      return activeMenuItemClass;
    }

    return ({ isActive }) => (isActive ? activeMenuItemClass : "");
  }
  useEffect(() => {}, [location]);

  return (
    <div
      className={`menu-group ${collapsed && !collapsedMenu ? "collapsed" : ""}`}
    >
      {!collapsedMenu && (
        <div
          className="menu-group-header p-sm fw-bold d-flex align-items-center"
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <ChevronDown24Regular /> : <ChevronUp24Regular />}{" "}
          <span className="menu-group-title">{header}</span>
        </div>
      )}
      <hr className="w-100" />
      <ul>
        {items.map((item) => {
          const selectedItem = !!(
            item.url &&
            item.url ===
              `/${location.pathname.split("/")[1]}`.concat(location.search)
          );

          if (!item.url) {
            return null;
          }
          if (collapsedMenu && item.icon) {
            return (
              <TooltipV9
                key={item.title}
                content={item.title}
                positioning="after"
                notTranslatable
              >
                <div className="my-xs">
                  <NavLink
                    className={getActiveLinkClassName(item)}
                    key={item.url}
                    to={item.url}
                  >
                    <li className="menu-group-item px-sm py-sm hover-menu">
                      {item.icon}
                    </li>
                  </NavLink>
                  <div className={`${selectedItem && `selected-collapsed`}`} />
                </div>
              </TooltipV9>
            );
          }

          return (
            <NavLink
              className={selectedItem ? getActiveLinkClassName(item) : ""}
              key={item.url}
              to={item.url}
            >
              <div className={`${selectedItem && `selected-full-submenu`}`} />
              <li className="menu-group-item py-sm">{item.title}</li>
            </NavLink>
          );
        })}
      </ul>
    </div>
  );
}
