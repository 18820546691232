export function isInTeamsDesktop() {
  return window.navigator.userAgent.includes("Teams");
}

export function isInTeamsWeb() {
  return window.name.includes("embedded-page-container");
}

export function isInTeams() {
  return isInTeamsDesktop() || isInTeamsWeb();
}
