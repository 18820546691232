import { DrawerBody, DrawerFooter, Spinner } from "@fluentui/react-components";
import { ArrowRight16Regular } from "@fluentui/react-icons";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "components/button";
import DrawerHeader from "components/drawer/drawerHeader/DrawerHeader";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import OverlayDrawer from "components/OverlayDrawer";
import { SpinnerSize } from "components/spinner";
import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import {
  ActivityInstance,
  BooleanWithText,
  FormQuestion,
  ServiceInstance,
} from "models/activities/activity";
import { Customer } from "models/customer";
import { User } from "models/user";
import { RootState } from "state";
import { completeActivity, fetchFormQuestions } from "state/activities/actions";
import "./YearlyEvaluationKYCForm.scss";
import { YearlyEvaluationKYCFormContent } from "./YearlyEvaluationKYCFormContent";

type YearlyEvaluationKYCFormProps = {
  setShowQuestionsModal: (show: boolean) => void;
  customer: Customer;
  activityInstance: ActivityInstance;
  currentUser: User;
  showQuestionsModal: boolean;
  service?: ServiceInstance;
};

type FromQuestionGroup = {
  group_header: string;
  group_sort_order: number;
  group_questions: FormQuestion[];
};

export function YearlyEvaluationKYCForm({
  setShowQuestionsModal,
  customer,
  activityInstance,
  currentUser,
  showQuestionsModal,
  service,
}: YearlyEvaluationKYCFormProps) {
  const formQuestions = useSelector(
    (state: RootState) => state.activities.formQuestions
  );

  const dispatch = useDispatch();
  const { translate } = useTranslation();

  const currentFormId = useRef<string>();
  const formQuestionsGrouped: FromQuestionGroup[] = [];

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (
      activityInstance.form_id !== null &&
      (formQuestions.length === 0 ||
        currentFormId.current !== activityInstance.form_id)
    ) {
      dispatch(fetchFormQuestions(customer, activityInstance));
      currentFormId.current = activityInstance.form_id;
    }
  }, [activityInstance, customer, dispatch, formQuestions.length]);

  function getGroupedQuestions() {
    if (formQuestionsGrouped.length > 0) {
      return formQuestionsGrouped;
    }

    formQuestions.forEach((question) => {
      const group = formQuestionsGrouped.find(
        (existingGroup) => existingGroup.group_header === question.group_header
      );

      if (group) {
        group.group_questions.push(question);
      } else {
        formQuestionsGrouped.push({
          group_header: question.group_header,
          group_sort_order: question.group_sort_order,
          group_questions: [question],
        });
      }
    });
    formQuestionsGrouped.sort(
      (a, b) => a.group_sort_order - b.group_sort_order
    );
    return formQuestionsGrouped;
  }

  const handleSendIn = () => {
    setOpenModal(true);
  };

  const handleCompleteActivityWithKYC = () => {
    dispatch(
      completeActivity(
        customer,
        activityInstance,
        currentUser.graphId,
        [...formQuestions],
        service?.service_box_id
      )
    );
    currentFormId.current = "";
    setShowQuestionsModal(false);
  };

  const isLoadingForm = () => {
    return (
      formQuestions.length === 0 ||
      currentFormId.current !== activityInstance.form_id ||
      getGroupedQuestions().length === 0
    );
  };

  const isKYCFormValid =
    formQuestions.length > 0
      ? formQuestions.some(
          (question) => (question.answer as BooleanWithText)?.value
        )
        ? !formQuestions
            .filter(
              (question) =>
                (question.answer as BooleanWithText)?.value.toString() ===
                "false"
            )
            ?.some(
              (question) => !(question.answer as BooleanWithText)?.comment
            ) &&
          !formQuestions.some((question) => question.answer === undefined)
        : false
      : false;

  const renderFooter = () => {
    return (
      <>
        <div>
          <Button
            variant="outline-primary"
            className="py-sm full-width"
            onClick={() => {
              setShowQuestionsModal(false);
              currentFormId.current = "";
            }}
          >
            {translate("CANCEL")}
          </Button>
        </div>

        <div>
          <TooltipV9
            content="KYC_SEND_TOOLTIP"
            enabled={formQuestions.length > 0 && !isKYCFormValid}
          >
            <Button
              disabled={!isKYCFormValid}
              variant="primary"
              className="py-sm full-width"
              onClick={handleSendIn}
            >
              <span className="d-flex align-items-center">
                <ArrowRight16Regular className="mr-sm" /> {translate("SEND")}
              </span>
            </Button>
          </TooltipV9>
        </div>
      </>
    );
  };

  return (
    <>
      <OverlayDrawer
        size="large"
        showPanel={
          showQuestionsModal &&
          activityInstance.form_id != null &&
          activityInstance.form_id !== ""
        }
        modalType="non-modal"
      >
        <DrawerHeader
          onDismiss={() => {
            setShowQuestionsModal(false);
          }}
          header="YEARLY_EVALUATION_KYC"
        />
        <DrawerBody>
          <Row className="gy-sm">
            <Col xs={12}>{translate("LOCK_ACTIVITY_WARNING")}</Col>
          </Row>
          <div className="horizontal-divider my-md" />

          {isLoadingForm() ? (
            <div>
              <Spinner size={SpinnerSize.ExtraSmall} />
            </div>
          ) : (
            <YearlyEvaluationKYCFormContent formQuestions={formQuestions} />
          )}
        </DrawerBody>
        <DrawerFooter className="justify-content-between">
          {renderFooter()}
        </DrawerFooter>
      </OverlayDrawer>
      {openModal && (
        <Modal
          isOpen={openModal}
          header={<ModalHeader headerTitleText="YEARLY_EVALUATION_KYC" />}
          onDismiss={() => setOpenModal(false)}
          footer={
            <ModalFooter
              onSave={() => {
                handleCompleteActivityWithKYC();
                setOpenModal(false);
              }}
              onCancel={() => setOpenModal(false)}
              labelSubmit="YES"
            />
          }
        >
          <div className="pt-md pb-sm">
            <p>{translate("KYC_MODAL_QUESTION")}</p>
            <p>{translate("KYC_MODAL_QUESTION_ADDITIONAL")}</p>
          </div>
        </Modal>
      )}
    </>
  );
}
