export class ActivityAppendix {
  constructor() {
    this.id = "";
    this.name = "";
    this.amount_per_document = "";
    this.count = 0;
    this.max_count = 0;
  }

  id: string;

  name: string;

  amount_per_document: string;

  count: number;

  max_count: number;
}
