import { IProvider, Providers, ProviderState } from "@microsoft/mgt-element";
import { AuthenticationProviderOptions } from "@microsoft/microsoft-graph-client";
import { mockWorker } from "../mocks/api";
import AppConfig from "../app-config";

export function isMockAuth() {
  const searchParams = new URLSearchParams(window.location.search);
  return AppConfig.USE_MOCK_AUTH || searchParams.has("mockAuth");
}

export function isMockApi() {
  return AppConfig.USE_MOCKS;
}

export function startMockWorker() {
  mockWorker().printHandlers();
  mockWorker().start({
    onUnhandledRequest(request) {
      const url = request.url.toString();
      const shouldIgnore = [".woff", ".png"].some((ignoreString) =>
        url.includes(ignoreString)
      );

      if (!shouldIgnore) {
        return request;
      }
    },
  });
}

export class MockProvider extends IProvider {
  constructor() {
    super();
    this.setSignedIn();
  }

  public get name(): string {
    return "MgtMockProvider";
  }

  public async getAccessToken(
    options?: AuthenticationProviderOptions
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("mock.authorization.token");
      }, 100);
    });
  }

  public async login(): Promise<void> {
    const token: string = await this.getAccessToken();

    if (token) {
      this.setSignedIn();
    }
  }

  public setSignedIn() {
    this.setState(ProviderState.SignedIn);
    if (this.setActiveAccount) {
      this.setActiveAccount({
        name: "Mock Ludvig",
        mail: "mock.user@ludvig.se",
        id: "mock.id",
        tenantId: "mock.tenant.id",
      });
    }
  }
}

export function mockInitialize() {
  if (isMockApi()) {
    startMockWorker();
  }

  Providers.globalProvider = new MockProvider();
  console.log(
    "%cMOCK API RESPONSES ENABLED. MITTLUDVIG NETWORK REQUESTS ARE INTERCEPTED BY SERVICE WORKER.",
    "font-size: 2em; color: green;"
  );
}
