import { rest } from "msw";
import { mockCheckToken } from "..";
import { Assignment } from "../../../models/assignment";
import { getRandomAssignments } from "../../generators/generator";
import { initialCustomersWithData } from "../customers/customers";

const BASE_PATH = process.env.REACT_APP_API_URL;

const initialAssignments = initialCustomersWithData.map((c) => ({
  customer_number: c.customer.customer_number,
  assignments: getRandomAssignments(c.customer),
}));

export const assignmentsHandlers = [
  rest.get<Assignment[]>(
    `${BASE_PATH}/assignments`,
    (req, res, { status, json, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      return res(status(200), json(initialAssignments));
    }
  ),
  rest.get<Assignment[]>(
    `${BASE_PATH}/customers/:customerId/assignments`,
    (req, res, { status, json, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      const customerAssignments = initialAssignments.find(
        (c) => c.customer_number === req.params.customerId
      );

      if (!customerAssignments) {
        return res(status(404), text("Could not find customer"));
      }

      return res(status(200), json(customerAssignments?.assignments));
    }
  ),
  rest.get<Assignment[]>(
    `${BASE_PATH}/customers/:customerId/assignments/:assignmentId`,
    (req, res, { status, json, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      const customerAssignments = initialAssignments.find(
        (c) => c.customer_number === req.params.customerId
      );

      if (!customerAssignments) {
        return res(status(404), text("Could not find customer"));
      }

      const assignment = customerAssignments.assignments.find(
        (a) => a.project_number === req.params.assignmentId
      );

      if (!assignment) {
        return res(status(404), text("Could not find assignment"));
      }

      return res(status(200), json(assignment));
    }
  ),
];
