import React from "react";
import { ErrorCircle16Regular } from "@fluentui/react-icons";

import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import Modal from ".";

type ModalAction = {
  actionLabel: string;
  onAction: () => void;
};

interface WarningModalProps {
  header: string;
  warningMessage: React.ReactNode;
  onCancel: () => void;
  action?: ModalAction;
}

function WarningModal({
  header,
  warningMessage,
  onCancel,
  action,
}: WarningModalProps) {
  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      isOpen
      onDismiss={() => handleCancel()}
      isWarning
      size="medium"
      header={<ModalHeader headerTitleText={header} />}
      footer={
        action && (
          <ModalFooter
            onCancel={() => handleCancel()}
            onSave={() => action.onAction()}
            labelSubmit={action.actionLabel}
          />
        )
      }
    >
      <div>
        <div className="d-flex px-md pb-lg text-color-red align-items-center">
          <ErrorCircle16Regular />
          <span className="p-sm">{warningMessage}</span>
        </div>
      </div>
    </Modal>
  );
}

export default WarningModal;
