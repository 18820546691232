import { useState } from "react";
import { Collapse, Row } from "react-bootstrap";
import {
  ChevronDown20Regular,
  ChevronUp20Regular,
} from "@fluentui/react-icons";

import "./stepper.scss";

export type Step = {
  key: string;
  label: string;
  subSteps: Step[];
  state?: StepState;
};

export type StepState = {
  active: boolean;
  valid: boolean;
  disabled: boolean;
  visible: boolean;
  passed: boolean;
};

interface IStepperProps {
  steps?: Step[];
}

export default function Stepper({ steps = [] }: IStepperProps) {
  const [stepperIsExpanded, setStepperIsExpanded] = useState(false);
  const renderVerticalLine = (key: string) => (
    <span
      key={`${key}-vLine`}
      className="d-block vertical-wizard-divider pt-3"
    />
  );

  const renderHorizontalLine = (key: string) => (
    <div key={`${key}-hLine`}>
      <hr />
    </div>
  );

  const renderStepCircleLabel = (
    { key, label, state }: Step,
    index: number
  ) => {
    const stepState = state
      ? [
          state.active && "active",
          state.disabled && "disabled",
          state.passed && "passed",
          state.passed && state.valid && "valid",
        ]
      : [];

    const stepStateClasses = stepState.filter((s) => s).join(" ");

    return (
      <span>
        <span className={`stepNumber ${stepStateClasses}`}>
          <span>{index + 1}</span>
        </span>
        <span className={`stepLabel ${stepStateClasses} ms-2`}>{label}</span>
      </span>
    );
  };

  const renderSubStep = ({ key, label, state }: Step) => {
    const stepState = state
      ? [
          state.active && "active",
          state.disabled && "disabled",
          state.passed && "passed",
          state.passed && state.valid && "valid",
        ]
      : [];

    const stepStateClasses = stepState.filter((s) => s).join(" ");

    return (
      <span className="substep d-block" key={key + label}>
        <span className={`substepNumber ${stepStateClasses}`}>
          <span>{key}</span>
        </span>
        <span className={`substepLabel ${stepStateClasses} ms-2`}>{label}</span>
      </span>
    );
  };

  function printSubsteps(step: Step) {
    return step.subSteps
      ?.filter((subStep) => subStep.state?.visible)
      .map((sStep) => [renderVerticalLine(sStep.key), renderSubStep(sStep)]);
  }

  return (
    <Row
      className="d-flex justify-content-center wizard-stepper cursor-pointer"
      onClick={() => setStepperIsExpanded(!stepperIsExpanded)}
    >
      {steps.map(
        (step, index) =>
          !step.state?.disabled && [
            <div key={step.key}>
              {renderStepCircleLabel(step, index)}
              <Collapse
                in={stepperIsExpanded}
                unmountOnExit
                mountOnEnter
                appear
              >
                <>{printSubsteps(step)}</>
              </Collapse>
            </div>,
            steps[index + 1] && renderHorizontalLine(step.key),
          ]
      )}
      <div
        className="pt-1 blue-link bold-icon"
        onClick={() => setStepperIsExpanded(!stepperIsExpanded)}
      >
        {stepperIsExpanded ? <ChevronDown20Regular /> : <ChevronUp20Regular />}
      </div>
    </Row>
  );
}
