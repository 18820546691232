import {
  ActivitiesDateReducerActionEnum,
  SequentialType,
} from "../helpers/reducerDate";

export function noticeNextOrPrevYear(
  month: number,
  year: number,
  sequential: SequentialType
): number {
  switch (sequential) {
    case ActivitiesDateReducerActionEnum.IncrementMonth:
      return month === 12 ? year + 1 : year;
    case ActivitiesDateReducerActionEnum.DecrementMonth:
      return month === 1 ? year - 1 : year;

    default:
      return year;
  }
}
