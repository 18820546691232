import "./Overlay.scss";

export function Overlay({
  children,
  className = "",
  ...rest
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div {...rest} className={`overlay ${className}`}>
      {children}
    </div>
  );
}
