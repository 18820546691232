import {
  ChevronDoubleDownFilled,
  ChevronDoubleUpFilled,
  ReOrderFilled,
} from "@fluentui/react-icons";

import { Priority } from "models/deals/deal";

type PriorityIconProps = {
  priority?: Priority;
  onClick?: () => void;
  size?: string;
};

export function PriorityIcon({
  priority = "medium",
  onClick,
  size = "fem-4",
}: PriorityIconProps) {
  const getColorClassName = () => {
    if (priority === "low") {
      return "text-color-green-light";
    }

    if (priority === "high") {
      return "text-color-red";
    }

    return "text-color-yellow";
  };
  const className = `blue-link ${getColorClassName()} ${size}`;

  if (priority === "low") {
    return (
      <ChevronDoubleDownFilled
        className={className}
        onClick={onClick && onClick}
      />
    );
  }

  if (priority === "high") {
    return (
      <ChevronDoubleUpFilled
        className={className}
        onClick={onClick && onClick}
      />
    );
  }

  return <ReOrderFilled className={className} />;
}
