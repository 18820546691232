import { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { RootState } from "state";
import { Customer } from "models/customer";
import Checkbox from "components/checkbox";
import CustomerDetails from "./CustomerDetails";
import ServiceTableRowMonthServiceBox from "./ServiceTableRowMonthServiceBox";
import "./ServiceTableCustomerRow.scss";

type Props = {
  customer: Customer;
  isCollapsedView: boolean;
  isExpanded: boolean;
  openPanel: (customer: Customer) => void;
  filteredMonths: Date[];
  showCompleted?: boolean;
  checkbox?: boolean;
  handleCheckbox: () => void;
  showDivider?: boolean;
  timelineLoadingMonthIndexes?: number[];
};

export default function ServiceTableCustomerRow({
  customer,
  isCollapsedView,
  isExpanded,
  openPanel,
  filteredMonths,
  showCompleted,
  checkbox,
  handleCheckbox,
  showDivider = true,
  timelineLoadingMonthIndexes,
}: Props) {
  const activitiesHasFailed = useSelector(
    (state: RootState) => state.activities.hasFailed
  );

  const [collapsedRow, setCollapsedRow] = useState<string | null>(null);

  const customerRowRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const URLProps = new URLSearchParams(window.location.search);
    const focusedClient = URLProps.get("focusedClient");

    if (focusedClient && customer.customer_number === focusedClient) {
      customerRowRef.current?.focus();
      setCollapsedRow(focusedClient);
    }
  }, [customer]);

  useEffect(() => {
    if (!isCollapsedView) {
      setCollapsedRow(null);
    }
  }, [isCollapsedView]);

  const extendRowView = (customerNumber: string) => {
    setCollapsedRow(collapsedRow === customerNumber ? null : customerNumber);
  };

  return (
    <>
      <Row
        xs={12}
        tabIndex={0}
        ref={customerRowRef}
        key={customer.customer_number}
        className={`w-100 my-xs wrapper ${
          checkbox ? "selected-customer-row" : ""
        } ${
          !isCollapsedView
            ? "min-height-80"
            : "cursor-pointer hover-collapsed-row"
        }`}
        onClick={() =>
          isCollapsedView && extendRowView(customer.customer_number)
        }
      >
        <div className="d-flex p-0 w-customer-details">
          <div className="d-flex align-items-center h-100 w-100">
            {checkbox !== undefined && (
              <Checkbox
                checked={checkbox}
                onChange={handleCheckbox}
                onClick={(e) => e.stopPropagation()}
                noMargin={false}
              />
            )}
            <div className="box-background details details-card h-100 w-100">
              <CustomerDetails
                customer={customer}
                isExpanded={
                  collapsedRow === customer.customer_number || isExpanded
                }
                toggleInfo={() => openPanel(customer)}
                isCollapsedView={
                  collapsedRow !== null
                    ? collapsedRow !== customer.customer_number
                    : isCollapsedView
                }
              />
            </div>
          </div>
        </div>
        <div className="d-flex p-0 w-timeline">
          {!activitiesHasFailed && (
            <ServiceTableRowMonthServiceBox
              customer={customer}
              filteredMonths={filteredMonths}
              showCollapsedView={isCollapsedView}
              showCompleted={showCompleted}
              isCollapsedRow={
                collapsedRow !== customer.customer_number && isCollapsedView
              }
              isExpanded={isExpanded}
              timelineLoadingMonthIndexes={timelineLoadingMonthIndexes}
            />
          )}
        </div>
      </Row>
      {showDivider && <div className="horizontal-divider m-0 d-block" />}
    </>
  );
}
