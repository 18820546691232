import { ServiceInstance } from "../../../../models/activities/activity";
import { TimespanFilter } from "../filters/service-filters";

// EN:"Documents available"; SV: Material tillgängligt
const DOCUMENTS_AVAILABLE = [
  "01-01-01-M",
  "01-01-01-Q",
  "01-01-01-Y",
  "01-01-16-Q",
  "01-01-16-M",
  "03-01-01-YE",
];

export const useCustomerDocumentsCompleted = (
  timelineWindow: Date[],
  customerServices: {
    customer_number: string;
    servicesFilter: ServiceInstance[];
  }[]
) => {
  const services = customerServices.filter(
    (f) => f.servicesFilter.length !== 0
  );

  const timelineFilter = new TimespanFilter(timelineWindow);

  const customersIdAndActivities = services
    .flatMap((s) => {
      s.servicesFilter = s.servicesFilter.filter((s) =>
        s.activities.every((a) => timelineFilter.check(a, s))
      );
      s.servicesFilter = s.servicesFilter.filter(
        (service) => !service.isCompleted()
      );
      return s.servicesFilter.flatMap((a) =>
        a.activities
          .map((t) => {
            return {
              customer_id: s.customer_number,
              activity_type: t.activity_type,
              completed_at: t.completed_at as Date,
            };
          })
          .filter(
            (f) =>
              DOCUMENTS_AVAILABLE.includes(f.activity_type) && f.completed_at
          )
      );
    })
    .sort((a, b) => (a.completed_at > b.completed_at ? -1 : 1));

  return customersIdAndActivities;
};
