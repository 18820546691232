import * as microsoftTeams from "@microsoft/teams-js";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ChatEmpty16Regular } from "@fluentui/react-icons";

import { Button } from "components/button";
import { DropdownV9 } from "components/dropdown/DropdownV9";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { TextArea } from "components/textarea";
import { useTranslation } from "hooks";
import {
  ActivityDefinition,
  ActivityInstance,
  ActivityStatus,
} from "models/activities/activity";
import { Customer } from "models/customer";
import { postActivityInstanceMessage } from "state/activities/actions";
import { createCustomerTeam } from "state/customers/actions";

type Props = {
  open: boolean;
  onClose: () => void;
  customer: Customer;
  selectedActivityInstance: ActivityInstance;
  definition: ActivityDefinition;
};

export default function MessageModal({
  open,
  onClose,
  customer,
  selectedActivityInstance,
  definition,
}: Props) {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const [messageContent, setMessageContent] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<
    ActivityStatus | undefined
  >();

  const postActivityMessage = async (
    selectedActivity: ActivityInstance | undefined,
    status: ActivityStatus
  ) => {
    if (!selectedActivity) {
      return;
    }

    dispatch(
      postActivityInstanceMessage(
        customer,
        selectedActivity,
        messageContent,
        status,
        definition
      )
    );

    setMessageContent("");
    onClose();
  };

  const statusOptions = [
    {
      value: ActivityStatus.ManagerSupport,
      text: translate(ActivityStatus.ManagerSupport),
    },
    {
      value: ActivityStatus.SupportSentToClient,
      text: translate(ActivityStatus.SupportSentToClient),
    },
    {
      value: ActivityStatus.SupportNeeded,
      text: translate(ActivityStatus.SupportNeeded),
    },
    {
      value: ActivityStatus.SupportFinished,
      text: translate(ActivityStatus.SupportFinished),
    },
  ];

  return (
    <Modal
      size="small"
      isOpen={open}
      onDismiss={onClose}
      header={<ModalHeader headerTitleText="MESSAGE" />}
      footer={
        <ModalFooter
          isDisabled={!selectedStatus || !messageContent}
          onSave={() =>
            selectedStatus &&
            postActivityMessage(selectedActivityInstance, selectedStatus)
          }
          labelSubmit="SET_NEW_STATUS"
        />
      }
    >
      {selectedActivityInstance.teams_conversation_weburl && (
        <div className="mb-4">
          <p>{translate("CONVERSATION_EXISTS")}</p>
          <Button
            onClick={() =>
              microsoftTeams.executeDeepLink(
                selectedActivityInstance.teams_conversation_weburl || ""
              )
            }
          >
            <ChatEmpty16Regular className="mr-sm" />
            {translate("GO_TO_CONVERSATION")}
          </Button>
        </div>
      )}
      {customer.apps?.teams?.status === "active" && (
        <>
          <p className="m-0">{translate("MESSAGE")}</p>
          <TextArea
            className="mb-2"
            value={messageContent}
            onChange={(str) => setMessageContent(str.target.value)}
          />
          <p className="m-0">{translate("SELECT_STATUS")}</p>
          <DropdownV9
            optionsWithIcons
            options={statusOptions}
            onOptionSelect={(e, data) => {
              setSelectedStatus(data.optionValue as ActivityStatus);
            }}
            selectedOptions={selectedStatus ? [selectedStatus] : []}
            placeholder={translate("SELECT_STATUS")}
          />
        </>
      )}
      {customer.apps?.teams?.status === "nonexistent" && (
        <>
          <p>{translate("CUSTOMER_MISSING_TEAMS_AREA")}</p>
          <Button
            className="mt-2"
            onClick={() => dispatch(createCustomerTeam(customer))}
          >
            {translate("CREATE_TEAM")}
          </Button>
        </>
      )}
      {customer.apps?.teams?.status === "loading" && (
        <>
          <p>{translate("CUSTOMER_MISSING_TEAMS_AREA")}</p>
          <p>{translate("CUSTOMER_TEAM_IS_BEING_CREATED")}</p>
        </>
      )}
      {customer.apps?.teams?.status === "inactive" && (
        <>
          <p>{translate("CUSTOMER_MISSING_TEAMS_AREA")}</p>
          <p>{translate("CUSTOMER_TEAM_IS_INACTIVE")}</p>
        </>
      )}
    </Modal>
  );
}
