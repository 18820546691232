import { SyntheticEvent, useMemo } from "react";
import { useControllableState } from "@fluentui/react-utilities";
import {
  LockedItemId,
  LockingHookParams,
  LockingMethods,
  TableLockingState,
} from "./types";
import { createSetFromIterable } from "../utils";

export const defaultTableLockingState: TableLockingState = {
  lockedRows: new Set(),
  isRowLocked: () => false,
};

function useLockingRowsState(params: LockingHookParams) {
  const [locked, setLocked] = useControllableState<Set<LockedItemId>>({
    initialState: new Set(),
    defaultState: useMemo(
      () =>
        params.defaultLockedItems &&
        createSetFromIterable(params.defaultLockedItems),
      [params.defaultLockedItems]
    ),
    state: useMemo(
      () => params.lockedItems && createSetFromIterable(params.lockedItems),
      [params.lockedItems]
    ),
  });

  const changeLockedItems = (
    event: SyntheticEvent,
    nextLockedItems: Set<LockedItemId>
  ) => {
    params.onLockingChange?.(event, { lockedItems: nextLockedItems });
    setLocked(nextLockedItems);
  };
  return [locked, changeLockedItems] as const;
}

export function useLockingRows(params: LockingHookParams) {
  const [locked] = useLockingRowsState(params);

  const methods: LockingMethods = {
    isLocked: (itemId) => locked.has(itemId),
  };
  return [locked, methods] as const;
}
