import { isPlaceholderEmail } from "../../libs/generate-placeholder-email";
import DealContactDetails from "./DealContactDetails";

export enum SigningMethod {
  BANKID = "bankid-se",
  PHYSICAL = "physical",
}

export const SigningMethodToPrettyString = (signingMethod: SigningMethod) => {
  const translations = {
    [SigningMethod.BANKID]: "BankID",
    [SigningMethod.PHYSICAL]: "Fysisk",
  };

  return translations[signingMethod];
};

export class DealContact {
  constructor(data?: Partial<DealContact>) {
    this.id = data?.id ?? undefined;
    this.is_signer = data?.is_signer ?? false;
    this.signing_method =
      data?.signing_method ??
      (isPlaceholderEmail(data?.contact?.email ?? "")
        ? SigningMethod.PHYSICAL
        : SigningMethod.BANKID);

    this.contact = new DealContactDetails(data?.contact);
  }

  id?: number;

  /**
   * If the contact is will be used to sign the contract
   */
  is_signer = true;

  /**
   * Signing method for the contact
   */
  signing_method: SigningMethod;

  /**
   * Contact details
   */
  contact: DealContactDetails = {
    id: "",
    social_security_number: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile_phone: "",
    phone_number: "",
    changed_by_username: "",
    created_by_username: "",
  };
}
