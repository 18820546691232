import { Label } from "@fluentui/react-components";
import { useSelector } from "react-redux";

import "./ServiceDetailsHeader.scss";
import { Dropdown } from "components/dropdown";
import { RootState } from "state";
import { getMonthsOfOneYear } from "libs/date/generate-month";
import { useTranslation } from "hooks/use-translate";
import { ServiceCategory } from "models/offer/ServiceLine";
import { PersonPicker } from "components/people";

export type Frequency = "weekly" | "monthly" | "quarterly" | "yearly";

export const FREQUENCY_ARRAY: Frequency[] = [
  "weekly",
  "monthly",
  "quarterly",
  "yearly",
];

type ServicesFormData = {
  startDate: string;
  endDate: string;
  projectManager: string;
  frequency: Frequency | "";
};

type YearEndForm = ServicesFormData & {
  yearEndYear: string | null;
  yearEndMonth: string;
};
interface ServiceDetailsProps {
  startDate?: string;
  projectManager?: string;
  frequency?: Frequency | "";
  frequencySelectionDisabled?: boolean;
  yearEndSelectionDisabled?: boolean;
  startDateOnChange: (value: string) => void;
  projectManagerOnChange: (value: string) => void;
  frequencyOnChange?: (value: Frequency) => void;
  updateServiceCategoryData?: (value: string) => void;
  yearEndData?: YearEndForm;
  yearEndInCurrentOffer?: ServiceCategory;
}

export default function ServiceDetailsHeader({
  startDateOnChange,
  projectManagerOnChange,
  frequencyOnChange,
  startDate = "",
  projectManager = "",
  frequency,
  frequencySelectionDisabled = true,
  updateServiceCategoryData,
  yearEndData,
  yearEndSelectionDisabled = false,
  yearEndInCurrentOffer,
}: ServiceDetailsProps) {
  const { data: currentOffer } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  const { currentUser } = useSelector((state: RootState) => state.users);
  const { translate, ts } = useTranslation();

  const frequencyOptions = FREQUENCY_ARRAY.map((freq) => {
    return {
      value: freq,
      label: ts(freq.toUpperCase()),
    };
  });

  const yearEndDisabled =
    (yearEndInCurrentOffer && !!yearEndInCurrentOffer.approved_by_email) ||
    yearEndSelectionDisabled;

  return (
    <div className="service-details-header w-100 d-flex align-items-center">
      <div className="pl-lg w-60">
        <Label className="p-0">{translate("FREQUENCY")}</Label>
        <Dropdown
          className="service-details-header-input px-0"
          placeholder={translate("SELECT_FREQUENCY")}
          onChange={(value) => {
            if (frequencyOnChange) {
              frequencyOnChange(value as Frequency);
            }
          }}
          options={frequencyOptions}
          disabled={frequencySelectionDisabled}
          value={frequency}
          defaultValue={frequency}
        />
      </div>
      {yearEndData && (
        <div className="pl-lg w-60">
          <Label className="p-0">{translate("YEAREND_MONTH")} *</Label>
          <Dropdown
            disabled={yearEndDisabled}
            className="service-details-header-input px-0"
            placeholder={translate("DROPDOWN_PLACEHOLDER")}
            onChange={(value) => {
              if (updateServiceCategoryData) {
                updateServiceCategoryData(value);
              }
            }}
            options={Array(12)
              .fill({ value: "", label: "" })
              .map((_data, index) => ({
                value: (index + 1).toString(),
                label: (index + 1).toString(),
              }))}
            value={yearEndData.yearEndMonth}
          />
        </div>
      )}
      <div className="pl-lg ps-0 w-60">
        <Label className="p-0">{translate("START_DATE")}</Label>
        <Dropdown
          className="service-details-header-input px-0"
          placeholder={translate("DROPDOWN_PLACEHOLDER")}
          onChange={(value) => {
            startDateOnChange(value);
          }}
          options={getMonthsOfOneYear().map((date) => {
            return {
              value: date,
              label: date,
            };
          })}
          value={startDate}
          defaultValue={startDate}
        />
      </div>
      <div className="pl-lg w-100">
        <div className="horizontal-scroll-auto">
          <PersonPicker
            className="me-xxs"
            label={translate("PROJECT_MANAGER")}
            selectionMode="single"
            placeholder={translate("SEARCH_PERSON")}
            selectedUsers={
              projectManager !== ""
                ? [{ id: projectManager, name: undefined }]
                : [
                    {
                      id: currentOffer?.sales_manager ?? currentUser.email,
                      name: undefined,
                    },
                  ]
            }
            onPersonChange={(email, _, person) => {
              if (person || email) {
                projectManagerOnChange(person?.userPrincipalName ?? email);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
