import {
  Switch as FluentSwitch,
  SwitchOnChangeData,
  SwitchProps as FluentSwitchProps,
} from "@fluentui/react-components";
import { UseFormRegisterReturn } from "react-hook-form";

import "./Switch.scss";
import { TooltipInfoIcon } from "components/icon";

type SwitchProps = {
  checked: boolean;
  className?: string;
  switchSize?: "normal" | "small";
  label?: string;
  onToggleMethod: (
    ev: React.ChangeEvent<HTMLInputElement>,
    data: SwitchOnChangeData
  ) => void;
  formRegister?: UseFormRegisterReturn;
  tooltipText?: JSX.Element;
  disabled?: boolean;
  noLeftMargin?: boolean;
} & FluentSwitchProps;

// This is a FluentUI v9 Switch component, which used to be called Toggle in v8
export default function Switch({
  checked,
  className = "",
  label,
  onToggleMethod,
  formRegister,
  tooltipText,
  disabled = false,
  noLeftMargin = false,
  switchSize = "small",
  ...rest
}: SwitchProps) {
  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <FluentSwitch
        className={`fluent-ui-v9-switch${
          noLeftMargin ? " no-left-margin" : ""
        } ${className}`}
        {...formRegister}
        label={label}
        checked={checked}
        onChange={onToggleMethod}
        indicator={{ className: switchSize }}
        disabled={disabled}
        {...rest}
      />
      {tooltipText && (
        <TooltipInfoIcon
          tooltip={{ content: tooltipText, hasJSXContent: true }}
        />
      )}
    </div>
  );
}
