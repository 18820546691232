import { useNavigate } from "react-router-dom";
import { Add16Regular } from "@fluentui/react-icons";

import { AppRouteHelper } from "routes";
import "./ActivitiesTable.scss";

type Props = {
  customerId: string;
};

export function ActivitiesNotAvailable({ customerId }: Props) {
  const navigate = useNavigate();
  return (
    <div className="no-activities-box">
      <p>The client has no activities for the selected period</p>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="activities-table-ghost-button"
          onClick={() =>
            navigate({
              pathname: AppRouteHelper.getAssignmentsEdit(customerId),
            })
          }
        >
          <Add16Regular className="icon-blue mr-sm" />
          <span className="text-color-blue fw-600 fpx-14">Add new package</span>
        </button>
      </div>
    </div>
  );
};
