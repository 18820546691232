import {
  ArrowClockwise20Regular,
  ArrowDownload20Regular,
  ChevronRight20Filled,
} from "@fluentui/react-icons";
import { addMonths, setYear } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import AppConfig from "app-config";
import { Button } from "components/button";
import { Dropdown, DropdownOption } from "components/dropdown";
import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { TextArea } from "components/textarea";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { dateLanguageFormatter } from "libs/date/date-format";
import { Customer } from "models/customer";
import "./ReportsModal.scss";
import { MessageBar, Tab, TabList } from "@fluentui/react-components";
import { DatePickerV9 } from "components/date/DatePickerV9";
import { DateRangeType } from "@fluentui/react-calendar-compat";

export enum TabsEnum {
  ANNUAL_REPORT = "ANNUAL_REPORT",
  BUDGET_FOLLOWUP = "BUDGET_FOLLOWUP",
  PERIOD_REPORT = "PERIOD_REPORT",
}

type ModalProps = {
  selectedCustomer?: Customer;
  showReportsModal: boolean;
  onDismiss: () => void;
};

type Period = "M" | "Q" | "Y";

const MAX_COMMENT_LENGTH = 1500;
const Now = new Date();
const Year = Now.getFullYear();
const AvailableYears: DropdownOption<number>[] = [
  { label: `${Year}`, value: Year },
  { label: `${Year - 1}`, value: Year - 1 },
  { label: `${Year - 2}`, value: Year - 2 },
  { label: `${Year - 3}`, value: Year - 3 },
];

export default function ReportsModal({
  selectedCustomer,
  showReportsModal,
  onDismiss,
}: ModalProps) {
  const [selectedYear, setSelectedYear] = useState(AvailableYears[1].value);
  const [pdfComment, setPDFComment] = useState("");
  const [pdfBalanceNote, setPDFBallanceComment] = useState("");

  const [selectedTab, selectTab] = useState(TabsEnum.ANNUAL_REPORT);
  const [selectedPeriodType, setSelectedPeriodType] = useState<Period>("M");
  const [selectedPeriodDate, setSelectedPeriodDate] = useState<Date>();
  const { translate, language, dateFormatter } = useTranslation();
  const formatter = dateLanguageFormatter(language);
  useEffect(() => {
    setSelectedPeriodDate(undefined);
  }, [selectedPeriodType]);

  const getGeneralReportURL = () => {
    const encodedResultNote = pdfComment
      .replace(/\n/g, "\\n")
      .replace(/\t/g, "\\t");
    const encodedBalanceNote = pdfBalanceNote
      .replace(/\n/g, "\\n")
      .replace(/\t/g, "\\t");
    window.open(
      `${AppConfig.PDF_REPORT_BASE_URL}/yearreport?customercode=${selectedCustomer?.customer_number}&year=${selectedYear}&resultNote=${encodedResultNote}&balanceNote=${encodedBalanceNote}`
    );
  };

  const downloadReport = async () => {
    if (!selectedPeriodDate || !selectedCustomer) {
      return;
    }

    const params = new URLSearchParams();
    if (selectedTab === TabsEnum.BUDGET_FOLLOWUP) {
      params.set("CustomerKey", selectedCustomer?.customer_key);
      params.set("PeriodType", selectedPeriodType);
      params.set(
        "Period",
        selectedPeriodType === "Y"
          ? selectedPeriodDate.getFullYear().toString()
          : `${selectedPeriodDate.getFullYear()}${(
              selectedPeriodDate.getUTCMonth() + 1
            )
              .toString()
              .padStart(2, "0")}`
      );
      params.set("Comment", pdfComment);

      window.open(
        `${
          AppConfig.BUDGET_REPORT_URL_BASE
        }/budgetfollowupreport?${params.toString()}`
      );
    }
    if (selectedTab === TabsEnum.PERIOD_REPORT) {
      const selectedMonth = (selectedPeriodDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const reportId = AppConfig.REPORTER_ID;
      const yearMonth = `${selectedPeriodDate.getFullYear()}${selectedMonth}`;
      const reporterComment = pdfComment;

      params.set("ReportId", reportId);
      params.set("autoAuth", "true");
      params.set("ctid", AppConfig.TENANT_ID);
      params.set("experience", "power-bi");
      params.set("rdl:format", "PDF");
      params.set("rdl:parameterPanel", "hidden");
      params.set("rp:CustomerCode", selectedCustomer?.customer_number);
      params.set("rp:YearMonth", yearMonth);
      params.set("rp:PeriodType", selectedPeriodType);
      params.set("rp:ReportComment", reporterComment);
      params.set("rdl:ReportView", "pageView");

      const monthlyReportUrl = `${
        AppConfig.MONTHLY_REPORT_URL_BASE
      }/?${params.toString()}`;

      window.open(monthlyReportUrl);
    }
  };

  const getCustomerQuarters = () => {
    if (!selectedCustomer) {
      return [];
    }

    return AvailableYears.map(({ value: year }) => {
      const yearDate = new Date(
        `${year}-${selectedCustomer.fiscalYearMonthStart || "01"}-01`
      );
      if (selectedTab === TabsEnum.PERIOD_REPORT) {
        return [
          {
            label: `Q1 ${year}`,
            value: addMonths(yearDate.setMonth(0), 0),
          },
          { label: `Q2 ${year}`, value: addMonths(yearDate.setMonth(0), 1) },
          { label: `Q3 ${year}`, value: addMonths(yearDate.setMonth(0), 2) },
          { label: `Q4 ${year}`, value: addMonths(yearDate.setMonth(0), 3) },
        ];
      }
      return [
        {
          label: `Q1 ${year}`,
          value: addMonths(yearDate, 3),
        },
        { label: `Q2 ${year}`, value: addMonths(yearDate, 6) },
        { label: `Q3 ${year}`, value: addMonths(yearDate, 9) },
        { label: `Q4 ${year}`, value: addMonths(yearDate, 12) },
      ];
    })
      .flatMap((year) => year)
      .sort((a, b) => (a.value > b.value ? -1 : 1));
  };

  const onModalDismiss = () => {
    setPDFComment("");
    setPDFBallanceComment("");
    setSelectedPeriodType("M");
    setSelectedPeriodDate(undefined);
    setSelectedYear(AvailableYears[1].value);
    selectTab(TabsEnum.ANNUAL_REPORT);

    onDismiss();
  };
  const renderGeneral = (
    <Col>
      <label>{translate("YEARS_END")} </label>
      <Dropdown
        variant="outline"
        style={{
          width: "50%",
        }}
        className="pt-xs"
        defaultValue={selectedYear}
        options={AvailableYears}
        onChange={(option) => setSelectedYear(option)}
      />
    </Col>
  );
  const periodOptions: DropdownOption<Period>[] = [
    { label: translate("MONTH"), value: "M" },
    {
      label: translate("QUARTER"),
      value: "Q",
    },
  ];
  const renderBudgetAndMonthly = (
    <div>
      <Col>
        <Row>
          <Col className="w-50">
            <label>{translate("PERIOD_TYPE")}</label>
            <Dropdown<Period>
              variant="outline"
              defaultValue="M"
              className="pt-xs"
              placeholder={translate("CHOSE_FROM_LIST")}
              options={
                selectedTab === TabsEnum.PERIOD_REPORT
                  ? periodOptions
                  : [
                      ...periodOptions,
                      {
                        label: translate("YEAR"),
                        value: "Y",
                      },
                    ]
              }
              value={selectedPeriodType}
              onChange={(option) => setSelectedPeriodType(option)}
            />
          </Col>

          <Col className="w-50 pl-md">
            <label>
              Period{" "}
              <ArrowClockwise20Regular
                onClick={() => setSelectedPeriodDate(undefined)}
                className="text-color-blue"
              />
            </label>
            {selectedPeriodType === "M" && (
              <div>
                <DatePickerV9
                  selectedDate={selectedPeriodDate ?? null}
                  placeholder={
                    !selectedPeriodDate
                      ? translate("SELECT_FROM_CALENDAR")
                      : formatter(selectedPeriodDate)
                  }
                  dateFormatter={dateFormatter}
                  onSelectDate={(date) =>
                    setSelectedPeriodDate(date ?? undefined)
                  }
                  calendarProps={{
                    isDayPickerVisible: false,
                    dateRangeType: DateRangeType.Month,
                  }}
                  borderless
                />
              </div>
            )}

            {selectedPeriodType === "Q" && (
              <Dropdown<Date>
                variant="outline"
                className="pt-xs"
                placeholder={translate("CHOSE_FROM_LIST")}
                options={getCustomerQuarters()}
                onChange={(option) => setSelectedPeriodDate(option)}
              />
            )}

            {selectedPeriodType === "Y" && (
              <Dropdown
                variant="outline"
                className="pt-xs"
                placeholder={translate("CHOSE_FROM_LIST")}
                options={AvailableYears}
                onChange={(option) =>
                  setSelectedPeriodDate(setYear(new Date(), option))
                }
              />
            )}
            {!selectedPeriodDate && (
              <div className="error-message">
                <span className="text-color-red">
                  <span>Please select a period date</span>
                </span>
              </div>
            )}
          </Col>
        </Row>
      </Col>

      <div className="py-sm">
        {selectedPeriodType === "Q" && !selectedCustomer?.fiscalYearMonthEnd && (
          <MessageBar intent="warning">
            <p className="m-0">{translate("NO_CUSTOMER_FINANCIAL_YEAR")}</p>
          </MessageBar>
        )}
      </div>

      {selectedCustomer?.fiscalYearMonthEnd !== "12" && (
        <p>
          {translate("NO_CALENDAR_YEAR")} (
          {selectedCustomer?.fiscalYearMonthEnd}-
          {selectedCustomer?.fiscalYearDayEnd}).
        </p>
      )}
    </div>
  );

  return (
    <Modal
      className="reports-modal"
      header={
        <ModalHeader
          headerTitleContent={
            <div className="contentHeaderTitle d-flex">
              <div className="contentTitleWrapper">
                <h3 className="contentHeader--title">
                  {translate("REPORTS_AND_TOOLS")}
                </h3>
              </div>
              <div>
                <ChevronRight20Filled />
              </div>
              <div className="sub-h1 pt-xs">
                {selectedCustomer && selectedCustomer.name}
              </div>
            </div>
          }
        />
      }
      isOpen={showReportsModal}
      onDismiss={() => onModalDismiss()}
    >
      <div className="d-flex align-items-center">
        <label className="pr-sm">{translate("REPORT")}:</label>
        <TabList
          className="p-0"
          selectedValue={selectedTab}
          onTabSelect={(_, item) => {
            if (item && item.value) {
              selectTab(item.value as TabsEnum);
              setPDFComment("");
            }
          }}
        >
          {Object.entries(TabsEnum).map(([key, value]) => (
            <Tab value={value} key={value}>
              {translate(key as TranslationKey)}
            </Tab>
          ))}
        </TabList>
      </div>
      <div className="pt-lg pb-sm">
        {(selectedTab === TabsEnum.BUDGET_FOLLOWUP ||
          selectedTab === TabsEnum.PERIOD_REPORT) &&
          renderBudgetAndMonthly}
        {selectedTab === TabsEnum.ANNUAL_REPORT && renderGeneral}
      </div>
      <div className="d-flex justify-content-between">
        <span>
          {translate(
            selectedTab === TabsEnum.ANNUAL_REPORT
              ? "COMMENT_REPORT"
              : "COMMENT"
          )}
        </span>
      </div>
      <TextArea
        rows={5}
        className="pdf-report"
        maxLength={MAX_COMMENT_LENGTH}
        value={pdfComment}
        onChange={(e) => setPDFComment(e.target.value)}
      />

      <div className="d-flex justify-content-end py-sm text-color-gray">
        {pdfComment.length} / {MAX_COMMENT_LENGTH} {translate("CHARACTERS")}
      </div>
      {selectedTab === TabsEnum.ANNUAL_REPORT && (
        <>
          <div className="d-flex justify-content-between">
            <span>{translate("BALANCE_NOTE")}</span>
          </div>
          <TextArea
            rows={5}
            className="pdf-report"
            maxLength={MAX_COMMENT_LENGTH}
            value={pdfBalanceNote}
            onChange={(e) => setPDFBallanceComment(e.target.value)}
          />

          <div className="d-flex justify-content-end py-sm text-color-gray">
            {pdfComment.length} / {MAX_COMMENT_LENGTH} {translate("CHARACTERS")}
          </div>
        </>
      )}
      <Button
        className="mt-2 text-center d-flex bg-white border-0 align-items-center justify-content-start cursor-pointer download-button"
        disabled={
          (selectedTab === TabsEnum.BUDGET_FOLLOWUP ||
            selectedTab === TabsEnum.PERIOD_REPORT) &&
          !selectedPeriodDate
        }
        onClick={() => {
          if (selectedTab === TabsEnum.ANNUAL_REPORT) {
            getGeneralReportURL();
          } else {
            downloadReport();
          }
        }}
      >
        <ArrowDownload20Regular className="blue-link" />
        <div className="text-color-blue px-2 fw-semibold">
          {translate("DOWNLOAD_REPORT")}
        </div>
      </Button>
    </Modal>
  );
}
