import { rest } from "msw";
import { mockCheckToken, MOCK_DELAY } from "..";
import { CreateCustomer, Customer } from "../../../models/customer";
import { CustomerState } from "../../../state/customers/reducer";
import { initialCustomersWithData } from "./customers";

export type UpdateCustomer = Customer;

const KEY = "customers-key-mock";
const BASE_PATH = process.env.REACT_APP_API_URL;

export const customerHandlers = [
  rest.get<Customer[]>(
    `${BASE_PATH}/customers`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      const searchTerm = req.url.searchParams.get("search_term");
      if (searchTerm) {
        const customers: CustomerState[] = JSON.parse(
          localStorage.getItem(KEY) ??
            JSON.stringify(
              initialCustomersWithData.filter(
                (c) =>
                  c.isFromSearch &&
                  c.customer.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
              )
            )
        );

        return res(
          delay(MOCK_DELAY),
          status(200),
          json(customers.map((c) => c.customer))
        );
      }

      const customers: CustomerState[] = JSON.parse(
        localStorage.getItem(KEY) ??
          JSON.stringify(
            initialCustomersWithData.filter((c) => !c.isFromSearch)
          )
      );

      return res(
        delay(MOCK_DELAY),
        status(200),
        json(customers.map((c) => c.customer))
      );
    }
  ),
  rest.post<string>(
    `${BASE_PATH}/customers/`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      const newCustomer = JSON.parse(req.body) as CreateCustomer;

      if (newCustomer.FirstName === "CUSTOMER_ALREADY_EXISTS") {
        return res(
          delay(MOCK_DELAY),
          status(400),
          json({
            error: "CUSTOMER_ALREADY_EXISTS",
            error_args: ["999000"],
          })
        );
      }

      if (newCustomer.FirstName === "SALESPERSON_NOT_FOUND") {
        return res(
          delay(MOCK_DELAY),
          status(400),
          json({ error: "SALESPERSON_NOT_FOUND" })
        );
      }

      if (newCustomer.FirstName === "BACKOFFICE_ERROR") {
        return res(
          delay(MOCK_DELAY),
          status(400),
          json({ error: "BACKOFFICE_ERROR" })
        );
      }

      if (newCustomer.FirstName === "COMPANY_INFO_NOT_FOUND") {
        return res(
          delay(MOCK_DELAY),
          status(400),
          json({ error: "COMPANY_INFO_NOT_FOUND" })
        );
      }

      if (newCustomer.FirstName === "LAMBDA_ERROR") {
        return res(delay(MOCK_DELAY), status(400), text("Lamda handler error"));
      }

      return res(delay(MOCK_DELAY), status(200), text("OK"));
    }
  ),
  rest.post<string>(
    `${BASE_PATH}/customers/:customerId/ludvig_service_feedback`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      return res(delay(MOCK_DELAY), status(200), text("OK"));
    }
  ),
  rest.patch(
    `${BASE_PATH}/customers/:customerId`,
    (req, res, { status, text, delay }) => {
      const customerId = req.params.customerId;
      if (customerId === "999000ERROR") {
        return res(delay(MOCK_DELAY), status(400), text("Lambda error"));
      }

      return res(delay(MOCK_DELAY), status(200), text("OK"));
    }
  ),
  rest.patch(
    `${BASE_PATH}/customers/:customerId/settings`,
    (req, res, { status, text, delay }) => {
      const customerId = req.params.customerId;
      if (customerId === "999000ERROR") {
        return res(delay(MOCK_DELAY), status(400), text("Lambda error"));
      }

      return res(delay(MOCK_DELAY), status(200), text("OK"));
    }
  ),
  rest.post<string>(
    `${BASE_PATH}/customers/:customerId/team`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      const { customerId } = req.params;

      if (["999012"].includes(customerId)) {
        return res(
          delay(MOCK_DELAY),
          status(400),
          json({
            error: "MISSING_EMPLOYEE",
            error_args: ["123479", "585858"],
          })
        );
      }

      return res(delay(MOCK_DELAY), status(200), text("OK"));
    }
  ),
  rest.delete<string>(
    `${BASE_PATH}/customers/:customerId/activities`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      return res(delay(MOCK_DELAY), status(200), text("OK"));
    }
  ),
  rest.get<Customer>(
    `${BASE_PATH}/customers/:customerId`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      const { customerId } = req.params;
      const customers: CustomerState[] = JSON.parse(
        localStorage.getItem(KEY) ?? JSON.stringify(initialCustomersWithData)
      );

      const customerIndex = customers.findIndex(
        (t) => t.customer.customer_number === customerId
      );

      if (customerIndex === -1) {
        return res(
          status(403),
          json({
            errorMessage: "Not found",
          })
        );
      }

      return res(
        delay(MOCK_DELAY),
        status(200),
        json(customers[customerIndex].customer)
      );
    }
  ),
];
