import { useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";

import { useTranslation } from "hooks";
import { Deal } from "models/deals/deal";
import { Stage } from "models/deals/stage";
import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";
import { useUpdateDealProperty } from "views/deals/configuration/components/useUpdateDealProperty";
import { DealInformationRequiredForm } from "views/deals/components/DealInformationRequiredForm";

type DealInformationRequiredFormModalProps = {
  onFinished: () => void;
  onDismiss: () => void;
  deal?: Deal;
  toStage?: Stage;
  isOpen: boolean;
};

export function DealInformationRequiredFormModal({
  onFinished,
  onDismiss,
  deal,
  toStage,
  isOpen,
}: DealInformationRequiredFormModalProps) {
  const { handleFormSubmit, isLoading } = useUpdateDealProperty();
  const { translate } = useTranslation();

  const methods = useForm<Partial<Deal>>({
    mode: "onTouched",
    defaultValues: useMemo(() => {
      return deal;
    }, [deal]),
  });

  const {
    reset,
    formState: { isValid, dirtyFields },
    watch,
  } = methods;

  useEffect(() => {
    if (deal) {
      reset(deal);
    }
  }, [deal, reset]);

  if (!toStage) {
    return <></>;
  }

  if (!deal) {
    return <></>;
  }

  const stageConfiguration = toStage.configuration;
  if (!stageConfiguration) {
    throw new Error(`Could not find stage configuration`);
  }

  const disableSave = !isValid;
  const formValues = watch();
  const formDirtyFields = Object.keys(dirtyFields);

  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const requiredFields = toStage.configuration?.RequiredFields?.split(";");
    handleFormSubmit(formValues, requiredFields ?? formDirtyFields, deal);
    if (toStage.configuration && stageConfiguration.onMove) {
      stageConfiguration.onMove(deal);
    }
    onFinished();
  };

  return (
    <Modal
      size="medium"
      header={
        <ModalHeader
          headerTitleText={translate("DEAL_REQUIRED_FIELDS_DIALOG.HEADER", [
            deal.dealname,
            toStage.label,
          ])}
          subheaderContent={
            <div
              className="pl-sm contentSubHeader fpx-14"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: translate("DEAL_REQUIRED_FIELDS_DIALOG.SUBHEADER", [
                  deal.dealname,
                ]),
              }}
            />
          }
        />
      }
      onDismiss={() => {
        onDismiss();
      }}
      isOpen={isOpen}
      footer={
        <ModalFooter
          labelSubmit="DEAL_REQUIRED_FIELDS_DIALOG.SUBMIT"
          isDisabled={isLoading || disableSave}
          onSave={(e) => handleOnClick(e)}
        />
      }
    >
      <DealInformationRequiredForm
        deal={deal}
        toStage={toStage}
        formMethods={methods}
      />
    </Modal>
  );
}
