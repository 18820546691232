import { useTranslation } from "hooks/use-translate";
import { numberFormatOrZero } from "libs/number-format";
import { Customer } from "models/customer";

type DetailsPerTaskTablePGUProps = {
  activePageObjects: Customer[];
};
export default function DetailsPerTaskTablePGU({
  activePageObjects,
}: DetailsPerTaskTablePGUProps) {
  const { translate } = useTranslation();

  return (
    <div className="py-md horizontal-scroll">
      <table>
        <thead>
          <tr className="full-width px-md align-items-center my-sm fw-bold no-border">
            <td>
              <span>{translate("FISCAL_YEAR")}</span>
            </td>
            <td>
              <span>{translate("TASK")}</span>
            </td>
            <td>
              <span>{translate("COMPLETED_BY")}</span>
            </td>
            <td>
              <span className="number-columns-alignment-right">
                {translate("NUM_REGISTERED_UNITS")}
              </span>
            </td>
            <td>
              <span className="number-columns-alignment-right">
                {translate("REGISTERED_PRICE")}
              </span>
            </td>
            <td>
              <span className="number-columns-alignment-right">
                {translate("OPEN_UNITS")}
              </span>
            </td>
            <td>
              <span className="number-columns-alignment-right">
                {translate("OPEN_SALES_PRICE")}
              </span>
            </td>

            <td>
              <span className="number-columns-alignment-right">
                {translate("UP_DOWN_UNITS")}
              </span>
            </td>
            <td>
              <span className="number-columns-alignment-right">
                {translate("UP_DOWN_ADJUSTMENTS")}
              </span>
            </td>
            <td>
              <span className="number-columns-alignment-right">
                {translate("INVOICED_UNITS")}
              </span>
            </td>
            <td>
              <span className="number-columns-alignment-right">
                {translate("INVOICED_SEK")}
              </span>
            </td>
          </tr>
        </thead>

        {activePageObjects &&
          activePageObjects.map((data: any, index) => {
            return (
              <tbody>
                <tr
                  className="full-width px-md align-items-center my-sm"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  {" "}
                  <td>
                    <span>{data.purposename}</span>
                  </td>
                  <td>
                    <span>{data.taskname}</span>
                  </td>
                  <td>
                    <span>{data.employeenumber}</span>
                  </td>
                  <td>
                    <span className="number-columns-alignment-right">
                      {numberFormatOrZero(
                        Number(data.numberregistered?.toFixed(2))
                      )}
                    </span>
                  </td>
                  <td>
                    <span className="number-columns-alignment-right">
                      {numberFormatOrZero(data.billingPriceReg)}
                    </span>
                  </td>
                  <td>
                    <span className="number-columns-alignment-right">
                      {numberFormatOrZero(data.numberOpen)}
                    </span>
                  </td>
                  <td>
                    <span className="number-columns-alignment-right">
                      {numberFormatOrZero(data.billingPriceOpen)}
                    </span>
                  </td>
                  <td>
                    <span className="number-columns-alignment-right">
                      {numberFormatOrZero(
                        Number(data.numberUpDown?.toFixed(2))
                      )}
                    </span>
                  </td>
                  <td>
                    <span className="number-columns-alignment-right">
                      {numberFormatOrZero(data.billingPriceUpDown)}
                    </span>
                  </td>
                  <td>
                    <span className="number-columns-alignment-right">
                      {numberFormatOrZero(
                        Number(data.numberInvoiced?.toFixed(2))
                      )}
                    </span>
                  </td>
                  <td>
                    <span className="number-columns-alignment-right">
                      {numberFormatOrZero(data.billingPriceInvoiced)}
                    </span>
                  </td>
                </tr>
              </tbody>
            );
          })}
      </table>
    </div>
  );
}
