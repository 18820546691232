export enum ActivitiesDateReducerActionEnum {
  IncrementMonth,
  DecrementMonth,
  ResetState,
}
export type SequentialType =
  | ActivitiesDateReducerActionEnum.IncrementMonth
  | ActivitiesDateReducerActionEnum.DecrementMonth;
type State = { month: number; year: number };
type Action =
  | { type: ActivitiesDateReducerActionEnum.IncrementMonth }
  | { type: ActivitiesDateReducerActionEnum.DecrementMonth }
  | { type: ActivitiesDateReducerActionEnum.ResetState; payload: State };

export const incrementMonth = (state: State) => {
  if (state.month === 12) {
    return {
      month: 1,
      year: state.year + 1,
    };
  }
  return {
    month: state.month + 1,
    year: state.year,
  };
};

export const decrementMonth = (state: State) => {
  if (state.month === 1) {
    return {
      month: 12,
      year: state.year - 1,
    };
  }
  return {
    month: state.month - 1,
    year: state.year,
  };
};

export const reducerDate = (state: State, action: Action) => {
  switch (action.type) {
    case ActivitiesDateReducerActionEnum.IncrementMonth:
      return incrementMonth(state);
    case ActivitiesDateReducerActionEnum.DecrementMonth:
      return decrementMonth(state);
    case ActivitiesDateReducerActionEnum.ResetState:
      return action.payload;

    default:
      throw new Error("Unknown action.");
  }
};
