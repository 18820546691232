import { ChevronLeft20Regular } from "@fluentui/react-icons";
import { Spinner } from "@fluentui/react-components";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { OffersAPI, PdfUploadDealData } from "api/offers";
import { authMethod } from "auth";
import { DropFileArea } from "components/dropFileArea/DropFileArea";
import { CompletedIcon } from "components/icon/CompletedIcon";
import { useTranslation } from "hooks";
import { SigningMethod } from "models/offer/DealContact";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import { SearchBy, searchForCustomer } from "state/offer/companyThunks";
import {
  fetchDeal,
  resetOffer,
  sendOfferForSigning,
} from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import { WizardSection } from "views/offer/components/wizardSection";
import DownloadPdfButton from "../components/downloadPdfButton";
export enum CompletionStatus {
  INITIAL,
  FILE_DOWNLOADED,
  FILE_UPLOADING,
  FAIL,
  SUCCESS,
}

export default function Completion() {
  const { data: currentOffer } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );

  const { currentUser } = useSelector((state: RootState) => state.users);

  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { offerId, orgId } = useParams<{ orgId: string; offerId: string }>();

  const [pdfUploadData, setpdfUploadData] = useState<PdfUploadDealData>();
  const [status, setStatus] = useState(CompletionStatus.INITIAL);

  const contact = currentOffer?.contacts.find((c) => c.is_signer);

  const fetchDealUploadUrl = useCallback(async () => {
    if (currentOffer?.id) {
      const token = await authMethod.getStoredAccessToken();
      const tempUploadData = await OffersAPI.fetchDealDealUploadUrl(
        token,
        currentOffer.id
      );

      return tempUploadData;
    }

    return undefined;
  }, [currentOffer?.id]);

  useEffect(() => {
    if (orgId && offerId && !currentOffer?.id) {
      dispatch(
        searchForCustomer({
          searchData: {
            customerId: orgId,
            searchByParam: SearchBy.OrgNumber,
          },
          checkCustomerBlocked: false,
        })
      );
      dispatch(fetchDeal({ dealId: offerId, orgId }));
    }
  }, [orgId, offerId, dispatch, currentOffer?.id]);

  useEffect(() => {
    const fetchAndSetDealUploadUrl = async () => {
      const tempUploadData = await fetchDealUploadUrl();
      setpdfUploadData(tempUploadData);
    };

    if (contact?.signing_method === SigningMethod.PHYSICAL) {
      fetchAndSetDealUploadUrl();
    }
  }, [fetchDealUploadUrl, currentOffer?.id, contact]);

  const handlePdfDownloaded = async () => {
    if (currentOffer?.id) {
      const tempUploadData = await fetchDealUploadUrl();
      setpdfUploadData(tempUploadData);

      setStatus(CompletionStatus.FILE_DOWNLOADED);
    }
  };

  const handleFileUploaded = async (file: File) => {
    try {
      if (currentOffer?.id && pdfUploadData) {
        setStatus(CompletionStatus.FILE_UPLOADING);
        const response = await OffersAPI.uploadDealDeal(pdfUploadData, file);

        if (response.ok) {
          const test = await dispatch(
            sendOfferForSigning({
              dealId: currentOffer.id,
              lcoUserEmail: currentUser.email,
            })
          );

          if (test.payload) {
            setStatus(CompletionStatus.SUCCESS);
          } else {
            setStatus(CompletionStatus.FAIL);
          }
        }
      }
    } catch (e) {
      setStatus(CompletionStatus.FAIL);
    }
  };

  const handleFileError = () => {
    setStatus(CompletionStatus.FAIL);
  };

  const reset = () => {
    setStatus(CompletionStatus.INITIAL);
    setpdfUploadData(undefined);
  };

  const showResetButton = () => (
    <Button onClick={reset}>
      {translate("PHYSICAL_CONFIRMATION_UPLOAD_AGAIN")}
    </Button>
  );

  const renderPhysicalSigningMethodSuccess = () => (
    <Col xs={12} className="mt-4">
      <Row>
        <Col className="d-flex justify-content-center">
          <CompletedIcon className="fem-7" />
        </Col>
      </Row>

      <Row className="text-center pt-5">
        <Col xs={12}>
          <Row>
            <Col xs={12} md={2} className="m-auto">
              <div className="horizontal-divider" />
            </Col>

            <Col xs={12} md={8}>
              <div className="fw-bold p-2">
                {translate("PHYSICAL_CONFIRMATION_FILE_UPLOADED")}
              </div>
            </Col>

            <Col xs={12} md={2} className="m-auto">
              <div className="horizontal-divider" />
            </Col>

            <Col xs={12} className="mt-5">
              {showResetButton()}
            </Col>
          </Row>

          <Row className="mt-4">
            <div
              onClick={() => {
                dispatch(resetOffer());
                navigate(AppRouteHelper.getOffers());
              }}
              className="pt-5"
            >
              <span className="d-flex justify-content-center cursor-pointer align-items-center">
                <ChevronLeft20Regular className="text-color-blue mr-xs" />
                <span> {translate("GO_TO_HOME_PAGE")}</span>
              </span>
            </div>
          </Row>
        </Col>
      </Row>
    </Col>
  );

  const renderPhysicalSigningMethodFailure = () => (
    <Col xs={12} className="mt-lg">
      <Row className="text-center pt-lg">
        <Col xs={12}>
          <Row>
            <Col xs={12} md={2} className="m-auto">
              <div className="horizontal-divider" />
            </Col>

            <Col xs={12} md={8}>
              <div className="fw-bold p-2">
                {translate("PHYSICAL_CONFIRMATION_FILE_ERROR")}
              </div>
            </Col>

            <Col xs={12} md={2} className="m-auto">
              <div className="horizontal-divider" />
            </Col>
          </Row>

          <Row className="mt-lg">
            <Col className="align-self-center">{showResetButton()}</Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );

  const renderPhysicalSigningMethod = () => {
    if (isLoading()) {
      return null;
    }

    if (status === CompletionStatus.FAIL) {
      return renderPhysicalSigningMethodFailure();
    }

    if (status === CompletionStatus.SUCCESS) {
      return renderPhysicalSigningMethodSuccess();
    }

    return (
      <Col xs={12}>
        <Row className="mt-lg">
          <Col xs={12} md={2} className="m-auto">
            <div className="horizontal-divider" />
          </Col>

          <Col xs={12} md={8}>
            <div className="fw-bold text-center p-sm">
              {translate("PHYSICAL_CONFIRMATION_TEXT_ROW_1")}
            </div>
          </Col>

          <Col xs={12} md={2} className="m-auto">
            <div className="horizontal-divider" />
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <DownloadPdfButton
              dealId={currentOffer?.id}
              type="link"
              onPdfDownloaded={handlePdfDownloaded}
            />
          </Col>
        </Row>

        <Row className="mt-lg">
          <Col className="d-flex justify-content-center">
            {status === CompletionStatus.FILE_UPLOADING ? (
              <Spinner />
            ) : (
              <DropFileArea
                onFileUploaded={handleFileUploaded}
                onError={handleFileError}
                disabled={!pdfUploadData}
              />
            )}
          </Col>
        </Row>

        <Row className="text-center pt-xl">
          <Col xs={12}>
            <Row className="mt-lg">
              <div
                onClick={() => {
                  dispatch(resetOffer());
                  navigate(AppRouteHelper.getOffers());
                }}
                className="pt-xl"
              >
                <span className="d-flex justify-content-center cursor-pointer align-items-center">
                  <ChevronLeft20Regular className="text-color-blue mr-xs" />
                  <span> {translate("GO_TO_HOME_PAGE")}</span>
                </span>
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  };

  const renderDigitalSigningMethod = () => {
    if (isLoading()) {
      return null;
    }

    return (
      <Col xs={12}>
        <Row>
          <Col className="d-flex justify-content-center">
            <CompletedIcon className="fem-7" />
          </Col>
        </Row>
        <Row className="pt-xl">
          <Col xs={12}>
            <Row>
              <Col xs={12} md={4} className="m-auto">
                <div className="horizontal-divider" />
              </Col>

              <Col xs={12} md={4}>
                <div className="fw-bold">
                  {translate("DIGITAL_CONFIRMATION_TEXT_PART_1")}
                  <span className="text-color-blue">
                    {contact?.contact.first_name} {contact?.contact.last_name}{" "}
                  </span>
                  {translate("DIGITAL_CONFIRMATION_TEXT_PART_2")}
                </div>

                <div className="mt-sm">
                  Så snart kunden har signerat avtalet kommer
                  onboardingprocessen att börja. Kunden kommer då automatiskt
                  att aktiveras i följande system:
                  <ul>
                    <li>Maconomy</li>
                    <li>Capego</li>
                    <li>Mina uppdrag</li>
                    <li>Mitt Kontor</li>
                  </ul>
                  När onboardingen är klar kommer ni att få ett bekräftelsemail
                  med instruktioner på om det är något av de här stegen som ni
                  behöver utföra manuellt.
                </div>

                <div className="text-grayed mt-sm pb-xl">
                  {translate("CONFIRMATION_INFO")}
                </div>
              </Col>

              <Col xs={12} md={4} className="m-auto">
                <div className="horizontal-divider" />
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col xs="auto">
                <DownloadPdfButton
                  dealId={currentOffer?.id}
                  type="link"
                  onPdfDownloaded={handlePdfDownloaded}
                />
              </Col>
            </Row>

            <Row>
              <div
                onClick={() => {
                  dispatch(resetOffer());
                  navigate(AppRouteHelper.getOffers());
                }}
                className="pt-xl"
              >
                <span className="d-flex justify-content-center cursor-pointer align-items-center">
                  <ChevronLeft20Regular className="text-color-blue mr-xs" />
                  <span>{translate("GO_TO_HOME_PAGE")}</span>
                </span>
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  };

  const isLoading = () => !currentOffer?.contacts || !contact;

  return (
    <WizardSection
      loadingStatus={isLoading() ? "pending" : "idle"}
      serviceName={translate("COMPLETION_TITLE")}
    >
      {contact?.signing_method === SigningMethod.PHYSICAL
        ? renderPhysicalSigningMethod()
        : renderDigitalSigningMethod()}
    </WizardSection>
  );
}
