import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowRight20Regular } from "@fluentui/react-icons";

import { useTranslation } from "hooks";
import { LoadingStatus, RootState } from "state";
import { Button } from "components/button";
import TooltipV9 from "components/tooltip/TooltipV9";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { getNextButtonText } from "views/offer/wizard/offerRoutes";
import { useRoutingForOffer } from "./useRoutingForOffer";

type Props = {
  isRoutingButton?: boolean;
  predefinedNextRoute?: string;
  nextButtonText?: string;
  loadingStatus?: LoadingStatus;
  onNext?: () => void;
  onBeforeNext?: () => Promise<boolean>;
  isDisabled?: boolean;
};

export default function WizardForwardButton({
  isRoutingButton,
  predefinedNextRoute,
  nextButtonText,
  loadingStatus,
  onNext,
  onBeforeNext,
  isDisabled = false,
}: Props) {
  const { ts } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentOffer = useSelector(
    (state: RootState) => state.offers.currentOffer.data
  );
  const routing = useRoutingForOffer(location.pathname, currentOffer);
  const buttonText =
    nextButtonText ||
    ts(getNextButtonText(location.pathname, currentOffer, undefined));

  if (isRoutingButton && !routing.next && !predefinedNextRoute) {
    return null;
  }

  function goToNextRoute() {
    if (!predefinedNextRoute && routing.next?.path) {
      navigate(routing.next.path);
    }

    if (predefinedNextRoute) {
      navigate(predefinedNextRoute);
    }
  }

  const handleClick = async () => {
    if ((onBeforeNext && !(await onBeforeNext())) || !routing.next) {
      return;
    }
    onNext?.();
    if (isRoutingButton) {
      goToNextRoute();
    }
  };

  return (
    <TooltipV9
      content="FORWARD_BUTTON_TOOLTIP"
      hasJSXContent
      enabled={!!isDisabled}
    >
      <Button
        disabled={loadingStatus === LoadingStatusEnum.PENDING || isDisabled}
        onClick={handleClick}
      >
        <span className="d-flex flex-row align-items-center">
          <span className="px-sm">{buttonText}</span>
          <ArrowRight20Regular className="mx-xs" />
        </span>
      </Button>
    </TooltipV9>
  );
}
