import {
  AttachArrowRight20Regular,
  Edit16Regular,
} from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";

import { Button } from "components/button";
import { DropdownV9 } from "components/dropdown/DropdownV9";
import { Persona } from "components/people";
import TooltipV9 from "components/tooltip/TooltipV9";
import { PowerOfAttorney } from "models/customerSettings/customerSettingsPowerofAttorney";
import { ViewType } from "models/mgt";

const EMPTY = "N/A";
const ACTIVE = "Active";
type CustomerPagePowerOfAttorneyRowProps = {
  powerofattorney: PowerOfAttorney;
  statusOptions: { value: number; text: string }[];
  value?: number;
  onStatusChange: (statusId: number) => void;
  onCommentClick: () => void;
  isDetailedPage: boolean;
  onEditClick: () => void;
};
export default function CustomerPagePowerOfAttorneyRow({
  powerofattorney,
  statusOptions,
  value,
  onStatusChange,
  onCommentClick,
  isDetailedPage = false,
  onEditClick,
}: CustomerPagePowerOfAttorneyRowProps) {
  const invalidData =
    powerofattorney.powerofattorneysource.title === EMPTY ||
    powerofattorney.powerofattorneytype.title === EMPTY;
  const statusOptionsLimited = invalidData
    ? statusOptions.filter((e) => e.text !== ACTIVE)
    : statusOptions;
  const bgColor =
    !statusOptionsLimited.find((e) => e.value === powerofattorney.status.id) ||
    !powerofattorney.status.fe_visible
      ? "row-muted"
      : "";
  return (
    <Row className={`${bgColor} pt-2 pb-2`}>
      <Col className="align-items-center d-flex" md={2}>
        {powerofattorney.powerofattorneysource.title}
      </Col>
      <Col className=" ps-3 align-items-center d-flex" md={2}>
        {powerofattorney.powerofattorneytype.title}
      </Col>
      <Col
        className="ps-lg align-items-center d-flex"
        md={3}
        onClick={() => {
          onCommentClick();
        }}
      >
        {powerofattorney.comment && (
          <TooltipV9 content={powerofattorney.comment} notTranslatable>
            <span>{`${powerofattorney.comment.slice(0, 27)}...`}</span>
          </TooltipV9>
        )}
      </Col>
      <Col
        className="ps-lg d-flex align-items-center d-flex justify-content-between"
        md={2}
      >
        <Persona
          userId={powerofattorney.employee_id}
          view={ViewType.oneline}
          className="d-inline-block me-3"
          fallbackDetails={{ displayName: powerofattorney.employee_name }}
        />
      </Col>
      <Col className="ps-lg d-flex align-items-center" md={1}>
        {powerofattorney.path && (
          <a
            href={powerofattorney.path}
            rel="noreferrer"
            target="_blank"
            className="justify-content-start primary-colored-text"
          >
            <AttachArrowRight20Regular />
          </a>
        )}
      </Col>
      <Col className="ps-lg d-flex align-items-center" md={1}>
        <DropdownV9
          className={`p-0 ${bgColor}`}
          placeholder="Pending"
          onOptionSelect={(_, { optionValue }) => {
            if (optionValue) {
              onStatusChange(parseInt(optionValue, 10));
            }
          }}
          options={statusOptionsLimited}
          selectedOptions={[powerofattorney.status.id.toString()]}
        />
      </Col>
      <Col
        md={1}
        className="ps-4 d-flex align-items-center justify-content-end"
      >
        <Button variant="outline-outline" onClick={onEditClick}>
          <Edit16Regular />
        </Button>
      </Col>
    </Row>
  );
}
