import { createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "..";
import { ServiceArea } from "../../models/offer/ServiceLine";
import { fetchServiceAreaCaller } from "./serviceAreaThunk";

export interface ServiceAreaState {
  status: LoadingStatus;
  data: ServiceArea[];
}

const initialState: ServiceAreaState = {
  status: "idle",
  data: [],
};

export const serviceAreaSlice = createSlice({
  name: "serviceArea",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchServiceAreaCaller.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(fetchServiceAreaCaller.fulfilled, (state, { payload }) => {
      state.status = "succeeded";
      state.data = payload;
    });
    builder.addCase(fetchServiceAreaCaller.rejected, (state, { payload }) => {
      state.status = "failed";
    });
  },
});

export default serviceAreaSlice.reducer;
