import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { Service } from "models/offer/ServiceLine";
import { Input } from "components/input";
import { Dropdown } from "components/dropdown";
import { DropdownV9 } from "components/dropdown/DropdownV9";
import { useTranslation } from "hooks/use-translate";
import {
  TOGGLE_INT,
  TOGGLE_MULTI_SELECT,
  TOGGLE_SINGLE_SELECT,
  TOGGLE_STRING,
} from ".";
import { prependWarningIcon } from "./ServiceRow";

interface ServiceRowValueFieldProps {
  service: Service;
  serviceIsToggled: boolean;
  updateService: (key: string, value: string) => void;
  validateNumericInput: (value: string, service: Service) => boolean;
  validateTextInput: (value: string, service: Service) => boolean;
  isInputFocused: boolean;
  onInputBlur: () => void;
}

export default function ServiceRowValueField({
  service,
  serviceIsToggled,
  updateService,
  validateNumericInput,
  validateTextInput,
  isInputFocused,
  onInputBlur,
}: ServiceRowValueFieldProps) {
  const { translate } = useTranslation();

  const {
    register,
    formState: { errors },
    setValue,
    reset,
    trigger,
  } = useForm<Service>({
    mode: "all",
  });

  useEffect(() => {
    if (serviceIsToggled) {
      setValue("input_form_type_value", service.input_form_type_value, {
        shouldValidate: true,
      });
    }
  }, [serviceIsToggled, service.input_form_type_value, setValue]);

  useEffect(() => {
    if (serviceIsToggled) {
      trigger();
    } else {
      reset();
    }
  }, [serviceIsToggled, reset, trigger]);

  let min: number | undefined;
  let max: number | undefined;

  if (
    service.input_form_type === TOGGLE_INT &&
    service.input_form_type_content
  ) {
    const splitContent = service.input_form_type_content.split(":");

    /**
     * If the received content is not in the expected format of "number:number",
     * then we don't know how to handle it.
     */
    if (splitContent.length === 2) {
      min = parseInt(splitContent[0], 10);
      max = parseInt(splitContent[1], 10);
    }
  }

  const generateNumericInputInfo = () => {
    const placeholder = translate("ENTER_NUMBER");

    if (!min && max) {
      return `${placeholder} ${translate("LESSER_OR_EQUAL_TO", [
        max.toString(),
      ])}`;
    }
    if (min && !max) {
      return `${placeholder} ${translate("GREATER_OR_EQUAL_TO", [
        min.toString(),
      ])}`;
    }
    if (min && max) {
      return `${placeholder} ${translate("BETWEEN_AND", [
        min.toString(),
        max.toString(),
      ])}`;
    }

    return placeholder;
  };

  const updateServiceValue = (value: string) => {
    setValue("input_form_type_value", value, {
      shouldValidate: true,
    });

    updateService("input_form_type_value", value);
  };

  const handleOnInputChange = (
    e: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = typeof e === "string" ? e : e.target.value;
    updateServiceValue(val);
  };

  const handleOnMultiselectChange = (
    isSelected: boolean,
    optionValue?: string
  ) => {
    if (!optionValue) {
      return;
    }

    const currentValues = service.input_form_type_value.split(";");

    let newValues = "";

    if (isSelected) {
      newValues = [...currentValues, optionValue as string]
        .filter((value) => value)
        .join(";");
    } else {
      newValues = currentValues
        .filter((value) => value && value !== optionValue)
        .join(";");
    }

    updateServiceValue(newValues);
  };

  switch (service.input_form_type) {
    case TOGGLE_INT:
      return (
        <Input
          key={`${service.service_matrix_id}${serviceIsToggled}Int`}
          type="number"
          placeholder={generateNumericInputInfo()}
          isFocused={isInputFocused}
          onBlur={onInputBlur}
          disabled={!serviceIsToggled}
          min={min || undefined}
          max={max || undefined}
          value={service.input_form_type_value}
          errorMessage={errors.input_form_type_value?.message}
          formRegister={register("input_form_type_value", {
            required: prependWarningIcon(translate("FORM_FIELD_REQUIRED")),
            validate: (value) =>
              validateNumericInput(value, service) ||
              prependWarningIcon(generateNumericInputInfo()),
          })}
          onChange={handleOnInputChange}
        />
      );

    case TOGGLE_STRING:
      return (
        <Input
          key={`${service.service_matrix_id}${serviceIsToggled}String`}
          type="text"
          placeholder={translate("ENTER_ALPHANUMERIC_CHARACTERS")}
          isFocused={isInputFocused}
          onBlur={onInputBlur}
          disabled={!serviceIsToggled}
          value={service.input_form_type_value}
          errorMessage={errors.input_form_type_value?.message}
          formRegister={register("input_form_type_value", {
            required: prependWarningIcon(translate("FORM_FIELD_REQUIRED")),
            validate: (value) =>
              validateTextInput(value, service) ||
              prependWarningIcon(translate("ENTER_ALPHANUMERIC_CHARACTERS")),
          })}
          onChange={handleOnInputChange}
        />
      );

    case TOGGLE_SINGLE_SELECT:
      return (
        <Dropdown
          key={`${service.service_matrix_id}${serviceIsToggled}SingleSelect`}
          className="px-0 mx-0"
          placeholder={translate("DROPDOWN_PLACEHOLDER")}
          isFocused={isInputFocused}
          onBlur={onInputBlur}
          disabled={!serviceIsToggled}
          value={service.input_form_type_value}
          options={
            service.input_form_type_content
              ? service.input_form_type_content
                  .split(";")
                  .filter((value) => value)
                  .map((value) => {
                    return {
                      value,
                      label: value,
                    };
                  })
              : []
          }
          onChange={(e) => handleOnInputChange(e)}
          errorMessage={errors.input_form_type_value?.message}
          formRegister={register("input_form_type_value", {
            required: prependWarningIcon(translate("FORM_FIELD_REQUIRED")),
          })}
        />
      );

    case TOGGLE_MULTI_SELECT:
      return (
        <DropdownV9
          key={`${service.service_matrix_id}${serviceIsToggled}MultiSelect`}
          placeholder={translate("DROPDOWN_PLACEHOLDER")}
          autoFocus={isInputFocused}
          onBlur={onInputBlur}
          disabled={!serviceIsToggled}
          selectedOptions={service.input_form_type_value?.split(";")}
          options={
            service.input_form_type_content
              ? service.input_form_type_content
                  .split(";")
                  .filter((value) => value)
                  .map((value) => {
                    return {
                      text: value,
                      value,
                    };
                  })
              : []
          }
          onOptionSelect={(_, { optionValue }, isSelected) =>
            handleOnMultiselectChange(isSelected, optionValue)
          }
          errorMessage={errors.input_form_type_value?.message}
          formRegister={register("input_form_type_value", {
            required: prependWarningIcon(translate("FORM_FIELD_REQUIRED")),
          })}
        />
      );

    default:
      return null;
  }
}
