import { Settings24Regular } from "@fluentui/react-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import { isUserTeamOwner } from "libs/customer-settings-helpers";
import { Customer } from "models/customer";
import { CompanyInformation } from "models/offer/Company";
import { AppRouteHelper } from "routes";
import { RootState } from "state";

type CustomerSettingsCompanyInfoProps = {
  companyInfo: CompanyInformation;
  customer: Customer;
};

export default function CustomerSettingsCompanyInfo({
  companyInfo,
  customer,
}: CustomerSettingsCompanyInfoProps) {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const { currentUser } = useSelector((state: RootState) => state.users);

  return (
    <div className="p-lg cs-content">
      <h2>
        {companyInfo?.name}{" "}
        {isUserTeamOwner(customer, currentUser) && (
          <TooltipV9 content="OPEN_CUSTOMER_SETTINGS">
            <Settings24Regular
              onClick={() =>
                navigate({
                  pathname: AppRouteHelper.getCustomerSettings(
                    companyInfo.customer_number
                  ),
                })
              }
            />
          </TooltipV9>
        )}
      </h2>
      <div className="pt-md d-flex">
        <div className="info-label">
          {translate("ORG_NUM")}
          {": "}
          <span className="fw-semibold"> {companyInfo.org_number} </span>
        </div>
        <div className="vertical-divider ml-lg mr-md" />

        <div className="info-label">
          {translate("CUSTOMER_NUM")}
          {": "}
          <span className="fw-semibold"> {companyInfo?.customer_number} </span>
        </div>
        <div className="vertical-divider ml-lg mr-md" />

        <div className="info-label">
          {translate("ADDRESS")}
          {": "}
          <span className="fw-semibold"> {companyInfo?.address} </span>
        </div>
        <div className="vertical-divider ml-lg mr-md" />

        <div className="info-label">
          {translate("ZIP")}
          {": "}
          <span className="fw-semibold"> {companyInfo?.zip} </span>
        </div>
        <div className="vertical-divider ml-lg mr-md" />

        <div className="info-label">
          {translate("CITY")}
          {": "}
          <span className="fw-semibold"> {companyInfo?.city} </span>
        </div>
      </div>
    </div>
  );
}
