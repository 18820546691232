import { Add20Regular } from "@fluentui/react-icons";

import { renderIcon } from "libs/render-icon";

export function TextButton({
  children,
  onClick,
  icon = <Add20Regular />,
}: {
  children: any;
  onClick: () => void;
  icon?: JSX.Element;
}) {
  return (
    <span
      onClick={onClick}
      className="text-color-blue cursor-pointer d-flex align-items-center"
    >
      {icon && renderIcon(icon)}
      <span className="ml-xs">{children}</span>
    </span>
  );
}
