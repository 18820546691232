import { Accordion, AccordionProps } from "react-bootstrap";

export interface FoldableItem {
  title: React.ReactNode;
  content: React.ReactNode;
  key?: string;
}

type IAccordion = {
  foldableItems: FoldableItem[];
} & AccordionProps;

export function AccordionComp({ foldableItems, ...rest }: IAccordion) {
  return (
    <Accordion {...rest}>
      {foldableItems.map((item, index) => (
        <Accordion.Item
          eventKey={item.key || `accordionItem-${item.title}`}
          key={item.key || `accordion-${item.title}`}
        >
          <Accordion.Header className="accordion-head-box">
            {item.title}
          </Accordion.Header>
          <Accordion.Body>{item.content}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}
