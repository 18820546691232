import {
  DrawerHeader as Header,
  DrawerHeaderTitle,
} from "@fluentui/react-components";
import { Dismiss16Regular } from "@fluentui/react-icons";
import { isValidElement } from "react";

import { useTranslation } from "hooks/use-translate";
import { isTranslationKey, TranslationKey } from "i18n";
import { Button } from "components/button";

type DrawerHeaderProps = {
  onDismiss: () => void;
  header: JSX.Element | string | TranslationKey;
  notTranslatable?: boolean;
  hasJSXContent?: boolean;
};

export default function DrawerHeader({
  onDismiss,
  header,
  notTranslatable,
  hasJSXContent,
}: DrawerHeaderProps) {
  const { ts } = useTranslation();
  let headerText: string | JSX.Element;

  if (
    notTranslatable &&
    typeof header !== "string" &&
    !isValidElement(header)
  ) {
    throw new Error("Header must be a string if not translatable");
  } else if (
    !notTranslatable &&
    typeof header === "string" &&
    !isTranslationKey(header)
  ) {
    throw new Error("Header must be a translation key if translatable");
  }

  if (isValidElement(header)) {
    headerText = header;
  } else {
    headerText = notTranslatable ? header : ts(header as TranslationKey);
  }

  const headerContent =
    hasJSXContent && isValidElement(headerText) ? (
      headerText
    ) : (
      <div className="header-title">
        <h3 className="pl-sm">{headerText}</h3>
      </div>
    );

  return (
    <Header>
      <DrawerHeaderTitle
        action={
          <Button
            variant="outline"
            size="sm"
            onClick={onDismiss}
            aria-label="Close"
          >
            <Dismiss16Regular />
          </Button>
        }
      >
        {headerContent}
      </DrawerHeaderTitle>
    </Header>
  );
}
