import { Step } from "../../../components/stepper";
import { useTranslation } from "../../../hooks/use-translate";
import { StepDefinition } from "../../../models/WIzardSteps";
import { BusinessOpportunityWizardRouteHelper } from "./BusinessOpportunityWizardRoutes";

/**
 * Generate the steps for the wizard, based on the contents of the customer offer.
 * @param currentOffer The customer offer to use for generating the steps.
 * @returns The steps for the wizard.
 */
export function getAvailableWizardStepsForCreateBusinessOpportunity(
  isNewCustomer = false
): StepDefinition[] {
  const customerSearchStep: StepDefinition = {
    labelKey: "OFFER_CUSTOMER_SEARCH",
    steps: [
      {
        path: BusinessOpportunityWizardRouteHelper.getCustomerSearchRoute(),
        nextRoute:
          BusinessOpportunityWizardRouteHelper.getCustomerDetailsRoute(),
        previousRoute: undefined,
        labelKey: "OFFER_CUSTOMER_SEARCH",
        visible: false,
        validate: () => true,
        disabled: true,
      },
    ],
  };

  const customerDetailsStep = isNewCustomer
    ? {
        path: BusinessOpportunityWizardRouteHelper.getNewCustomerDetailsRoute(),
        nextRoute:
          BusinessOpportunityWizardRouteHelper.getCustomerContactsRoute(),
        previousRoute:
          BusinessOpportunityWizardRouteHelper.getCustomerSearchRoute(),
        labelKey: "CUSTOMER_TYPE",
        visible: true,
        validate: () => true,
      }
    : {
        path: BusinessOpportunityWizardRouteHelper.getCustomerDetailsRoute(),
        nextRoute:
          BusinessOpportunityWizardRouteHelper.getCustomerContactsRoute(),
        previousRoute:
          BusinessOpportunityWizardRouteHelper.getCustomerSearchRoute(),
        labelKey: "CUSTOMER_DETAILS",
        visible: true,
        validate: () => true,
      };

  const customerInfoStep: StepDefinition = {
    labelKey: "OFFER_CUSTOMERINFO",
    steps: [
      customerDetailsStep,
      {
        path: BusinessOpportunityWizardRouteHelper.getCustomerContactsRoute(),
        nextRoute:
          BusinessOpportunityWizardRouteHelper.getBusinessOpportunityBusinessAreaRoute(),
        previousRoute:
          BusinessOpportunityWizardRouteHelper.getCustomerDetailsRoute(),
        labelKey: "CUSTOMER_CONTACTS",
        visible: true,
        validate: () => true,
      },
    ],
  };

  const businessDetailsStep: StepDefinition = {
    labelKey: "BUSINESS_OPPORTUNITY",
    steps: [
      {
        path: BusinessOpportunityWizardRouteHelper.getBusinessOpportunityBusinessAreaRoute(),
        nextRoute:
          BusinessOpportunityWizardRouteHelper.getBusinessOpportunityBusinessDetailsRoute(),
        previousRoute:
          BusinessOpportunityWizardRouteHelper.getCustomerContactsRoute(),
        labelKey: "BUSINESS_AREA",
        visible: true,
        validate: () => true,
      },
      {
        path: BusinessOpportunityWizardRouteHelper.getBusinessOpportunityBusinessDetailsRoute(),
        nextRoute:
          BusinessOpportunityWizardRouteHelper.getBusinessOpportunityBusinessAreaRoute(),
        previousRoute:
          BusinessOpportunityWizardRouteHelper.getBusinessOpportunityCompletionRoute(),
        labelKey: "DEAL_BUSINESS_DETAILS",
        visible: true,
        validate: () => true,
      },
    ],
  };

  const completionStep: StepDefinition = {
    labelKey: "COMPLETION_TITLE",
    steps: [
      {
        path: BusinessOpportunityWizardRouteHelper.getBusinessOpportunityCompletionRoute(),
        nextRoute: undefined,
        previousRoute: undefined,
        labelKey: "COMPLETION_TITLE",
        visible: false,
        validate: () => true,
      },
    ],
  };

  const routes = [
    customerSearchStep,
    customerInfoStep,
    businessDetailsStep,
    completionStep,
  ];
  return routes;
}

/**
 * Generate the routes for the wizard.
 * @param currentLocationPath The current location.
 * @returns The routes for the wizard.
 */
export function useRoutingForCreateBusinessOpportunity(
  currentLocationPath: string,
  isNewCustomer = false
) {
  const availableSteps =
    getAvailableWizardStepsForCreateBusinessOpportunity(isNewCustomer);
  const stepsWithState = availableSteps
    .flatMap((a) => a.steps)
    .map((s) => ({ ...s, valid: s.validate() }));

  // strip the current path from the url params if there are any
  const currentPathParamsIndex = currentLocationPath.search(/\/[0-9]/);
  const currentBasePath =
    currentPathParamsIndex > 0
      ? currentLocationPath.slice(0, currentPathParamsIndex)
      : currentLocationPath;

  const isQuickEdit = currentLocationPath.includes("/edit");
  const activeStepIndex = stepsWithState.findIndex((s) =>
    isQuickEdit ? `${s.path}/edit` : s.path === currentBasePath
  );

  const nextStep = () => {
    if (!stepsWithState?.[activeStepIndex]?.nextRoute) {
      return;
    }

    return isQuickEdit
      ? stepsWithState[stepsWithState.length - 1]
      : stepsWithState[activeStepIndex + 1];
  };

  const previousStep = () => {
    if (!stepsWithState?.[activeStepIndex]?.previousRoute) {
      return;
    }

    return stepsWithState[activeStepIndex - 1];
  };

  return {
    previous: previousStep(),
    next: nextStep(),
    current: stepsWithState[activeStepIndex],
    steps: stepsWithState,
  };
}

/**
 * Generate stepper items for the wizard,based on the current location.
 * @param currentLocationPath The current location.
 * @returns The stepper items for the wizard.
 */
export function useStepperItemsForCreateBusinessOpportunity(
  currentLocationPath: string,
  availableSteps: StepDefinition[]
): Step[] {
  const { ts } = useTranslation();

  // strip the current path from the url params if there are any
  const currentPathParamsIndex = currentLocationPath.search(/\/[0-9]/);
  const currentBasePath =
    currentPathParamsIndex > 0
      ? currentLocationPath.slice(0, currentPathParamsIndex)
      : currentLocationPath;

  const currentActiveIndex = availableSteps.findIndex((step) =>
    step.steps.some(
      (ss) =>
        ss.path === currentBasePath || `${ss.path}/edit` === currentBasePath
    )
  );

  if (currentActiveIndex === -1) {
    return [];
  }

  return availableSteps.map((s, index) => {
    const passed = currentActiveIndex > index;
    const activeChildIndex = s.steps.findIndex(
      (subS) =>
        subS.path === currentBasePath || `${subS.path}/edit` === currentBasePath
    );

    return {
      key: index.toString(),
      label: ts(s.labelKey),
      subSteps: s.steps.map(
        (ss, subIndex) =>
          ({
            ...ss,
            subSteps: [],
            key: ss.labelKey,
            label: ss.labelKey ? ts(ss.labelKey) : "",
            state: {
              visible: ss.visible,
              passed: passed || activeChildIndex >= subIndex,
              valid: ss.validate(),
              active: activeChildIndex === subIndex,
              disabled: ss.disabled,
            },
          } as Step)
      ),
      state: {
        active: currentActiveIndex === index,
        disabled: s.disabled,
        valid: s.steps.every((ss) => ss.validate()),
        passed,
        visible: true,
      },
    } as Step;
  });
}

export const sortedBusinessOpportunityWizardSteps = (stepperSteps: Step[]) => {
  const sortedSteps = stepperSteps
    .filter((step) => !step.state?.disabled)
    .map((step) => {
      if (step.subSteps) {
        const tempStep = {
          ...step,
          subSteps: step.subSteps.filter((subStep) => !subStep.state?.disabled),
        };

        return tempStep;
      }

      return step;
    })
    .sort((a, b) => a.key.localeCompare(b.key));
  return sortedSteps;
};
