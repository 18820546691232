import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { DropdownV9 } from "components/dropdown/DropdownV9";
import { useTranslation } from "hooks/use-translate";
import { ServiceLine } from "models/customerSettings/customerSettingsServiceLine";
import { RootState } from "state";
import { setCurrentServiceLine } from "state/customerSettings/customerSettingsSlice";
import {
  fetchDetailedInfoCallerWrapper,
  fetchPowerOfAttorneyCallerWrapper,
  fetchServiceNotesCallerWrapper,
} from "state/customerSettings/customerSettingsThunk";
import { AppRouteHelper } from "routes";
import "./index.scss";

export default function CustomerPageServiceLine() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { language, translate } = useTranslation();

  const serviceLineOptions = useSelector(
    (state: RootState) => state.settings.serviceLines.data
  );
  const currServiceLine = useSelector(
    (state: RootState) => state.settings.serviceLines.currentServiceLine
  );
  const customer = useSelector(
    (state: RootState) => state.settings.customer.data
  );
  const setNewServiceLine = (serviceLine?: ServiceLine) => {
    dispatch(setCurrentServiceLine(serviceLine));
  };

  const serviceLineMappedOptions = [
    { text: "All", value: "-1" },
    ...serviceLineOptions.map((serviceLine) => {
      return {
        text:
          language && language === "EN"
            ? serviceLine.serviceline
            : serviceLine.serviceline_se,
        value: serviceLine.id.toString(),
      };
    }),
  ];

  useEffect(() => {
    if (
      customer.customer_number &&
      location.pathname !==
        AppRouteHelper.getCustomerSettings(customer.customer_number)
    ) {
      dispatch(
        fetchDetailedInfoCallerWrapper({
          customerId: customer.customer_number,
          ...(currServiceLine && { serviceLineId: currServiceLine.id }),
        })
      );

      dispatch(
        fetchServiceNotesCallerWrapper({
          customerId: customer.customer_number,
          ...(currServiceLine && { serviceLineId: currServiceLine.id }),
        })
      );

      dispatch(
        fetchPowerOfAttorneyCallerWrapper({
          customerId: customer.customer_number,
          ...(currServiceLine && { serviceLineId: currServiceLine.id }),
        })
      );
    }
  }, [currServiceLine, customer.customer_number, dispatch, location.pathname]);

  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Col>
        <DropdownV9
          placeholder={translate("SELECT_SERVICE_LINE")}
          selectedOptions={[currServiceLine?.id.toString() || "-1"]}
          dropdownClassName="service-line-dropdown"
          options={serviceLineMappedOptions}
          onOptionSelect={(_, { optionValue }) => {
            const selectedServiceLine = serviceLineOptions.find(
              (service) => service.id.toString() === optionValue
            );
            setNewServiceLine(selectedServiceLine);
          }}
        />
      </Col>
    </Row>
  );
}
