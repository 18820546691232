import { MouseEvent } from "react";
import { Spinner } from "@fluentui/react-components";

import "./ElementLoadingOverlay.scss";

// to work properly, parent element needs to be of relative or sticky
// position and preferably not to be of 0 height
export default function ElementLoadingOverlay() {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div className="loading-overlay-container" onClick={handleClick}>
      <div className="loading-overlay" />
      <div className="loading-overlay-spinner">
        <Spinner />
      </div>
    </div>
  );
}
