import { useEffect, useRef } from "react";
import {
  ControllerRenderProps,
  UseFormRegisterReturn,
  UseFormTrigger,
} from "react-hook-form";
import Select, {
  ActionMeta,
  SelectInstance,
  SingleValue,
  StylesConfig,
} from "react-select";
import { ChevronDown16Regular, Dismiss16Regular } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";

export type DropdownOption<T> = {
  value: T;
  label: React.ReactNode;
  isDisabled?: boolean;
};

function outlineStyle<T>(
  disabled: boolean,
  invalid?: boolean,
  onlyListItems?: boolean
): StylesConfig<T> {
  return {
    placeholder: (base: any) => ({
      ...base,
      fontSize: "1em",
      fontStyle: "italic",
    }),
    control: (base: any) =>
      !onlyListItems
        ? {
            ...base,
            cursor: "pointer",
            border: disabled
              ? "1px solid #eee"
              : `1px solid ${
                  invalid ? "var(--redLight)" : "var(--border-color)"
                }`,
            borderRadius: "2px",
            boxShadow: "none",
            minHeight: 0,
            "&:hover": {
              border: `1px solid ${
                invalid ? "var(--redLight)" : "var(--gray-160)"
              }`,
            },
          }
        : {
            ...base,
            border: "none",
            display: "none",
            width: 0,
          },
    menu: (base: any) => ({
      ...base,
      marginTop: 0,
      borderRadius: 0,
      zIndex: 9999,
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: "0.325em 0.5em",
    }),
    input: (base: any) => ({
      ...base,
      margin: "0",
      padding: "0",
    }),
    menuList: (base: any) => ({ ...base, padding: 0 }),
    option: (base: any, state: any) => ({
      ...base,
      padding: ".5em .5em",
      cursor: "pointer",
      backgroundColor: state.isSelected ? "var(--gray-40)" : "var(--white)",
      color: "var(--black)",
      "&:hover": {
        backgroundColor: state.isSelected ? "var(--gray-40)" : "var(--gray-20)",
      },
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      padding: "1px",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: "5px",
    }),
    singleValue: (base: any, state: any) => ({
      ...base,
      color: state.isDisabled ? "var(--input-disabled-color)" : base.color,
    }),
  };
}

const computeOptionColor = (isDisabled: boolean, isSelected: boolean) => {
  if (isDisabled) {
    return isSelected ? "var(--gray-10)" : "var(--gray-40)";
  }
  return "var(--black)";
};

function inlineStyle<T>(
  disabled: boolean,
  invalid?: boolean,
  onlyListItems?: boolean
): StylesConfig<T> {
  return {
    ...outlineStyle(disabled, invalid, onlyListItems),
    valueContainer: (base) => ({
      ...base,
      padding: 0,
    }),
    control: (base: any) => ({
      ...base,
      cursor: "pointer",
      border: "1px solid rgba(0,0,0,0)",
      borderRadius: "2px",
      boxShadow: "none",
      minHeight: 0,
      "&:hover": { border: "1px solid var(--gray-160)" },
    }),
    option: (_, state: any) => ({
      padding: ".5em .5em",
      cursor: "pointer",
      backgroundColor: state.isSelected ? "var(--gray-40)" : "var(--white)",
      color: computeOptionColor(state.isDisabled, state.isSelected),
      "&:hover": {
        backgroundColor: state.isSelected ? "var(--gray-40)" : "var(--gray-20)",
      },
    }),
  };
}

function defaultStyle<T>(
  disabled: boolean,
  invalid?: boolean,
  onlyListItems?: boolean
): StylesConfig<T> {
  return {
    ...outlineStyle(disabled, invalid, onlyListItems),
    valueContainer: (base) => ({
      ...base,
      padding: 0,
    }),
    control: (base: any) => ({
      ...base,
      cursor: "unset",
      border: "none",
      borderRadius: "2px",
      boxShadow: "none",
      minHeight: 0,
      backgroundColor: "none",
      "&:hover": { border: "none" },
    }),
    menu: (base: any) => ({
      ...base,
      marginTop: 0,
      borderRadius: 0,
      zIndex: 9999,
      minWidth: "115px",
    }),
    option: (_, state: any) => ({
      padding: ".5em .5em",
      cursor: "pointer",
      backgroundColor: state.isSelected ? "var(--gray-40)" : "var(--white)",
      color: computeOptionColor(state.isDisabled, state.isSelected),
      "&:hover": {
        backgroundColor: state.isSelected ? "var(--gray-40)" : "var(--gray-20)",
      },
    }),
  };
}

export type DropdownProps<T> = Pick<
  React.HTMLProps<HTMLDivElement>,
  "style"
> & {
  defaultValue?: T;
  onChange: (value: T) => void;
  options: DropdownOption<T>[];
  className?: string;
  value?: T;
  required?: boolean;
  disabled?: boolean;
  isFocused?: boolean;
  onBlur?: () => void;

  /**
   * Inline can be used in table rows because of it's slimmer profile
   */
  variant?: "outline" | "primary" | "inline" | "default";

  placeholder?: string;
  formRegister?: UseFormRegisterReturn | ControllerRenderProps<any, any>;
  trigger?: UseFormTrigger<any>;
  errorMessage?: string;
  menuIsOpen?: boolean;
  onlyShowItems?: boolean;
  cancelChoice?: () => void;
};

const chevronDownIcon = () => {
  return <ChevronDown16Regular className="mr-sm" />;
};

export function Dropdown<T extends Object>({
  options,
  style,
  value,
  onChange,
  defaultValue,
  className = "",
  required = false,
  disabled = false,
  isFocused = false,
  onBlur,
  variant = "outline",
  placeholder = "",
  formRegister,
  trigger,
  errorMessage,
  menuIsOpen,
  onlyShowItems,
  cancelChoice,
  ...props
}: DropdownProps<T>) {
  const selectRef = useRef<SelectInstance<DropdownOption<T>>>(null);
  const { language } = useTranslation();

  useEffect(() => {
    if (formRegister && trigger && errorMessage) {
      trigger(formRegister.name);
    }
    // this useEffect needs to be triggered only on language change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (isFocused) {
      selectRef.current?.focus();
    }
  }, [isFocused]);

  const onSelectChange = (
    selectedOption: SingleValue<DropdownOption<T>>,
    _: ActionMeta<DropdownOption<T>>
  ) => {
    const tempValue = selectedOption?.value;

    if (!tempValue) {
      return defaultValue;
    }

    onChange(tempValue);
  };

  let defaultStyling: StylesConfig<DropdownOption<T>> = {};
  if (variant === "outline") {
    defaultStyling = outlineStyle<DropdownOption<T>>(
      disabled,
      !!errorMessage,
      onlyShowItems
    );
  } else if (variant === "inline") {
    defaultStyling = inlineStyle<DropdownOption<T>>(
      disabled,
      !!errorMessage,
      onlyShowItems
    );
  } else if (variant === "default") {
    defaultStyling = defaultStyle<DropdownOption<T>>(
      disabled,
      !!errorMessage,
      onlyShowItems
    );
  }
  const defaultValueMapped = options.find((o) => o.value === defaultValue);
  const valueMapped = options.find((o) => o.value === value);
  const enableRequired = required && !disabled;

  const inlineStyling = variant === "inline" ? "d-flex align-items-center" : "";

  return cancelChoice && valueMapped ? (
    <div className="w-100 border border-dark d-flex justify-content-between pl-sm py-xs ">
      <div>{valueMapped.label}</div>
      <div>
        <Dismiss16Regular className="text-gray mr-xs" onClick={cancelChoice} />
      </div>
    </div>
  ) : (
    <div
      style={{ ...style, position: "relative" }}
      className={`dropdown ${className} ${inlineStyling}`}
      {...props}
    >
      <Select<DropdownOption<T>>
        {...formRegister}
        ref={selectRef}
        placeholder={placeholder}
        styles={{ ...defaultStyling }}
        onChange={onSelectChange}
        options={options}
        defaultValue={defaultValueMapped}
        isDisabled={disabled}
        value={valueMapped}
        menuIsOpen={menuIsOpen}
        openMenuOnFocus
        onBlur={onBlur}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => chevronDownIcon(),
        }}
      />

      {enableRequired && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            height: 0,
            width: "100%",
            position: "absolute",
          }}
          value={!valueMapped ? valueMapped : valueMapped.value.toString()}
          onChange={() => {}}
          required={required}
        />
      )}

      {errorMessage && (
        <div className="error-message">
          <span className="text-color-red">
            <span>{errorMessage}</span>
          </span>
        </div>
      )}
    </div>
  );
}
