import { Spinner } from "@fluentui/react-components";

import { ReactNode } from "react";
import { Overlay } from "../overlay";

export enum SpinnerSize {
  ExtraTiny = "extra-tiny",
  Tiny = "tiny",
  ExtraSmall = "extra-small",
  Small = "small",
  Medium = "medium",
  Large = "large",
  ExtraLarge = "extra-large",
  Huge = "huge",
}

type OverlaySpinnerProps = {
  size?: SpinnerSize;
  label?: string;
  children?: ReactNode;
};

export function OverlaySpinner({
  size = SpinnerSize.Small,
  children,
  ...rest
}: OverlaySpinnerProps) {
  return (
    <Overlay>
      <Spinner {...rest} size={size} />
      {children}
    </Overlay>
  );
}
