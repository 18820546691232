import React from "react";
import {
  makeStyles,
  webLightTheme,
  Theme,
  mergeClasses,
  FluentProvider,
  shorthands,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  provider: {
    ...shorthands.margin(0),
    ...shorthands.borderWidth("1px"),
    width: "100%",
  },
  text: {
    fontSize: "14px",
  },
  components: {},
  spacing: {},
});

// The designs in Figma are with the webLightTheme,
// but because we are using Teams is this correct with the light blue?
export const customLightTheme: Theme = {
  ...webLightTheme,
  colorBrandForeground1: "var(--primary)",
  colorBrandForeground2: "var(--primaryLight)",
  colorBrandStroke1: "var(--primary)",
  colorBrandStroke2: "var(--primaryLight)",
  colorNeutralForeground2BrandSelected: "var(--primary)",
};

export function LudvigThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const styles = useStyles();
  return (
    <FluentProvider
      className={mergeClasses(styles.provider, styles.text)}
      theme={customLightTheme}
      // Inline style is needed here, adding height through useStyles hook messes up fluent UI components such as Dropdown, Combobox
      style={{ height: "100%" }}
    >
      {children}
    </FluentProvider>
  );
}
