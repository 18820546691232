import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { fetchOfferTemplate, resetOffer } from "state/offer/offersThunks";
import {
  fetchBusinessOpportunities,
  fetchDealProperties,
  fetchPipelines,
  resetCurrentDeal,
} from "state/sales/actions";
import { LayoutFooter, LayoutWrapper } from "../../../components/layout/Layout";
import { RootState } from "../../../state";

import WizardHeader from "../components/wizardHeader";
import WizardStepper from "../components/wizardStepper";
import { OfferRouteHelper } from "./offerRoutes";
import "./wizard.scss";

export default function OfferWizard() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { data: offerTemplate } = useSelector(
    (state: RootState) => state.offers.offerTemplate
  );
  const currentOffer = useSelector(
    (state: RootState) => state.offers.currentOffer.data
  );
  const {
    sales: { pipelines, dealOptions },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    const init = async () => {
      if (!pipelines.data.length) {
        dispatch(fetchPipelines());
      }
      if (!dealOptions.data.length) {
        dispatch(fetchDealProperties());
      }
    };

    init();
  }, [dealOptions.data.length, dispatch, pipelines.data.length]);

  useEffect(() => {
    if (!offerTemplate) {
      dispatch(fetchOfferTemplate());
      dispatch(resetOffer());
    }
  }, [offerTemplate, dispatch]);

  useEffect(() => {
    return () => {
      // fetch Business Opportunities on unmount to refresh the deals
      // needed to get the new data in deals due to DV-2291
      dispatch(fetchBusinessOpportunities());
      dispatch(resetCurrentDeal());
    };
  }, [dispatch]);

  return (
    <>
      <WizardHeader currentOffer={currentOffer} />

      <LayoutWrapper>
        <Outlet />
      </LayoutWrapper>

      {location.pathname !== OfferRouteHelper.getOffersRedirect() && (
        <LayoutFooter>
          <WizardStepper />
        </LayoutFooter>
      )}
    </>
  );
}
