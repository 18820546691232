import { useState } from "react";
import {
  ArrowLeft16Regular,
  ArrowRight16Regular,
  Info20Regular,
} from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";

import Modal from "components/modal";
import { useTranslation } from "hooks/use-translate";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";
import { DropdownV9 } from "components/dropdown/DropdownV9";

type FinalPriceModalProps = {
  sourceOptions: {
    value: string;
    text: string;
  }[];
  handleCancel: () => void;
  handleConfirm: (value: string) => void;
  labelCancel: string;
  disableClickOutside?: boolean;
  hideCloseButton?: boolean;
  isLoading?: boolean;
};

export default function CustomerSourceModal({
  sourceOptions,
  handleCancel,
  handleConfirm,
  labelCancel,
  disableClickOutside = false,
  hideCloseButton = false,
  isLoading = false,
}: FinalPriceModalProps) {
  const { translate } = useTranslation();

  const [source, setSource] = useState("");

  const onSave = () => {
    handleConfirm(source);
  };

  return (
    <Modal
      size="medium"
      disableClickOutside={disableClickOutside}
      hideCloseButton={hideCloseButton}
      isOpen
      onDismiss={handleCancel}
      header={
        <ModalHeader
          subheaderText="CUSTOMER_SOURCE_INQUIRY_SUBHEADER"
          subheaderIcon={<Info20Regular className="mx-sm text-color-blue" />}
          headerTitleText="CUSTOMER_SOURCE_INQUIRY_HEADER"
        />
      }
      footer={
        <ModalFooter
          onCancel={handleCancel}
          onSave={onSave}
          labelSubmit="ADD_SOURCE"
          labelCancel={labelCancel}
          hideSubmit={!source}
          sendButtonIcon={<ArrowRight16Regular />}
          cancelButtonIcon={<ArrowLeft16Regular />}
          isLoading={isLoading}
          isDisabled={isLoading}
        />
      }
    >
      <Row className="minHeight-100">
        <div className="d-flex align-items-center pb-lg">
          <div className="w-100">
            <Row className="text-center m-auto search-customer w-100 pt-lg">
              <Col md={3} className="m-auto">
                <div className="horizontal-divider" />
              </Col>
              <Col md={6}>
                <div className="text-left">
                  <label className="p-0 fw-bold">
                    {translate("DEAL_SOURCE")} *
                  </label>
                  <br />
                  <DropdownV9
                    className="px-0 mx-0"
                    placeholder={translate("SOURCE_DROPDOWN_PLACEHOLDER")}
                    selectedOptions={[source]}
                    onOptionSelect={(e, data) => {
                      setSource(data.optionValue ?? "");
                    }}
                    options={sourceOptions}
                  />
                </div>
              </Col>
              <Col md={3} className="m-auto">
                <div className="horizontal-divider" />
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </Modal>
  );
}
