import AppConfig from "app-config";
import { CompanyInformation } from "models/offer/Company";
import { KYCCached } from "models/offer/kyc";
import { SearchBy } from "state/offer/companyThunks";
import { getAuthorizationHeader } from "./libs/auth-header";
import { ResponseError } from "./libs/responseError";

export class CompanyAPI {
  static async fetchCompanyFromSales(
    token: string,
    customerOrgNum: string,
    searchByParam: SearchBy
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/companies/${customerOrgNum}?search_by_param=${searchByParam}`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw ResponseError(response);
    }

    return (await response.json()) as CompanyInformation;
  }

  static async fetchExistingKycDataForCustomer(
    token: string,
    customerOrgNum: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/companies/${customerOrgNum}/kyc`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as KYCCached[];
    return json;
  }

  static async queryCompanyKYC(
    token: string,
    orgNum: string,
    contactPersonSsn: string,
    contactPersonFirstName: string,
    contactPersonLastName: string,
    isLimitedCompany: boolean
  ) {
    const params = new URLSearchParams({
      contactPersonSsn,
      contactPersonFirstName,
      contactPersonLastName,
      isLimitedCompany: isLimitedCompany.toString(),
    });

    const response = await fetch(
      `${AppConfig.API_URL}/companies/${orgNum}/kyc/query?${params.toString()}`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as KYCCached;
    return json;
  }

  static async updateCompany(
    token: string,
    customerNumber: string,
    company: Partial<CompanyInformation>
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/companies/${customerNumber}`,
      {
        method: "PATCH",
        headers: { ...getAuthorizationHeader(token) },
        body: JSON.stringify(company),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }
}
