import { useSelector } from "react-redux";

import { getDefaultTranslator } from "../i18n";
import { dateLanguageFormatter } from "../libs/date/date-format";
import { RootState } from "../state";

const translator = getDefaultTranslator();

export function useTranslation() {
  const i18n = useSelector(
    (state: RootState) => state.i18n,
    (left, right) => left.language === right.language
  );
  const { byKey, byString } = translator.getTranslator(i18n.language);
  const count = translator.getCounting(i18n.language);
  const ts = byString;
  const translate = byKey;

  return {
    translate,
    ts,
    count,
    language: i18n.language,
    dateFormatter: dateLanguageFormatter(i18n.language),
  };
}
