import {
  createTableColumn,
  TableColumnDefinition,
} from "@fluentui/react-components";
import type { Item } from "./mock";

export const columns: TableColumnDefinition<Item>[] = [
  createTableColumn<Item>({
    columnId: "customerName",
  }),
  createTableColumn<Item>({
    columnId: "customerId",
  }),
  createTableColumn<Item>({
    columnId: "businessUnit",
  }),
  createTableColumn<Item>({
    columnId: "projectTypes",
  }),
  createTableColumn<Item>({
    columnId: "salesPerson",
  }),
  createTableColumn<Item>({
    columnId: "clientProgram",
  }),
  createTableColumn<Item>({
    columnId: "actions",
  }),
];
