import { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";

import { CheckmarkCircle48Regular } from "@fluentui/react-icons";
import "./completionComponent.scss";

type CompletionSuccessComponentProps = {
  successMessage: string;
  infoMessage: string | ReactNode;
  icon?: ReactNode;
};

export default function CompletionSuccessComponent({
  successMessage,
  infoMessage,
  icon = (
    <CheckmarkCircle48Regular className="blue-link text-color-green-light extra-large-icon" />
  ),
}: CompletionSuccessComponentProps) {
  return (
    <div className="p-md display-grid-center minHeight-100">
      <Row className="text-center w-100">
        <Col xs={12} lg={3} className="m-auto">
          <div className="horizontal-divider" />
        </Col>
        <Col xs={12} lg={4} className="justify-content-center">
          <div className="pb-xxl">{icon}</div>
          <div className="sub-h1 fw-semibold pb-lg">{successMessage}</div>
          <div className="sub-h1">{infoMessage}</div>
        </Col>
        <Col xs={12} lg={3} className="m-auto">
          <div className="horizontal-divider" />
        </Col>
      </Row>
    </div>
  );
}
