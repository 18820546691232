import React from "react";
import "./RingChart.scss";

const CIRCLE_DEGREES = 360;
const HALF_CIRCLE_DEGREES = CIRCLE_DEGREES / 2;

const TEXT_ROTATE_OFFSET = 75;
const CHART_ROTATE_OFFSET = 120;
const TEXT_MARGIN = 0.5;
const FONT_SIZE_TO_PIXELS_RATIO = 0.245;

export type RingChartLayerSegment = {
  /**
   * A unique key for the segment
   */
  key?: string;

  /**
   * A variable number of strings that is shown inside the segment
   */
  texts: string[];

  /**
   * Style
   */
  textStyle?: Omit<React.CSSProperties, "fontSize">;

  /**
   * Font size, this needs to be set explicitly to a pixel value, due to the calculations we need to carry out on the margin between texts
   */
  fontSizePixels?: number;

  /**
   * Segment text orientation
   */
  textOrientation?: "start" | "end" | "middle";

  /**
   * Decides if the segment should be marked as selected
   */
  selected?: boolean;

  /**
   * Fill color for the segment
   */
  fill?: string;

  /**
   * Class name
   */
  className?: string;

  onMouseEnter?: () => void;

  onMouseLeave?: () => void;

  onClick?: () => void;
};

export type RingChartLayer = {
  /**
   * A unique key for the layer
   */
  key?: string;

  /**
   * A variable number of segments which will occupy the layer
   */
  segments: RingChartLayerSegment[];

  /**
   * Margin between segments inside the layer
   */
  margin: number;

  /**
   * Layer start radius (distance from center)
   */
  innerRadius: number;

  /**
   * Layer end radius (distance from center)
   */
  outerRadius: number;

  /**
   * Any rotation of the layer
   */
  rotate: number;

  /**
   * Triggers when hovering mouse over any of the segments in the layer
   */
  onMouseEnter?: (layer: RingChartLayer) => void;

  /**
   * Triggers when hovering mouse over any of the segments in the layer
   */
  onMouseLeave?: (layer: RingChartLayer) => void;
};

/**
 * Transform a (radius, degrees)-coordinate to a (x, y)-coordinate.
 *
 * @param origoX Origo X position
 * @param origoY Origo Y position
 * @param radius Coordinate radius (distance from origo)
 * @param degrees Coordinate degrees from x-axis
 * @returns An (x, y) coordinate
 */
function polarToCartesianCoordinates(
  origoX: number,
  origoY: number,
  radius: number,
  degrees: number
) {
  const radians = (degrees * Math.PI) / HALF_CIRCLE_DEGREES;

  return {
    x: origoX + radius * Math.cos(radians),
    y: origoY + radius * Math.sin(radians),
  };
}

/**
 * Build a segment path (part of a circle), from the given "center" coordinates, inner radius, outer radius, and the start and end degrees
 * @param origoX Origo X position
 * @param origoY Origo Y position
 * @param innerRadius Distance from the center where the segment starts
 * @param outerRadius Distance from the center where the segment ends
 * @param degreesStart Start degrees of the segment
 * @param degreesEnd End degrees of the segment
 * @returns An SVG path, describing the shape of the segment
 */
function buildSegmentPath(
  origoX: number,
  origoY: number,
  innerRadius: number,
  outerRadius: number,
  degreesStart: number,
  degreesEnd: number
) {
  const arc = Math.abs(degreesStart - degreesEnd) > HALF_CIRCLE_DEGREES ? 1 : 0;

  const buildPointSVG = (radius: number, degree: number) => {
    const coordinates = polarToCartesianCoordinates(
      origoX,
      origoY,
      radius,
      degree
    );

    return [coordinates.x, coordinates.y]
      .map((n) => n.toPrecision(5))
      .join(",");
  };

  return [
    `M${buildPointSVG(outerRadius, degreesStart)}`,
    `A${outerRadius},${outerRadius},0,${arc},1,${buildPointSVG(
      outerRadius,
      degreesEnd
    )}`,
    `L${buildPointSVG(innerRadius, degreesEnd)}`,
    `A${innerRadius},${innerRadius},0,${arc},0,${buildPointSVG(
      innerRadius,
      degreesStart
    )}`,
    "Z",
  ].join("");
}

type RingChartSegmentFillProps = Omit<
  React.HTMLProps<SVGPathElement>,
  "crossOrigin"
> & {
  chartTotalSize: number;
  startDegrees: number;
  endDegrees: number;
  innerRadius: number;
  outerRadius: number;
  className?: string;
  fill?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
};

/**
 * Represents the background fill of a segment of the entire ring chart
 */
function RingChartSegmentFill({
  id = "",
  className = "",
  fill,
  startDegrees,
  endDegrees,
  chartTotalSize,
  innerRadius,
  outerRadius,
  onMouseEnter,
  onMouseLeave,
  children,
  onClick,
  ...rest
}: RingChartSegmentFillProps) {
  const center = chartTotalSize / 2;
  const path = buildSegmentPath(
    center,
    center,
    innerRadius,
    outerRadius,
    startDegrees,
    endDegrees
  );

  return (
    <path
      id={id}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      style={{ fill }}
      className={`segment ${className}`}
      d={path}
      {...rest}
    >
      {children}
    </path>
  );
}

export type RingChartProps = Omit<
  React.HTMLProps<SVGSVGElement>,
  "crossOrigin"
> & {
  /**
   * The total size of the chart (width and height)
   */
  chartTotalSizePixels?: number;

  /**
   * A variable number of layers, which the chart is divided into
   */
  layers?: RingChartLayer[];

  /**
   * A mouse enter callback for the whole chart
   */
  onMouseEnter?: () => void;

  /**
   * A mouse leave callback for the whole chart
   */
  onMouseLeave?: () => void;

  /**
   * A mouse enter callback for specific segments
   */
  onSegmentMouseEnter?: (segment: RingChartLayerSegment) => void;

  /**
   * A mouse leave callback for specific segments
   */
  onSegmentMouseLeave?: () => void;

  /**
   * A mouse click callback for specific segments
   */
  onSegmentClick?: (segment: RingChartLayerSegment) => void;

  /**
   * Base font size of the chart, express using any unit (px, em, rem, ...)
   */
  baseFontSize?: string;

  /**
   * Size of the shadow which extends from the whole chart
   */
  shadowSizePixels?: number;
};

/**
 * Ring chart, with a number layers, each containing a number of segments
 * @param RingChartProps Options for the chart
 * @returns The rendered chart
 */
export function RingChart({
  chartTotalSizePixels = 700,
  baseFontSize = "12px",
  shadowSizePixels = 5,
  layers = [],
  onMouseLeave = () => {},
  onMouseEnter = () => {},
  ...rest
}: RingChartProps) {
  const layersRendered = layers.map((layer) => {
    const layerRotate = layer.rotate;
    const layerSegmentDegrees = CIRCLE_DEGREES / layer.segments.length;
    const layerRendered = layer.segments.map((segment, segmentIndex) => {
      const segmentStartDegrees =
        CIRCLE_DEGREES / layer.segments.length +
        segmentIndex * layerSegmentDegrees +
        layer.margin / 2 -
        CHART_ROTATE_OFFSET +
        layerRotate;

      const segmentEndDegrees =
        segmentStartDegrees + layerSegmentDegrees - layer.margin / 2;

      const segmentRotation =
        (segmentIndex + layer.rotate) * layerSegmentDegrees;

      const fontSizePixels = segment.fontSizePixels || 10;

      const textTotalHeight =
        fontSizePixels * FONT_SIZE_TO_PIXELS_RATIO * (segment.texts.length - 1);

      return {
        fill: (
          <RingChartSegmentFill
            key={segment.key}
            className={`${segment.className || ""} ${
              segment.selected && "selected"
            }`}
            startDegrees={segmentStartDegrees}
            endDegrees={segmentEndDegrees}
            fill={segment.fill || "#000"}
            chartTotalSize={chartTotalSizePixels}
            onMouseLeave={segment.onMouseLeave}
            onMouseEnter={segment.onMouseEnter}
            onClick={segment.onClick}
            outerRadius={layer.outerRadius}
            innerRadius={layer.innerRadius}
            data-testid={`${segment.key}-fill`}
          />
        ),
        texts: segment.texts.map((text, textIndex) => {
          const segmentRotate =
            segmentIndex * layerSegmentDegrees + layerSegmentDegrees;

          const segmentRotateNormalize = segmentRotate % CIRCLE_DEGREES;

          // Adjust the start and end positions of the text, depending on if the text appears on the right or left side of the center
          const mirrorTextCutOff =
            segmentRotateNormalize > HALF_CIRCLE_DEGREES ||
            segmentRotateNormalize <= 0;

          const textMargin = textIndex * fontSizePixels * TEXT_MARGIN;

          // Calculate coordinates rotation of text inside the chart
          const textCoordinatesRotation = mirrorTextCutOff
            ? segmentRotation + textTotalHeight - textMargin
            : segmentRotation - textTotalHeight + textMargin;

          const { x: textX, y: textY } = polarToCartesianCoordinates(
            chartTotalSizePixels / 2,
            chartTotalSizePixels / 2,
            segment.textOrientation === "start"
              ? layer.innerRadius + 10
              : layer.outerRadius - 10,
            textCoordinatesRotation - TEXT_ROTATE_OFFSET
          );

          const textOrientationInvert =
            segment.textOrientation === "middle"
              ? "middle"
              : segment.textOrientation === "end"
              ? "start"
              : "end";

          // Rotate text around it's own center
          const textSelfRotation = mirrorTextCutOff
            ? textCoordinatesRotation +
              (HALF_CIRCLE_DEGREES - TEXT_ROTATE_OFFSET)
            : textCoordinatesRotation - TEXT_ROTATE_OFFSET;

          return (
            <text
              key={text}
              data-testid={`${segment.key}-text`}
              dominantBaseline="middle"
              className="text"
              textAnchor={
                !mirrorTextCutOff
                  ? segment.textOrientation
                  : textOrientationInvert
              }
              x={textX}
              y={textY}
              style={{ fontSize: `${fontSizePixels}px`, ...segment.textStyle }}
              transform={`rotate(${textSelfRotation}, ${textX}, ${textY})`}
            >
              {text}
            </text>
          );
        }),
      };
    });

    return (
      <g key={layer.key} data-testid={layer.key}>
        {layerRendered.map((l) => l.fill)}
        {layerRendered.map((l) => l.texts)}
      </g>
    );
  });

  return (
    <svg
      onMouseEnter={() => onMouseEnter && onMouseEnter()}
      onMouseLeave={() => onMouseLeave && onMouseLeave()}
      height={chartTotalSizePixels}
      width={chartTotalSizePixels}
      style={{ fontSize: baseFontSize }}
      viewBox={`0 0 ${chartTotalSizePixels} ${chartTotalSizePixels}`}
      className="ring-chart"
      {...rest}
    >
      <circle
        cx={chartTotalSizePixels / 2}
        cy={chartTotalSizePixels / 2}
        r={chartTotalSizePixels / 2 - shadowSizePixels}
        className="drop-shadow"
      />
      {layersRendered}
    </svg>
  );
}
