import {
  Button,
  Dialog,
  DialogActions,
  DialogActionsPosition,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import React from "react";
import "./Modal.scss";

export interface ModalStepsConfig {
  activeStep: number;
  stepsCount: number;
}

export type ModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  children: React.ReactNode;
  size?: "small" | "small-to-mid" | "medium" | "large" | "fit-content-min";
  header?: React.ReactNode;
  headerAs?: "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  hideCloseButton?: boolean;
  footer?: React.ReactNode;
  className?: string;
  dialogContentClassName?: string;
  stepsConfig?: ModalStepsConfig;
  isLoading?: boolean;
  isWarning?: boolean;
  scrollableContent?: boolean;
  disableClickOutside?: boolean;
  dialogActionsPosition?: DialogActionsPosition;
};

export default function Modal({
  isOpen,
  onDismiss,
  children,
  size = "medium",
  header,
  headerAs,
  hideCloseButton = false,
  footer,
  className = "",
  dialogContentClassName = "",
  stepsConfig,
  isLoading = false,
  isWarning = false,
  scrollableContent = false,
  disableClickOutside = false,
  dialogActionsPosition,
}: ModalProps) {
  return (
    <Dialog
      open={isOpen}
      inertTrapFocus
      onOpenChange={() => !disableClickOutside && onDismiss()}
    >
      <DialogSurface
        className={`${className} ${size} ${isWarning ? "is-warning" : ""}`}
      >
        <DialogBody className={`modal-body ${isLoading ? " o-50" : ""}`}>
          <DialogTitle
            as={headerAs}
            action={
              !hideCloseButton && !stepsConfig ? (
                <Button
                  appearance="subtle"
                  aria-label="close"
                  onClick={onDismiss}
                  icon={<Dismiss24Regular />}
                />
              ) : (
                stepsConfig && (
                  <span className="steps ml-auto">{`${stepsConfig.activeStep}/${stepsConfig.stepsCount}`}</span>
                )
              )
            }
          >
            {header || null}
          </DialogTitle>
          <DialogContent
            className={`${dialogContentClassName} ${
              scrollableContent ? "scrollable-content" : "no-overflow"
            }`}
          >
            {children}
          </DialogContent>
          <DialogActions
            fluid
            position={dialogActionsPosition}
            className="modal-footer"
          >
            {footer || null}
          </DialogActions>
        </DialogBody>
        {isLoading && <Spinner className="loading-spinner" />}
      </DialogSurface>
    </Dialog>
  );
}
