import { Col, Row } from "react-bootstrap";

import { useTranslation } from "hooks/use-translate";
import { FormAnswerBatch } from "models/activities/activity";
import { YearlyEvaluationKYCFormRowReadonly } from "./YearlyEvaluationKYCFormRowReadonly";
import "./YearlyEvaluationKYCReadonly.scss";

type YearlyEvaluationKYCFormContentReadonlyProps = {
  kyc: FormAnswerBatch;
  isLatest?: boolean;
};

export function YearlyEvaluationKYCFormContentReadonly({
  kyc,
  isLatest = false,
}: YearlyEvaluationKYCFormContentReadonlyProps) {
  const { translate, dateFormatter } = useTranslation();

  // Summary is hidden as per DV-2569
  // NOTE: this commented code is needed for future!
  // const calculatePointsSummary = () => {
  //   const positiveAnswersCount = kyc.answers.filter(
  //     (formAnswer) =>
  //       (formAnswer?.answer as BooleanWithText)?.value.toString() === "true"
  //   ).length;

  //   return positiveAnswersCount > 0 ? positiveAnswersCount * 10 : 0;
  // };

  return (
    <div
      className={`justify-content-center pt-sm cs-content ${
        isLatest && "p-md"
      }`}
    >
      {isLatest && (
        <Row className="d-flex justify-content-center mx-xxs my-sm">
          <Col md={6}>
            <h5>{translate("LATEST_KYC_QUESTIONNAIRE")}</h5>
          </Col>
          <Col md={6} className="text-end">
            <span className="text-muted">
              {translate("LATEST_UPDATE_BY")} {kyc.completed_by_user} on{" "}
              {dateFormatter(kyc.completed_at, "yyyy-MM-dd")} at{" "}
              {kyc.completed_at.toLocaleString("sv-SE").slice(-8)}
            </span>
          </Col>
        </Row>
      )}

      <Row className="px-lg py-sm  align-items-center justify-content-between fw-600">
        <Col md={1}>
          <span>#</span>
        </Col>
        <Col md={9}>
          <span>{translate("QUESTION")}</span>
        </Col>
        <Col md={2}>
          <span>{translate("ANSWER")}</span>
        </Col>
        {/* Points column is hidden as per DV-2569 
            NOTE: this commented code is needed for future! */}
        {/* <Col md={1}>
          <span>{translate("POINTS")}</span>
        </Col> */}
      </Row>

      {kyc.answers.map((answer, index) => (
        <div key={answer.question_id}>
          <YearlyEvaluationKYCFormRowReadonly
            formAnswer={answer}
            index={index}
          />
          {/* NOTE: remove this condition for horizontal-divider when Summary is used */}
          {index + 1 < kyc.answers.length && (
            <div className="horizontal-divider my-sm" />
          )}
        </div>
      ))}
      {/* Summary is hidden as per DV-2569 
            NOTE: this commented code is needed for future! */}
      {/* <Row className="px-lg">
        <Col className="text-end p-sm">
          {translate("SUMMARY_TITLE")} {calculatePointsSummary()} /{" "}
          {kyc.answers.length * 10}
        </Col>
      </Row> */}
    </div>
  );
}
