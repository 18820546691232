import { CreateCustomer } from "models/customer";
import { CreateDeal } from "models/deals/deal";
import {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";

interface CreateBusinessOpportunityContextProps {
  businessAreaSelected: string;
  newDeal: CreateDeal;
  newCustomer: CreateCustomer;
  customerTypeSelected: string;
  updateNewCustomer: (customer: Partial<CreateCustomer>) => CreateCustomer;
  toggleBusinessArea: (businessArea: string) => void;
  toggleCustomerType: (customerType: string) => void;
  updateNewDeal: (customer: Partial<CreateDeal>) => CreateDeal;
  resetContext: () => void;
}

export const CreateBusinessOpportunityContext =
  createContext<CreateBusinessOpportunityContextProps>({
    businessAreaSelected: "",
    customerTypeSelected: "",
    toggleBusinessArea: () => {},
    toggleCustomerType: () => {},
    newDeal: new CreateDeal(),
    updateNewDeal: () => new CreateDeal(),
    newCustomer: new CreateCustomer(),
    updateNewCustomer: () => new CreateCustomer(),
    resetContext: () => {},
  });

interface IRegistrationProvider {
  children: ReactNode;
}

export function CreateBusinessOpportunityContextProvider({
  children,
}: IRegistrationProvider) {
  const [businessAreaSelected, setBusinessAreaSelected] = useState<string>("");

  const [customerTypeSelected, setCustomerTypeSelected] = useState<string>("");

  const [newDeal, setNewDeal] = useState<CreateDeal>(new CreateDeal());

  const [newCustomer, setNewCustomer] = useState<CreateCustomer>(
    new CreateCustomer()
  );

  const updateNewCustomer = useCallback(
    (customer: Partial<CreateCustomer>) => {
      setNewCustomer({ ...newCustomer, ...customer });
      return { ...newCustomer, ...customer };
    },
    [newCustomer, setNewCustomer]
  );

  const selectBusinessArea = useCallback(
    (businessArea: string) => {
      if (businessAreaSelected === businessArea) {
        setBusinessAreaSelected("");
        return;
      }
      setBusinessAreaSelected(businessArea);
    },
    [setBusinessAreaSelected, businessAreaSelected]
  );

  const selectCustomerType = useCallback(
    (customerType: string) => {
      if (customerTypeSelected === customerType) {
        setCustomerTypeSelected("");
        return;
      }
      setCustomerTypeSelected(customerType);
    },
    [setCustomerTypeSelected, customerTypeSelected]
  );

  const updateNewDeal = useCallback(
    (deal: Partial<CreateDeal>) => {
      setNewDeal({ ...newDeal, ...deal });
      return { ...newDeal, ...deal };
    },
    [newDeal, setNewDeal]
  );

  const resetContext = useCallback(() => {
    setNewDeal(new CreateDeal());
    setBusinessAreaSelected("");
    setNewCustomer(new CreateCustomer());
    setCustomerTypeSelected("");
  }, []);

  const initContextState = useMemo<CreateBusinessOpportunityContextProps>(
    () => ({
      businessAreaSelected,
      toggleBusinessArea: selectBusinessArea,
      customerTypeSelected,
      toggleCustomerType: selectCustomerType,
      newDeal,
      updateNewDeal,
      resetContext,
      newCustomer,
      updateNewCustomer,
    }),
    [
      businessAreaSelected,
      selectBusinessArea,
      customerTypeSelected,
      selectCustomerType,
      newDeal,
      updateNewDeal,
      resetContext,
      newCustomer,
      updateNewCustomer,
    ]
  );

  return (
    <CreateBusinessOpportunityContext.Provider value={initContextState}>
      {children}
    </CreateBusinessOpportunityContext.Provider>
  );
}
