import { useState } from "react";
import { useDispatch } from "react-redux";

import Checkbox from "components/checkbox";
import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";
import { useTranslation } from "hooks";
import { ActivityInstance, ServiceInstance } from "models/activities/activity";
import { Customer } from "models/customer";
import { updateActivityDeadline } from "state/activities/actions";
import { getDateSpan } from "../helpers/getDateSpan";
import { DeadlineCalendar } from "./DeadlineCalendar";

type Props = {
  open: boolean;
  onClose: () => void;
  service: ServiceInstance;
  customer: Customer;
  selectedActivityInstance?: ActivityInstance;
  selectedChangeDeadlineDate: Date;
  setChangeDeadlineDate: (selectedChangeDeadlineDate: Date) => void;
};

export default function CalendarModal({
  open,
  onClose,
  service,
  customer,
  selectedActivityInstance,
  selectedChangeDeadlineDate,
  setChangeDeadlineDate,
}: Props) {
  const { translate, language } = useTranslation();
  const dispatch = useDispatch();
  const [selectedChangeDeadlineUpcoming, setChangeDeadlineUpcoming] =
    useState(false);

  const { minDate, maxDate } = getDateSpan(
    service.recurrence,
    selectedActivityInstance
  );

  const setActivityDeadline = (
    selectedActivity: ActivityInstance | undefined,
    deadlineDate: Date,
    setDeadlineUpcoming: boolean
  ) => {
    if (!selectedActivity) {
      return;
    }

    dispatch(
      updateActivityDeadline(
        customer,
        selectedActivity,
        deadlineDate,
        setDeadlineUpcoming
      )
    );
  };

  return (
    <Modal
      isOpen={open}
      size="small"
      onDismiss={onClose}
      header={<ModalHeader headerTitleText="CHANGE_DEADLINE" />}
      footer={
        <ModalFooter
          labelCancel="CANCEL"
          onCancel={onClose}
          labelSubmit="CONFIRM"
          onSave={() => {
            onClose();
            setActivityDeadline(
              selectedActivityInstance,
              selectedChangeDeadlineDate,
              selectedChangeDeadlineUpcoming
            );
          }}
        />
      }
    >
      <p>{translate("SELECT_DATELINE_DATE")}</p>
      <div className="d-flex justify-content-center">
        <DeadlineCalendar
          minDate={minDate}
          maxDate={maxDate}
          translate={translate}
          timespan={service?.recurrence}
          language={language}
          defaultValue={selectedActivityInstance?.deadline}
          value={selectedChangeDeadlineDate}
          onSelectedDate={setChangeDeadlineDate}
        />
      </div>

      <Checkbox
        className="mb-4"
        label={translate("CHANGE_DEADLINE_FOR_ALL_ACTIVITIES")}
        checked={selectedChangeDeadlineUpcoming}
        onChange={() =>
          setChangeDeadlineUpcoming(!selectedChangeDeadlineUpcoming)
        }
      />
    </Modal>
  );
}
