import WarningModal from "components/modal/WarningModal";
import { useTranslation } from "hooks/use-translate";

interface ModalProps {
  onCancel?: () => void;
}

function MissingSmallBusinessYear({ onCancel }: ModalProps) {
  const { translate } = useTranslation();

  const onDismiss = () => {
    onCancel?.();
  };

  return (
    <WarningModal
      header={translate("WARNING")}
      onCancel={() => onDismiss()}
      warningMessage={translate("MISSING_SMALL_BUSINESS_YEAR")}
    />
  );
}

export default MissingSmallBusinessYear;
