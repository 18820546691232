interface ProjectType {
  type: string;
  managerEmail: string;
}

export interface Item {
  customerId: string;
  customerName: string;
  businessUnit: string;
  projects: ProjectType[];
  clientProgram: "high" | "medium" | "low";
  salesPerson: string;
}

export const mockData: Item[] = [
  {
    customerId: "5591393334",
    customerName: "Andersson Packages",
    businessUnit: "Vara",
    projects: [
      {
        type: "MK",
        managerEmail: "Mikael Noran",
      },
      {
        type: "EO",
        managerEmail: "Jonathan Birger",
      },
      {
        type: "MK",
        managerEmail: "Jakob Leekens",
      },
      {
        type: "SK",
        managerEmail: "Jonathan Birger",
      },
    ],
    salesPerson: "Jonathan Birger",
    clientProgram: "high",
  },
  {
    customerId: "5591393334",
    customerName: "Adams, Sokes and Golden si...",
    businessUnit: "Vara",
    projects: [
      {
        type: "MK",
        managerEmail: "Mikael Noran",
      },
      {
        type: "EO",
        managerEmail: "Jonathan Birger",
      },
    ],
    salesPerson: "Jonathan Birger",
    clientProgram: "high",
  },
  {
    customerId: "5591393334",
    customerName: "Stockholms Elbolag",
    businessUnit: "Vara",
    projects: [
      {
        type: "MK",
        managerEmail: "Mikael Noran",
      },
      {
        type: "EO",
        managerEmail: "Jonathan Birger",
      },
      {
        type: "MK",
        managerEmail: "Jakob Leekens",
      },
      {
        type: "SK",
        managerEmail: "Jonathan Birger",
      },
    ],
    salesPerson: "Jonathan Birger",
    clientProgram: "high",
  },
  {
    customerId: "5591393334",
    customerName: "Andersson Packages",
    businessUnit: "Vara",
    projects: [],
    salesPerson: "Jonathan Birger",
    clientProgram: "low",
  },
  {
    customerId: "5591393334",
    customerName: "Andersson Packages",
    businessUnit: "Vara",
    projects: [],
    salesPerson: "Jonathan Birger",
    clientProgram: "low",
  },
  {
    customerId: "5591393334",
    customerName: "Andersson Packages",
    businessUnit: "Vara",
    projects: [
      {
        type: "MK",
        managerEmail: "Mikael Noran",
      },
      {
        type: "EO",
        managerEmail: "Jonathan Birger",
      },
      {
        type: "MK",
        managerEmail: "Jakob Leekens",
      },
      {
        type: "SK",
        managerEmail: "Jonathan Birger",
      },
    ],
    salesPerson: "Jonathan Birger",
    clientProgram: "medium",
  },
  {
    customerId: "5591393334",
    customerName: "Andersson Packages",
    businessUnit: "Vara",
    projects: [
      {
        type: "MK",
        managerEmail: "Mikael Noran",
      },
      {
        type: "EO",
        managerEmail: "Jonathan Birger",
      },
      {
        type: "MK",
        managerEmail: "Jakob Leekens",
      },
      {
        type: "SK",
        managerEmail: "Jonathan Birger",
      },
    ],
    salesPerson: "Jonathan Birger",
    clientProgram: "high",
  },
];
