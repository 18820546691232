import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "state";
import { appendToastMessage } from "state/notifications";
import { AppDispatch } from "state/use-app-redux";
import { setSelectedCustomerSource } from "state/offer/offersSlice";
import { setupNewOfferForCustomer } from "state/offer/offersThunks";
import { useTranslation } from "hooks/use-translate";
import { getSourceOptions } from "helpers/OffersHelper";
import { DealCustomer } from "models/offer/Customer";
import CustomerSourceModal from "./CustomerSourceModal";
import { OfferRouteHelper } from "../offerRoutes";

type FinalPriceModalProps = {
  isOpen: boolean;
  customer: DealCustomer | undefined;
  handleCancel: () => void;
  handleConfirm: () => void;
  disableClickOutside?: boolean;
  hideCloseButton?: boolean;
};

export default function CustomerSource({
  isOpen,
  customer,
  handleCancel,
  handleConfirm,
  disableClickOutside = false,
  hideCloseButton = false,
}: FinalPriceModalProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { translate } = useTranslation();

  const {
    sales: deals,
    sales: { currentDeal },
  } = useSelector((state: RootState) => state);
  const offers = useSelector((state: RootState) => state.offers);
  const currentOffer = offers.currentOffer.data;
  const isNewCustomer = currentOffer?.new_customer;

  const handleModalCancel = () => {
    handleCancel();
  };

  const handleModalConfirm = async (value: string) => {
    dispatch(setSelectedCustomerSource(value));
    handleConfirm();
    if (!currentDeal) {
      if (customer && !isNewCustomer) {
        await dispatch(setupNewOfferForCustomer(customer));
      }
      dispatch(appendToastMessage("NEW_OFFER_TOAST", "success"));
      navigate(OfferRouteHelper.getRegisterCompany());
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <CustomerSourceModal
      disableClickOutside={disableClickOutside}
      hideCloseButton={hideCloseButton}
      sourceOptions={getSourceOptions(deals.dealOptions.data)}
      handleCancel={handleModalCancel}
      handleConfirm={handleModalConfirm}
      labelCancel={
        isNewCustomer ? translate("MAKE_ANOTHER_SEARCH") : translate("CANCEL")
      }
    />
  );
}
