import { DealContact, SearchContact } from "models/deals/deal";

// TODO remove this after BE fixes the get HS contact response
// mapper added due to POG-49
// this is a temporary solution to map the SearchContact to DealContact
// needed since the get HS contacts is of type DealContact,
// but POST/PATCH contact is of type SearchContact
export function mapSearchToDealContact(
  searchContact: SearchContact
): DealContact {
  return {
    ...searchContact,
    firstname: searchContact.firstName,
    lastname: searchContact.lastName,
    mobilephone: searchContact.mobile,
  };
}
