import React from "react";
import { useNavigate } from "react-router-dom";

import WarningModal from "components/modal/WarningModal";
import { useTranslation } from "hooks/use-translate";
import { OfferRouteHelper } from "views/offer/wizard/offerRoutes";

interface ModalProps {
  onCancel?: () => void;
}

function InvalidSigningMethodModal({ onCancel }: ModalProps) {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const onDismiss = () => {
    onCancel?.();
  };

  return (
    <WarningModal
      onCancel={() => onDismiss()}
      warningMessage={translate("INVALID_SIGNING_METHOD_DATA")}
      header={translate("WARNING")}
      action={{
        actionLabel: translate("GO_TO_CONTACTS"),
        onAction: () => navigate(OfferRouteHelper.getContactInfo()),
      }}
    />
  );
}

export default InvalidSigningMethodModal;
