import { ClassConstructor, plainToClass } from "class-transformer";

// Should be used when we parse data from batch endpoints
//
// Example response with value being an object:
// {
//   key1: { objectKey: "value1" },
//   key2: { objectKey: "value2" },
//   key3: { objectKey: "value3" },
// };
//
// USAGE
//
// const batchResponse: BatchResponse<CustomerActivitiesServices> = await response.json();
// const result = ParseBatchResponse(batchResponse, { cls: CustomerActivitiesServices });
//
//
// Example response with value being an array of objects:
// {
//   key1: [
//     { objectKey: "value10" },
//     { objectKey: "value11" },
//     { objectKey: "value12" },
//   ],
//   key2: [
//     { objectKey: "value20" },
//     { objectKey: "value21" },
//     { objectKey: "value22" },
//   ],
// }
//
// USAGE
//
// const batchResponse: BatchResponse<CustomerDelivery[]> = await response.json();
// const result = ParseBatchResponseArray(batchResponse, { cls: CustomerDelivery });

export type BatchResponse<T> = {
  [key: string]: T;
};

export type BatchResult<T> = Map<string, T>;

/**
 * Parses batch response into a map with option to transform
 * all values into a class with the help of plainToClass. This
 * function handles a response where the value of the key-value
 * pair is a object.
 *
 * @param batchResponse The batch response where the value is a single object
 * @param plainToClassOptions Options for plainToClass
 * @returns Map with key-value pairs from the response
 */
export const ParseBatchResponse = <T>(
  batchResponse: BatchResponse<T>,
  plainToClassOptions?: { cls: ClassConstructor<T> }
): BatchResult<T> => {
  const result = new Map<string, T>();

  for (const [key, value] of Object.entries(batchResponse)) {
    const tempValue: T = plainToClassOptions
      ? plainToClass(plainToClassOptions.cls, value)
      : value;

    result.set(key, tempValue);
  }

  return result;
};

/**
 * Parses batch response into a map with option to transform
 * all values into a class with the help of plainToClass. The
 * array version handles a response where the value of the
 * key-value pair is an array.
 *
 * @param batchResponse The batch response where the value is an array of objects
 * @param plainToClassOptions Options for plainToClass
 * @returns Map with key-value pairs from the response
 */
export const ParseBatchResponseArray = <T>(
  batchResponse: BatchResponse<T[]>,
  plainToClassOptions?: { cls: ClassConstructor<T> }
): BatchResult<T[]> => {
  const result = new Map<string, T[]>();

  for (const [key, responseValues] of Object.entries(batchResponse)) {
    const tempValue = responseValues.map((responseValue) => {
      const res: T = plainToClassOptions
        ? plainToClass(plainToClassOptions.cls, responseValue)
        : responseValue;

      return res;
    });

    result.set(key, tempValue);
  }

  return result;
};
