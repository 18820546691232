import { Controller, useFormContext } from "react-hook-form";

import { Deal } from "models/deals/deal";
import Checkbox from "components/checkbox";
import { DealCheckboxProps } from "./models/field-types";
import { useUpdateDealProperty } from "./useUpdateDealProperty";

export default function DealCheckbox({
  configuration,
  disabled = false,
  option,
}: DealCheckboxProps) {
  const { dealsFreeConsulting, isLoading } = useUpdateDealProperty();
  const { setValue, control, getValues } = useFormContext<Partial<Deal>>();

  const checkboxValue = getValues(configuration.property);
  const isChecked =
    checkboxValue === dealsFreeConsulting?.options[option].value;

  return (
    <Controller
      name={configuration.property}
      control={control}
      render={({ field }) => (
        <Checkbox
          disabled={isLoading || disabled}
          checked={isChecked}
          onChange={() => {
            field.onChange(
              checkboxValue ? null : dealsFreeConsulting?.options[option].value
            );
            setValue(
              configuration.property,
              checkboxValue ? null : dealsFreeConsulting?.options[option].value,
              {
                shouldValidate: true,
                shouldDirty: true,
              }
            );
          }}
        />
      )}
    />
  );
}
