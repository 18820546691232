import React from "react";
import "./accordionV2.scss";
import { AccordionContentV2 } from "./components/AccordionBodyV2";
import { AccordionHeaderV2 } from "./components/AccordionHeaderV2";
import { AccordionProviderV2 } from "./AccordionContextProviderV2";

interface IAccordionCustom {
  title: string;
  children: React.ReactNode;
  loadingStatus?: string;
  isInitiallyOpen?: boolean;
  subtitle?: string;
  expanded?: boolean;
  infoTooltip?: string;
  className?: string;
  lengthIndicator?: number;
}

export function AccordionV2({
  title,
  children,
  loadingStatus,
  isInitiallyOpen = false,
  subtitle,
  expanded,
  infoTooltip,
  className,
  lengthIndicator,
}: IAccordionCustom) {
  return (
    <AccordionProviderV2 isInitiallyOpen={isInitiallyOpen} expanded={expanded}>
      <div className={`accordion-custom-item2 ${className}`}>
        <AccordionHeaderV2
          title={title}
          loadingStatus={loadingStatus}
          subtitle={subtitle}
          infoTooltip={infoTooltip}
          lengthIndicator={lengthIndicator}
        />
        <AccordionContentV2 loadingStatus={loadingStatus}>
          {children}
        </AccordionContentV2>
      </div>
    </AccordionProviderV2>
  );
}
