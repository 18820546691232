import { rest } from "msw";

const BASE_PATH = process.env.REACT_APP_API_URL;
const response = {
  access_token: process.env.REACT_APP_MICROSOFT_GRAPH_TEMP_TOKEN,
};

export const graphTokenHandler = [
  rest.get<string>(
    `${BASE_PATH}/graph-token`,
    async (req, res, { status, json }) => {
      return res(status(200), json(response));
    }
  ),
];
