import { useCallback, useState } from "react";

export type Sortable = number | string | boolean | Date | null;

export type Selector<T> = (e: T) => Sortable;

export type SortFunction<T> = {
  sort: (a: T, b: T) => number;
  selector: Selector<T>;
  isDescendingOrder: boolean;
};

export interface Sorting<T> {
  sorting: SortFunction<T>;
  toggleSortSelector: (
    identifier: (el: T) => Sortable,
    flipDescending?: boolean
  ) => void;
}

/**
 * Stores sorting descending state and contains the sorting logic.
 *
 * If the same sorting property is selected, the sort order is toggled.
 *
 * If you want to sort ascending, but show a descending arrow in the UI, use `invertOrder`.
 * @param selector A selector function, which selects which property to sort on (customer name, customer application status, etc.)
 * @param invertOrder Flag to decide if the sorting should be flipped (ascending/descending). Used for flipping sorting when sorting on names (A to Z).
 */
export function useSorting<T>(
  selector: (el: T) => Sortable,
  invertOrder = false
): Sorting<T> {
  // Create a sort function, depending on the selector and descending flag
  const Sort = useCallback(
    (selector: Selector<T>, descending: boolean) => (a: T, b: T) => {
      const va = selector(a) || 0;
      const vb = selector(b) || 0;

      if (va > vb) {
        return descending ? -1 : 1;
      }

      return descending ? 1 : -1;
    },
    []
  );

  const [sorting, setSortFunctionInner] = useState<SortFunction<T>>({
    sort: Sort(selector, !invertOrder),
    selector,
    isDescendingOrder: true,
  });

  // If the selector is the same as previously, then invert the descending flag
  // If the selector is not the same, reset the descending flag
  const toggleSortSelector = useCallback(
    (selector: (el: T) => Sortable, invertOrder: boolean = false) => {
      const showDescending =
        selector === sorting.selector ? !sorting.isDescendingOrder : true;

      const sortOrder = invertOrder ? !showDescending : showDescending;

      setSortFunctionInner({
        sort: Sort(selector, sortOrder),
        selector: selector,
        isDescendingOrder: showDescending,
      });
    },
    [Sort, sorting.isDescendingOrder, sorting.selector]
  );

  return { sorting, toggleSortSelector };
}
