import { DealOption } from "models/deals/deal";
import { DEAL_SOURCE, INCLUDED_SOURCES } from "shared/dealConstants";

export const getSourceOptions = (dealOptions: DealOption[]) => {
  const sourceOptions =
    dealOptions
      .find((data) => data.name === DEAL_SOURCE)
      ?.options.filter((o) => INCLUDED_SOURCES.includes(o.value))
      .sort((a, b) => a.display_order - b.display_order)
      .map((option) => {
        return {
          value: option.value,
          text: option.label,
        };
      }) || [];

  return sourceOptions;
};
