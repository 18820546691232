import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ArrowRight16Regular, Warning16Filled } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutWrapper,
} from "components/layout/Layout";
import {
  Content,
  ContentBody,
  ContentBodyCenter,
  ContentFooter,
} from "components/content/Content";
import { Breadcrumb, ContentHeader } from "components/content/ContentHeader";
import { useAuth } from "auth/use-auth";
import { Button } from "components/button";
import { usePostFortnoxAdminAddAdminsMutation } from "services/ludvigApi";
import AppConfig from "app-config";
import { isValidOrganizationNumberOrSSN } from "libs/is-valid-organization-number-or-ssn";
import { appendToastMessage } from "state/notifications";
import { AppDispatch } from "state/use-app-redux";
import { UserRoles } from "models/user";
import { AccessDeniedPage } from "views/AccessDeniedPage/AccessDeniedPage";
import { Tab, TabList } from "@fluentui/react-components";
import SearchInput from "components/input/search";

enum AdminIdType {
  FortnoxCustomerId = "fortnox_customer_id",
  OrgNumber = "org_no",
}

export function FortnoxAdminPage() {
  const { translate, ts } = useTranslation();
  const { user, userHasSystemRole } = useAuth();
  const dispatch: AppDispatch = useDispatch();

  const [postFortnoxAdminAddAdmins, { isLoading, reset }] =
    usePostFortnoxAdminAddAdminsMutation();

  const [searchInput, setSearchInput] = useState("");
  const [searchInputError, setSearchInputError] = useState("");

  const [searchByAdminType, setSearchByAdminType] = useState<AdminIdType>(
    AdminIdType.FortnoxCustomerId
  );

  const getBreadcrumbs = (): Breadcrumb[] => {
    return [
      { title: translate("FORTNOX_ADMIN") },
      { title: translate("FORTNOX_HEADER") },
    ];
  };

  const onClearInput = () => {
    setSearchInput("");
    resetErrors();
  };

  const resetErrors = () => {
    setSearchInputError("");
    reset();
  };

  const handleSubmit = async () => {
    validate();

    if (!user?.username || isLoading) {
      return;
    }

    try {
      await postFortnoxAdminAddAdmins({
        orgNo: searchByAdminType === AdminIdType.OrgNumber ? searchInput : "",
        clientId:
          searchByAdminType === AdminIdType.FortnoxCustomerId
            ? searchInput
            : "",
        user: user?.username,
      }).unwrap();

      dispatch(appendToastMessage("FORTNOX_ADMIN_ADDED", "success"));
      onClearInput();
    } catch (e) {
      dispatch(appendToastMessage("SOMETHING_WENT_WRONG", "error"));
    }
  };

  const getErrorMessage = () => {
    if (searchInputError) {
      const inputText = ts(searchInputError);

      return (
        <span className="mt-sm color-red">
          <Warning16Filled className="mr-sm" />
          {inputText}
        </span>
      );
    }

    return null;
  };

  const validate = () => {
    if (
      searchByAdminType === AdminIdType.OrgNumber &&
      !isValidOrganizationNumberOrSSN(searchInput)
    ) {
      return setSearchInputError("INVALID_ORGANIZATION_NUMBER");
    }

    return true;
  };

  if (
    !(
      userHasSystemRole(AppConfig.USER_ROLES.MITT_LUDVIG_SUPERUSER) ||
      user?.roles?.includes(UserRoles.USERS_SUPER)
    )
  ) {
    return <AccessDeniedPage />;
  }

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <h1>{translate("FORTNOX_ADMIN")}</h1>
        </LayoutHeaderLeft>
      </LayoutHeader>

      <LayoutWrapper>
        <Content>
          <ContentHeader
            breadcrumbs={getBreadcrumbs()}
            subtitles={[translate("FORTNOX_HEADER_INFO")]}
          />

          <ContentBody>
            <ContentBodyCenter>
              <Row className="justify-content-center">
                <Col xs="auto">
                  <TabList
                    selectedValue={searchByAdminType}
                    onTabSelect={(_, item) => {
                      onClearInput();
                      setSearchByAdminType(item?.value as AdminIdType);
                    }}
                  >
                    <Tab
                      key={AdminIdType.FortnoxCustomerId}
                      value={AdminIdType.FortnoxCustomerId}
                    >
                      {translate("FORTNOX_CUSTOMER_DATABASE_ID")}
                    </Tab>
                    <Tab
                      key={AdminIdType.OrgNumber}
                      value={AdminIdType.OrgNumber}
                    >
                      {translate("ORG_NUM")}
                    </Tab>
                  </TabList>
                </Col>
              </Row>

              <Row className="text-center m-auto search-customer align-items-center pt-lg">
                <Col md={4}>
                  <div className="horizontal-divider" />
                </Col>

                <Col xs={4}>
                  <SearchInput
                    disabled={isLoading}
                    placeholder={
                      searchByAdminType === AdminIdType.OrgNumber
                        ? translate("OFFER_COMPANY_SEARCH_ORG_PLACEHOLDER")
                        : translate(
                            "OFFER_COMPANY_SEARCH_FORTNOX_CUSTOMER_DATABASE_ID_PLACEHOLDER"
                          )
                    }
                    onChange={(_, newValue) => {
                      setSearchInput(newValue.value || "");
                      resetErrors();
                    }}
                    onBlur={() => validate()}
                    value={searchInput}
                    errorMessage={getErrorMessage()}
                    data-testid="search-input"
                  />
                </Col>

                <Col md={4}>
                  <div className="horizontal-divider" />
                </Col>
              </Row>
            </ContentBodyCenter>
          </ContentBody>

          <ContentFooter>
            <Row className="stepper-buttons justify-content-end">
              <Col md="auto">
                <Button
                  disabled={isLoading || !searchInput || !!searchInputError}
                  onClick={handleSubmit}
                >
                  <span className="d-flex flex-row align-items-center">
                    <span className="px-sm">
                      {translate("ADD_FORTNOX_ADMIN")}
                    </span>
                    <ArrowRight16Regular />
                  </span>
                </Button>
              </Col>
            </Row>
          </ContentFooter>
        </Content>
      </LayoutWrapper>
    </>
  );
}
