import {
  Content,
  ContentBody,
  ContentFooter,
} from "components/content/Content";
import { Breadcrumb, ContentHeader } from "components/content/ContentHeader";
import { SectionProps } from "components/section/Section";
import { LoadingStatus } from "state";
import {
  WizardNavigationButtons,
  WizardNavigationProps,
} from "./WizardNavigationButtons";

type CustomerWizardSectionProps = {
  serviceName?: string;
  titleBreadCrumb?: string;
  loadingStatus?: LoadingStatus;
  subtitles?: string[];
  hasDivider?: boolean;
  customBackButtonRoute?: string;
  disabledBackMessage?: string | JSX.Element | JSX.Element[];
} & WizardNavigationProps &
  SectionProps;

function WizardSectionCustomer({
  serviceName = "",
  titleBreadCrumb,
  onNext = () => true,
  onPrevious,
  isNextDisabled,
  onBeforeNext,
  children,
  predefinedNextRoute,
  hideNavigation = false,
  loadingStatus,
  subtitles,
  nextLabel,
  backLabel,
  isNextHidden,
  disabledBackMessage,
  customBackButtonRoute,
  hasDivider,
}: CustomerWizardSectionProps) {
  const getBreadcrumbs = (): Breadcrumb[] => {
    const result = [];

    if (serviceName) {
      result.push({ title: serviceName });
    }

    if (titleBreadCrumb) {
      result.push({ title: titleBreadCrumb });
    }

    return result;
  };

  return (
    <Content>
      <ContentHeader
        loadingStatus={loadingStatus}
        subtitles={subtitles}
        breadcrumbs={getBreadcrumbs()}
        hasDivider={hasDivider}
      />

      <ContentBody>{children}</ContentBody>

      <ContentFooter>
        <WizardNavigationButtons
          onNext={onNext}
          onBeforeNext={onBeforeNext}
          isNextDisabled={isNextDisabled}
          predefinedNextRoute={predefinedNextRoute}
          onPrevious={onPrevious}
          hideNavigation={hideNavigation}
          nextLabel={nextLabel}
          backLabel={backLabel}
          isNextHidden={isNextHidden}
          disabledBackMessage={disabledBackMessage}
          customBackButtonRoute={customBackButtonRoute}
        />
      </ContentFooter>
    </Content>
  );
}

export default WizardSectionCustomer;
