import { isValidSwedishSSN } from "./is-valid-ssn";

/**
 * Checks whether a string is a valid organization number or SSN.
 * @param orgnr The string to check.
 * @returns Whether the string is a valid organization number or SSN.
 */
export function isValidOrganizationNumberOrSSN(
  orgnr: string,
  checkEmpty = false,
  checkPrivate = false
) {
  if (checkEmpty && !orgnr) {
    return false;
  }

  const orgnrRegex = /^(([5]\d{5}[-]\d{4})|([5]\d{9}\s))$/;
  const match = orgnrRegex.test(orgnr);

  if (checkPrivate) {
    return match || isValidSwedishSSN(orgnr, false) || orgnr === "000";
  }

  return match || isValidSwedishSSN(orgnr, false);
}
