import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Warning16Filled } from "@fluentui/react-icons";

import { hasResponseError } from "api/libs/responseError";
import { useTranslation } from "hooks/use-translate";
import { isValidOrganizationNumberOrSSN } from "libs/is-valid-organization-number-or-ssn";
import { normalizeOrgNrAndSSN } from "libs/number-format";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { DealCustomer } from "models/offer/Customer";
import { DealContact } from "models/offer/DealContact";
import { CustomerSearchComponent } from "shared/CustomerSearch";
import { RootState } from "state";
import { appendError, appendToastMessage } from "state/notifications";
import { SearchBy, searchForCustomer } from "state/offer/companyThunks";
import {
  resetSelectedServiceOptions,
  resetSelectedServiceType,
  updateCurrentOffer,
  updateOfferContact,
  updateOfferCustomer,
} from "state/offer/offersSlice";
import { resetOffer } from "state/offer/offersThunks";
import { fetchDealsByOrgNumber } from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import { useRoutingForOffer } from "views/offer/components/wizardSection/useRoutingForOffer";
import { WizardSection } from "../../components/wizardSection";
import { OfferRouteHelper } from "../offerRoutes";

export default function CustomerSearch() {
  const { translate } = useTranslation();

  const { data: currentOffer, status } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  const { status: billingStatus } = useSelector(
    (state: RootState) => state.offers.customerBillings
  );
  const [combinedStatus, setCombinedStatus] = useState<LoadingStatusEnum>();

  const location = useLocation();
  const routing = useRoutingForOffer(location.pathname, currentOffer);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [customerId, setCustomerId] = useState(
    currentOffer?.customer?.customer_number ?? ""
  );

  const [searchCustomerBy, setSearchCustomerBy] = useState<SearchBy>(
    customerId.length > 8 ? SearchBy.OrgNumber : SearchBy.CustomerNumber
  );

  const hasServicesSelected =
    (currentOffer?.service_areas[0].service_lines.length ?? 0) > 0;

  const [searchErrorHasOccurred, setSearchErrorHasOccurred] = useState(false);

  const [customerIsBlocked, setCustomerIsBlocked] = useState(false);

  const isInvalidOrgNumber =
    searchCustomerBy === SearchBy.OrgNumber &&
    !isValidOrganizationNumberOrSSN(customerId);

  const customer = currentOffer?.customer;

  useEffect(() => {
    setCustomerId("");
    dispatch(resetOffer());
  }, [dispatch]);

  useEffect(() => {
    if (currentOffer?.new_customer && !hasServicesSelected) {
      dispatch(resetOffer());
    }
  }, [hasServicesSelected, currentOffer, dispatch]);

  useEffect(() => {
    if (
      status === LoadingStatusEnum.FAILED ||
      billingStatus === LoadingStatusEnum.FAILED
    ) {
      setCombinedStatus(LoadingStatusEnum.FAILED);
      return;
    }

    if (
      status === LoadingStatusEnum.PENDING ||
      billingStatus === LoadingStatusEnum.PENDING
    ) {
      setCombinedStatus(LoadingStatusEnum.PENDING);
      return;
    }

    setCombinedStatus(LoadingStatusEnum.IDLE);
  }, [status, billingStatus]);

  const fetchCompanyData = async () => {
    const normalizedNumber = normalizeOrgNrAndSSN(customerId);
    if (
      [customer?.org_number, customer?.customer_number].includes(customerId) ||
      isInvalidOrgNumber ||
      !normalizedNumber
    ) {
      return;
    }

    dispatch(resetOffer());
    try {
      const tempCustomerResult = await dispatch(
        searchForCustomer({
          searchData: {
            customerId: normalizedNumber,
            searchByParam: searchCustomerBy,
          },
          checkCustomerBlocked: true,
        })
      );

      if (tempCustomerResult.meta.requestStatus === "rejected") {
        throw tempCustomerResult.payload;
      }

      const tempCustomer = unwrapResult(tempCustomerResult);
      if (tempCustomer) {
        if (tempCustomer === "CUSTOMER_BLOCKED") {
          setCustomerIsBlocked(true);
        } else {
          if (tempCustomer.org_number) {
            dispatch(fetchDealsByOrgNumber(tempCustomer.org_number));
          }
          navigate(OfferRouteHelper.getCustomerDetails());
        }
      }
    } catch (error) {
      if (hasResponseError(404, error)) {
        setSearchErrorHasOccurred(true);
      } else {
        dispatch(
          appendError("FAILED_TO_FETCH_CUSTOMER", {
            cause: error,
          } as Error)
        );
      }
    }
  };

  const goToRegisterCustomer = () => {
    dispatch(resetOffer());
    dispatch(updateOfferCustomer(new DealCustomer()));
    dispatch(updateOfferContact(new DealContact()));
    dispatch(
      updateCurrentOffer({
        new_customer: true,
      })
    );

    navigate(OfferRouteHelper.getRegisterCompany());
  };

  function formatErrorMessage(message: string) {
    return (
      <span style={{ color: "var(--red)" }}>
        <Warning16Filled className="mr-xs" />
        {message}
      </span>
    );
  }

  const searchCustomerErrorMessage = () => {
    if (customerId === "") {
      return "";
    }

    if (isInvalidOrgNumber) {
      return formatErrorMessage(translate("INVALID_ORGANIZATION_NUMBER"));
    }

    if (searchErrorHasOccurred) {
      if (searchCustomerBy === SearchBy.CustomerNumber) {
        return formatErrorMessage(translate("INVALID_CUSTOMER_NUMBER"));
      }

      return formatErrorMessage(translate("ORGANIZATION_NUMBER_NOT_FOUND"));
    }

    if (customerIsBlocked) {
      return formatErrorMessage(translate("CUSTOMER_BLOCKED"));
    }

    return "";
  };

  const clearInputErrorMsg = () => {
    setSearchErrorHasOccurred(false);
    setCustomerIsBlocked(false);
  };

  const handleOnBeforePrevious = async () => {
    await dispatch(resetOffer());
    await dispatch(resetSelectedServiceType());
    await dispatch(resetSelectedServiceOptions());

    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
  };

  return (
    <WizardSection
      loadingStatus={combinedStatus}
      isNextHidden={!customer}
      onNext={() =>
        !currentOffer?.id &&
        dispatch(appendToastMessage("NEW_OFFER_TOAST", "success"))
      }
      onBeforePrevious={handleOnBeforePrevious}
      subtitles={[translate("BUSINESS_DESCRIPTION")]}
    >
      <CustomerSearchComponent
        fetchCompanyData={fetchCompanyData}
        goToRegisterCustomer={goToRegisterCustomer}
        searchErrorHasOccurred={searchErrorHasOccurred}
        searchCustomerErrorMessage={searchCustomerErrorMessage()}
        clearInputErrorMsg={clearInputErrorMsg}
        setSearchCustomerBy={setSearchCustomerBy}
        searchCustomerBy={searchCustomerBy}
        setCustomerIdValue={setCustomerId}
      />
    </WizardSection>
  );
}
