import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { RootState } from "state";
import { BreadcrumbDefinitionItem } from "components/breadcrumbV2";
import { Breadcrumb } from "components/content/ContentHeader";
import { useStepperItemsForOffer } from "../wizardStepper";

export function useGetBreadcrumbs() {
  const currentOffer = useSelector(
    (state: RootState) => state.offers.currentOffer.data
  );
  const location = useLocation();

  const steps = useStepperItemsForOffer(location.pathname, currentOffer);

  const currentStep = steps.find((step) => step.state?.active);

  const activeSubSteps =
    currentStep?.subSteps
      .filter(
        (subStep) =>
          subStep.state?.active && subStep.label !== currentStep.label
      )
      .flatMap((subStep) => subStep.label) ?? [];

  const renameProperty = (
    objects: Breadcrumb[],
    oldPropName: keyof Breadcrumb,
    newPropName: keyof BreadcrumbDefinitionItem
  ): BreadcrumbDefinitionItem[] => {
    return objects.map((obj) => {
      const { [oldPropName]: oldValue, ...rest } = obj;
      return {
        ...rest,
        [newPropName]: oldValue,
      } as BreadcrumbDefinitionItem;
    });
  };

  const getBreadcrumbs = (
    serviceName: string,
    isForV9?: boolean,
    numberBreadCrumb?: number,
    titleBreadCrumb?: string
  ): Breadcrumb[] | BreadcrumbDefinitionItem[] => {
    let result = [];

    if (serviceName) {
      result.push({ title: serviceName });
    }

    if (currentStep?.label) {
      result.push({ title: currentStep.label });
    }

    if (activeSubSteps && activeSubSteps.length > 0) {
      result = [
        ...result,
        ...activeSubSteps.map((activeSubStep, index) => ({
          title:
            index === activeSubSteps.length - 1 && numberBreadCrumb
              ? `${activeSubStep} (${numberBreadCrumb})`
              : activeSubStep,
        })),
      ];
    }

    if (titleBreadCrumb) {
      result.push({ title: titleBreadCrumb });
    }

    if (isForV9) {
      const breadcrumbItems = renameProperty(result, "title", "text");
      return breadcrumbItems.length > 0 ? breadcrumbItems : [];
    }

    return result.length > 0 ? result : [];
  };

  return {
    currentStep,
    activeSubSteps,
    getBreadcrumbs,
  };
}
