import { ServiceToggle } from "./ServicePicker";

interface IDictionary<TValue> {
  [id: string]: TValue;
}
export const availableTogglesMeta: IDictionary<{
  disabled: boolean;
  isPackage: boolean;
  sortingNumber: number;
}> = {
  "Annual accounts": {
    disabled: false,
    isPackage: false,
    sortingNumber: 1,
  },
  "Income tax": {
    disabled: false,
    isPackage: false,
    sortingNumber: 2,
  },
  "Current accounting": {
    disabled: false,
    isPackage: true,
    sortingNumber: 3,
  },
  "Small business": {
    disabled: false,
    isPackage: true,
    sortingNumber: 4,
  },
  "Small business (EF)": {
    disabled: false,
    isPackage: true,
    sortingNumber: 5,
  },
};

export const isToggleDisabled = (
  serviceName: string,
  serviceToggles: ServiceToggle[]
) => {
  const serviceToggle = availableTogglesMeta[serviceName];

  // disable toggle if service state is not defined
  if (!serviceToggle) {
    return true;
  }

  const selectedServices = serviceToggles.filter((st) => st.enabled);

  if (selectedServices.length) {
    const isPackageSelected = selectedServices.find(
      (st) => availableTogglesMeta[st.label].isPackage
    );

    // disable all toggle if package is selected
    if (isPackageSelected && isPackageSelected.label !== serviceName) {
      return true;
    }

    // disable packages if non package is selected
    if (!isPackageSelected && serviceToggle.isPackage) {
      return true;
    }
  }

  return serviceToggle.disabled;
};
