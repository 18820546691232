import { Row, Col } from "react-bootstrap";
import { ArrowClockwise20Regular } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import { Deal } from "models/deals/deal";
import { DropdownV9 } from "components/dropdown/DropdownV9";
import { LayoutSubHeader } from "components/layout/Layout";
import { useDealsFilters } from "./useDealsFilters";
import { ActiveFilters } from "..";

import "./DealsFilters.scss";

type DealsFiltersProps = {
  setFilteredDeals: (filteredDeals: Deal[]) => void;
  activeFilters: ActiveFilters;
  setActiveFilters: (activeFilters: ActiveFilters) => void;
};

export function DealsFiltersBox({
  setFilteredDeals,
  activeFilters,
  setActiveFilters,
}: DealsFiltersProps) {
  const { translate } = useTranslation();
  const { filterByDateOptions, filterByInboundOutboundOptions } =
    useDealsFilters({
      activeFilters,
      setFilteredDeals,
    });

  return (
    <LayoutSubHeader>
      <Row className="w-100 py-lg">
        <Col md={11}>
          <Row>
            <Col md={3}>
              <label className="mb-sm">
                <span className="fw-semibold mr-sm">
                  {translate(
                    "MY_BUSINESS_OPPORTUNITIES.FILTERS.DATE_OF_CREATION"
                  )}
                </span>
                <ArrowClockwise20Regular
                  className="blue-link"
                  onClick={() =>
                    setActiveFilters({
                      ...activeFilters,
                      selectedDateRange: undefined,
                    })
                  }
                />
              </label>
              <DropdownV9
                className="deals-filters-dropdown"
                placeholder={translate(
                  "MY_BUSINESS_OPPORTUNITIES.FILTERS.CHOOSE_DATERANGE"
                )}
                appearance="underline"
                selectedOptions={[activeFilters.selectedDateRange ?? ""]}
                onOptionSelect={(_, item) => {
                  if (item.optionValue) {
                    setActiveFilters({
                      ...activeFilters,
                      selectedDateRange: item.optionValue,
                    });
                  }
                }}
                options={filterByDateOptions}
              />
            </Col>
            <Col md={3}>
              <label className="mb-sm">
                <span className="fw-semibold mr-sm">
                  {translate(
                    "MY_BUSINESS_OPPORTUNITIES.FILTERS.INBOUND_OUTBOUND"
                  )}
                </span>
                <ArrowClockwise20Regular
                  className="blue-link"
                  onClick={() =>
                    setActiveFilters({
                      ...activeFilters,
                      inboundOutboundDeals: undefined,
                    })
                  }
                />
              </label>
              <DropdownV9
                className="deals-filters-dropdown"
                placeholder={translate(
                  "MY_BUSINESS_OPPORTUNITIES.FILTERS.INBOUND_OUTBOUND"
                )}
                appearance="underline"
                selectedOptions={[activeFilters.inboundOutboundDeals ?? ""]}
                onOptionSelect={(_, item) => {
                  if (item.optionValue) {
                    setActiveFilters({
                      ...activeFilters,
                      inboundOutboundDeals: item.optionValue,
                    });
                  }
                }}
                options={filterByInboundOutboundOptions}
              />
            </Col>
          </Row>
        </Col>
        <Col md={1} className="d-flex align-items-center">
          <div className="deals-filter-vertical-divider pr-sm ml-sm" />
          <div
            className="cursor-pointer text-color-blue"
            onClick={() => setActiveFilters({})}
          >
            <ArrowClockwise20Regular className="mr-xs" />
            <span>{translate("NO_FILTERED_CUSTOMERS_RESET")}</span>
          </div>
        </Col>
      </Row>
    </LayoutSubHeader>
  );
}