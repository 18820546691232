import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import BreadcrumbV9, {
  BreadcrumbDefinitionItem,
} from "components/breadcrumbV2";
import {
  LayoutFooter,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutWrapper,
} from "components/layout/Layout";
import Stepper from "components/stepper";
import { useTranslation } from "hooks/use-translate";
import { CompanyTypeOptions, ValidLegalFormCodes } from "models/offer/Company";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import {
  fetchDealProperties,
  fetchPipelines,
  fetchProducts,
} from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import {
  getAvailableWizardStepsForCreateBusinessOpportunity,
  sortedBusinessOpportunityWizardSteps,
  useStepperItemsForCreateBusinessOpportunity,
} from "./StepperHelper";

export default function BusinessOpportunityWizard() {
  const location = useLocation();
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { isNewCustomer } = useSelector((state: RootState) => state.sales);
  const { data: companyInfo } = useSelector(
    (state: RootState) => state.offers.companyInfo
  );
  const customer = useSelector(
    (state: RootState) => state.offers.currentOffer.data?.customer
  );

  useEffect(() => {
    const init = async () => {
      dispatch(fetchPipelines());
      dispatch(fetchProducts());
      dispatch(fetchDealProperties());
    };

    init();
  }, [dispatch]);

  const availableSteps =
    getAvailableWizardStepsForCreateBusinessOpportunity(isNewCustomer);
  const steps = useStepperItemsForCreateBusinessOpportunity(
    location.pathname,
    availableSteps
  );
  const sortedSteps = sortedBusinessOpportunityWizardSteps(steps);

  const breadcrumbs: BreadcrumbDefinitionItem[] = [
    {
      text: translate("CREATE_NEW"),
      onClick: () => navigate("/create-new"),
    },
    {
      text: translate("BUSINESS_OPPORTUNITY"),
      onClick: () => {
        if (isNewCustomer || customer?.org_number || companyInfo?.org_number) {
          navigate(AppRouteHelper.getBusinessOpportunity());
        }
      },
    },
  ];

  if (isNewCustomer) {
    breadcrumbs.push({
      text: translate("NEW_CUSTOMER"),
    });
  } else if (customer?.org_number || companyInfo?.org_number) {
    breadcrumbs.push({
      text: translate("EXISTING_CUSTOMER"),
    });
  }

  const getCompanyTypeLabel = () => {
    let companyType = "";
    if (customer && customer.legal_form) {
      companyType =
        CompanyTypeOptions[customer.legal_form as ValidLegalFormCodes].label;
    } else if (companyInfo && companyInfo.company_type) {
      companyType =
        CompanyTypeOptions[companyInfo.company_type as ValidLegalFormCodes]
          .label;
    }
    return companyType;
  };

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <BreadcrumbV9 items={breadcrumbs} />

          {((!isNewCustomer && companyInfo) || customer) && (
            <div className="d-flex align-items-center">
              <div className="vertical-divider ml-lg mr-md" />
              <div className="info-label">
                {translate("CUSTOMER")}:
                <span className="fw-semibold ml-xs">
                  {customer?.customer_name || companyInfo?.name}
                </span>
              </div>
              <div className="info-label ml-lg">
                {translate("COMP_TYPE")}:
                <span className="fw-semibold ml-xs">
                  {getCompanyTypeLabel()}
                </span>
              </div>
            </div>
          )}
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutWrapper>
        <Outlet />
      </LayoutWrapper>
      <LayoutFooter>
        <Stepper steps={sortedSteps} />
      </LayoutFooter>
    </>
  );
}
