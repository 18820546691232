import { I18nAction, SET_LANGUAGE } from ".";
import { Language } from "../../i18n";

export type I18nState = {
  language: Language;
};

const STORAGE_KEY = "language";
const initialState: I18nState = { language: "SV" };

export function i18nReducer(
  state: I18nState = initialState,
  action: I18nAction
) {
  switch (action.type) {
    case SET_LANGUAGE:
      localStorage.setItem(STORAGE_KEY, action.payload);
      return { language: action.payload };
    default:
      // TODO: This can be fetched from the context of the Teams tab
      const language = localStorage.getItem(STORAGE_KEY) || state.language;
      return { language };
  }
}
