import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { unwrapResult } from "@reduxjs/toolkit";
import { Info20Regular } from "@fluentui/react-icons";

import { TranslationKey } from "i18n";
import { useTranslation } from "hooks/use-translate";
import { useAppDispatch } from "state/use-app-redux";
import { SearchBy, fetchCompanyInfoFromSales } from "state/offer/companyThunks";
import { RHFInput } from "components/input/RHFInput";
import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";
import { MAX_CHAR_COMPANY_NAME } from "constants/maxCharConsts";
import { isValidOrganizationNumberOrSSN } from "libs/is-valid-organization-number-or-ssn";
import { getDigitsFromString, normalizeOrgNrAndSSN } from "libs/number-format";
import { CustomerTypes } from "models/customer";
import { CreateDeal } from "models/deals/deal";
import { CompanyInformation } from "models/offer/Company";
import { CreateBusinessOpportunityContext } from "views/bussinessOpportunity/wizard/CreateBusinessOpportinityContext";

type CustomerTypeRemoveModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onDiscard: () => void;
  onSave: () => void;
};

export default function CompanyDetailsModal({
  isOpen,
  onCancel,
  onDiscard,
  onSave,
}: CustomerTypeRemoveModalProps) {
  const { translate, ts } = useTranslation();
  const dispatch = useAppDispatch();

  const { updateNewDeal, newDeal } = useContext(
    CreateBusinessOpportunityContext
  );

  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const [existingCustomer, setExistingCustomer] =
    useState<CompanyInformation>();
  const [orgNumber, setOrgNumber] = useState("");

  const methods = useForm<CreateDeal>({
    mode: "onTouched",
  });

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = methods;

  const isExistingCustomer = async (newOrgNumber: string) => {
    if (newOrgNumber) {
      if (newOrgNumber === orgNumber) {
        return existingCustomer;
      }
      try {
        setIsLoadingCustomer(true);
        setOrgNumber(newOrgNumber);

        const tempCustomerResult = await dispatch(
          fetchCompanyInfoFromSales({
            customerId: newOrgNumber,
            searchByParam: SearchBy.OrgNumber,
          })
        );

        const customer = unwrapResult(tempCustomerResult);
        if (customer && customer.org_number) {
          setExistingCustomer(customer);
        }
        return customer;
      } catch (e) {
        setExistingCustomer(undefined);
        return false;
      } finally {
        setIsLoadingCustomer(false);
      }
    }
  };

  const organizationNumberValidation = register("organisationsnummer", {
    validate: {
      isValid: async (number) => {
        if (!number) {
          return true;
        }

        if (
          (number.length !== 10 && number.length !== 12) ||
          !isValidOrganizationNumberOrSSN(number, true)
        ) {
          return "INVALID_ORGNR_OR_SSN_MESSAGE";
        }

        return true;
      },
      isExistingCustomer: async (number) => {
        const normalizedNumber = normalizeOrgNrAndSSN(number);
        const customer = await isExistingCustomer(normalizedNumber);
        if (customer) {
          return customer.org_number;
        }
      },
    },
  });

  const companyValidation = register("company", {
    required: "CUSTOMER_NAME_REQUIRED",
  });

  const getErrorTranslationWithArgs = (
    errorMessage: TranslationKey,
    errorType: string
  ) => {
    if (errorType === "isExistingCustomer") {
      return translate("CUSTOMER_ALREADY_EXISTS", [errorMessage]);
    }
    return translate(errorMessage);
  };

  const handleOnOrganizationNumberChange = (value: string) => {
    setValue("organisationsnummer", getDigitsFromString(value), {
      shouldValidate: true,
    });
    if (newDeal.customer_type === CustomerTypes.COMPANY) {
      updateNewDeal({
        organisationsnummer: getDigitsFromString(value),
      });
    }
  };

  const handleCustomerNameChange = (
    value: string,
    field: string,
    shouldValidate = false
  ) => {
    setValue("company", value, {
      shouldValidate,
    });
    updateNewDeal({ [field]: value });
  };

  return (
    <Modal
      size="medium"
      isOpen={isOpen}
      onDismiss={onDiscard}
      header={
        <ModalHeader
          headerTitleText="MY_BUSINESS_OPPORTUNITIES.WIZARD.CUSTOMER_DETAILS_MODAL_HEADER"
          subheaderText="MY_BUSINESS_OPPORTUNITIES.WIZARD.CUSTOMER_DETAILS_MODAL_SUBHEADER"
          subheaderIcon={<Info20Regular className="mx-sm text-color-blue" />}
        />
      }
      footer={
        <ModalFooter
          onCancel={onCancel}
          onSave={onSave}
          labelSubmit="CONTINUE"
          isDisabled={!getValues("company")}
        />
      }
    >
      <Row className="minHeight-100 align-items-center pb-sm">
        <Col md={3}>
          {" "}
          <div className="horizontal-divider m-sm" />
        </Col>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <label className="fw-semibold" htmlFor={companyValidation.name}>
                {translate("COMPANY_NAME")}*
              </label>
              <RHFInput
                placeholder={translate("COMPANY_NAME")}
                className="customer-details-input"
                maxChar={MAX_CHAR_COMPANY_NAME}
                value={getValues("company")}
                onChange={(e) => {
                  handleCustomerNameChange(e.target.value, "company", true);
                }}
                register={companyValidation}
                errorMessage={
                  errors.company &&
                  errors.company.message &&
                  ts(errors.company.message)
                }
                disabled={isLoadingCustomer}
              />
            </Col>
            <Col md={12}>
              <label
                className="fw-semibold"
                htmlFor={organizationNumberValidation.name}
              >
                {translate("ORG_NUM")}
              </label>
              <RHFInput
                register={organizationNumberValidation}
                title={`${translate("USE_FORMAT")} ${translate(
                  "ORGNR_OR_SSN_PLACEHOLDER"
                )}`}
                placeholder={translate("ORGANIZATION_NUMBER")}
                className="customer-details-input"
                onChange={(e) => {
                  handleOnOrganizationNumberChange(e.target.value);
                }}
                errorMessage={
                  errors.organisationsnummer &&
                  getErrorTranslationWithArgs(
                    errors?.organisationsnummer?.message as TranslationKey,
                    errors?.organisationsnummer.type
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col md={3}>
          {" "}
          <div className="horizontal-divider m-sm" />
        </Col>
      </Row>
    </Modal>
  );
}
