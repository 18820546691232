import { useRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import "./TextArea.scss";

export type TextAreaProps = {
  formRegister?: UseFormRegisterReturn;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export function TextArea({
  className = "",
  formRegister,
  ...rest
}: TextAreaProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const cName = `form-control textarea ${className || ""}`;

  // Support tabs
  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (
    e: React.KeyboardEvent
  ) => {
    if (e.key !== "Tab") {
      return;
    }

    e.preventDefault();

    const start = textareaRef.current!.selectionStart;
    const end = textareaRef.current!.selectionEnd;

    textareaRef.current!.value =
      textareaRef.current!.value.substring(0, start) +
      "\t" +
      textareaRef.current!.value.substring(end);
    textareaRef.current!.selectionStart = textareaRef.current!.selectionEnd =
      start + 1;
  };

  return (
    <textarea
      {...formRegister}
      {...rest}
      className={cName}
      ref={textareaRef}
      onKeyDown={(e) => onKeyDown(e)}
    />
  );
}
