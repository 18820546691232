import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useTranslation } from "hooks/use-translate";
import { CustomerDetailsParams } from "routes";
import { RootState } from "state";
import {
  resetExpandAll,
  resetSearchTerm,
} from "state/customerSettings/customerSettingsSlice";
import { AppDispatch } from "state/use-app-redux";
import CustomerPageDetailedInfo from "./customerPageDetailedInfo";
import "./customerPageDetailedInfo.scss";

function CustomerPageDetailedInfoPage() {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { customerId } = useParams<CustomerDetailsParams>();
  const customers = useSelector((state: RootState) => state.customers.data);
  const customer = customers.find(
    (e) => e.customer.customer_number === customerId
  )?.customer;
  const { info } = useSelector(
    (state: RootState) => state.settings.detailedInfo.data
  );

  useEffect(() => {
    dispatch(resetExpandAll());
    dispatch(resetSearchTerm());
  }, [dispatch]);

  return (
    <div className="new-page-content">
      <div className="header-title px-lg d-flex header-sticky contentHeader">
        <h3 className="pl-sm stringLength">
          {translate("CUSTOMER_SPECIFIC_INFORMATION")}
        </h3>
        <span className="accordion-subtitle align-self-center ml-sm">
          ({info.length})
        </span>
      </div>
      {customer && customerId ? (
        <CustomerPageDetailedInfo
          customerId={customerId}
          customerManagerId={customer.salesperson_number}
        />
      ) : (
        <div className="p-lg">{translate("NO_CUSTOMER_ID")}</div>
      )}
    </div>
  );
}

export { CustomerPageDetailedInfoPage };
