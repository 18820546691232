import { Spinner } from "@fluentui/react-components";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { authentication } from "@microsoft/teams-js";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { useEffect, useState } from "react";

import { SpinnerSize } from "components/spinner";
import App from "./App";
import {
  teamsCredentialsConfig,
  TeamsFxContext,
  useNavigateFromTeamsUrl,
} from "./auth/teams.msal";
import { useTranslation } from "./hooks";
import { setTranslation } from "./mgt-config";

function AppTeams() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const { loading, teamsUserCredential } = useTeamsUserCredential(
    teamsCredentialsConfig
  );
  const { translate } = useTranslation();

  useNavigateFromTeamsUrl();

  useEffect(() => {
    setTranslation(translate);
  }, [translate]);

  useEffect(() => {
    const init = async () => {
      try {
        await authentication.getAuthToken({ silent: true });
        Providers.globalProvider.setState(ProviderState.SignedIn);
        setIsSignedIn(true);
      } catch (err) {
        console.error("An error occurred on initial authentication", err);
      }
    };

    if (!isSignedIn) {
      init();
    }
  }, [isSignedIn]);

  if (
    !isSignedIn ||
    loading ||
    Providers.globalProvider.state === ProviderState.Loading
  ) {
    return (
      <div className="p-lg text-center d-flex align-items-center justify-content-center">
        <Spinner
          className="mr-sm"
          label={translate("LOGGING_IN")}
          size={SpinnerSize.ExtraSmall}
        />
      </div>
    );
  }

  return (
    <TeamsFxContext.Provider value={{ teamsUserCredential }}>
      <App />
    </TeamsFxContext.Provider>
  );
}

export default AppTeams;
