import { useState } from "react";
import { ArrowMinimize20Regular } from "@fluentui/react-icons";

import Section from "components/section/Section";
import SectionHeader from "components/section/SectionHeader";
import { LoadingStatus } from "state";

type CustomerPageSectionProps = {
  sectionTitle: string;
  status?: LoadingStatus;
  headerContent?: React.ReactNode;
  expanded?: boolean;
} & React.HTMLAttributes<HTMLElement>;

export default function CustomerPageSection({
  children,
  sectionTitle = "",
  status = "succeeded",
  headerContent,
  expanded = true,
}: CustomerPageSectionProps) {
  const [isSectionExpanded, setIsSectionExpanded] = useState(expanded);

  return (
    <Section>
      <SectionHeader
        title={sectionTitle}
        loadingStatus={status}
        content={
          headerContent ||
          (status !== "pending" ? (
            <ArrowMinimize20Regular
              className="icon-blue"
              onClick={() => setIsSectionExpanded(!isSectionExpanded)}
            />
          ) : null)
        }
      />
      {isSectionExpanded && children}
    </Section>
  );
}
