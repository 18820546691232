import { LockClosed20Regular } from "@fluentui/react-icons";

import { useTranslation } from "hooks";
import { ActivityInstance } from "models/activities/activity";
import TooltipV9 from "components/tooltip/TooltipV9";

type Props = {
  activityInstance: ActivityInstance;
  isEndOfYearTooltip?: boolean;
};

export default function ActivityTooltip({
  activityInstance,
  isEndOfYearTooltip = false,
}: Props) {
  const { translate } = useTranslation();

  return isEndOfYearTooltip ? (
    <TooltipV9
      hasJSXContent
      content={
        <p className="no-margin">
          {translate("YEAREND_FINISH_ACTIVITY_TOOLTIP")}
        </p>
      }
    >
      <LockClosed20Regular
        className={`mr-xs ${
          activityInstance.completed_at ? "text-color-blue" : null
        }`}
      />
    </TooltipV9>
  ) : (
    <TooltipV9
      hasJSXContent
      content={
        <p className="no-margin">{translate("LOCK_ACTIVITY_TOOLTIP")}</p>
      }
    >
      <LockClosed20Regular
        className={`mr-xs ${
          activityInstance.completed_at ? "text-color-blue" : null
        }`}
      />
    </TooltipV9>
  );
}
