import { createAsyncThunk } from "@reduxjs/toolkit";

import { OffersAPI } from "api/offers";
import { UsersAPI } from "api/users";
import { authMethod } from "auth";
import { DealContact } from "models/offer/DealContact";
import { CustomerDeal } from "models/offer/CustomerDeal";
import { ServiceArea, ServiceCategory } from "models/offer/ServiceLine";
import { TaxObjectDetails } from "models/offer/TaxObject";
import { TaxObjectService } from "models/offer/TaxObjectService";
import { isEqualSSN } from "libs/is-equal-ssn";
import { DealCustomer } from "models/offer/Customer";
import { appendError } from "../notifications";
import { resetKYC } from "./kycSlice";
import { acquireKYCdata } from "./kycThunks";
import {
  updateCurrentOffer,
  setCurrentOffer,
  resetOffers,
  resetContacts,
  updateOfferCustomer,
  overwriteCurrentOffer,
  updateOfferContact,
  resetSelectedCustomerSource,
  updateOffers,
} from "./offersSlice";
import { RootState } from "..";

export interface IVerifiedBody {
  envelope_name: string;
  org_number: string;
  company_name: string;
  offer_name: string;
  consultant: {
    email: string;
    first_name: string;
    last_name: string;
    shall_sign: boolean;
  };
  customer: {
    email: string;
    first_name: string;
    last_name: string;
    ssn: string;
    signing_method: string;
    phone_number?: string;
  };
}

export const fetchOffers = createAsyncThunk(
  "offers/FETCH_CUSTOMER_OFFERS",
  async (orgNum: string) => {
    const token = await authMethod.getStoredAccessToken();
    const response: any = await OffersAPI.fetchCustomerOffersByOrgNum(
      token,
      orgNum
    );
    return response;
  }
);

export const fetchDeals = createAsyncThunk(
  "company/FETCH_DEALS",
  async (orgNum: string, { rejectWithValue }) => {
    try {
      const token = await authMethod.getStoredAccessToken();
      const response = await OffersAPI.fetchCreatedDeals(token, orgNum);
      return response;
    } catch (error) {
      return rejectWithValue("FAILED_TO_FETCH_DEALS");
    }
  }
);

export const fetchDeal = createAsyncThunk(
  "company/FETCH_DEAL",
  async (dealInfo: { orgId: string; dealId: string }) => {
    const token = await authMethod.getStoredAccessToken();
    const response = await OffersAPI.fetchCreatedDeal(
      token,
      dealInfo.orgId,
      dealInfo.dealId
    );
    return response;
  }
);

export const copyDeal = createAsyncThunk(
  "company/COPY_DEAL",
  async (dealId: string) => {
    const token = await authMethod.getStoredAccessToken();
    const response = await OffersAPI.copyDeal(token, dealId);
    return response;
  }
);

export const deleteDeal = createAsyncThunk(
  "company/DELETE_DEAL",
  async (deleteDeal: {
    dealId: string;
    orgNum: string;
    data: Partial<CustomerDeal>;
  }) => {
    const token = await authMethod.getStoredAccessToken();
    const response = await OffersAPI.deleteDeal(
      token,
      deleteDeal.dealId,
      deleteDeal.orgNum,
      deleteDeal.data
    );
    return response;
  }
);

export const sendOfferForSigning = createAsyncThunk(
  "offers/SEND_OFFER_FOR_SIGNING",
  async ({
    dealId,
    lcoUserEmail,
  }: {
    dealId: string;
    lcoUserEmail?: string;
  }) => {
    const token = await authMethod.getStoredAccessToken();
    const response = await OffersAPI.sendDealForSigning(
      token,
      dealId,
      lcoUserEmail
    );

    return response;
  }
);

export const updateServicesAndPostDealData = createAsyncThunk(
  "offers/UPDATE_SERVICES_AND_POST_DEAL_DATA",
  async (serviceData: Partial<CustomerDeal>, { dispatch }) => {
    await dispatch(updateCurrentOffer(serviceData));
    await dispatch(sendOfferForCalculation());
  }
);

export const sendOfferForCalculation = createAsyncThunk(
  "offers/SEND_OFFER_FOR_CALCULATION",
  async (_, { getState, dispatch }) => {
    const {
      offers: { currentOffer: offer },
    } = getState() as RootState;

    if (!offer.data) {
      throw new Error("No offer selected");
    }

    const token = await authMethod.getStoredAccessToken();

    try {
      const response = await OffersAPI.sendOfferForCalculation(token, {
        ...offer.data,
        deal_source: offer.data.deal_source,
      } as CustomerDeal);
      return response;
    } catch (error) {
      dispatch(
        appendError("FAILED_TO_CALCULATE_DEAL_PRICE", {
          cause: error,
        } as unknown as Error)
      );
      throw new Error("Save offer failed");
    }
  }
);

export const updateOffer = createAsyncThunk(
  "offers/UPDATE_OFFER",
  async (
    data: { dealId: string; orgNumber: string; payload: Partial<CustomerDeal> },
    { dispatch }
  ) => {
    const token = await authMethod.getStoredAccessToken();

    try {
      const response = await OffersAPI.patchOffer(
        token,
        data.orgNumber,
        data.dealId,
        data.payload
      );
      dispatch(updateOffers({ id: data.dealId, data: response }));
      return response;
    } catch (error) {
      dispatch(
        appendError("FAILED_TO_UPDATE_DEAL", {
          cause: error,
        } as unknown as Error)
      );
      throw new Error("Save offer failed");
    }
  }
);

export const patchOffer = createAsyncThunk(
  "offer/PATCH_OFFER",
  async (data: Partial<CustomerDeal>, { dispatch, getState }) => {
    const {
      offers: { currentOffer: offer },
    } = getState() as RootState;

    if (!offer.data || !data) {
      throw new Error("No offer selected");
    }

    const token = await authMethod.getStoredAccessToken();

    const orgNr = offer.data.customer?.org_number;
    const dealId = offer.data.id;

    if (orgNr && dealId) {
      try {
        const response = await OffersAPI.patchOffer(token, orgNr, dealId, data);

        await dispatch(updateCurrentOffer(data));

        return response;
      } catch (error) {
        dispatch(
          appendError("FAILED_TO_PATCH_DEAL", {
            cause: error,
          } as unknown as Error)
        );

        throw new Error("Failed to patch offer");
      }
    }
  }
);

export const sendCreateDealFromOffer = createAsyncThunk(
  "offers/SEND_CREATE_DEAL_FROM_OFFER",
  async (_, { getState, dispatch }) => {
    const {
      offers: { currentOffer: offer },
    } = getState() as RootState;

    if (!offer.data) {
      throw new Error("No offer selected");
    }

    const deal = { ...offer.data, state: "contract" };
    const token = await authMethod.getStoredAccessToken();
    try {
      const response = await OffersAPI.sendOfferForCalculation(token, {
        ...deal,
        deal_source: offer.data.deal_source,
      } as CustomerDeal);
      return response;
    } catch (error) {
      dispatch(
        appendError("FAILED_TO_CALCULATE_DEAL_PRICE", {
          cause: error,
        } as unknown as Error)
      );
      return offer;
    }
  }
);

export const fetchOfferTemplate = createAsyncThunk(
  "offers/FETCH_OFFER_STATIC_DATA",
  async () => {
    const token = await authMethod.getStoredAccessToken();
    const response = await OffersAPI.fetchOfferTemplate(token);
    return response;
  }
);

export const resetOffer = createAsyncThunk(
  "offers/RESET_OFFER",
  async (_, { dispatch, getState }) => {
    const { users } = getState() as RootState;
    const offer = CustomerDeal.fromUser(users.currentUser);
    dispatch(resetKYC());
    dispatch(resetOffers());
    dispatch(resetContacts());
    dispatch(resetSelectedCustomerSource());
    dispatch(setCurrentOffer(offer));
  }
);

export const setupNewOfferForCustomer = createAsyncThunk(
  "offers/SETUP_NEW_OFFER",
  async (customer: DealCustomer, { dispatch, getState }) => {
    const {
      users,
      offers: { currentOffer },
    } = getState() as RootState;
    const offer = CustomerDeal.fromUser(users.currentUser);
    offer.customer = customer;
    offer.deal_source = currentOffer.data?.deal_source;
    dispatch(overwriteCurrentOffer(offer));
  }
);

export const setupOfferStateForNewCustomerFlow = createAsyncThunk(
  "offers/RESET_OFFER_FOR_NEW_CUSTOMER_FLOW",
  async (_, { dispatch, getState }) => {
    const {
      offers: { offerTemplate },
    } = getState() as RootState;
    if (!offerTemplate.data) await dispatch(fetchOfferTemplate());
    dispatch(resetOffer());
    dispatch(updateOfferCustomer(new DealCustomer()));
    dispatch(updateOfferContact(new DealContact()));
    dispatch(
      updateCurrentOffer({
        new_customer: true,
      })
    );
  }
);

export const updateSigner = createAsyncThunk(
  "offers/UPDATE_SIGNER",
  async (contactData: DealContact, { dispatch, getState }) => {
    dispatch(updateCurrentOffer({ contacts: [contactData] }));
    const { currentOffer: customerOffer } = (getState() as RootState).offers;
    if (!customerOffer.data) {
      throw new Error("No offer selected");
    }
    const signer = customerOffer.data.contacts.find((c) => c.is_signer);

    if (!signer) {
      throw new Error("No signer found");
    }

    if (
      signer.contact.social_security_number &&
      customerOffer.data.customer?.org_number
    ) {
      dispatch(acquireKYCdata(customerOffer.data.customer.org_number));
    } else {
      dispatch(resetKYC());
    }
  }
);

export const fetchAllUsers = createAsyncThunk(
  "users/FETCH_ALL_USERS",
  async () => {
    const token = await authMethod.getStoredAccessToken();
    const response = await UsersAPI.fetchAllUsers(token);
    return response;
  }
);

type UpdateCustomerTaxObjectsData = {
  taxDetailsFormData: TaxObjectDetails;
  newTaxObjectsServices: TaxObjectService[];
};

export const updateCustomerIncomeTaxData = createAsyncThunk(
  "customer/UPDATE_CUSTOMER_TAX_OBJECTS",
  async (
    { taxDetailsFormData, newTaxObjectsServices }: UpdateCustomerTaxObjectsData,
    { dispatch, getState }
  ) => {
    const customerTaxObjects = (getState() as RootState).offers.currentOffer
      ?.data?.customer?.tax_objects;

    if (!customerTaxObjects) {
      throw new Error("Customer objects missing");
    }

    const filteredTaxObjects = customerTaxObjects.filter(
      (tObj) =>
        !isEqualSSN(
          tObj.social_security_number,
          taxDetailsFormData.social_security_number
        )
    );

    dispatch(
      updateOfferCustomer({
        tax_objects: [...filteredTaxObjects, taxDetailsFormData],
      })
    );
  }
);

export type SaveDiscountCommentDataType = {
  comment: string;
  discount: number;
  serviceCategory: ServiceCategory;
  serviceAreas: ServiceArea[];
};

export const saveServiceCategoryDiscountComment = createAsyncThunk(
  "offers/UPDATE_SERVICE_CATEGORY_DISCOUNT_COMMENT",
  async (data: SaveDiscountCommentDataType, { dispatch }) => {
    const { comment, discount, serviceCategory, serviceAreas } = data;
    if (comment && discount) {
      const updatedServiceCategory: ServiceCategory = {
        ...serviceCategory,
        discount_comment: comment,
        discount,
      };
      const newServiceAreas =
        ServiceCategory.updateServiceCategoryInServiceArea(
          serviceAreas,
          updatedServiceCategory
        );
      dispatch(
        updateServicesAndPostDealData({
          service_areas: newServiceAreas,
        })
      );
    }
  }
);

type SavePriceAdjustmentDataType = {
  serviceCategory: ServiceCategory;
  value: number;
  serviceAreas: ServiceArea[];
};

export const saveServiceCategoryPriceAdjustment = createAsyncThunk(
  "offers/UPDATE_SERVICE_CATEGORY_PRICE_ADJUSTMENT",
  async (data: SavePriceAdjustmentDataType, { dispatch }) => {
    const { serviceCategory, value, serviceAreas } = data;
    if (serviceCategory.price_adjustment !== value) {
      const updatedServiceCategory = {
        ...serviceCategory,
        price_adjustment: value,
      };
      const newAreas = ServiceCategory.updateServiceCategoryInServiceArea(
        serviceAreas,
        updatedServiceCategory
      );
      dispatch(
        updateServicesAndPostDealData({
          service_areas: newAreas,
        })
      );
    }
  }
);

type SaveDiscountDataType = {
  serviceCategory: ServiceCategory;
  value: number;
  serviceAreas: ServiceArea[];
};

export const saveServiceCategoryDiscount = createAsyncThunk(
  "offers/UPDATE_SERVICE_CATEGORY_DISCOUNT",
  async (data: SaveDiscountDataType, { dispatch }) => {
    const { serviceCategory, value, serviceAreas } = data;

    if (serviceCategory.discount !== value) {
      const updatedServiceCategory = {
        ...serviceCategory,
        discount: value,
      };
      const newAreas = ServiceCategory.updateServiceCategoryInServiceArea(
        serviceAreas,
        updatedServiceCategory
      );
      dispatch(
        updateServicesAndPostDealData({
          service_areas: newAreas,
        })
      );
    }
  }
);

type UpdateProjectManagerData = {
  newProjectManager: string;
  sc: ServiceCategory;
};

export const saveServiceCategoryProjectManager = createAsyncThunk(
  "offer/UPDATE_SERVICE_CATEGORY_PM",
  async (updateData: UpdateProjectManagerData, { dispatch, getState }) => {
    const {
      offers: { currentOffer: offer },
    } = getState() as RootState;

    if (!offer.data) {
      throw new Error("Missing active offer data");
    }
    const { service_areas: serviceAreas } = offer.data;

    const newAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      serviceAreas,
      { ...updateData.sc, project_manager: updateData.newProjectManager }
    );

    dispatch(
      updateServicesAndPostDealData({
        service_areas: newAreas,
      })
    );
  }
);

export const saveOfferSalesperson = createAsyncThunk(
  "offer/UPDATE_OFFER_SALESPERSON",
  async (newSalesPerson: string, { dispatch, getState }) => {
    const {
      offers: { currentOffer: offer },
    } = getState() as RootState;

    if (!offer.data) {
      throw new Error("Missing active offer data");
    }

    dispatch(
      updateServicesAndPostDealData({
        sales_manager: newSalesPerson,
      })
    );
  }
);

export const fetchDealAdditionalAttachment = createAsyncThunk(
  "offers/FETCH_DEAL_ADDITIONAL_ATTACHMENT",
  async (dealId: string) => {
    const token = await authMethod.getStoredAccessToken();
    const response = await OffersAPI.fetchDealAttachment(token, dealId);

    return response;
  }
);
