import {
  TableColumnDefinition,
  createTableColumn,
} from "@fluentui/react-components";

export interface TableColumnDefinitionExtended<T>
  extends TableColumnDefinition<T> {
  classNameTh?: string;
  classNameTd?: string;
}

export function createTableColumnExtended<T>(
  column: TableColumnDefinitionExtended<T>
): TableColumnDefinitionExtended<T> {
  const originalColumn = createTableColumn({
    columnId: column.columnId,
    compare: column.compare,
    renderHeaderCell: column.renderHeaderCell,
    renderCell: column.renderCell,
  });

  return {
    ...originalColumn,
    classNameTh: column.classNameTh,
    classNameTd: column.classNameTd,
  };
}
