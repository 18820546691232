import "reflect-metadata";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "styles/index.scss";
import "styles/global/colors.scss";
import reportWebVitals from "reportWebVitals";
import store from "state";
import { Bugsnag, getBugsnagErrorBoundary } from "bugsnag";
import Login from "views/login";
import "mgt-config";
import { teamsInitialize } from "auth/teams.msal";
import { isMockAuth, mockInitialize } from "auth/mock.msal";
import { isInTeams } from "libs/teams";
import { browserInitialize } from "auth/browser.msal";
import { AuthorizationProvider } from "auth/use-auth";
import { AppVersion } from "AppVersion";
import AppTeams from "AppTeams";
import AppBrowser from "AppBrowser";
import { LudvigThemeProvider } from "styles/fluent-theme";

// react does not provide toplevel await for now
// and we need it for teams initialization
(async () => {
  if (isMockAuth()) {
    mockInitialize();
  } else if (isInTeams()) {
    await teamsInitialize();
  } else {
    await browserInitialize();
  }
})();

Bugsnag.initialize();
const BugsnagErrorBoundary = getBugsnagErrorBoundary();

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <AuthorizationProvider>
        <LudvigThemeProvider>
          <BugsnagErrorBoundary>
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="*"
                  element={isInTeams() ? <AppTeams /> : <AppBrowser />}
                />
              </Routes>
            </BrowserRouter>
          </BugsnagErrorBoundary>
        </LudvigThemeProvider>
      </AuthorizationProvider>
    </StoreProvider>
    <AppVersion />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
