export class ApplicationError extends Error {
  constructor(
    public name: string = "UNKNOWN_ERROR",
    public args: string[] = []
  ) {
    super(name);
  }
}

function tryParseJSON(str: string) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}

export function parseApplicationError(originError: Error) {
  const rawError = (originError.message || "").replace("Error: ", "");
  const parsed = tryParseJSON(rawError);

  if (!parsed) {
    return new ApplicationError(rawError);
  }

  const { error, error_args } = parsed;

  return new ApplicationError(error, error_args || []);
}
