import { ReactNode, useState } from "react";

import {
  ArrowNext20Regular,
  ArrowPrevious20Regular,
  ChevronLeft20Regular,
  ChevronRight20Regular,
} from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import { DropdownV9, OptionItem } from "components/dropdown/DropdownV9";
import "./Paginator.scss";

export interface PaginatorProps {
  itemsLength: number;
  defaultItemsShownPerPage?: number;
  label?: ReactNode;
  showItemsLabel?: string;
  options?: OptionItem[];
  className?: string;
  presetActivePage?: number;
  onSelectPage?: (pageNumber: number) => void;
  onChangeShownItems?: (itemsShownNumber: number) => void;
}

function Paginator({
  itemsLength,
  label,
  showItemsLabel = "Show",
  defaultItemsShownPerPage = 15,
  options,
  className = "",
  presetActivePage = 1,
  onSelectPage,
  onChangeShownItems,
}: PaginatorProps) {
  const { translate } = useTranslation();

  const [activePage, setActivePage] = useState<number>(presetActivePage);
  const [itemsShownPerPage, setItemsShowPerPage] = useState<number>(
    defaultItemsShownPerPage
  );
  const lastPage = Math.ceil(itemsLength / itemsShownPerPage);

  function setPage(page: number) {
    setActivePage(page);
    onSelectPage?.(page);
  }

  function setShown(itemsNumber: number) {
    setItemsShowPerPage(itemsNumber);
    onChangeShownItems?.(itemsNumber);
    if (activePage === lastPage) {
      const newLastPage = Math.ceil(itemsLength / itemsNumber);
      if (newLastPage < lastPage) {
        setPage(newLastPage);
      }
    }
  }

  const getArrowsClassName = (refPage: number) => {
    const arrowsClassName =
      activePage === refPage ? "disabled" : "cursor-pointer";
    return arrowsClassName;
  };

  return (
    <div className={`d-flex flex-row justify-content-between ${className}`}>
      <div>
        {label && (
          <div>
            <span className="text-grey">{label}:</span> {itemsLength}{" "}
            {translate("RESULTS").toLowerCase()}
          </div>
        )}
      </div>
      <div className="d-flex flex-row justify-content-end align-items-center">
        <div className="d-flex align-items-center pr-sm">
          <ArrowPrevious20Regular
            className={`${getArrowsClassName(1)}`}
            onClick={() => setPage(1)}
          />

          <ChevronLeft20Regular
            className={`${getArrowsClassName(1)}`}
            onClick={() => activePage > 1 && setPage(activePage - 1)}
          />

          {activePage > 1 && (
            <div
              onClick={() => setPage(activePage - 1)}
              className="p-2 cursor-pointer"
            >
              {activePage - 1}
            </div>
          )}

          {activePage < lastPage && (
            <div className="p-2 text-color-blue fw-bold">{activePage}</div>
          )}

          {activePage + 1 < lastPage && (
            <div
              onClick={() => setPage(activePage + 1)}
              className="p-2 cursor-pointer"
            >
              {activePage + 1}
            </div>
          )}

          <div
            onClick={() => setPage(activePage)}
            className="p-2 cursor-pointer"
          >
            ...
          </div>

          <div
            onClick={() => setPage(lastPage)}
            className={`p-2 cursor-pointer ${
              activePage === lastPage && "text-color-blue fw-bold"
            }`}
          >
            {lastPage}
          </div>

          <ChevronRight20Regular
            className={`${getArrowsClassName(lastPage)}`}
            onClick={() => lastPage !== activePage && setPage(activePage + 1)}
          />

          <ArrowNext20Regular
            className={`${getArrowsClassName(lastPage)}`}
            onClick={() => setPage(lastPage)}
          />
        </div>

        {options !== undefined && (
          <>
            <div className="d-flex align-items-center">
              <div className="vertical-divider pl-md transform-x-3 border-color-gray-500" />
            </div>

            <ShowNumberOfRows
              label={showItemsLabel}
              itemsShownPerPage={[itemsShownPerPage.toString()]}
              options={options}
              setShown={(v) => setShown(v)}
            />
          </>
        )}
      </div>
    </div>
  );
}
export default Paginator;

type ShowNumberOfRowsProps = {
  label: ReactNode | string;
  itemsShownPerPage: string[];
  options: OptionItem[];
  setShown: (itemsShownNumber: number) => void;
};

function ShowNumberOfRows({
  itemsShownPerPage,
  label,
  options,
  setShown,
}: ShowNumberOfRowsProps) {
  const onChange = (optionValue?: string): void => {
    if (optionValue) {
      setShown(parseInt(optionValue, 10));
    }
  };

  return (
    <div className="d-flex">
      <span className="text-grey  pt-xs pr-sm fw-600">{label}:</span>
      <DropdownV9
        selectedOptions={itemsShownPerPage}
        options={options}
        dropdownClassName="paginator-dropdown"
        onOptionSelect={(_, { optionValue }) => onChange(optionValue)}
      />
    </div>
  );
}
