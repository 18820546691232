import { useDispatch, useSelector } from "react-redux";

import { Language } from "i18n";
import { RootState } from "state";
import { setLanguage } from "state/i18n";
import ENFlag from "assets/flags/gb.svg";
import SEFlag from "assets/flags/se.svg";
import "./I18nFlag.scss";

const LanguageFlags: { [index: string]: string } = {
  EN: ENFlag,
  SV: SEFlag,
};

type I18nFlagProps = {
  language: Language;
} & React.HTMLProps<HTMLImageElement>;

export function I18nFlag({ className, language }: I18nFlagProps) {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(
    (state: RootState) => state.i18n.language
  );

  const selected = language === currentLanguage && "selected";
  const flagSrc = LanguageFlags[language];

  if (!flagSrc) {
    throw new Error(`Missing flag for language ${language}`);
  }

  return (
    <img
      src={flagSrc}
      alt={language}
      className={`i18n-flag ${selected || ""} ${className || ""}`}
      onClick={() => dispatch(setLanguage(language))}
    />
  );
}
