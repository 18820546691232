import { DrawerBody } from "@fluentui/react-components";
import { useState } from "react";

import DrawerHeader from "components/drawer/drawerHeader/DrawerHeader";
import OverlayDrawer from "components/OverlayDrawer";
import DealContactDetails from "models/offer/DealContactDetails";
import ContactsCards from "./ContactsCards";

interface ContactListProps {
  contacts: DealContactDetails[];
  editable?: boolean;
  showNumberOfItems?: number;
}

function CustomerContactsList({
  contacts,
  editable = false,
  showNumberOfItems,
}: ContactListProps) {
  const [showPanel, setShowPanel] = useState(false);

  return (
    <>
      {showPanel && (
        <OverlayDrawer showPanel={showPanel} size="medium">
          <DrawerHeader
            onDismiss={() => setShowPanel(false)}
            header="CONTACTS"
          />
          <DrawerBody>
            <ContactsCards
              contacts={contacts}
              editable={editable}
              showPanel={showPanel}
              onShowPanel={setShowPanel}
              showNumberOfItems={showNumberOfItems}
              showAllContacts
            />
          </DrawerBody>
        </OverlayDrawer>
      )}
      <ContactsCards
        contacts={contacts}
        editable={editable}
        showPanel={showPanel}
        onShowPanel={setShowPanel}
        showNumberOfItems={showNumberOfItems}
      />
    </>
  );
}

export default CustomerContactsList;
