import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";

type CustomerPagePowerOfAttorneyCommentModalProps = {
  comment: string;
  isOpen: boolean;
  onDismiss: () => void;
};
export default function CustomerPagePowerOfAttorneyCommentModal({
  comment,
  isOpen,
  onDismiss,
}: CustomerPagePowerOfAttorneyCommentModalProps) {
  return (
    <Modal
      size="medium"
      isOpen={isOpen}
      onDismiss={onDismiss}
      header={<ModalHeader headerTitleText="COMMENT" />}
    >
      <div className="px-md">{comment}</div>
    </Modal>
  );
}
