import { Type } from "class-transformer";
import { removeWhiteSpace } from "../../libs/remove-whitespace-from-strings";
import { Customer } from "../customer";
import { User } from "../user";
import { CompanyInformation } from "./Company";
import { TaxObjectDetails } from "./TaxObject";

export interface IFrequency {
  id: number;
  value: string;
  text: string;
  default?: boolean;
}

export class DealCustomer {
  /**
   * Identifier of the customer in the deal context
   */
  id: string;

  /**
   * Organization number
   */
  org_number: string;

  /**
   * Name
   */
  customer_name: string;

  /**
   * Legal form / company form
   */
  legal_form?: string;

  /**
   * Customer number of the customer if the customer is a registered customer
   */
  customer_number?: string;

  /**
   * Physical post address
   */
  address: string;

  /**
   * Physical post co-address
   */
  co_address: string;

  /**
   * Postal code
   */
  postal_code: string;

  /**
   * Postal town
   */
  postal_town: string;

  /**
   * Customer tax object details
   */
  @Type(() => TaxObjectDetails)
  tax_objects?: TaxObjectDetails[];

  /**
   * User name of the employee that created the customer
   */
  changed_by_username?: string;

  /**
   * User name of the employee that last changed the customer
   */
  created_by_username?: string;

  isCompany() {
    return this.legal_form !== "pp";
  }

  static fromCustomer(customer: Customer, user: User): Partial<DealCustomer> {
    return {
      customer_number: customer.customer_number,
      org_number: customer.company_registration_number,
      address: `${customer.address1} ${customer.address2}`,
      customer_name: customer.name,
      postal_code: customer.zip ? removeWhiteSpace(customer.zip) : "",
      postal_town: customer.city ?? "",
      legal_form: customer.companyForm,
      created_by_username: user.email,
      changed_by_username: user.email,
    };
  }

  static fromCompanyInfo(
    company: CompanyInformation,
    user: User
  ): Partial<DealCustomer> {
    return {
      customer_number: company.customer_number,
      org_number: company.org_number,
      address: company.address,
      customer_name: company.name,
      postal_code: company.zip,
      postal_town: company.city,
      legal_form: company.company_type,
      created_by_username: user.email,
      changed_by_username: user.email,
    };
  }
}

export function getNextMonthDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  return `${new Date(year, month + 2).toISOString().substr(0, 8)}01`;
}
