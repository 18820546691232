import { SerializedError } from "@reduxjs/toolkit";

/**
 * When a response error occurs, throw this function and catch it in the appropriate place
 *
 *
 * Example:
 *
 * const response = await fetch(url);
 * if (!response.ok) {
 *   throw ResponseError(response);
 * }
 *
 */
export const ResponseError = (response: Response): SerializedError => {
  if (response.ok) {
    return {};
  }

  const errorResponse = {
    code: response.status.toString(),
    message: `${response.status} ${response.statusText}`,
  };

  return errorResponse;
};

/**
 * Takes an exception and checks if it is a ResponseError which is sent from ML Backend.
 * If no ResponseError is found, then re-throw the original exception. Please note that you
 * need to use unwrap() on the dispatch to catch the exception that we throw in the thunk.
 *
 *
 * Example:
 *
 * try {
 *   await dispatch(aRandomThunkAction()).unwrap().
 * } catch (error) {
 *   const responseError = CatchResponseError(error);
 *
 *   if (responseError.code === "404") {
 *     // Do something
 *   }
 * }
 */
export const catchResponseError = (error: unknown): SerializedError => {
  if (typeof error === "object" && error) {
    const result: SerializedError = {};

    if ("code" in error) {
      result.code = error.code as string;
    }

    if ("message" in error) {
      result.message = error.message as string;
    }

    return result;
  }

  throw error;
};

export const hasResponseError = (code: number, error: unknown) => {
  const responseError = catchResponseError(error);
  return responseError.code === code.toString();
};
