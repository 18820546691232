import Others from "./assets/assignments.json";
import Dates from "./assets/dates.json";
import UI from "./assets/ui.json";
import MGT from "./assets/mgt.json";
import Customers from "./assets/customers.json";
import Errors from "./assets/errors.json";
import Deals from "./assets/deals.json";
import Companies from "./assets/companies.json";
import ActivitiesPackages from "./assets/activities_packages.json";
import DeliveryPlanner from "./assets/delivery_planner.json";
import Offer from "./assets/offer.json";
import Contacts from "./assets/contacts.json";
import General from "./assets/general.json";
import IncomeTax from "./assets/IncomeTax.json";
import Summary from "./assets/summary.json";
import Settings from "./assets/customerSettings.json";
import Admin from "./assets/admin.json";
import Activities from "./assets/activities.json";
import Components from "./assets/components.json";
import ManageCustomers from "./assets/manageCustomers.json";

import { Translate, Translator } from "./translator";

export type I18n = { t: (key: TranslationKey) => string; language: Language };
export type Language = "SV" | "EN";

export const Dictionary = {
  ...Deals,
  ...Dates,
  ...Others,
  ...UI,
  ...MGT,
  ...Errors,
  ...Customers,
  ...Companies,
  ...ActivitiesPackages,
  ...DeliveryPlanner,
  ...Offer,
  ...Contacts,
  ...General,
  ...IncomeTax,
  ...Summary,
  ...Settings,
  ...Admin,
  ...Activities,
  ...Components,
  ...ManageCustomers,
};

type DictionaryKey = keyof typeof Dictionary;

export type TranslationKey =
  | Uppercase<DictionaryKey>
  | Lowercase<DictionaryKey>;

export function getDefaultTranslator() {
  const translator = new Translator<TranslationKey>();
  const keys = Object.keys(Dictionary) as DictionaryKey[];

  keys.forEach((key: DictionaryKey) => {
    const upper = key.toUpperCase() as DictionaryKey;
    translator.addWord(key, Dictionary[upper]);
  });

  return translator;
}

export function isTranslationKey(key: string): key is TranslationKey {
  return key in Dictionary;
}

export interface Translatable<T = TranslationKey> {
  translate(t: Translate<T>): string;
}
