import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";

import { useAppDispatch } from "state/use-app-redux";
import { CompanyInformation } from "models/offer/Company";
import { SearchBy, fetchCompanyInfoFromSales } from "state/offer/companyThunks";

export function useIsExistingCustomer() {
  const dispatch = useAppDispatch();

  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const [existingCustomer, setExistingCustomer] =
    useState<CompanyInformation>();
  const [orgNumber, setOrgNumber] = useState("");

  const isExistingCustomer = async (newOrgNumber: string) => {
    if (newOrgNumber) {
      if (newOrgNumber === orgNumber) {
        return existingCustomer;
      }
      try {
        setIsLoadingCustomer(true);
        setOrgNumber(newOrgNumber);

        const tempCustomerResult = await dispatch(
          fetchCompanyInfoFromSales({
            customerId: newOrgNumber,
            searchByParam: SearchBy.OrgNumber,
          })
        );

        const customer = unwrapResult(tempCustomerResult);
        if (customer && customer.org_number) {
          setExistingCustomer(customer);
        }
        return customer;
      } catch (e) {
        setExistingCustomer(undefined);
        return false;
      } finally {
        setIsLoadingCustomer(false);
      }
    }
  };

  return {
    isLoadingCustomer,
    isExistingCustomer,
  };
}
