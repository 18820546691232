import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { DropdownV9 } from "components/dropdown/DropdownV9";
import { Input } from "components/input";
import Modal from "components/modal";
import { TextArea } from "components/textarea";
import { useTranslation } from "hooks/use-translate";
import { DetailedInfo } from "models/customerSettings/customerSettingsDetailedInfo";
import { DetailedInfoCategory } from "models/customerSettings/customerSettingsDetailedInfoCategory";
import { DetailedInfoSubCategory } from "models/customerSettings/customerSettingsDetailedInfoSubcategory";
import { ServiceLine } from "models/customerSettings/customerSettingsServiceLine";
import { RootState } from "state";
import { resetOpenCreateModalFor } from "state/customerSettings/customerSettingsSlice";
import {
  createDetailedInfoCallerWrapper,
  updateDetailedInfoCallerWrapper,
} from "state/customerSettings/customerSettingsThunk";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";

type customerPageDetailedInfoModalProps = {
  openModal: boolean;
  setOpenModal: (arg: boolean) => void;
  categories: DetailedInfoCategory[];
  subcategories: DetailedInfoSubCategory[];
  customerId: string;
  serviceLines: ServiceLine[];
  currentInfo?: DetailedInfo;
  setIsEditMode?: (arg: boolean) => void;
};

export default function CustomerPageDetailedInfoCreateModal({
  openModal,
  setOpenModal,
  categories,
  subcategories,
  customerId,
  serviceLines,
  currentInfo,
  setIsEditMode,
}: customerPageDetailedInfoModalProps) {
  const dispatch = useDispatch();
  const { translate, language } = useTranslation();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const subcategoriesOptions: {
    value: string;
    content: JSX.Element;
  }[] = subcategories.map((subcategory) => ({
    content: (
      <span>
        <span className="fw-bold">
          {language === "SV"
            ? subcategory.customerdetailscategory.category_se
            : subcategory.customerdetailscategory.category_en}
        </span>
        {" - "}
        {language === "SV"
          ? subcategory.subcategory_se
          : subcategory.subcategory_en}
      </span>
    ),
    value: subcategory.id.toString(),
  }));

  const [selectedSubcategory, setSelectedSubcategory] =
    useState<DetailedInfoSubCategory>();
  const [newInfo, setNewInfo] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [selectedServiceLineIds, setSelectedServiceLineIds] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (currentInfo) {
      setTitle(currentInfo.title);
      setNewInfo(currentInfo.content);
      setSelectedSubcategory(currentInfo.customerdetailssubcategory);
      setSelectedServiceLineIds(
        currentInfo.servicelines.map((serviceLine) => serviceLine.id.toString())
      );
    }
  }, [currentInfo]);

  const createDetailedInfo = () => {
    if (selectedSubcategory && newInfo && title) {
      const newInfoObj = {
        ...currentInfo,
        id: currentInfo ? currentInfo.id : undefined,
        content: newInfo,
        customerdetailssubcategory: {
          ...selectedSubcategory,
        },
        title,
        created_by: currentUser.graphId,
        modified_by: currentUser.graphId,
        modified_by_user: currentUser,
        customer_number: customerId,
        servicelines: serviceLines.filter(
          (serviceLine) =>
            selectedServiceLineIds.indexOf(serviceLine.id.toString()) !== -1
        ),
        enabled: true,
      } as DetailedInfo;

      if (currentInfo) {
        dispatch(updateDetailedInfoCallerWrapper(newInfoObj));
      } else {
        dispatch(createDetailedInfoCallerWrapper(newInfoObj));
      }
      dispatch(resetOpenCreateModalFor());
    }
  };

  const serviceLineOptions = serviceLines.map((serviceLine) => {
    return {
      text:
        language && language === "EN"
          ? serviceLine.serviceline
          : serviceLine.serviceline_se,
      value: serviceLine.id.toString(),
    };
  });

  const onSetOpenModal = (open: boolean) => {
    setOpenModal(open);
    dispatch(resetOpenCreateModalFor());
    if (setIsEditMode && !open) {
      setIsEditMode(false);
    }
  };

  return (
    <Modal
      isOpen={openModal}
      onDismiss={() => onSetOpenModal(false)}
      size="medium"
      header={<ModalHeader headerTitleText="CUSTOMER_SPECIFIC_INFORMATION" />}
      footer={
        <ModalFooter
          onCancel={() => onSetOpenModal(false)}
          onSave={() => {
            onSetOpenModal(false);
            createDetailedInfo();
          }}
          labelSubmit={currentInfo ? "CUSTOMER_INFORMATION_EDIT" : "CREATE"}
          labelCancel="CANCEL"
          isDisabled={!(selectedSubcategory && newInfo && title)}
        />
      }
    >
      <Row>
        <Col md={12}>
          <Row className="px-md fw-bold pb-sm">{translate("CATEGORY")}</Row>
          <Row className="mb-md pb-xs px-sm">
            <DropdownV9
              placeholder={translate("SELECT_CATEGORY")}
              selectedOptions={[
                selectedSubcategory?.id
                  ? selectedSubcategory?.id.toString()
                  : "",
              ]}
              optionsWithJSXContent
              options={subcategoriesOptions}
              onOptionSelect={(e, { optionValue }) => {
                const selectedSubcat = subcategories.find(
                  (subcategory) => subcategory.id.toString() === optionValue
                );
                setSelectedSubcategory(selectedSubcat);
              }}
            />
          </Row>
          <Row className="px-md fw-bold pb-sm">{translate("TITLE")}</Row>
          <Row className="px-md mb-md">
            <Input
              required
              placeholder={translate("INSERT_TITLE")}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              defaultValue={title}
            />
          </Row>
          <Row className="px-md fw-bold pb-sm">{translate("SERVICE")}</Row>
          <Row className="mb-md pb-xs px-sm">
            <DropdownV9
              multiselect
              placeholder={translate("SELECT_SERVICE")}
              onOptionSelect={(e, { optionValue }, isSelected) => {
                if (optionValue) {
                  setSelectedServiceLineIds(
                    isSelected
                      ? [...selectedServiceLineIds, optionValue as string]
                      : selectedServiceLineIds.filter(
                          (key) => key !== optionValue
                        )
                  );
                }
              }}
              selectedOptions={selectedServiceLineIds}
              options={serviceLineOptions}
            />
          </Row>

          <Row className="px-md fw-bold pb-sm">{translate("CONTENT")}</Row>
          <Row className="px-md mb-lg">
            <TextArea
              aria-required
              rows={5}
              className="text-area-size"
              placeholder={translate("CONTENT_PLACEHOLDER")}
              onChange={(e) => {
                setNewInfo(e.target.value);
              }}
              required
              defaultValue={newInfo}
            />
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}
