import { Type } from "class-transformer";
import { User } from "../user";
import { DealContact } from "./DealContact";
import { DealCustomer } from "./Customer";
import { KYC } from "./kyc";
import { ServiceArea } from "./ServiceLine";
import { TaxObjectService } from "./TaxObjectService";

export type OfferService = {
  id: string;
  name: string;
};

type ContractText = {
  id: string;
  header_swe: string;
  header_eng: string;
  appendix_title: string;
  content_eng: string;
  content_swe: string;
  header_sorting_nr: number;
};

export type Appendix = {
  appendix_title: string;
  generic_text: boolean;
  contract_texts: ContractText[];
};

export class CustomerDeal {
  id?: string;

  /**
   * State of the deal
   */
  state:
    | "offer"
    | "contract"
    | "contract_sent"
    | "contract_signed"
    | "contract_rejected"
    | "contract_expired"
    | "deleted";

  /**
   * The selected services for the deal
   */
  service_areas: ServiceArea[];

  /**
   * Contract texts for the deal, where some of the texts will show up depending on what services has been selected
   */
  contract_texts?: Appendix[];

  /**
   * Contacts
   */
  contacts: DealContact[];

  /**
   * Customer information
   */
  @Type(() => DealCustomer)
  customer?: DealCustomer;

  /**
   * Determine if the customer on the deal is a completely new customer
   */
  new_customer: boolean;

  /**
   * Know your customer information on the customer
   */
  kyc?: Partial<KYC>;

  /**
   * Envelope id received when the deal is sent for signing to Verified
   */
  envelope_id?: string;

  /**
   * HubSpot deal ID
   */
  hubspot_deal_id?: string;

  /**
   * Email of the employee that created the deal
   */
  changed_by_username?: string;

  /**
   * Email of the employee that last changed the deal
   */
  created_by_username?: string;

  /**
   * tax_objects selected services for the current deal
   */
  tax_objects: TaxObjectService[];

  /**
   * Date of the deal creation
   */
  @Type(() => Date)
  created_date?: Date;

  /**
   * Date of the deal last change
   */
  @Type(() => Date)
  changed_date?: Date;

  /**
   * Employee sales manager for the offer
   */
  sales_manager: string;

  /**
   * Offer has additional PDF attached
   */
  has_attachment?: boolean;

  /**
   * Hubspot deal source
   */
  deal_source?: string;

  static fromUser(user: User): CustomerDeal {
    return {
      changed_by_username: user.email,
      created_by_username: user.email,
      created_date: new Date(),
      changed_date: new Date(),
      state: "offer",
      new_customer: false,
      customer: undefined,
      sales_manager: user.email,
      contract_texts: [],
      contacts: [],
      kyc: undefined,
      tax_objects: [],
      service_areas: [
        {
          name: "TOEK",
          service_lines: [],
        },
      ],
    };
  }
}
