export function isValidRegex(regexStr: string): RegExp | false {
  if (typeof regexStr !== "string" || regexStr.trim() === "") {
    return false;
  }

  try {
    const regex = new RegExp(regexStr);
    return regex;
  } catch (e) {
    return false;
  }
}
