import { StageConfiguration } from "../../views/deals/configuration/stages-config";

export class Stage {
  id: string;

  label: string;

  display_order: number;

  /**
   * Stage configuration describes how deals interact with the stage, ie. when moving a deal to this stage etc.
   */
  configuration?: StageConfiguration;
}
