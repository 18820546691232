import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { AppRouteHelper, CustomerDetailsParams, ServicesParams } from "routes";
import { RootState } from "state";
import { fetchActivitiesForCustomers } from "state/activities/actions";
import {
  GENERAL_ACCOUNTING_DELIVERY,
  YEAR_END_DELIVERY,
  fetchCustomerDeliveryPlan,
  endDelivery as endDeliveryDispatch,
} from "state/customers/actions";
import { useTranslation } from "hooks/use-translate";
import { Customer } from "models/customer";
import { CustomerDelivery } from "models/deliveryPlan";
import { Button } from "components/button";
import { LayoutBody, LayoutHeader } from "components/layout/Layout";
import BreadcrumbV9, {
  BreadcrumbDefinitionItem,
} from "components/breadcrumbV2";
import { GeneralAccountingPackageConfiguration } from "./GeneralAccountingPackageConfiguration";
import { YearEndPackageConfiguration } from "./YearEndPackageConfiguration";

export type DeliveryChangeProps = {
  customer: Customer;
  existingDelivery?: CustomerDelivery;
  preSelectedStartDate?: Date;
  onChange: () => void;
  remove?: (date: Date) => void;
  loadingDeliveries?: boolean;
};

type PackageConfigurationComponent = (
  props: DeliveryChangeProps
) => JSX.Element;

export const DeliveryServiceConfigurationForms = new Map<
  string,
  PackageConfigurationComponent
>([
  [YEAR_END_DELIVERY, YearEndPackageConfiguration],
  [GENERAL_ACCOUNTING_DELIVERY, GeneralAccountingPackageConfiguration],
]);

export function CustomerDeliveryPlannerConfiguration() {
  const { customerId, serviceId } = useParams<
    CustomerDetailsParams & ServicesParams
  >();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingDeliveries, setLoadingDeliveries] = useState(false);
  const customers = useSelector((state: RootState) => state.customers);
  const availableServices = useSelector(
    (state: RootState) => state.activities.availableDeliveryServices
  );
  const customerState = customers.data.find(
    (c) => c.customer.customer_number === customerId
  );

  if (!customerState) {
    return <p>{translate("DP.MISSING_CUSTOMER")}</p>;
  }

  const { customer } = customerState;

  const breadcrumbs: BreadcrumbDefinitionItem[] = [
    {
      text: translate("MY_ASSIGNMENTS"),
      onClick: () => navigate(AppRouteHelper.getAssignments(undefined)),
    },
    {
      text: customer ? customer.name : "...",
      onClick: () => navigate(AppRouteHelper.getAssignments(customerId)),
    },
    {
      text: translate("DELIVERY_PLANNER"),
      onClick: () =>
        customer &&
        navigate(
          AppRouteHelper.getCustomerDeliveryPlanner(customer.customer_number)
        ),
    },
    {
      text: translate("FILL_IN_INFORMATION"),
    },
  ];

  if (!serviceId) {
    return <p>{translate("DP.MISSING_SERVICE_ID")}</p>;
  }

  const { deliveryPlan } = customer;

  if (!deliveryPlan.length) {
    return (
      <>
        <p>{translate("DP.EMPTY_DELIVERY_PLAN")}</p>
        <Button onClick={() => navigate(-1)}>{translate("BACK")}</Button>
      </>
    );
  }

  const delivery = deliveryPlan.find((dp) => dp.uuid === serviceId);
  if (!delivery) {
    return <p>{translate("DP.MISSING_DELIVERY_PLAN_ENTRY", [serviceId])}</p>;
  }

  const definition = availableServices.data.find(
    (a) => a.delivery_name === delivery.delivery_name
  );
  if (!definition) {
    return <p>{translate("DP.MISSING_DELIVERY_SERVICE_DEFINITION")}</p>;
  }

  const DeliveryConfigurationForm = DeliveryServiceConfigurationForms.get(
    definition.delivery_name
  );

  if (!DeliveryConfigurationForm) {
    return (
      <p>{translate("DP.MISSING_CONFIGURATION", [definition.delivery_name])}</p>
    );
  }

  const endDelivery = async (deliveryId: string, endDate: Date) => {
    if (!customerState) {
      return;
    }

    setLoadingDeliveries(true);
    dispatch(endDeliveryDispatch({ customer, deliveryId, endDate }));
    dispatch(fetchCustomerDeliveryPlan({ customer }));
    dispatch(
      fetchActivitiesForCustomers({
        customerNumbers: [customer.customer_number],
      })
    );
    setLoadingDeliveries(false);
  };

  return (
    <>
      <LayoutHeader>
        <span />
      </LayoutHeader>
      <LayoutBody>
        <BreadcrumbV9 items={breadcrumbs} />
        <DeliveryConfigurationForm
          customer={customer}
          existingDelivery={delivery}
          onChange={() => {
            navigate(-1);
          }}
          remove={(endDate) => {
            endDelivery(delivery.uuid, endDate);
            navigate(-1);
          }}
          loadingDeliveries={loadingDeliveries}
        />
      </LayoutBody>
    </>
  );
}
