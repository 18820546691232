import { ThunkAction } from "redux-thunk";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import { customerManagementApiService } from "services/customerManagementApi";
import rootReducer from "./reducers";
import { CustomersAssignmentsState } from "./assignments/reducer";
import { CustomersState } from "./customers/reducer";
import { I18nState } from "./i18n/reducer";
import { ServicesDescriptionState } from "./servicesDescriptions/reducer";
import { CustomersActivitiesState } from "./activities/reducer";
import { NotificationsAction, NotificationsState } from "./notifications";
import { CustomersAction } from "./customers";
import { ActivitiesAction } from "./activities";
import { ServicesAction } from "./servicesDescriptions";
import { AssignmentsAction } from "./assignments";
import { SalesState } from "./sales/reducer";
import { SalesAction } from "./sales";
import { OfferState } from "./offer/offersSlice";
import { KycState } from "./offer/kycSlice";
import { CustomerSettingsState } from "./customerSettings/customerSettingsSlice";
import { ServiceAreaState } from "./serviceAreas/serviceAreaSlice";
import { ludvigApiService } from "../services/ludvigApi";
import { UsersState } from "./users/userSlice";
import { SyncActivitiesState } from "./activities/syncActivities.state";

export type RootState = {
  activities: CustomersActivitiesState;
  syncActivities: SyncActivitiesState;
  assignments: CustomersAssignmentsState;
  customers: CustomersState;
  servicesDescriptions: ServicesDescriptionState;
  i18n: I18nState;
  users: UsersState;
  notifications: NotificationsState;
  sales: SalesState;
  offers: OfferState;
  kyc: KycState;
  settings: CustomerSettingsState;
  serviceArea: ServiceAreaState;
};

export type Action =
  | NotificationsAction
  | CustomersAction
  | ActivitiesAction
  | ServicesAction
  | AssignmentsAction
  | SalesAction;

export type MyThunkAction<T = Promise<void>> = ThunkAction<
  T,
  RootState,
  unknown,
  Action
>;

export type LoadingStatus = "idle" | "pending" | "succeeded" | "failed";

const customizedMiddleware = getDefaultMiddleware({
  immutableCheck: false, // TODO: change this to value
  serializableCheck: false,
})
  .concat(ludvigApiService.middleware)
  .concat(customerManagementApiService.middleware);

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});

export default store;
