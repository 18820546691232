import { Common } from "./Common";
import { DealEmployee } from "./Employee";
import { Frequency } from "./Frequency";
import { IInvoicingPlan } from "./InvoicingPlan";

export type EnvelopState =
  | "sent"
  | "signed"
  | "rejected"
  | "aborted"
  | "expired";

export const contractState: Record<EnvelopState, EnvelopState> = {
  sent: "sent",
  signed: "signed",
  rejected: "rejected",
  aborted: "aborted",
  expired: "expired",
};

export type Envelop = {
  envelope: string;
  service: string;
  state: EnvelopState;
};

export class Offer {
  content!: string;

  modified!: string;

  envelopes?: Envelop[];

  offer_id?: string;

  org_no?: string;

  state?: string;

  deletion_timestamp?: string;
}

export type OfferLegacy = {
  id: string;
  pogversion: string;
  state:
    | "offer"
    | "contract"
    | "contract_sent"
    | "contract_signed"
    | "contract_rejected"
    | "contract_expired"
    | "deleted";
  environment: string;
  selectedofferperiod: Frequency;
  customer: CustomerLegacy;
  contacts: ContactLegacy[];
  employee: DealEmployee;
  originalemployee: DealEmployee;
  common: Common;
  totals: [];
  bookkeeping: { selected: boolean };
  financial: { selected: boolean };
  salary: { selected: boolean };
  additionalservices: { selected: boolean };
  additionalService?: { selected: boolean };
  special: { selected: boolean };
  invoicingplan: IInvoicingPlan;
  kyc: {};
  region: { id: number; label: string; regionindex: number };
  created_at?: string;
  message?: string;
  displayError?: {
    title: string;
    body: React.ReactNode;
    type: string;
  };
};

export interface CustomerLegacy {
  registrationnumber: string;
  name: string;
  legalform: string;
  customernumber?: number;
  startdate: string;
  frequency: Frequency;
  companyRegistered: boolean;
  address: string;
  coAddress: string;
  postalCode: string;
  postalTown: string;
}
interface ContactLegacy {
  socialsecuritynumber: string;
  firstname: string;
  lastname: string;
  mailaddress: string;
  signer: boolean;
  signingmethod: {
    id: number;
    value: string;
    text: string;
    name: string;
    default?: boolean;
  };
  mobilephone?: string;
}
