/* eslint-disable react/jsx-no-useless-fragment */
import { Spinner } from "@fluentui/react-components";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Add20Filled,
  ArrowDownload20Filled,
  ArrowMaximize20Regular,
  ArrowMinimize20Regular,
} from "@fluentui/react-icons";

import BreadcrumbV9, {
  BreadcrumbDefinitionItem,
} from "components/breadcrumbV2";
import { Button } from "components/button";
import { ContentBodyV2, ContentV2 } from "components/contentV2/ContentV2";
import SearchInput from "components/input/search";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
  LayoutWrapper,
} from "components/layout/Layout";
import { LayoutBodyV2 } from "components/layoutV2/LayoutV2";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { Debounce } from "libs/debounce";
import { CustomerPageDetailedPageEnum } from "models/enums/CustomerPageDetailedPage.enum";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { AppRouteHelper, CustomerDetailsParams } from "routes";
import { RootState } from "state";
import {
  fetchBalancesDownloadUrlData,
  fetchPguDownloadUrlData,
} from "state/activities/actions";
import {
  resetCustomerSettingsState,
  resetOpenCreateModalFor,
  resetSearchTerm,
  setOpenCreateModalFor,
  setSearchTerm,
  toggleExpandAll,
} from "state/customerSettings/customerSettingsSlice";
import { AppDispatch } from "state/use-app-redux";
import CustomerPageServiceLine from "./customerPageServiceLine";
import "./index.scss";

interface CustomerPageLayoutProps {
  children: ReactNode;
  isMainPage?: boolean;
}

export default function CustomerPageLayout({
  children,
}: CustomerPageLayoutProps) {
  const dispatch: AppDispatch = useDispatch();
  const { translate, ts } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const lastUrlSegment = location.pathname.split("/").pop();
  const isNotBackofficePage =
    location.pathname.split("/").indexOf("pgu") === -1 &&
    location.pathname.split("/").indexOf("balances-invoices-payments") === -1;

  const isKYCPage =
    location.pathname.split("/").pop() ===
    CustomerPageDetailedPageEnum.KYC_EVALUATIONS;
  const newPageFor =
    lastUrlSegment === "customer-page"
      ? ""
      : location.pathname.split("/").pop();

  const {
    customerId,
    jobnumber,
    purposename,
    details,
    partialInvoice,
    detailsTaskName,
  } = useParams<CustomerDetailsParams>();
  const expandAll = useSelector((state: RootState) => state.settings.expandAll);
  const customerStatus = useSelector(
    (state: RootState) => state.settings.customer.status
  );
  const customers = useSelector((state: RootState) => state.customers.data);
  const customer = customers.find(
    (e) => e.customer.customer_number === customerId
  )?.customer;

  useEffect(() => {
    return () => {
      dispatch(resetCustomerSettingsState());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetSearchTerm());
    dispatch(resetOpenCreateModalFor());
  }, [dispatch]);

  let breadcrumbs: BreadcrumbDefinitionItem[] = [
    {
      text: translate("MY_ASSIGNMENTS"),
      onClick: () => navigate(AppRouteHelper.getAssignments("")),
    },
    {
      text: (customer && customer.name) || "",
      onClick: () => navigate(AppRouteHelper.getAssignments(customerId)),
    },
    {
      text: translate("CUSTOMER_PAGE"),
      onClick: () =>
        navigate({
          pathname: AppRouteHelper.getCustomerPage(customer?.customer_number),
        }),
    },
  ];

  if (newPageFor) {
    const newPageTitle = newPageFor.replace(/-/g, "_").toUpperCase();
    if (
      newPageFor !== "pgu" &&
      location.pathname.split("/").indexOf("pgu") > -1
    ) {
      breadcrumbs = [
        ...breadcrumbs,
        {
          text: "PGU",
          onClick: () =>
            navigate(
              AppRouteHelper.getCustomerPguPage(customer?.customer_number)
            ),
        },
      ];

      if (jobnumber && purposename) {
        breadcrumbs = [
          ...breadcrumbs,
          {
            text: ts("CUSTOMER_PAGE_ASSIGNMENT").concat(" ", jobnumber || ""),
            onClick: () =>
              navigate(
                AppRouteHelper.getAssignmentTable(
                  customer?.customer_number,
                  jobnumber
                )
              ),
          },
          {
            text: purposename
              ? ts(`CUSTOMER_PAGE_${newPageTitle}`).concat(
                  " ",
                  purposename || ""
                )
              : ts(`CUSTOMER_PAGE_${newPageTitle}`),
          },
        ];
      } else if (jobnumber && !purposename) {
        breadcrumbs = [
          ...breadcrumbs,
          {
            text: ts("CUSTOMER_PAGE_ASSIGNMENT").concat(" ", jobnumber || ""),
            onClick: () =>
              navigate(
                AppRouteHelper.getAssignmentTable(
                  customer?.customer_number,
                  jobnumber
                )
              ),
          },
        ];
      }
    } else {
      breadcrumbs = [
        ...breadcrumbs,
        {
          text: ts(`CUSTOMER_PAGE_${newPageTitle}`),
        },
      ];
    }
  }

  const debounceSearch = new Debounce(300);
  const searchInputOnSearch = (newValue?: string) => {
    if (newValue) {
      debounceSearch.fire(async () => {
        dispatch(setSearchTerm(newValue?.toLowerCase()));
      });
    } else {
      debounceSearch.fire(async () => {
        dispatch(resetSearchTerm());
      });
    }
  };

  const [downloadUrl, setDownloadUrl] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const exportToExcel = (
    tableType: string,
    params: {
      jobnumber?: string | undefined;
      purposename?: string | undefined;
      details?: string | undefined;
      detailsTaskName?: string | undefined;
      partialInvoice?: string | undefined;
    }
  ) => {
    setLoading(true);
    if (customer) {
      if (tableType === "balances-invoices-payments") {
        dispatch(fetchBalancesDownloadUrlData(customer?.customer_number)).then(
          (res: any) => {
            setDownloadUrl(res);
          }
        );
      } else {
        dispatch(
          fetchPguDownloadUrlData(customer?.customer_number, params)
        ).then((res: any) => {
          setDownloadUrl(res);
        });
      }
    }
  };

  useEffect(() => {
    if (downloadUrl) {
      const a = document.createElement("a");
      a.href = downloadUrl.url;
      a.click();
      setLoading(false);
    }
  }, [downloadUrl]);

  return (
    <>
      {customer && (
        <>
          <LayoutHeader>
            <LayoutHeaderLeft>
              <div className="d-flex align-items-center justify-content-between">
                <div className="pr-md">
                  <BreadcrumbV9 items={breadcrumbs} />
                </div>

                <>
                  <div className="vertical-divider pr-sm" />
                  <div className="d-flex align-items-center justify-content-between">
                    {" "}
                    {isNotBackofficePage && !isKYCPage && (
                      <>
                        <label className="pr-md pl-sm">
                          {translate("VIEW")}:
                        </label>
                        <CustomerPageServiceLine />
                      </>
                    )}
                  </div>
                </>
              </div>
            </LayoutHeaderLeft>
            <LayoutHeaderRight>
              {!newPageFor && (
                <div className="pl-sm d-flex">
                  {expandAll ? (
                    <ArrowMaximize20Regular
                      className="cursor-pointer blue-link"
                      onClick={() => {
                        dispatch(toggleExpandAll());
                      }}
                    />
                  ) : (
                    <ArrowMinimize20Regular
                      className="cursor-pointer blue-link"
                      onClick={() => {
                        dispatch(toggleExpandAll());
                      }}
                    />
                  )}
                </div>
              )}
              {newPageFor &&
                newPageFor ===
                  CustomerPageDetailedPageEnum.CUSTOMER_SPECIFIC_INFORMATION && (
                  <div className="d-flex mb-xs px-0">
                    <div className="d-flex mb-xs px-0">
                      <Button
                        variant="outline-outline"
                        className="justify-content-start primary-colored-text "
                        onClick={() =>
                          dispatch(setOpenCreateModalFor(newPageFor))
                        }
                      >
                        <div className="d-flex align-items-center">
                          <Add20Filled />
                          <span className="d-inline mx-sm fw-bold">
                            {translate("ADD_NEW_INFO")}
                          </span>
                        </div>
                      </Button>
                    </div>
                    <SearchInput
                      placeholder={translate("SEARCH_PLACEHOLDER")}
                      onChange={(_, newValue) =>
                        searchInputOnSearch(newValue.value)
                      }
                      data-testid="search-input"
                    />
                  </div>
                )}
              {newPageFor &&
                newPageFor === CustomerPageDetailedPageEnum.CONTACTS && (
                  <div className="d-flex mb-xs px-0">
                    <div className="d-flex mb-xs px-0">
                      <Button
                        variant="outline-outline"
                        className="justify-content-start primary-colored-text"
                        onClick={() =>
                          dispatch(setOpenCreateModalFor(newPageFor))
                        }
                      >
                        <div className="d-flex align-items-center">
                          <Add20Filled />
                          <span className="d-inline mx-sm fw-bold">
                            {translate("ADD_NEW_CONTACT")}
                          </span>
                        </div>
                      </Button>
                    </div>
                    <SearchInput
                      className="width-250 "
                      placeholder={translate("CUSTOMER_SEARCH_PLACEHOLDER")}
                      onChange={(_, newValue) =>
                        searchInputOnSearch(newValue.value)
                      }
                      data-testid="search-input"
                    />
                  </div>
                )}
              {newPageFor &&
                newPageFor === CustomerPageDetailedPageEnum.SERVICE_NOTES && (
                  <div className="d-flex mb-xs px-0">
                    <div className="d-flex mb-xs px-0">
                      <Button
                        variant="outline-outline"
                        className="justify-content-start primary-colored-text"
                        onClick={() =>
                          dispatch(setOpenCreateModalFor(newPageFor))
                        }
                      >
                        <div className="d-flex align-items-center">
                          <Add20Filled />
                          <span className="d-inline mx-sm fw-bold">
                            {translate("ADD_NEW_NOTE")}
                          </span>
                        </div>
                      </Button>
                    </div>
                    <SearchInput
                      className="width-250 "
                      placeholder={translate(
                        "CUSTOMER_PAGE_SEARCH_SERVICE_NOTES"
                      )}
                      onChange={(_, newValue) =>
                        searchInputOnSearch(newValue.value)
                      }
                      data-testid="search-input"
                    />
                  </div>
                )}
              {newPageFor &&
                newPageFor ===
                  CustomerPageDetailedPageEnum.POWER_OF_ATTORNEY && (
                  <div className="d-flex mb-xs px-0">
                    <div className="d-flex mb-xs px-0">
                      <Button
                        variant="outline-outline"
                        className="justify-content-start primary-colored-text"
                        onClick={() =>
                          dispatch(setOpenCreateModalFor(newPageFor))
                        }
                      >
                        <div className="d-flex align-items-center">
                          <Add20Filled />
                          <span className="d-inline mx-sm fw-bold">
                            {translate("ADD_P0A_ENTITY")}
                          </span>
                        </div>
                      </Button>
                    </div>
                    <SearchInput
                      className="width-250 "
                      placeholder={translate("SEARCH_PLACEHOLDER")}
                      onChange={(_, newValue) =>
                        searchInputOnSearch(newValue.value)
                      }
                      data-testid="search-input"
                    />
                  </div>
                )}

              {!isNotBackofficePage && newPageFor && (
                <>
                  <Button
                    className="p-0 pr-md m-0 border-0 text-color-blue fw-600"
                    size="md"
                    variant="outline-outline"
                    disabled={loading}
                    onClick={() =>
                      exportToExcel(newPageFor, {
                        jobnumber,
                        purposename,
                        details,
                        detailsTaskName,
                        partialInvoice,
                      })
                    }
                  >
                    <div className="d-flex align-items-center">
                      {loading ? (
                        <div>
                          <Spinner size={SpinnerSize.ExtraSmall} />
                        </div>
                      ) : (
                        <ArrowDownload20Filled className="mr-sm" />
                      )}
                      <span>{translate("DOWNLOAD_TO_EXCEL_LINK")}</span>
                    </div>
                  </Button>
                </>
              )}
            </LayoutHeaderRight>
          </LayoutHeader>

          {newPageFor &&
          newPageFor !== CustomerPageDetailedPageEnum.CONTACTS ? (
            <LayoutWrapper>
              {customerStatus === LoadingStatusEnum.PENDING && (
                <div>
                  <Spinner size={SpinnerSize.ExtraSmall} />
                </div>
              )}
              {customerId ? (
                <>{children}</>
              ) : (
                <div>{translate("NO_CUSTOMER_ID")}</div>
              )}
            </LayoutWrapper>
          ) : (
            <LayoutBodyV2>
              <ContentV2>
                <ContentBodyV2>
                  {customerStatus === LoadingStatusEnum.PENDING && (
                    <div>
                      <Spinner size={SpinnerSize.ExtraSmall} />
                    </div>
                  )}
                  {customerId ? (
                    <>{children}</>
                  ) : (
                    <div>{translate("NO_CUSTOMER_ID")}</div>
                  )}
                </ContentBodyV2>
              </ContentV2>
            </LayoutBodyV2>
          )}
        </>
      )}
    </>
  );
}
