/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  TabList,
  Tab,
  DrawerFooter,
} from "@fluentui/react-components";
import {
  ArrowClockwise16Regular,
  Checkmark16Regular,
  Dismiss24Regular,
} from "@fluentui/react-icons";

import "./StageDealPanel.scss";
import { useTranslation } from "hooks/use-translate";
import {
  FieldGrupKeys,
  useFieldsConfiguration,
} from "views/deals/configuration/fields-config";
import { Deal } from "models/deals/deal";
import { Customer } from "models/customer";
import { Button } from "components/button";
import { OverlaySpinner } from "components/spinner";
import { useUpdateDealProperty } from "views/deals/configuration/components/useUpdateDealProperty";
import { FieldGroupTable } from "../FieldGroup";
import { StageDealContacts } from "./StageDealContacts";
import StageDealConfirmationModal from "./StageDealConfirmationModal";
import { StageDealActions } from "./StageDealActions";
import OverlayDrawer from "components/OverlayDrawer";

type StageDealPanelProps = {
  isOpen: boolean;
  onDismiss: (isOpen: boolean) => void;
  deal: Deal;
  loading: boolean;
  company?: Customer;
  label?: string;
  setShowReturnToManagerModal: (showReturnToManagerModal: boolean) => void;
  setCreatePogOfferLoading: (isLoading: boolean) => void;
};

export function StageDealPanel({
  isOpen,
  deal,
  onDismiss,
  loading,
  company,
  label,
  setShowReturnToManagerModal,
  setCreatePogOfferLoading,
}: StageDealPanelProps) {
  const { translate } = useTranslation();
  const { fieldGroups } = useFieldsConfiguration();
  const { handleFormSubmit, handleContactFormSubmit } = useUpdateDealProperty();

  const sortedFieldGroups = fieldGroups.sort((a, b) => a.order - b.order);

  const [selectedTab, setSelectedTab] = useState<string>(
    FieldGrupKeys.PROCESSING
  );

  const [tempSelectedTab, setTempSelectedTab] = useState<string>();

  const methods = useForm<Partial<Deal>>({
    mode: "onTouched",
    defaultValues: new Deal(deal),
  });
  const formValues = methods.watch();
  const dirtyFields = Object.keys(methods.formState.dirtyFields);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isDismissPanelAction, setisDismissPanelAction] = useState(false);

  const showActions = methods.formState.isDirty;

  useEffect(() => {
    if (!loading) {
      methods.reset(new Deal(deal));
    }
  }, [deal, loading, methods]);

  const handleOnSave = (isFromModal: boolean) => {
    if (selectedTab === FieldGrupKeys.CONTACT && formValues.contact) {
      handleContactFormSubmit(formValues.contact, deal);
    } else {
      handleFormSubmit(formValues, dirtyFields, deal);
    }
    if (isFromModal) {
      onBeforeDismissModal();
    }
  };

  const handleResetForm = (isFromModal: boolean) => {
    methods.reset();
    if (isFromModal) {
      onBeforeDismissModal();
    }
  };

  const handleShowModal = (open: boolean) => {
    if (showActions) {
      setShowConfirmationModal(true);
      setisDismissPanelAction(true);
    } else {
      onDismiss(open);
    }
  };

  const handleTabChange = (value: string) => {
    if (showActions) {
      setShowConfirmationModal(true);
      setisDismissPanelAction(false);
      setTempSelectedTab(value);
    } else {
      setSelectedTab(value);
    }
  };

  const onBeforeDismissModal = () => {
    if (isDismissPanelAction) {
      onDismiss(false);
      setSelectedTab(FieldGrupKeys.PROCESSING);
    }
    if (!isDismissPanelAction && tempSelectedTab) {
      setSelectedTab(tempSelectedTab);
    }
    setShowConfirmationModal(false);
  };

  return (
    <>
      <OverlayDrawer
        size="medium"
        showPanel={isOpen}
        onOpenChange={(_, { open }) => handleShowModal(open)}
      >
        <DrawerHeader className="no-padding d-block">
          <DrawerHeaderTitle
            className="p-md bg-gray-400 box-shadow-xs"
            action={
              <Button
                aria-label="Close"
                onClick={() => {
                  handleShowModal(false);
                }}
                variant="outline"
                size="sm"
              >
                <Dismiss24Regular />
              </Button>
            }
          >
            <div className="d-flex header-title">
              <h3 className="stage-deal-panel-title pl-sm fw-400">
                {deal.dealname}
              </h3>
              <StageDealActions
                deal={deal}
                company={company}
                setShowReturnToManagerModal={setShowReturnToManagerModal}
                setCreatePogOfferLoading={setCreatePogOfferLoading}
              />
            </div>
          </DrawerHeaderTitle>
          <div className="p-md">
            <TabList
              defaultSelectedValue={FieldGrupKeys.PROCESSING}
              onTabSelect={(e, data) => handleTabChange(data.value as string)}
            >
              {sortedFieldGroups.map((group) => (
                <Tab value={group.key} key={group.key}>
                  {group.name}
                </Tab>
              ))}
              <Tab value={FieldGrupKeys.CONTACT}>
                {translate("MY_BUSINESS_OPPORTUNITIES.PANEL.CONTACT")}
              </Tab>
            </TabList>
          </div>
        </DrawerHeader>
        <DrawerBody>
          <FormProvider {...methods}>
            {loading && <OverlaySpinner label={label} />}
            {!loading &&
              (selectedTab === FieldGrupKeys.CONTACT ? (
                <StageDealContacts formReset={methods.reset} />
              ) : (
                sortedFieldGroups.map(
                  (group) =>
                    group.key === selectedTab && (
                      <FieldGroupTable
                        deal={deal}
                        group={group}
                        key={group.name}
                      />
                    )
                )
              ))}
          </FormProvider>
        </DrawerBody>
        {showActions && (
          <DrawerFooter className="justify-content-space-between border-top-gray">
            <Button
              onClick={() => handleResetForm(false)}
              variant="outline-primary"
            >
              <div className="d-flex align-items-center">
                <ArrowClockwise16Regular className="mr-sm" />
                <span>
                  {translate("MY_BUSINESS_OPPORTUNITIES.CLEAR_CHANGES")}
                </span>
              </div>
            </Button>
            <Button
              onClick={() => handleOnSave(false)}
              disabled={methods.formState.isDirty && !methods.formState.isValid}
            >
              <div className="d-flex align-items-center">
                <Checkmark16Regular className="mr-sm" />
                <span>{translate("MY_BUSINESS_OPPORTUNITIES.SAVE_FORM")}</span>
              </div>
            </Button>
          </DrawerFooter>
        )}
      </OverlayDrawer>
      {showConfirmationModal && (
        <StageDealConfirmationModal
          isOpen={showConfirmationModal}
          setOpenModal={setShowConfirmationModal}
          handleOnSave={() => handleOnSave(true)}
          handleResetForm={() => handleResetForm(true)}
          disableSave={!methods.formState.isValid}
        />
      )}
    </>
  );
}
