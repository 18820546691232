import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authMethod } from "auth";
import { ActivitiesAPI } from "api/activities";
import * as ActivityTypes from "./index";
import { LoadingStatus, RootState } from "../index";
import { appendError, appendToastMessage } from "../notifications";

export interface SyncActivitiesState {
  syncing: LoadingStatus;
  data: unknown;
}

export const syncActivitiesInitialState = {
  syncing: "idle",
  data: {},
} as SyncActivitiesState;

export const syncCustomerActivities = createAsyncThunk(
  ActivityTypes.SYNC_CUSTOMER_ACTIVITIES,
  async (customerId: string, { dispatch }) => {
    dispatch(appendToastMessage("SYNCING_CUSTOMER_ACTIVITIES", "info"));
    const token = await authMethod.getStoredAccessToken();

    try {
      const response = await ActivitiesAPI.syncCustomerActivities(
        token,
        customerId
      );
      dispatch(
        appendToastMessage("SYNC_CUSTOMER_ACTIVITIES_SUCCEEDED", "success")
      );
      return response;
    } catch (err) {
      dispatch(appendError("FAILED_TO_SYNC_CUSTOMER_ACTIVITIES", err as Error));
    }

    return {};
  },
  {
    condition: (customerId, { getState, extra }) => {
      const { syncActivities } = getState() as RootState;
      // a request is in progress, wait to finish
      if (syncActivities.syncing === "pending") {
        return false;
      }
    },
  }
);

export const syncActivitiesSlice = createSlice({
  name: "activities",
  initialState: syncActivitiesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(syncCustomerActivities.pending, (state, action) => {
        state.syncing = "pending";
        state.data = {};
      })
      .addCase(syncCustomerActivities.fulfilled, (state, action) => {
        state.syncing = "succeeded";
        state.data = action.payload.data;
      })
      .addCase(syncCustomerActivities.rejected, (state, action) => {
        state.syncing = "failed";
        state.data = {};
      });
  },
});
