export const MISSING_PLACEHOLDER_EMAIL_DOMAIN = "e-postadress-saknas.com";

/**
 * Generates a placeholder email address.
 *
 * @returns A placeholder email
 */
export function generatePlaceholderEmail(): string {
  return `e-postadress-saknas-${new Date().getTime()}@${MISSING_PLACEHOLDER_EMAIL_DOMAIN}`;
}

/**
 * Check if email address is a placeholder or not.
 *
 * @returns True if placeholder email, false otherwise
 */
export function isPlaceholderEmail(email?: string): boolean {
  const emailToCheck = email ?? "";
  return emailToCheck.includes(MISSING_PLACEHOLDER_EMAIL_DOMAIN);
}
