import AppConfig from "app-config";
import { Deal } from "models/deals/deal";

function isDealInCurrentYear(deal: Deal): boolean {
  if (deal.entered_current_stage_date === null) {
    return true;
  }

  const currentYear = new Date().getFullYear();
  const dealYear = new Date(deal.entered_current_stage_date).getFullYear();
  return currentYear === dealYear;
}

export function getStageDeals(deals: Deal[]) {
  const filteredDeals = deals.filter((deal) => {
    const isDealWonOrLost =
      deal.dealstage === AppConfig.FEATURES.DEALS.DEAL_STAGE_WON ||
      deal.dealstage === AppConfig.FEATURES.DEALS.DEAL_STAGE_LOST;

    return isDealWonOrLost ? isDealInCurrentYear(deal) : true;
  });
  return filteredDeals;
}
