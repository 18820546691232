/* eslint-disable react/jsx-no-useless-fragment */
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Info20Filled, Warning20Regular } from "@fluentui/react-icons";
import { Radio, RadioGroup } from "@fluentui/react-components";

import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import { isEqualSSN } from "libs/is-equal-ssn";
import { uniqByFilter } from "libs/uniqe-filter";
import { Customer } from "models/customer";
import { CustomerTaxObject, DeliveryFact } from "models/deliveryPlan";
import { getDateFromYearMonth } from "views/deliveryPlanner/libs/get-date-from-year-month";
import { CustomerTaxObjectRow } from "views/deliveryPlanner/components/CustomerTaxObjectRow";

type TaxObjectsModalProps = {
  customer: Customer;
  isOpen: boolean;
  setOpen: (arg: boolean) => void;
  onSubmit: (
    deliveryFact: DeliveryFact,
    startDate?: Date,
    endDate?: Date
  ) => void;
  service: DeliveryFact;
  selectedMonthNumber: number;
};

export function CustomerTaxObjectsModal({
  customer,
  isOpen,
  setOpen,
  onSubmit,
  service,
  selectedMonthNumber,
}: TaxObjectsModalProps) {
  const { translate } = useTranslation();
  const [selectedTaxObjects, setSelectedTaxObjects] = useState<
    CustomerTaxObject[]
  >([]);
  const availableStartPeriods = [
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
  ];

  const [selectedStartDate, setSelectedStartDate] = useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>();

  const selectedTaxObjListExtended = (taxObject: CustomerTaxObject) =>
    selectedTaxObjects
      ? uniqByFilter([...selectedTaxObjects, taxObject])
      : [taxObject];

  const resetDates = () => {
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
  };

  const resetTaxObjects = () => {
    setSelectedTaxObjects([]);
  };

  const onTaxObjectSelectChange = (
    taxObject: CustomerTaxObject,
    checked: boolean
  ) => {
    const exists = selectedTaxObjects?.find(
      (e) => e.delivery_name_ext === taxObject.delivery_name_ext
    );
    if (checked && !exists) {
      const extendedTaxObjList = selectedTaxObjListExtended(taxObject);
      setSelectedTaxObjects(extendedTaxObjList);
    }
    if (!checked && exists) {
      setSelectedTaxObjects(
        selectedTaxObjects?.filter((e) => !isEqualSSN(e.ssn, taxObject.ssn))
      );
    }
  };

  const onModalClose = () => {
    resetDates();
    resetTaxObjects();
    setOpen(false);
  };

  const createTaxObjectDeliveries = () => {
    selectedTaxObjects?.forEach((e) => {
      onSubmit(
        { ...service, delivery_name_ext: e.delivery_name_ext },
        selectedStartDate,
        selectedEndDate
      );
    });
    resetDates();
    resetTaxObjects();
    setOpen(false);
  };

  const onChangeDate = (isStartDate: boolean, optionValue: string) => {
    if (optionValue) {
      const formattedDate = getDateFromYearMonth(
        Number(optionValue),
        selectedMonthNumber + 1
      );

      if (isStartDate) {
        setSelectedStartDate(formattedDate);
      } else {
        setSelectedEndDate(formattedDate);
      }
    } else if (isStartDate) {
      setSelectedStartDate(undefined);
    } else {
      setSelectedEndDate(undefined);
    }
  };

  const yearDateToString = (date?: Date) =>
    date ? date.getFullYear().toString() : undefined;

  const isEndDateDisabled = (option: number) =>
    selectedStartDate && option - selectedStartDate.getFullYear() < 0;

  return (
    <>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onDismiss={() => onModalClose()}
          size="small-to-mid"
          header={<ModalHeader headerTitleText="SELECT_DELIVERY_TAX_SETTING" />}
          footer={
            <ModalFooter
              labelSubmit="COMPLETE"
              onCancel={() => onModalClose()}
              onSave={createTaxObjectDeliveries}
              isDisabled={
                !selectedStartDate || selectedTaxObjects?.length === 0
              }
            />
          }
        >
          <Row className="mb-3">
            <span className="fw-bold">{translate("SELECT_TAX_OBJECTS")}</span>
          </Row>
          {customer.taxObjects && (
            <>
              {!customer.taxObjects.company &&
                customer.taxObjects.persons.length === 0 && (
                  <div className="pb-md">
                    {translate("DP.NO_TAX_OBJECTS_FOR_CUSTOMER")}
                  </div>
                )}
              {customer.taxObjects.company && (
                <CustomerTaxObjectRow
                  taxObject={customer.taxObjects.company}
                  onChange={onTaxObjectSelectChange}
                  disabled={false}
                  className="mb-3"
                  key={`customer-tax-object-row-${customer.taxObjects.company.delivery_name_ext}`}
                />
              )}

              {customer.taxObjects.persons.map((customerTaxObject) => (
                <CustomerTaxObjectRow
                  taxObject={customerTaxObject}
                  onChange={onTaxObjectSelectChange}
                  disabled={false}
                  key={`customer-tax-object-row-${customerTaxObject.delivery_name_ext}`}
                  className="mb-3"
                />
              ))}

              {!customer.taxObjects.company &&
                customer.taxObjects.persons.length === 0 && (
                  <div className="color-red mb-md d-flex">
                    <div className="mr-sm">
                      <Warning20Regular />
                    </div>
                    <span>
                      {translate(
                        "DELIVERY_PLANNER.WARNING.NO_TAX_OBJECTS_FOUND"
                      )}
                    </span>
                  </div>
                )}

              <Row>
                <Col md={6} className="fw-bold">
                  <TooltipV9 content="TAX_OBJECT_START_DATE_DESCR">
                    <div className="d-flex align-items-center">
                      <Info20Filled className="mr-sm" />
                      <span>{translate("START_DATE")}</span>
                    </div>
                  </TooltipV9>
                </Col>
                <Col md={6} className="fw-bold">
                  <TooltipV9 content="TAX_OBJECT_END_DATE_DESCR">
                    <div className="d-flex align-items-center">
                      <Info20Filled className="mr-sm" />
                      <span>{translate("END_DATE")}</span>
                    </div>
                  </TooltipV9>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <RadioGroup
                    onChange={(_, option) => onChangeDate(true, option.value)}
                    value={yearDateToString(selectedStartDate)}
                  >
                    {availableStartPeriods.map((option) => (
                      <Radio
                        value={option.toString()}
                        label={option.toString()}
                      />
                    ))}
                  </RadioGroup>
                </Col>
                <Col md={6}>
                  <RadioGroup
                    onChange={(_, option) => onChangeDate(false, option.value)}
                    value={
                      selectedEndDate &&
                      isEndDateDisabled(selectedEndDate?.getFullYear())
                        ? undefined
                        : yearDateToString(selectedEndDate)
                    }
                  >
                    {availableStartPeriods.map((option) => (
                      <Radio
                        value={option.toString()}
                        label={option.toString()}
                        disabled={isEndDateDisabled(option)}
                      />
                    ))}
                  </RadioGroup>
                </Col>
              </Row>
            </>
          )}
        </Modal>
      )}
    </>
  );
}
