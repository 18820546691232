import { DataHistogram16Regular } from "@fluentui/react-icons";

import { TranslationKey } from "i18n";
import StatusIndicatorIcon from "components/icon/StatusIndicatorIcon";
import TooltipV9 from "components/tooltip/TooltipV9";
import "./index.scss";

type CustomerOverviewReportsLinkProps = {
  content: TranslationKey;
  onClick?: () => void;
};
export default function CustomerOverviewReportsLink({
  content,
  onClick,
}: CustomerOverviewReportsLinkProps) {
  return onClick ? (
    <TooltipV9 content={content}>
      <DataHistogram16Regular onClick={onClick} className="cursor-pointer" />
    </TooltipV9>
  ) : (
    <TooltipV9 content={content}>
      <StatusIndicatorIcon status="offline" />
    </TooltipV9>
  );
}
