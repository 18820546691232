export class CustomerSettingsContact {
  id?: string;

  email: string;

  firstname: string;

  lastname: string;

  phone: string;

  mobilephone: string;

  email_status?: EmailStatus;
}

export type EmailStatus = "valid" | "invalid";
