import { LocalizationHelper, CacheService } from "@microsoft/mgt-react";
import { TranslationKey } from "./i18n";
import { Translate } from "./i18n/translator";

const timeToUpdate = 24 * 60 * 60 * 1000;
const LAST_CACHE_CLEAR_KEY = "lastCacheClearTime";

CacheService.config.isEnabled = true;
CacheService.config.defaultInvalidationPeriod = timeToUpdate;

const getLastClearTime = (): number | null => {
  const lastClearTime = localStorage.getItem(LAST_CACHE_CLEAR_KEY);
  return lastClearTime ? Number(lastClearTime) : null;
};

const setLastClearTime = (time: number): void => {
  localStorage.setItem(LAST_CACHE_CLEAR_KEY, time.toString());
};

const getStoreInvalidationTime = () => {
  const lastClearTime = getLastClearTime();

  if (lastClearTime === null || Date.now() - lastClearTime > timeToUpdate) {
    removeAllIndexedDBs().then(() => {
      setLastClearTime(Date.now());
    });
  }
};

function removeAllIndexedDBs() {
  return indexedDB
    .databases()
    .then((databases) => {
      const deletionPromises = databases.map((db) => {
        return new Promise<void>((resolve) => {
          indexedDB.deleteDatabase(db.name as string);
          resolve();
        });
      });
      return Promise.all(deletionPromises);
    })
    .catch((error) => {
      console.error("Failed to retrieve databases:", error);
    });
}

getStoreInvalidationTime();

export function setTranslation(translation: Translate<TranslationKey>) {
  LocalizationHelper.strings = {
    noResultsFound: translation("NO_SEARCH_RESULTS"),
    _components: {
      "people-picker": {
        inputPlaceholderText: translation(
          "MGT_PEOPLE_PICKER_INPUT_PLACEHOLDER_TEXT"
        ),
        noResultsFound: translation("MGT_PEOPLE_PICKER_NO_RESULTS_FOUND"),
        loadingMessage: translation("MGT_PEOPLE_PICKER_LOADING"),
        maxSelectionsPlaceHolder: "",
      },
    },
  } as any;
}
