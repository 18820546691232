import { ArrowClockwise20Regular } from "@fluentui/react-icons";

import TooltipV9 from "components/tooltip/TooltipV9";
import "./layoutV2.scss";

/*

  These components are used to create a layout for the app. Current setup is:
   ___________                 ___________________________________________
  |   |___2___|       or      | 1 |_layoutLeft______________|_layoutRight_|
  |   |       |
  | 1 |   3   |
  |   |_______|
  |___|___4___|

  0. Layout                // This is the wrapper for everything
  1. layoutSidebar
  2. layoutHeader
    2a. layoutHeaderLeft   // These are optional and should be used if you'd like to split the
    2b. layoutHeaderRight  // header into 2 columns of which the right one is aligned to the right.
  3. layoutBody
  4. layoutFooter

  These components are used on the top level of the app and should only be used "once" per page.
  Because we have functionality where each page could have a individual header, currently the
  only thing that is placed in App is Layout and LayoutSidebar. The other essential Layout components
  such as LayoutHeader, LayoutBody and LayoutFooter are placed in the individual pages.
  
*/

export function LayoutV2({ children }: any) {
  return <div className="layout">{children}</div>;
}

export function LayoutHeaderV2({ children }: any) {
  return (
    <div className="layoutHeader">
      <div className="d-flex justify-content-between align-items-center w-100">
        {children}
        <div className="d-flex align-items-center">
          <div className="reload-vertical-divider pr-sm ml-md" />
          <TooltipV9 content="RELOAD_APP">
            <ArrowClockwise20Regular
              className="cursor-pointer"
              onClick={() => window.document.location.replace("/")}
            />
          </TooltipV9>
        </div>
      </div>
    </div>
  );
}

export function LayoutSubHeaderV2({ children }: any) {
  return (
    <div className="layoutSubHeader">
      <div className="layoutSubHeader--topShadow" />
      {children}
    </div>
  );
}

export function LayoutHeaderLeftV2({ children }: any) {
  return <div className="layoutHeader--left">{children}</div>;
}

export function LayoutHeaderRightV2({ children }: any) {
  return <div className="layoutHeader--right">{children}</div>;
}

export function LayoutSidebarV2({ children }: any) {
  return <div className="layoutSidebar">{children}</div>;
}

export function LayoutBodyV2({ children }: any) {
  return <div className="layoutBody2">{children}</div>;
}

export function LayoutFooterV2({ children }: any) {
  return <div className="layoutFooter2">{children}</div>;
}
