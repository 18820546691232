export function getDateFromYearMonth(year: number, month: number) {
  const date = new Date(
    `${year}-${(`${month}`).padStart(2, "0")}-01T12:00:00.000Z`
  );

  if (isNaN(date.getTime())) {
    throw new Error("Could not parse date");
  }

  return date;
}
