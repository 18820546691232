import { Comment12Filled, Comment20Regular } from "@fluentui/react-icons";

import { isUserInCustomerTeam } from "libs/customer-settings-helpers";
import { ActivityInstance } from "models/activities/activity";
import { Customer } from "models/customer";
import { User } from "models/user";

type Props = {
  activityInstance: ActivityInstance;
  customer: Customer;
  currentUser: User;
  setShowMessageModal: (showMessageModal: boolean) => void;
  setSelectedActivityInstance: (
    selectedActivityInstance: ActivityInstance | undefined
  ) => void;
};

export default function MessageButton({
  activityInstance,
  customer,
  currentUser,
  setShowMessageModal,
  setSelectedActivityInstance,
}: Props) {
  if (!isUserInCustomerTeam(customer, currentUser)) {
    return null;
  }

  return activityInstance.teams_conversation_weburl ? (
    <Comment12Filled
      className="cursor-pointer"
      onClick={() => {
        setSelectedActivityInstance(activityInstance);
        setShowMessageModal(true);
      }}
    />
  ) : (
    <Comment20Regular
      className="cursor-pointer"
      onClick={() => {
        setSelectedActivityInstance(activityInstance);
        setShowMessageModal(true);
      }}
    />
  );
}
