import { useFormContext } from "react-hook-form";

import { useTranslation } from "hooks/use-translate";
import { TextArea } from "components/textarea";
import { Deal } from "models/deals/deal";
import { DealTextAreaProps } from "./models/field-types";
import { useUpdateDealProperty } from "./useUpdateDealProperty";

function DealTextArea({
  deal,
  configuration,
  disabled = false,
  stage,
  ...rest
}: DealTextAreaProps) {
  const { isLoading } = useUpdateDealProperty();
  const { translate } = useTranslation();

  const { register, setValue, getValues } = useFormContext<Partial<Deal>>();

  const value = getValues(configuration.property) as string;

  const dealTextAreaOptions = register(configuration.property, {
    validate: {
      isValid: (val) => {
        if (
          stage?.configuration?.RequiredFields.includes(
            configuration.property
          ) &&
          !val
        ) {
          return translate("FIELD_REQUIRED");
        }
      },
    },
  });

  return (
    <TextArea
      disabled={isLoading || disabled}
      className="text-area-min-250"
      value={value}
      formRegister={dealTextAreaOptions}
      placeholder={configuration.placeholder}
      onChange={(e) =>
        setValue(configuration.property, e.target.value, {
          shouldDirty: true,
          shouldValidate: true,
        })
      }
      onBlur={(e) => e.preventDefault()}
      {...rest}
    />
  );
}
export default DealTextArea;
