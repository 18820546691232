import { CheckmarkCircleRegular } from "@fluentui/react-icons";

type CompletedIconProps = { className?: string; onClick?: () => void };

export function CompletedIcon({ onClick, className = "" }: CompletedIconProps) {
  return (
    <CheckmarkCircleRegular
      className={`${className} blue-link text-color-green-light`}
      onClick={onClick && onClick}
    />
  );
}
