import {
  BookContacts20Regular,
  CheckmarkCircle20Regular,
  Delete20Regular,
  Edit20Regular,
  Warning20Regular,
} from "@fluentui/react-icons";
import { HTMLAttributes } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import ContextualMenuV9 from "components/contextualMenu/ContextualMenuV9";
import InfoCard from "components/infoCard/InfoCard";
import { useTranslation } from "hooks/use-translate";
import { CustomerSettingsContact } from "models/customerSettings/customerSettingsContact";
import { CustomerPageDetailedPageEnum } from "models/enums/CustomerPageDetailedPage.enum";
import TooltipV9 from "components/tooltip/TooltipV9";
import { CardOption } from "views/offer/components/customerContactsList/ContactsCards";

type CustomerPageContactCardProps = {
  editingContact?: (arg: string) => void;
  deletingContact?: (arg: string) => void;
  setPrimaryContact?: (arg: string) => void;
  contact: CustomerSettingsContact;
  isPrimaryContact?: boolean;
  horizontal?: boolean;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
} & HTMLAttributes<HTMLElement>;

export default function CustomerPageContactCard({
  editingContact,
  deletingContact,
  setPrimaryContact,
  contact,
  isPrimaryContact = false,
  horizontal = true,
  md,
  lg,
  xl,
  xxl,
  ...rest
}: CustomerPageContactCardProps) {
  const { translate } = useTranslation();

  const location = useLocation();
  const lastUrlSegment = location.pathname.split("/").pop();
  const isDetailedPage =
    lastUrlSegment === CustomerPageDetailedPageEnum.CONTACTS;

  const canBePrimaryContact = () => {
    return (
      contact.email &&
      contact.email_status === "valid" &&
      !(
        contact.email.endsWith("@ludvig.se") ||
        contact.email.endsWith("@ludvigfast.se")
      ) &&
      !isPrimaryContact
    );
  };

  const contactsContextualMenu = (contactId: string) => {
    if (isDetailedPage) {
      return (
        <div className="contextual-menu">
          <ContextualMenuV9
            buttonStyle="ghost-button d-flex justify-content-start"
            menuItems={[
              {
                text: `${translate("EDIT_CONTACT")}`,
                content: <Edit20Regular className="mr-xs" />,
                onClick: () => editingContact && editingContact(contactId),
              },
              {
                text: `${translate("DELETE_CONTACT")}`,
                content: <Delete20Regular className="mr-xs" />,
                onClick: () => deletingContact && deletingContact(contactId),
              },
              {
                text: `${translate("CUSTOMER_PAGE.PRIMARY_CONTACT_ACTION")}`,
                content: <BookContacts20Regular className="mr-xs" />,
                onClick: () =>
                  setPrimaryContact && setPrimaryContact(contactId),
                hidden: !canBePrimaryContact(),
              },
            ]}
          />
        </div>
      );
    }
    return undefined;
  };

  const contactCardOptions = [
    {
      label: translate("NAME"),
      value: `${contact.firstname} ${contact.lastname}`,
    },
    {
      label: translate("MOBILE"),
      value: contact.mobilephone || (
        <span className="text-gray fs-italic fw-400">
          {translate("CUSTOMER_PAGE.MISSING_MOBILE")}
        </span>
      ),
      hasJSXContent: true,
    },
    {
      label: translate("PHONE"),
      value: contact.phone || (
        <span className="text-gray fs-italic fw-400">
          {translate("CUSTOMER_PAGE.MISSING_PHONE")}
        </span>
      ),
      hasJSXContent: true,
    },
    {
      label: translate("EMAIL_SHORT"),
      value: (
        <div className="d-flex w-100 justify-content-space-between">
          <span className="stringLength pr-xs">{contact.email} </span>
          <span>
            {contact.email_status === "valid" && (
              <CheckmarkCircle20Regular className="text-color-green-light" />
            )}
            {contact.email_status === "invalid" && (
              <Warning20Regular className="text-color-red" />
            )}
          </span>
        </div>
      ),
    },
  ];

  const renderCardContent = (cardOption: CardOption, contactId: string) => (
    <Row className="pb-sm cs-contact-card-row ">
      <Col className="d-flex pl-sm" md={4}>
        <TooltipV9
          content={cardOption.label}
          notTranslatable
          hasJSXContent={cardOption.hasJSXContent}
          positioning="above-start"
          childrenClassName="cs-contact-card-row-title"
        >
          <div className="stringLength">{cardOption.label}</div>
        </TooltipV9>
      </Col>
      <Col md={8} className="pl-sm stringLength">
        <TooltipV9
          content={cardOption.value}
          notTranslatable
          hasJSXContent={cardOption.hasJSXContent}
          positioning="above-start"
          childrenClassName="cs-contact-card-row-text"
        >
          <div className="stringLength">{cardOption.value}</div>
        </TooltipV9>
      </Col>
    </Row>
  );

  return (
    <Col md={md} lg={lg} xl={xl} xxl={xxl} {...rest}>
      {contact.id && (
        <InfoCard
          contextualMenu={contactsContextualMenu(contact.id)}
          showRibbon={isPrimaryContact}
          ribbonTooltipKey="CUSTOMER_PAGE.PRIMARY_CONTACT_TOOLTIP"
        >
          {contactCardOptions.map((cardOption) =>
            renderCardContent(cardOption, contact.id ?? "")
          )}
        </InfoCard>
      )}
    </Col>
  );
}