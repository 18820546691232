import { Spinner } from "@fluentui/react-components";
import { SpinnerSize } from "components/spinner";
import { Col, Row } from "react-bootstrap";
import { ErrorCircle16Regular } from "@fluentui/react-icons";

import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { LoadingStatus } from "state";
import "./section.scss";

export type SectionHeaderProps = {
  title?: string;
  loadingStatus?: LoadingStatus;
  content?: any;
} & React.HTMLAttributes<HTMLElement>;

export default function SectionHeader({
  title = "",
  loadingStatus = "idle",
  content = null,
}: SectionHeaderProps) {
  return (
    <div className="sectionHeader">
      <Row className="align-items-center">
        <Col md className="d-flex align-items-center ">
          <h3>{title}</h3>

          {loadingStatus === "pending" && (
            <span>
              <Spinner className="ml-sm" size={SpinnerSize.Tiny} />
            </span>
          )}
          {loadingStatus === LoadingStatusEnum.FAILED && (
            <span>
              <ErrorCircle16Regular className="ml-sm text-color-red" />
            </span>
          )}
        </Col>

        {content && <Col md="auto">{content}</Col>}
      </Row>
    </div>
  );
}
