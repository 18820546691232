import { addDays } from "date-fns";
import { rest } from "msw";
import { mockCheckToken, MOCK_DELAY } from "..";
import {
  Deal,
  DealContact,
  DealOption,
  DealType,
} from "../../../models/deals/deal";
import { Pipeline } from "../../../models/deals/pipeline";
import { Product } from "../../../models/deals/product";

import {
  getDigits,
  getEmployees,
  getRandomContact,
} from "../../generators/generator";

const BASE_PATH = process.env.REACT_APP_API_URL;

let callAttempts = 0;

const initialPipelines: Pipeline[] = [
  {
    id: "default",
    label: "Försäljning 2021",
    stages: [
      {
        display_order: 0,
        id: "7960864",
        label: "KVT:s - Rör ej",
      },
      {
        display_order: 1,
        id: "7960865",
        label: "Att kontakta",
      },
      {
        display_order: 2,
        id: "7960866",
        label: "Är i dialog med",
      },
      {
        display_order: 3,
        id: "7960867",
        label: "Har lämnat förslag",
      },
      {
        display_order: 4,
        id: "7960868",
        label: "Vunna affärer",
      },
      {
        display_order: 5,
        id: "7960869",
        label: "Förlorade affärer",
      },
    ],
  },
];

const initialProducts: Product[] = [
  {
    id: "product1",
    name: "Product #1",
    price: 9999,
    createdAt: new Date(),
    updatedAt: new Date(),
  },

  {
    id: "product2",
    name: "Product #2",
    price: 11999,
    createdAt: new Date(),
    updatedAt: new Date(),
  },

  {
    id: "product3",
    name: "Product #3",
    price: 12999,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const initialDeals: Partial<Deal>[] = [
  {
    dealstage: "7960865",
    dealname: "Missing products, missing amount",
    productIds: [],
    recent_conversion_event_name: "Framtidssamtal | Ludvig & Co",
    oms_ttning: 1000000,
    narmaste_kontor_deal: "blekinge@ludvig.se",
    meddelande_salj:
      "Framtidssamtal Framtidssamtal Framtidssamtal Framtidssamtal Framtidssamtal Framtidssamtal Framtidssamtal | Ludvig & Co Framtidssamtal Framtidssamtal Framtidssamtal Framtidssamtal Framtidssamtal Framtidssamtal Framtidssamtal | Ludvig & Co",
    contact: {
      id: getDigits(10),
      firstname: "Firstname",
      lastname: "Lastname",
      email: "contact@contact.com",
      aktivitet_event: "",
      aterkommande_aktiviteter: "sam_eu_radgivning",
    },
  },
  {
    dealstage: "7960865",
    dealname: "Missing amount",
    narmaste_kontor_deal: "bollnas@ludvig.se",
    productIds: ["product1"],
    recent_conversion_event_name:
      "Framtidssamtal Framtidssamtal Framtidssamtal Framtidssamtal Framtidssamtal Framtidssamtal Framtidssamtal | Ludvig & Co",
    oms_ttning: 1204400,
    contact: {
      id: getDigits(10),
      firstname: "Firstname",
      lastname: "Lastname",
      email: "contact@contact.com",
      aktivitet_event: "",
      aterkommande_aktiviteter: "sam_eu_radgivning",
    },
  },
  {
    dealstage: "7960865",
    dealname: "Kostnadsfri värdebedömning #1",
    deal_type: DealType["Kostnadsfri värdebedömning"],
    productIds: [],
    oms_ttning: 1200,
    contact: {
      id: getDigits(10),
      firstname: "Firstname",
      lastname: "Lastname",
      email: "contact@contact.com",
      aktivitet_event: "",
      aterkommande_aktiviteter: "sam_eu_radgivning",
    },
  },
  {
    dealstage: "7960865",
    dealname: "Centrala juristenheten #1",
    deal_type: DealType["Centrala juristenheten"],
    productIds: [],
    juridiska_omraden: "Affärsjuridik",
    onskemal_typ_av_mote: "Telefon",
    debiteras_enligt_pristaxa: "Yes",
    deal_source: "1",
    contact: {
      id: getDigits(10),
      firstname: "Firstname",
      lastname: "Lastname",
      email: "contact@contact.com",
      aktivitet_event: "",
      aterkommande_aktiviteter: "sam_eu_radgivning",
    },
  },
  {
    dealstage: "7960865",
    dealname: "Värdeindikator - fastighetsvärdering #1",
    deal_type: DealType["Värdeindikator - fastighetsvärdering"],
    vardeindiktator_fastighet_i_jamforelse_med_andra_fastigheter_i_ditt_omr_de_avkastningsf_rm_ga_arron:
      "worse",
    vardeindiktator_fastighet_i_jamforelse_med_andra_fastigheter_i_ditt_omrade:
      "better",
    vardeindiktator_lan: "Västerbotten",
    fastighetsbeteckning: "Skogen 1",
    v_rdeindikator_storlek_skogsmark_m3sk_: 1000,
    vardeindikator_storlek_akermark_hektar_: 1000,
    vardeindikator_storlek_skogsmark_hektar_: 1000,
    productIds: [],
    deal_source: "2",
    contact: {
      id: getDigits(10),
      firstname: "Firstname",
      lastname: "Lastname",
      email: "contact@contact.com",
      aktivitet_event: "",
      aterkommande_aktiviteter: "sam_eu_radgivning",
    },
  },
  {
    dealstage: "7960865",
    dealname: "Offertförfrågan ekonomi #1",
    deal_type: DealType["Offertförfrågan ekonomi"],
    efterfr_gade_tj_nster_ekonomi: [
      "Lön",
      "Bokslut, deklaration och löpande bokföring",
    ],
    productIds: [],
    contact: {
      id: getDigits(10),
      firstname: "Firstname",
      lastname: "Lastname",
      email: "contact@contact.com",
      aktivitet_event: "",
      aterkommande_aktiviteter: "sam_eu_radgivning",
    },
  },
  {
    dealstage: "7960866",
    amount: 2999,
    dealname: "Kostnadsfritt rådgivningsmöte #1",
    deal_type: DealType["Kostnadsfritt rådgivningsmöte"],
    productIds: [],
    contact: {
      id: getDigits(10),
      firstname: "Firstname",
      lastname: "Lastname",
      email: "contact@contact.com",
      aktivitet_event: "",
      aterkommande_aktiviteter: "sam_eu_radgivning",
    },
  },
  {
    dealstage: "7960866",
    hs_priority: "medium",
    amount: 1999,
    productIds: [],
    contact: {
      id: getDigits(10),
      firstname: "Firstname",
      lastname: "Lastname",
      email: "contact@contact.com",
      aktivitet_event: "",
      aterkommande_aktiviteter: "sam_eu_radgivning",
    },
  },
  {
    dealstage: "7960866",
    hs_priority: "high",
    amount: 2999,
    productIds: [],
    contact: {
      id: getDigits(10),
      firstname: "Firstname",
      lastname: "Lastname",
      email: "contact@contact.com",
      aktivitet_event: "",
      aterkommande_aktiviteter: "sam_eu_radgivning",
    },
  },
].map(({ amount, dealstage, productIds, dealname, ...rest }) => {
  const contact = getRandomContact();

  return {
    ...rest,
    id: "" + getDigits(6),
    dealname: dealname || `${contact.firstName} ${contact.lastName}`,
    dealstage: dealstage,
    amount: amount,
    pipeline: "1",
    createdAt: addDays(new Date(), -20 * Math.random()),
    updatedAt: addDays(new Date(), -20 * Math.random()),
    closedate: new Date(),
    owner: getEmployees()[0].email,
    productIds: productIds,
  } as Partial<Deal>;
});

export const dealsHandlers = [
  rest.get<Product[]>(
    `${BASE_PATH}/sales/products`,
    (req, res, { status, json, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      return res(status(200), json(initialProducts));
    }
  ),
  rest.get<Pipeline[]>(
    `${BASE_PATH}/sales/pipelines`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      return res(status(200), delay(MOCK_DELAY), json(initialPipelines));
    }
  ),
  rest.get<Deal[]>(
    `${BASE_PATH}/sales/deals`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      return res(status(200), delay(MOCK_DELAY), json(initialDeals));
    }
  ),
  rest.post<string>(
    `${BASE_PATH}/sales/contacts/search`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      const jsonBody = JSON.parse(req.body);
      const email = jsonBody["restrictions"]["emails"][0];

      if (email.includes("notfound")) {
        return res(status(404));
      }

      try {
        return res(
          status(200),
          delay(MOCK_DELAY),
          json([
            {
              id: getDigits(10),
              address: "Address 1",
              email,
              firstname: "Firstname",
              lastname: "Lastname",
              phone: "011 111 111 111",
            } as DealContact,
          ])
        );
      } catch (e) {
        return res(status(500));
      }
    }
  ),
  rest.post<string>(
    `${BASE_PATH}/sales/contacts`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      return res(status(200), delay(MOCK_DELAY), json([{}]));
    }
  ),
  rest.patch<string>(
    `${BASE_PATH}/sales/contacts/:id`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      return res(status(200), delay(MOCK_DELAY), text("OK"));
    }
  ),
  rest.post<string>(
    `${BASE_PATH}/sales/deals`,
    (req, res, { status, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      // Success every 2nd attempt
      if (++callAttempts % 2 === 0) {
        return res(status(200), delay(MOCK_DELAY), text("OK"));
      }

      return res(status(400), delay(MOCK_DELAY), text("Bad request"));
    }
  ),
  rest.patch<string>(
    `${BASE_PATH}/sales/deals/:dealId`,
    (req, res, { status, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      return res(status(200), delay(MOCK_DELAY), text("OK"));
    }
  ),
  rest.patch<string>(
    `${BASE_PATH}/sales/contact/:contactId`,
    (req, res, { status, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      return res(status(200), delay(MOCK_DELAY), text("OK"));
    }
  ),
  rest.patch<string>(
    `${BASE_PATH}/sales/deals/:dealId/products`,
    (req, res, { status, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      return res(status(200), delay(MOCK_DELAY), text("OK"));
    }
  ),
  rest.get<DealOption[]>(
    `${BASE_PATH}/sales/properties/deals`,
    (req, res, { status, json, delay, text }) => {
      const dealOptions: DealOption[] = [
        {
          label: "Interna anteckningar",
          name: "meddelande_salj",
          options: [],
        },
        {
          label: "Närmsta kontor",
          name: "narmaste_kontor_deal",
          options: [
            {
              display_order: 0,
              label: "Bollnäs",
              value: "bollnas@ludvig.se",
            },
            {
              display_order: 0,
              label: "Blekinge",
              value: "blekinge@ludvig.se",
            },
          ],
        },
        {
          label: "Telefonnummer - företag",
          name: "company_phone",
          options: [],
        },
        {
          label: "Postort",
          name: "postal_city",
          options: [],
        },
        {
          label: "Omsättning",
          name: "oms_ttning",
          options: [],
        },
        {
          label: "Värdeindiktator - Län",
          name: "vardeindiktator_lan",
          options: [
            {
              display_order: 0,
              label: "Blekinge",
              value: "Blekinge",
            },
            {
              display_order: 1,
              label: "Dalarna",
              value: "Dalarna",
            },
            {
              display_order: 2,
              label: "Gotland",
              value: "Gotland",
            },
            {
              display_order: 3,
              label: "Gävleborg",
              value: "Gävleborg",
            },
            {
              display_order: 4,
              label: "Halland",
              value: "Halland",
            },
            {
              display_order: 5,
              label: "Jämtland",
              value: "Jämtland",
            },
            {
              display_order: 6,
              label: "Jönköping",
              value: "Jönköping",
            },
            {
              display_order: 7,
              label: "Kalmar",
              value: "Kalmar",
            },
            {
              display_order: 8,
              label: "Kronoberg",
              value: "Kronoberg",
            },
            {
              display_order: 9,
              label: "Norrbotten",
              value: "Norrbotten",
            },
            {
              display_order: 10,
              label: "Skåne",
              value: "Skåne",
            },
            {
              display_order: 11,
              label: "Stockholm",
              value: "Stockholm",
            },
            {
              display_order: 12,
              label: "Södermanland",
              value: "Södermanland",
            },
            {
              display_order: 13,
              label: "Uppsala",
              value: "Uppsala",
            },
            {
              display_order: 14,
              label: "Värmland",
              value: "Värmland",
            },
            {
              display_order: 15,
              label: "Västerbotten",
              value: "Västerbotten",
            },
            {
              display_order: 16,
              label: "Västernorrland",
              value: "Västernorrland",
            },
            {
              display_order: 17,
              label: "Västmanland",
              value: "Västmanland",
            },
            {
              display_order: 18,
              label: "Västra Götaland",
              value: "Västra Götaland",
            },
            {
              display_order: 19,
              label: "Örebro",
              value: "Örebro",
            },
            {
              display_order: 20,
              label: "Östergötland",
              value: "Östergötland",
            },
          ],
        },
        {
          label: "Önskemål - typ av möte",
          name: "onskemal_typ_av_mote",
          options: [
            {
              display_order: 0,
              label: "Fysiskt",
              value: "fysiskt",
            },
            {
              display_order: 1,
              label: "Telefon",
              value: "telefon",
            },
            {
              display_order: 2,
              label: "Video",
              value: "video",
            },
          ],
        },
        {
          label: "Priority",
          name: "hs_priority",
          options: [
            {
              display_order: 0,
              label: "Low",
              value: "low",
            },
            {
              display_order: 1,
              label: "Medium",
              value: "medium",
            },
            {
              display_order: 2,
              label: "High",
              value: "high",
            },
          ],
        },
        {
          label: "Värdeindikator - Storlek skogsmark (hektar)",
          name: "vardeindikator_storlek_skogsmark_hektar_",
          options: [],
        },
        {
          label: "Instegserbjudanden",
          name: "free_consulting",
          options: [
            {
              display_order: 0,
              label: "Genomfört - kostnadsfritt rådgivningsmöte",
              value: "1",
            },
            {
              display_order: 1,
              label: "Genomförd - kostnadsfri värdebedömning",
              value: "2",
            },
          ],
        },
        {
          label: "Postadress - C/O",
          name: "postal_address_co",
          options: [],
        },
        {
          label: "Värdeindikator - resultat",
          name: "v_rdeindikator_resultat",
          options: [],
        },
        {
          label: "Förfrågan skickades in från",
          name: "recent_conversion_event_name",
          options: [],
        },
        {
          label: "Postnummer",
          name: "postnummer",
          options: [],
        },
        {
          label: "Bolagsnamn",
          name: "company",
          options: [],
        },
        {
          label: "Kommun fastigheten ligger i",
          name: "state",
          options: [],
        },
        {
          label: "Bearbetningsstatus",
          name: "deal_status",
          options: [
            {
              display_order: 0,
              label: "Kontaktad",
              value: "1",
            },
            {
              display_order: 1,
              label: "Försökt få kontakt - inget svar",
              value: "2",
            },
            {
              display_order: 2,
              label: "Möte bokat",
              value: "3",
            },
            {
              display_order: 3,
              label: "Genomförd behovsanalys",
              value: "4",
            },
            {
              display_order: 4,
              label: "Ej kontaktad",
              value: "5",
            },
          ],
        },
        {
          label: "Postadress",
          name: "postal_address",
          options: [],
        },
        {
          label: "Typ av förfrågan",
          name: "deal_type",
          options: [
            {
              display_order: 0,
              label: "Generell förfrågan",
              value: "1",
            },
            {
              display_order: 1,
              label: "Värdeindikator - fastighetsvärdering",
              value: "2",
            },
            {
              display_order: 2,
              label: "Offertförfrågan ekonomi",
              value: "3",
            },
            {
              display_order: 3,
              label: "Kostnadsfri värdebedömning",
              value: "4",
            },
            {
              display_order: 4,
              label: "Kostnadsfritt rådgivningsmöte",
              value: "5",
            },
            {
              display_order: 5,
              label: "Centrala juristenheten",
              value: "6",
            },
          ],
        },
        {
          label: "Information om ärendet (jurisenhet)",
          name: "information_om_arendet_jurisenhet_",
          options: [],
        },
        {
          label: "Hur kan vi hjälpa dig?",
          name: "message",
          options: [],
        },
        {
          label: "Juridiska områden",
          name: "juridiska_omraden",
          options: [
            {
              display_order: 0,
              label: "Affärsjuridik",
              value: "Affärsjuridik",
            },
            {
              display_order: 1,
              label: "Allmän fastighetsrätt",
              value: "Allmän fastighetsrätt",
            },
            {
              display_order: 2,
              label: "Arbetsrätt",
              value: "Arbetsrätt",
            },
            {
              display_order: 3,
              label: "Arrende- och nyttjanderätt",
              value: "Arrende- och nyttjanderätt",
            },
            {
              display_order: 4,
              label: "Bostadsrättsföreningar/stadgar",
              value: "Bostadsrättsföreningar/stadgar",
            },
            {
              display_order: 5,
              label: "Ekonomi och skatt",
              value: "Ekonomi och skatt",
            },
            {
              display_order: 6,
              label: "Entreprenadrätt",
              value: "Entreprenadrätt",
            },
            {
              display_order: 7,
              label: "Familjerätt",
              value: "Familjerätt",
            },
            {
              display_order: 8,
              label: "Familjerättsliga vårdnadstvister",
              value: "Familjerättsliga vårdnadstvister",
            },
            {
              display_order: 9,
              label: "Företrädaransvar",
              value: "Företrädaransvar",
            },
            {
              display_order: 10,
              label: "Försäkring brand och skada",
              value: "Försäkring brand och skada",
            },
            {
              display_order: 11,
              label: "GDPR/dataskydd",
              value: "GDPR/dataskydd",
            },
            {
              display_order: 12,
              label: "Generationsskifte",
              value: "Generationsskifte",
            },
            {
              display_order: 13,
              label: "Hästjuridik",
              value: "Hästjuridik",
            },
            {
              display_order: 14,
              label: "Intrång",
              value: "Intrång",
            },
            {
              display_order: 15,
              label: "Jakt- och djurskyddsfrågor",
              value: "Jakt- och djurskyddsfrågor",
            },
            {
              display_order: 16,
              label: "Kommersiell fastigheter",
              value: "Kommersiell fastigheter",
            },
            {
              display_order: 17,
              label: "Kommersiell hyresrätt",
              value: "Kommersiell hyresrätt",
            },
            {
              display_order: 18,
              label: "Lantmäteritjänster",
              value: "Lantmäteritjänster",
            },
            {
              display_order: 19,
              label: "Mark-och miljörätt",
              value: "Mark-och miljörätt",
            },
            {
              display_order: 20,
              label: "Miljö",
              value: "Miljö",
            },
            {
              display_order: 21,
              label: "Miljötillstånd",
              value: "Miljötillstånd",
            },
            {
              display_order: 22,
              label: "Obeståndsrätt",
              value: "Obeståndsrätt",
            },
            {
              display_order: 23,
              label: "Plan- och bygglagsfrågor",
              value: "Plan- och bygglagsfrågor",
            },
            {
              display_order: 24,
              label: "Skatt",
              value: "Skatt",
            },
            {
              display_order: 25,
              label: "Skog och lantbruk",
              value: "Skog och lantbruk",
            },
            {
              display_order: 26,
              label: "Stämpelskatt, lagfartssammanträden",
              value: "Stämpelskatt, lagfartssammanträden",
            },
            {
              display_order: 27,
              label: "Tvister",
              value: "Tvister",
            },
            {
              display_order: 28,
              label: "Upphandling",
              value: "Upphandling",
            },
            {
              display_order: 29,
              label: "Vattenrätt",
              value: "Vattenrätt",
            },
            {
              display_order: 30,
              label: "Vindkraft",
              value: "Vindkraft",
            },
          ],
        },
        {
          label: "Värdeindikator - Storlek åkermark (hektar)",
          name: "vardeindikator_storlek_akermark_hektar_",
          options: [],
        },
        {
          label: "Efterfrågade tjänster ekonomi",
          name: "efterfr_gade_tj_nster_ekonomi",
          options: [
            {
              display_order: 0,
              label: "Bokslut, deklaration och löpande bokföring",
              value: "Bokslut, deklaration och löpande bokföring",
            },
            {
              display_order: 1,
              label: "Lön",
              value: "Lön",
            },
            {
              display_order: 2,
              label: "Annat",
              value: "Annat",
            },
          ],
        },
        {
          label: "Orsak till förlorad affär",
          name: "lost_reason",
          options: [
            {
              display_order: 0,
              label: "Valde annan leverantör",
              value: "1",
            },
            {
              display_order: 1,
              label: "På grund av priset",
              value: "2",
            },
            {
              display_order: 2,
              label: "Efterfrågade annan kompetens/lösning",
              value: "3",
            },
            {
              display_order: 3,
              label: "Inte intressant för Ludvig",
              value: "4",
            },
            {
              display_order: 4,
              label: "Ev aktuellt längre fram",
              value: "5",
            },
            {
              display_order: 5,
              label: "Ej affärsförfrågan",
              value: "6",
            },
            {
              display_order: 6,
              label: "Inget svar från kund",
              value: "7",
            },
            {
              display_order: 7,
              label: "Övrigt",
              value: "8",
            },
          ],
        },
        {
          label: "Tjänsteområde",
          name: "affarsomrade_deal_",
          options: [
            {
              display_order: 0,
              label: "Ekonomitj\u00e4nster",
              value: "Ekonomitj\u00e4nster",
            },
            {
              display_order: 1,
              label: "Juridik",
              value: "Juridik",
            },
            {
              display_order: 2,
              label: "Skatter\u00e5dgivning",
              value: "Skatter\u00e5dgivning",
            },
            {
              display_order: 3,
              label: "Fastighetsf\u00f6rmedling",
              value: "Fastighetsf\u00f6rmedling",
            },
            {
              display_order: 4,
              label: "Aff\u00e4rsr\u00e5dgivning",
              value: "Aff\u00e4rsr\u00e5dgivning",
            },
            {
              display_order: 5,
              label: "Skoglig r\u00e5dgivning",
              value: "Skoglig r\u00e5dgivning",
            },
            {
              display_order: 6,
              label: "Fakturafr\u00e5gor",
              value: "Fakturafr\u00e5gor",
            },
            {
              display_order: 7,
              label: "Lediga tj\u00e4nster/Studentprojekt",
              value: "Lediga tj\u00e4nster/Studentprojekt",
            },
            {
              display_order: 8,
              label: "Annat",
              value: "Annat",
            },
          ],
        },
        {
          label: "Källa",
          name: "deal_source",
          options: [
            {
              display_order: 0,
              label: "Telefon (inkommande samtal)",
              value: "1",
            },
            {
              display_order: 1,
              label: "E-post (inkommande e-post)",
              value: "2",
            },
            {
              display_order: 2,
              label: "Eget säljarbete",
              value: "3",
            },
            {
              display_order: 3,
              label: "Event",
              value: "4",
            },
            {
              display_order: 4,
              label: "ludvig.se",
              value: "5",
            },
          ],
        },
        {
          label: "Verifikationer",
          name: "verifikationer",
          options: [],
        },
        {
          label: "Företagsbeskrivning",
          name: "foretagsbeskrivning",
          options: [],
        },
        {
          label: "Värdeindikator - Storlek skogsmark (m3sk)",
          name: "v_rdeindikator_storlek_skogsmark_m3sk_",
          options: [],
        },
        {
          label: "Fastighetsbeteckning",
          name: "fastighetsbeteckning",
          options: [],
        },
        {
          label: "Debiteras enligt pristaxa",
          name: "debiteras_enligt_pristaxa",
          options: [
            {
              display_order: 0,
              label: "Yes",
              value: "true",
            },
            {
              display_order: 1,
              label: "No",
              value: "false",
            },
          ],
        },
        {
          label:
            "Värdeindiktator - Fastighet i jämförelse med andra fastigheter i ditt område (Bonitet, arrondering, läge, kvalitet, vägnät, avstånd till industrier, jakt)",
          name: "vardeindiktator_fastighet_i_jamforelse_med_andra_fastigheter_i_ditt_omrade",
          options: [
            {
              display_order: 0,
              label: "Sämre än genomsnittet",
              value: "worse",
            },
            {
              display_order: 1,
              label: "Som genomsnittet",
              value: "average",
            },
            {
              display_order: 2,
              label: "Bättre än genomsnittet",
              value: "better",
            },
          ],
        },
        {
          label:
            "Värdeindiktator - Fastighet i jämförelse med andra fastigheter i ditt område (Avkastningsförmåga, arrondering, läget, avståndet till tätort)",
          name: "vardeindiktator_fastighet_i_jamforelse_med_andra_fastigheter_i_ditt_omr_de_avkastningsf_rm_ga_arron",
          options: [
            {
              display_order: 0,
              label: "Sämre än genomsnittet",
              value: "worse",
            },
            {
              display_order: 1,
              label: "Som genomsnittet",
              value: "average",
            },
            {
              display_order: 2,
              label: "Bättre än genomsnittet",
              value: "better",
            },
          ],
        },
        {
          label: "Antal löneavier",
          name: "antal_l_ner",
          options: [],
        },
        {
          label: "Organisationsnummer",
          name: "organisationsnummer",
          options: [],
        },
      ];

      return res(status(200), delay(MOCK_DELAY), json(dealOptions));
    }
  ),
];
