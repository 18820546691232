/**
 * Calculate the clock index on a pie, with offset and wrap-around
 * @param index
 * @param offset
 * @param max
 * @returns
 */
export function wrapIndex(index: number, offset: number, max: number) {
  const indexWithOffset = index + offset;

  if (indexWithOffset > 0 && indexWithOffset < max) {
    return indexWithOffset;
  }

  if (indexWithOffset < 0) {
    return indexWithOffset + max;
  }

  if (indexWithOffset >= max) {
    return indexWithOffset - max + 1;
  }

  return index;
}
