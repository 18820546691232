/* eslint-disable no-underscore-dangle */
import { useDispatch, useSelector } from "react-redux";

import { capitalize } from "libs/capitalize";
import { EKONOMI_BUSINESS_AREA } from "libs/constants";
import { Deal, DealContact } from "models/deals/deal";
import { CompanyInformation } from "models/offer/Company";
import { RootState } from "state";
import { updateDealProducts, updateDealValues } from "state/sales/actions";
import { availableTogglesMeta } from "views/offer/components/servicePicker/ServicePickerHelper";

export function useUpdateDealProperty() {
  const dispatch = useDispatch();
  const sales = useSelector((state: RootState) => state.sales);
  const dealsOptions = sales.dealOptions;
  const dealsBusinessAreas = dealsOptions.data.find(
    (d) => d.name === "affarsomrade_deal_"
  );

  const setDealProperty = async (dealId: string, change: Partial<Deal>) => {
    dispatch(updateDealValues(dealId, change));
  };

  const getDealNameOnBusinessAreaUpdate = (
    firstname: string,
    lastname: string,
    option: string
  ) => {
    const selectedBusinessAreaLabel = dealsBusinessAreas?.options.find(
      (ba) => ba.value === option
    )?.label;
    return `${firstname} ${lastname} | ${selectedBusinessAreaLabel}`;
  };

  const productOptions = sales.products.data.map((p) => ({
    text: p.name,
    data: p,
    key: p.id,
    disabled: false,
  }));

  // using the same logic from the pog service picker
  // only for pog services
  // package services should disable all services,
  // non package services should disable package services
  const disableProductsToMatchPOGServices = (
    selectedIds: string[],
    businessArea: string
  ) => {
    const selectedBusinessArea = dealsBusinessAreas?.options.find(
      (ba) => ba.value === businessArea
    );
    const productOptionsByArea = productOptions.filter(
      (po) => po.data?.activity_category_label === selectedBusinessArea?.label
    );
    if (businessArea === EKONOMI_BUSINESS_AREA) {
      selectedIds.forEach((pid) => {
        const currentProduct = productOptionsByArea.find(
          (product) => product.key === pid
        );
        if (currentProduct && currentProduct.data?.description) {
          const currentService =
            availableTogglesMeta[capitalize(currentProduct.data.description)];
          if (currentService) {
            const { isPackage } = currentService;
            productOptionsByArea.forEach((product) => {
              if (
                product.data?.description &&
                product.data.description !== currentProduct.data!.description
              ) {
                const targetService =
                  availableTogglesMeta[capitalize(product.data.description)];
                product.disabled = isPackage
                  ? !!targetService
                  : targetService && targetService.isPackage;
              }
            });
          }
        }
      });
    }
    const dropdownProducts = productOptionsByArea.map((product) => {
      const dropdownProduct = {
        text: product.text,
        value: product.key,
        disabled: product.disabled,
      };
      return dropdownProduct;
    });
    return dropdownProducts;
  };

  const setDealProducts = async (dealId: string, products: string[]) => {
    dispatch(updateDealProducts(dealId, products, false));
  };

  const dealsFreeConsulting = dealsOptions.data.find(
    (d) => d.name === "free_consulting"
  );

  const handleFormSubmit = async (
    formValues: Partial<Deal>,
    dirtyFields: string[],
    deal: Deal,
    hubspotCompany?: Partial<CompanyInformation>
  ) => {
    const updatedDealValues = { ...formValues };
    let updatedDealProducts;
    for (const [key, value] of Object.entries(updatedDealValues)) {
      if (dirtyFields.includes(key)) {
        if (key === "productIds") {
          delete updatedDealValues[key as keyof Deal];
          updatedDealProducts = value;
        }
        if (key === "affarsomrade_deal_") {
          dirtyFields.push("dealname");
          updatedDealValues.dealname = getDealNameOnBusinessAreaUpdate(
            deal.contact.firstname,
            deal.contact.lastname,
            value as string
          );
        }
        if (key === "amount") {
          const amount = value as string;
          updatedDealValues.amount = parseInt(amount, 10);
        }
      } else {
        delete updatedDealValues[key as keyof Deal];
      }
    }

    if (hubspotCompany) {
      updatedDealValues.hubspot_company = hubspotCompany;
    }
    await setDealProperty(deal.id, updatedDealValues);
    if (updatedDealProducts) {
      await setDealProducts(deal.id, updatedDealProducts as string[]);
    }
  };

  const handleContactFormSubmit = async (
    formValues: DealContact,
    deal: Deal
  ) => {
    const updatedDealContactValues = { ...formValues };

    const dealName = getDealNameOnBusinessAreaUpdate(
      updatedDealContactValues.firstname,
      updatedDealContactValues.lastname,
      deal.affarsomrade_deal_
    );
    await setDealProperty(deal.id, {
      contact: updatedDealContactValues,
      dealname: dealName,
    });
  };

  return {
    handleFormSubmit,
    handleContactFormSubmit,
    disableProductsToMatchPOGServices,
    dealsFreeConsulting,
    isLoading: sales.isLoading,
  };
}
