import React from "react";
import { Col } from "react-bootstrap";

export type ColSize =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12"
  | "auto"
  | number
  | undefined;

interface ServiceColumnProps {
  children: React.ReactNode;
  colSize: ColSize;
  className?: string;
}

export default function ServiceColumn({
  children,
  colSize,
  className,
}: ServiceColumnProps): React.ReactElement {
  const isNumber = typeof colSize === "number";
  const customWidth = isNumber ? colSize : undefined;
  const size = !isNumber ? colSize : undefined;

  if (typeof customWidth === "number") {
    return (
      <div style={{ width: customWidth }} className={className}>
        {children}
      </div>
    );
  }

  return (
    <Col md={size} className={className}>
      {children}
    </Col>
  );
}
