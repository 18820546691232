import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { Persona } from "components/people";
import { useTranslation } from "hooks/use-translate";
import { ServiceCategory } from "models/offer/ServiceLine";
import { RootState } from "state";
import { ViewType } from "models/mgt";

enum TeamLevel {
  Offer = "OFFER",
  Service = "SERVICE",
}
enum PersonType {
  SalesPerson = "SALESPERSON",
  ProjectManager = "PROJECT_MANAGER",
}

function OfferTeam() {
  const { translate, ts } = useTranslation();

  const { data: currentOffer } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );

  if (!currentOffer) {
    return <div>Missing offer data</div>;
  }

  const serviceCategories = currentOffer.service_areas
    .flatMap((sa) => sa.service_lines)
    .flatMap((sl) => sl.service_groups)
    .flatMap((sg) => sg.service_categories);

  function renderResponsiblePerson(personEmail: string) {
    return <Persona userId={personEmail} view={ViewType.oneline} />;
  }

  function renderTeamRowItem(
    level: TeamLevel,
    personType: PersonType,
    person: string,
    service?: ServiceCategory
  ) {
    return (
      <Row className="bottom-border">
        <Col className="pt-sm">
          <span>{translate(level)}</span>
        </Col>
        <Col className="pt-sm">
          <span>{translate(personType)}</span>
        </Col>
        <Col className="pt-sm">{service?.name ? ts(service.name) : "-"}</Col>
        <Col className="pt-sm">{renderResponsiblePerson(person)}</Col>
      </Row>
    );
  }

  return (
    <Row className="pt-lg pr-xl pb-lg">
      <Col>
        <Row className="bottom-border pb-sm">
          <Col>
            <span className="fw-semibold">{translate("LEVEL")}</span>
          </Col>
          <Col>
            <span className="fw-semibold">{translate("TYPE")}</span>
          </Col>
          <Col>
            <span className="fw-semibold">{translate("SERVICE")}</span>
          </Col>
          <Col>
            <span className="fw-semibold">{translate("PERSON")}</span>
          </Col>
        </Row>
        {renderTeamRowItem(
          TeamLevel.Offer,
          PersonType.ProjectManager,
          currentOffer?.sales_manager ?? "N/A"
        )}
        {serviceCategories.map((sc) => (
          <div key={`teamRow-${sc.name}`}>
            {renderTeamRowItem(
              TeamLevel.Service,
              PersonType.SalesPerson,
              sc.project_manager,
              sc
            )}
          </div>
        ))}
      </Col>
    </Row>
  );
}

export default OfferTeam;
