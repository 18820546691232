import {
  LOADING_SERVICES,
  ServicesAction as ServicesDescriptionAction,
  SET_SERVICES,
} from ".";
import { ServiceDescription } from "../../models/serviceDescription";

export type ServicesDescriptionState = {
  data: ServiceDescription[];
  isLoading: boolean;
};

const initialState: ServicesDescriptionState = {
  data: [],
  isLoading: false,
};

export function servicesDescriptionsReducer(
  { data, isLoading } = initialState,
  action: ServicesDescriptionAction
) {
  switch (action.type) {
    case SET_SERVICES: {
      return { data: action.payload, isLoading: false };
    }
    case LOADING_SERVICES: {
      return { data, isLoading: action.payload };
    }
    default:
      return { data, isLoading };
  }
}
