import { Type } from "class-transformer";

import { CompanyForm } from "models/customer";

type CompanyType = {
  value: CompanyTypes;
  label: string;
};

export enum CompanyTypes {
  SE_PrivatePerson = "SE_PrivatePerson",
  SE_JointStockCompany = "SE_JointStockCompany",
  SE_TradingCompany = "SE_TradingCompany",
  SE_SoleProprietorship = "SE_SoleProprietorship",
  SE_Foundation = "SE_Foundation",
  SE_LimitedPartnership = "SE_LimitedPartnership",
  SE_VoluntaryAssociation = "SE_VoluntaryAssociation",
  SE_EconomicAssociation = "SE_EconomicAssociation",
  SE_EnkeltBolag = "SE_EnkeltBolag",
  SE_RegisteredReligiousCommunity = "SE_RegisteredReligiousCommunity",
  SE_HousingAssociation = "SE_HousingAssociation",
  SE_Municipality = "SE_Municipality",
  SE_Filial = "SE_Filial",
  SE_Other = "SE_Other",
}

export enum LegalFormCodes {
  PrivatePerson = "pp",
  JointStockCompany = "ab",
  TradingCompany = "hb",
  SoleProprietorship = "ef",
  Foundation = "sti",
  LimitedPartnership = "kb",
  VoluntaryAssociation = "idf",
  EconomicAssociation = "ekf",
  EnkeltBolag = "eb",
  RegisteredReligiousCommunity = "rt",
  HousingAssociation = "bf",
  Municipality = "kk",
  Filial = "fi",
  Other = "öv",
}

export interface CompanyTypeList {
  [key: string]: CompanyType;
}

export const CompanyTypeOptions = {
  pp: {
    value: CompanyTypes.SE_PrivatePerson,
    label: "Privatperson",
  },
  ab: {
    value: CompanyTypes.SE_JointStockCompany,
    label: "Aktiebolag",
  },
  hb: {
    value: CompanyTypes.SE_TradingCompany,
    label: "Handelsbolag",
  },
  ef: {
    value: CompanyTypes.SE_SoleProprietorship,
    label: "Enskild firma",
  },
  sti: {
    value: CompanyTypes.SE_Foundation,
    label: "Stiftelse",
  },
  kb: {
    value: CompanyTypes.SE_LimitedPartnership,
    label: "Kommanditbolag",
  },
  idf: {
    value: CompanyTypes.SE_VoluntaryAssociation,
    label: "Ideell förening",
  },
  ekf: {
    value: CompanyTypes.SE_EconomicAssociation,
    label: "Ekonomisk förening",
  },
  eb: {
    value: CompanyTypes.SE_EnkeltBolag,
    label: "Enkelt bolag",
  },
  rt: {
    value: CompanyTypes.SE_RegisteredReligiousCommunity,
    label: "Registrerat trossamfund",
  },
  bf: {
    value: CompanyTypes.SE_HousingAssociation,
    label: "Bostadsrättsförening",
  },
  kk: {
    value: CompanyTypes.SE_Municipality,
    label: "Kommun/kommunalförbund",
  },
  fi: {
    value: CompanyTypes.SE_Filial,
    label: "Filial",
  },
  öv: {
    value: CompanyTypes.SE_Other,
    label: "Övriga",
  },
} as const;

export type ValidLegalFormCodes = keyof typeof CompanyTypeOptions;

export type CompanyTypeFactsTableItem = {
  "": string;
  ID: number;
  "Content Type ID": string;
  Title: string;
  Modified: string;
  Created: string;
  Attachments: boolean;
  GUID: string;
  "Compliance Asset Id": null;
  CapegoID: CompanyTypes[keyof CompanyTypes];
  NameSE: string;
  NameEN: string;
  ShortNameSE: Uppercase<CompanyFormOption>;
  RetrieverName: string | null;
  WKName: ValidLegalFormCodes;
};

export type CompanyTypeListItem = Pick<
  CompanyTypeFactsTableItem,
  | "Title"
  | "CapegoID"
  | "NameEN"
  | "NameSE"
  | "ShortNameSE"
  | "WKName"
  | "RetrieverName"
>;

export type LegalForm = {
  id: number;
  value: CompanyForm;
  text: string;
};

export const legalForms: LegalForm[] = [
  {
    id: 0,
    value: "ef",
    text: "Enskild firma",
  },
  {
    id: 1,
    value: "ab",
    text: "Aktiebolag",
  },
];

export type CompanyFormOption =
  | CompanyForm
  | "öv"
  | "foreign_company"
  | "not_registered"
  | "";

export type CompanyInformation = {
  name: string;
  id?: string;
  customer_number?: string;
  org_number?: string;
  company_type?: string;
  phone?: string;
  mobile?: string;
  city?: string;
  zip?: string;
  address?: string;
  company_type_retriever?: string;
  bolagsform?: string;
  customer_status?: string;
  sni_1?: string;
  sni_2?: string;
  sni_3?: string;
  sni_4?: string;
  sni_description_1?: string;
  sni_description_2?: string;
  sni_description_3?: string;
  sni_description_4?: string;
  sni_industry_1?: string;
  sni_industry_2?: string;
  sni_industry_3?: string;
  sni_industry_4?: string;
  cost_center?: string;
  customer_owner_id?: string;
  hubspot_owner_id?: string;
  office_name?: string;
  active_projects?: string;
  inactive_projects?: string;
  create_date_mac?: string;
  chosen_program_new_sales_manager?: string;
  primary_contact_id?: string;
};

export class Company {
  address?: string;

  @Type(() => Date)
  changeDate?: Date | undefined;

  coAddress?: string;

  commune?: string;

  communeCode?: string;

  companyDeregistrationDate?: string;

  companyId!: string;

  companyName?: string;

  companyRegistrationDate?: string;

  county?: string;

  employerContributionReg?: boolean;

  industryCode?: string;

  industryText?: string;

  legalGroupCode?: string;

  legalGroupText?: string;

  numberCompanyUnits?: number;

  numberEmployeesInterval?: string;

  preliminaryTaxReg?: boolean;

  preliminaryTaxRegDate?: string;

  severalCompanyName?: boolean;

  statusCode?: string;

  statusDateFrom?: string;

  statusTextDetailed?: string;

  statusTextHigh?: string;

  town?: string;

  vatReg?: boolean;

  visitTown?: string;

  visitZipCode?: string;

  zipCode?: string;
}
