import { Spinner } from "@fluentui/react-components";
import { SpinnerSize } from "components/spinner";
import { LoadingStatus } from "../../state";

type EmptyListProps = {
  isEmpty: boolean;
  title: string;
  actionButton: JSX.Element;
  children: any;
  loadingStatus?: LoadingStatus;
};

export const EmptyList = ({
  isEmpty,
  title,
  actionButton,
  children,
  loadingStatus,
}: EmptyListProps) => {
  if (loadingStatus === "pending") {
    return (
      <div className="minHeight-80 d-flex flex-column align-items-center justify-content-center">
        <Spinner size={SpinnerSize.Tiny} />
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className="minHeight-80 d-flex flex-column align-items-center justify-content-center">
        <span className="body-italic-light">{title}</span>

        <div className="mt-4">{actionButton}</div>
      </div>
    );
  }

  return children;
};
