import { Spinner } from "@fluentui/react-components";
import { SpinnerSize } from "components/spinner";
import { ReactNode, useContext } from "react";
import { Collapse } from "react-bootstrap";

import { LoadingStatusEnum } from "../../../models/enums/LoadingStatus.enum";
import { AccordionContextV2 } from "../AccordionContextProviderV2";
import "../accordionV2.scss";

interface IAccordionContent {
  children: ReactNode;
  loadingStatus?: string;
}

export function AccordionContentV2({
  children,
  loadingStatus,
}: IAccordionContent) {
  const { isOpen } = useContext(AccordionContextV2);

  return (
    <Collapse in={isOpen} mountOnEnter unmountOnExit>
      <div>
        <div className="accordion-item-content2">
          {loadingStatus && loadingStatus === LoadingStatusEnum.PENDING ? (
            <Spinner size={SpinnerSize.Tiny} />
          ) : (
            children
          )}
        </div>
      </div>
    </Collapse>
  );
}
