import { Language } from "../../i18n";

export const SET_LANGUAGE = "i18n/SET_LANGUAGE";

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  payload: Language;
}

export type I18nAction = SetLanguageAction;

export function setLanguage(language: Language) {
  return {
    type: SET_LANGUAGE,
    payload: language,
  };
}
