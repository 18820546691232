/* eslint-disable max-classes-per-file */
/* eslint-disable default-case */
import { Transform, Type } from "class-transformer";
import { IsLoading } from "../state/is-loading";
import {
  YearEndDeliveryPackage,
  YearEndGeneralAccounting,
} from "../views/deliveryPlanner/components/configuration/YearEndPackageConfiguration";
import { Recurrence } from "./activities/activity";
import { Assignment } from "./assignment";
import { CustomerDelivery, CustomerTaxObjects } from "./deliveryPlan";
import { ValidLegalFormCodes } from "./offer/Company";

export type ProjectType = "ek" | "lon" | "unknown";

export type VariableKeyObject = {
  [key: string]: boolean;
};

export enum CustomerTypes {
  COMPANY = "Company",
  PRIVATE_PERSON = "Private person",
}

export class CreateCustomer {
  constructor(customerData?: Partial<CreateCustomer>) {
    this.CompanyForm = customerData?.CompanyForm ?? "";
    this.CostPlace = customerData?.CostPlace ?? "";
    this.CompanyName = customerData?.CompanyName ?? "";
    this.Type = customerData?.Type ?? null;
    this.CompanyRegistrationNumber =
      customerData?.CompanyRegistrationNumber ?? "";
    this.CompanyElectronicMailaddress =
      customerData?.CompanyElectronicMailaddress ?? "";
    this.ElectronicMailaddress = customerData?.ElectronicMailaddress ?? "";
    this.Telephone = customerData?.Telephone ?? "";
    this.Mobile = customerData?.Mobile ?? "";
    this.FirstName = customerData?.FirstName ?? "";
    this.LastName = customerData?.LastName ?? "";
    this.PostalAddress = customerData?.PostalAddress ?? "";
    this.PostalDistrict = customerData?.PostalDistrict ?? "";
    this.SalespersonEmail = customerData?.SalespersonEmail ?? "";
    this.SalespersonFirstname = customerData?.SalespersonFirstname ?? "";
    this.SalespersonLastname = customerData?.SalespersonLastname ?? "";
    this.SecuredId = customerData?.SecuredId ?? "";
    this.FinalAccountsMonth = customerData?.FinalAccountsMonth ?? "";
    this.SignerSsn = customerData?.SignerSsn ?? "";
    this.SigningMethod = customerData?.SigningMethod ?? "";
    this.ZipCode = customerData?.ZipCode ?? "";
    this.id = customerData?.id ?? "";
  }

  Type: CustomerTypes | null;

  CostPlace: string;

  id: string;

  FinalAccountsMonth: string;

  CompanyElectronicMailaddress: string;

  CompanyRegistrationNumber: string;

  CompanyName: string;

  Telephone: string;

  Mobile: string;

  FirstName: string;

  LastName: string;

  ElectronicMailaddress: string;

  PostalAddress: string;

  ZipCode: string;

  PostalDistrict: string;

  SalespersonEmail: string;

  SalespersonFirstname: string;

  SalespersonLastname: string;

  CompanyForm: ValidLegalFormCodes | "";

  SignerSsn: string;

  SecuredId: string;

  SigningMethod: string;
}

export type CustomerApplications = {
  wolters_kluwer: CustomerApplication;
  fortnox: CustomerApplication;
  mittkontor: CustomerApplication;
  datalagret: CustomerApplication;
  ludvig_retriever: CustomerApplication;
  ludvig_collector: CustomerApplication;
  teams?: CustomerApplication;
};

export class CustomerSettings {
  constructor(params?: Partial<CustomerSettings>) {
    this.ludvig_service_year_end_finish =
      params?.ludvig_service_year_end_finish ?? false;
    this.ludvig_service_year_end_start =
      params?.ludvig_service_year_end_start ?? false;
    this.ludvig_service_ara = params?.ludvig_service_ara ?? false;
    this.ludvig_service_ek_project = params?.ludvig_service_ek_project ?? "";
    this.fortnox_interpretation_costs_allocated_for =
      params?.fortnox_interpretation_costs_allocated_for ?? "";
    this.should_calculate_interpretation_cost =
      params?.should_calculate_interpretation_cost ?? false;
  }

  ludvig_service_year_end_finish: boolean;

  ludvig_service_year_end_start: boolean;

  ludvig_service_ara: boolean;

  ludvig_service_ek_project: string;

  fortnox_interpretation_costs_allocated_for: string;

  should_calculate_interpretation_cost: boolean;
}

export type CompanyForm = "ab" | "ef" | "kb" | "hb" | "pp";

export type LudvigServiceStatus = {
  ServiceType: string;
  NeedsFeedback: boolean;
};

export type CustomersLudvigServiceStatus = {
  customer_number: string;
  ludvig_service_statuses: LudvigServiceStatus[];
};

export class Customer {
  sieFiles: SieFile[] = [];

  company_registration_number: CompanyRegistrationNumber;

  customer_number: CustomerNumber;

  customer_key: string;

  name: string;

  acceptedTerms: string;

  fiscalYearDayEnd: string;

  fiscalYearDayStart: string;

  fiscalYearMonthEnd: string;

  fiscalYearMonthStart: string;

  @Type(() => Project)
  projects: Project[];

  @Transform(({ value }) => value && value.toLowerCase())
  companyForm: CompanyForm;

  @Transform(({ value }) => value && value.toUpperCase())
  frequency?: Recurrence;

  @Type(() => Date)
  changed_date: Date;

  @Transform(({ value }) => value || [])
  ludvig_service: LudvigServiceStatus[] = [];

  address1: string;

  address2: string;

  zip: string;

  city: string;

  settings?: CustomerSettings;

  salesperson_email: string;

  salesperson_name: string;

  salesperson_number: string;

  salesperson_username: string;

  projectManagerEmail: string;

  taxObjects?: CustomerTaxObjects;

  apps: CustomerApplications;

  @Type(() => Assignment)
  assignments: Assignment[];

  standard_accounting_package: string;

  special_accounting_package: string;

  general_accounting: YearEndGeneralAccounting;

  year_end_package: YearEndDeliveryPackage;

  @Transform(({ value }) => value || [])
  deliveryPlan: CustomerDelivery[] = [];

  teamsStatus?: boolean;

  teamsFilesTabLink?: string;

  ms_team: CustomerMSTeam;

  restrict_access: RestrictAccess[];

  hasUnfinishedLudvigServiceFeedback() {
    return this.ludvig_service?.some((s) => s.NeedsFeedback) || false;
  }

  getManagers() {
    const projectManagers = this.projects.map((p) => ({
      email: p.projectManagerEmail,
    }));

    return [{ email: this.salesperson_email }, ...projectManagers];
  }

  getCompanyRegistrationNumber() {
    return `${this.company_registration_number.substring(
      0,
      6
    )}-${this.company_registration_number.substring(6)}`;
  }

  getAssignmentValue() {
    return 0;
  }

  isOnboardingComplete() {
    return (
      this.apps?.wolters_kluwer.status === "active" &&
      this.apps?.fortnox.status === "active" &&
      this.apps?.mittkontor.status === "active" &&
      this.apps?.ludvig_retriever.status === "active" &&
      (this.apps?.teams?.status === "active" ||
        this.apps?.ludvig_collector.status === "active")
    );
  }

  hasAcceptedTerms() {
    return this.acceptedTerms && this.acceptedTerms.length > 0;
  }

  getRecurrence() {
    return this.frequency;
  }

  isAllowedToOnboardInCB(isFromSearch: boolean) {
    const hasFiscalYear = this.fiscalYearMonthEnd !== undefined;
    return (
      hasFiscalYear &&
      !isFromSearch &&
      this.hasAcceptedTerms() &&
      this.hasSupportedCompanyFormForCB()
    );
  }

  hasSupportedCompanyFormForCB() {
    const supportedCompanyForms = ["ab", "ef", "kb", "hb"];
    return supportedCompanyForms.includes(
      (this.companyForm || "").toLowerCase()
    );
  }

  isAssignUserForPackageEnabled(p: string): boolean {
    switch (p) {
      case "YEAR_END":
        return !!this.year_end_package;
      case "GENERAL_ACCOUNTING":
        return !!(
          this.standard_accounting_package || this.special_accounting_package
        );
    }
    throw new Error(`Missing package ${p}`);
  }

  isCompany(): boolean {
    return this.companyForm && this.companyForm !== "pp";
  }

  customerHasUnfinishedLudvigServiceFeedback(
    ludvigServicesStates: CustomersLudvigServiceStatus[],
    serviceType?: string
  ): boolean {
    return !!ludvigServicesStates.find(
      (cs) =>
        cs.customer_number === this.customer_number &&
        cs.ludvig_service_statuses?.find((s) =>
          serviceType
            ? s.NeedsFeedback && s.ServiceType === serviceType
            : s.NeedsFeedback
        )
    );
  }
}

export class SieFile extends IsLoading {
  last_modified: string;

  source: string;

  period: string;

  key: string;

  bucket: string;

  justPrioritized = false;
}

export class Project {
  projectType: string;

  projectManagerEmail: string;

  projectManagerNumber: string;

  projectNumber: string;

  projectManagerUsername: string;

  projectManagerName: string;
}

export type CompanyRegistrationNumber = string;
export type CustomerNumber = string;
export type ApplicationStatus =
  | "active"
  | "inactive"
  | "nonexistent"
  | "offline"
  | "loading";

export type CRMDeal = {
  name: string;
  id: string;
  pipeline: string;
  stage: string;
  source: "digital_forestry";
  packageType: "small" | "medium" | "large";
  vouchersCount: number;
  declarationsCount: number;
  newForestOwner: boolean;
  billingPeriod: "månad" | "år";
  price: number;
  amount: number;
  owner: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
  };
};

export class CustomerApplication {
  status: ApplicationStatus;

  @Type(() => Date)
  created?: Date;

  @Type(() => Date)
  updated?: Date;
}

export class CustomerMSTeam {
  members: string[];

  owners: string[];
}

export class RestrictAccess {
  list_id: number;

  status: false;

  title: string;

  title_en: string;
}
