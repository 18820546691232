import { plainToClass } from "class-transformer";
import { IDynamicPerson } from "@microsoft/mgt-react";

import { fetchGraphDataWithToken } from "auth";
import AppConfig from "app-config";
import { AzureGroup, User } from "models/user";
import { getAuthorizationHeader } from "./libs/auth-header";

export class UsersAPI {
  static async fetchCurrentUser(
    token: string,
    roles?: string[]
  ): Promise<User> {
    const response = await fetch(`${AppConfig.API_URL}/me`, {
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const usr = (await response.json()) as User;
    return plainToClass(User, { ...usr, roles });
  }

  static async fetchAllUsers(token: string): Promise<User[]> {
    const response = await fetch(
      `${AppConfig.API_URL}/employees?fields=id,email,username,systemRoles`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as User[];
    return json;
  }

  static async fetchUser(token: string, userId: string): Promise<User> {
    const response = await fetch(`${AppConfig.API_URL}/user/${userId}`, {
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      const emptyUser = new User();
      emptyUser.id = "";
      return emptyUser;
    }

    return (await response.json()) as User;
  }

  static async fetchCurrentUserMemberOf(token: string, userId: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/user/${userId}/memberOf`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as AzureGroup[];
    return json;
  }

  static async getDefaultPeopleForPersonPicker(excludeMe?: boolean) {
    const peopleUrlPath = "me/people/";
    const peopleSearchParams = new URLSearchParams({
      $top: "6",
    });
    const others = await fetchGraphDataWithToken(
      peopleUrlPath,
      peopleSearchParams
    );

    if (excludeMe) {
      return others.value;
    }

    const me = await fetchGraphDataWithToken("me");
    const results = [me].concat(
      others.value
        .filter((user: IDynamicPerson) => user.id !== me.id)
        .slice(0, 5)
    );
    return results;
  }

  static async getDefaultFilteredUsers(
    userFilter: string,
    excludeMe?: boolean
  ) {
    const urlPath = "users";
    const searchParams = new URLSearchParams({
      $count: "true",
      $filter: userFilter,
      $top: "6",
    });
    const filteredUsers = await fetchGraphDataWithToken(urlPath, searchParams);
    if (excludeMe) {
      return filteredUsers.value;
    }
    const me = await fetchGraphDataWithToken("me");
    const results = [me].concat(
      filteredUsers.value
        .filter((user: IDynamicPerson) => user.id !== me.id)
        .slice(0, 5)
    );
    return results;
  }

  static async getFilteredUsers(filter: string, userFilter?: string) {
    const urlPath = "users";
    const searchParams = new URLSearchParams({
      $count: "true",
      $search: `"displayName:${filter}" OR "mail:${filter}"`,
      $top: "6",
    });
    if (userFilter) {
      searchParams.append("$filter", userFilter);
    }
    const filteredUsers = await fetchGraphDataWithToken(urlPath, searchParams);
    return filteredUsers.value;
  }
}

