import { normalizeOrgNrAndSSN } from "./number-format";

export const isEqualSSN = (ssn1?: string, ssn2?: string) => {
  if (!ssn1 || !ssn2) {
    return false;
  }

  const tempSSN1 = normalizeOrgNrAndSSN(ssn1);
  const tempSSN2 = normalizeOrgNrAndSSN(ssn2);

  return tempSSN1 === tempSSN2;
};
