import { useTranslation } from "hooks/use-translate";
import { dateLanguageFormatter } from "libs/date/date-format";
import { numberFormatOrZero } from "libs/number-format";
import { Customer } from "models/customer";

type FiscalYearTableProps = {
  activePageObjects: Customer[];
};

export default function FiscalYearTable({
  activePageObjects,
}: FiscalYearTableProps) {
  const { translate, language } = useTranslation();
  const dateFormat = dateLanguageFormatter(language, "yyyy-MM-dd");

  return (
    <div className="py-md horizontal-scroll">
      <table>
        <tr className="full-width px-md align-items-center my-sm fw-bold no-border">
          <td>
            <span>{translate("INVOICE")}</span>
          </td>
          <td>
            <span>{translate("COMPLETED_BY")}</span>
          </td>
          <td>
            <span>{translate("DATE")}</span>
          </td>
          <td>
            <span>{translate("TASK")}</span>
          </td>
          <td>
            <span className="number-columns-alignment-right">
              {translate("NUM_REGISTERED_UNITS")}
            </span>
          </td>
          <td>
            <span className="number-columns-alignment-right">
              {translate("REGISTERED_PRICE")}
            </span>
          </td>
          <td>
            <span className="number-columns-alignment-right">
              {translate("OPEN_UNITS")}
            </span>
          </td>
          <td>
            <span className="number-columns-alignment-right">
              {translate("OPEN_SALES_PRICE")}
            </span>
          </td>
          <td>
            <span className="number-columns-alignment-right">
              {translate("UP_DOWN_UNITS")}
            </span>
          </td>
          <td>
            <span className="number-columns-alignment-right">
              {translate("UP_DOWN_ADJUSTMENTS")}
            </span>
          </td>
          <td>
            <span className="number-columns-alignment-right">
              {translate("INVOICED_UNITS")}
            </span>
          </td>
          <td>
            <span className="number-columns-alignment-right">
              {translate("INVOICED_SEK")}
            </span>
          </td>
        </tr>
        {activePageObjects &&
          activePageObjects.map((data: any, index) => {
            return (
              <tr
                className="full-width px-md align-items-center my-sm"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <td>
                  <span>{data.invoicenumber}</span>
                </td>
                <td>
                  <span>{data.employeenumber}</span>
                </td>
                <td>
                  <span>
                    {data.entrydate && dateFormat(new Date(data.entrydate))}
                  </span>
                </td>
                <td>
                  <span>{data.taskname}</span>
                </td>
                <td>
                  <span className="number-columns-alignment-right">
                    {numberFormatOrZero(data.numberregistered)}
                  </span>
                </td>
                <td>
                  <span className="number-columns-alignment-right">
                    {numberFormatOrZero(data.billingPriceReg)}
                  </span>
                </td>
                <td>
                  <span className="number-columns-alignment-right">
                    {numberFormatOrZero(data.numberopen)}
                  </span>
                </td>
                <td>
                  <span className="number-columns-alignment-right">
                    {numberFormatOrZero(data.billingPriceOpen)}
                  </span>
                </td>
                <td>
                  <span className="number-columns-alignment-right">
                    {numberFormatOrZero(Number(data.numberUpDown?.toFixed(2)))}
                  </span>
                </td>
                <td>
                  <span className="number-columns-alignment-right">
                    {numberFormatOrZero(data.billingPriceUpDown)}
                  </span>
                </td>
                <td>
                  <span className="number-columns-alignment-right">
                    {numberFormatOrZero(data.numberinvoiced)}
                  </span>
                </td>
                <td>
                  <span className="number-columns-alignment-right">
                    {numberFormatOrZero(data.billingPriceInvoiced)}
                  </span>
                </td>
              </tr>
            );
          })}
      </table>
    </div>
  );
}
