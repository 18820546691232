import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

interface AccordionContextProps {
  isOpen: boolean;
  toggle: () => void;
}

export const AccordionContext = createContext<AccordionContextProps>({
  isOpen: false,
  toggle: () => {},
});

interface IAccordionProvider {
  children: ReactNode;
  isInitiallyOpen?: boolean;
}

export function AccordionProvider({
  children,
  isInitiallyOpen = false,
}: IAccordionProvider) {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const initContextState = useMemo(
    () => ({
      isOpen,
      toggle,
    }),
    [isOpen, toggle]
  );

  return (
    <AccordionContext.Provider value={initContextState}>
      {children}
    </AccordionContext.Provider>
  );
}
