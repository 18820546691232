import { DividerShort24Regular } from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";

import Modal from "components/modal";
import { capitalize } from "libs/capitalize";
import { formatDate } from "libs/date/date-format";
import { ServiceNote } from "models/customerSettings/customerSettingsServiceNote";
import { ModalHeader } from "components/modal/ModalHeader";

type CustomerPageServiceNoteReadModalProps = {
  currentNote: ServiceNote;
  openModal: boolean;
  setOpenModal: (arg: boolean) => void;
};

export default function CustomerPageServiceNoteReadModal({
  currentNote,
  openModal,
  setOpenModal,
}: CustomerPageServiceNoteReadModalProps) {
  const dateString = formatDate(currentNote.modified_date);
  return (
    <Modal
      isOpen={openModal}
      onDismiss={() => setOpenModal(false)}
      size="medium"
      header={<ModalHeader headerTitleText="SERVICE_NOTE" />}
    >
      <Row className="mb-md">
        <Col>
          {currentNote.title && (
            <Row>
              <Col>
                <b>{currentNote.title}</b>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <span>{dateString}</span>
              <span>
                <DividerShort24Regular className="text-muted" />
              </span>
              <span className="modified-by">
                {currentNote.modified_by.firstName}{" "}
                {currentNote.modified_by.lastName}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>{capitalize(currentNote.notes)}</Col>
      </Row>
    </Modal>
  );
}
