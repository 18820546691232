import { Spinner } from "@fluentui/react-components";
import { ArrowUpload20Regular, Delete24Regular } from "@fluentui/react-icons";
import { PdfUploadDealData } from "api/offers";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import DownloadPdfButton from "views/offer/wizard/components/downloadPdfButton";
import "./AttachmentPdf.scss";

export enum FileUploadStatus {
  INITIAL,
  FILE_UPLOADING,
  FAIL,
  SUCCESS,
}

type AttachmentPdfProps = {
  onFileUpload: (file: File) => void;
  onFileDelete: () => void;
  dealId: string;
  onError?: () => void;
  status?: FileUploadStatus;
  hasAttachment?: boolean;
  pdfUploadData?: PdfUploadDealData;
};

export function AttachmentPdf({
  onFileUpload,
  onFileDelete,
  dealId,
  onError,
  status,
  hasAttachment = true,
  pdfUploadData,
}: AttachmentPdfProps) {
  const { translate } = useTranslation();

  const getAdditionalAttachmentFilename = () => {
    if (pdfUploadData) {
      const filenameItems = pdfUploadData.fields.key.split("/");
      return filenameItems[filenameItems.length - 1];
    }
    return "";
  };

  const onDrop = useCallback(
    (files: File[]) => {
      if (files && files.length > 0) {
        onFileUpload(files[0]);
      }
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onError,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const renderUploadButton = () => {
    if (isDragActive) {
      return (
        <>
          <ArrowUpload20Regular color="blue" />
          <span className="box-action-subtitle">
            {translate("COMPLETION_DROP_FILES_HOVER")}
          </span>
        </>
      );
    }

    return (
      <div
        className={`d-flex align-items-center cursor-pointer ${
          status === FileUploadStatus.FILE_UPLOADING && "disabled"
        }`}
      >
        {status === FileUploadStatus.FILE_UPLOADING && (
          <Spinner className="mr-xs" size={SpinnerSize.ExtraSmall} />
        )}
        <ArrowUpload20Regular className="text-color-blue" />
        <span className="ml-sm fw-bold text-color-blue">
          {translate("UPLOAD_FILE")}
        </span>
        <div className="fs-italic">
          <span className="px-md">{translate("OR")}</span>{" "}
          <span>{translate("DRAG_AND_DROP")}</span>
        </div>
      </div>
    );
  };

  return (
    <div
      {...(!hasAttachment && getRootProps())}
      className="dropFileAreaPdf-container py-sm my-xl"
    >
      <div className="sub-h1 fw-semibold pb-md">
        {translate("ADDITIONAL_PDF_ATTACHMENT")}
      </div>

      {!hasAttachment && <input {...getInputProps()} />}

      <div className="horizontal-divider my-lg" />
      {hasAttachment ? (
        <div className="d-flex">
          <DownloadPdfButton
            dealId={dealId}
            type="link"
            pdf_type="additional"
            filename={getAdditionalAttachmentFilename()}
          />
          {status === FileUploadStatus.FILE_UPLOADING ? (
            <Spinner className="mr-xs ml-lg" size={SpinnerSize.ExtraSmall} />
          ) : (
            <Delete24Regular
              color="#EC666B"
              className="ml-lg cursor-pointer"
              onClick={onFileDelete}
            />
          )}
        </div>
      ) : (
        renderUploadButton()
      )}
    </div>
  );
}
