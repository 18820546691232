export enum ViewType {
  image = "image",
  oneline = "oneline",
  twolines = "twolines",
  threelines = "threelines",
  fourlines = "fourlines",
}

export enum UserType {
  user = "user",
  contact = "contact",
  any = "any",
}
