/* eslint-disable max-classes-per-file */

export class CustomerManagement {
  org_number: string;

  customer_number: string;

  customer_name: string;

  salesperson_name: string;

  salesperson_graph_id?: string | null;

  previous_salesperson_graph_id?: string;

  business_unit_name: string;

  market_area_name: string;

  recommended_client_program?: string | null;

  chosen_client_program?: string | null;

  /**
   * Year end - represents the month of the year as a string, ranging from 1 to 12
   */
  YE?: string | null;

  status: boolean;

  projects: CustomerManagementProject[];
}

export class CustomerManagementProject {
  project_number: string;

  project_type: string;

  projectmanager_name: string;

  projectmanager_graph_id?: string | null;
}

export class CustomerManagementUpdateResponse {
  customer_number: string;

  status: boolean;
}

export enum CustomerManagementRoles {
  ProjectManager = "project_manager",
  SalesPerson = "sales_person",
  Both = "both",
  None = "",
}

export interface CustomerManagementWithIndex {
  index: number;
  customer: CustomerManagement;
}
