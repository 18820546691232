/* eslint-disable max-classes-per-file */
import { Type } from "class-transformer";
import "reflect-metadata";

import { Language, TranslationKey } from "i18n";
import { UUID } from "libs/uuid";
import { IsLoading } from "state/is-loading";
import { ServiceActivitiesGroup } from "views/activities/serviceActivities";
import { CompanyRegistrationNumber, CustomerNumber } from "../customer";
import { ActivityAppendix } from "./ActivityAppendix";

const MISSING_TRANSLATION = "Missing translation";

type ServiceArea = "GENERAL_ACCOUNTING" | "YEAR_END" | "INCOME_TAX";

export type CustomerActivitiesServicesAssignedUsers = {
  activities_users: string[];
  client_user?: string;
  year_end_activities_users: string[];
  year_end_client_user?: string;
};

export type PackageAssignedUsers = {
  activities_users: string[];
  client_user?: string;
};

export class ActivityInstanceMessage {
  teams_conversation_id: string;

  teams_conversation_weburl: string;
}

const REPORT_ACTIVITIES_TYPES = [
  "01-02-13-M",
  "01-02-12-Q",
  "01-02-11-Y",
  "01-02-11-Q",
  "01-02-11-M",
];

export enum Recurrence {
  Weekly = "WEEKLY",
  Monthly = "MONTHLY",
  Quarterly = "QUARTERLY",
  Yearly = "YEARLY",
  Oneoff = "ONEOFF",
  Unknown = "UNKNOWN",
}

export enum ActivityStatus {
  SupportNeeded = "SUPPORT_NEEDED",
  SupportSentToClient = "SUPPORT_SENT_TO_CLIENT",
  ManagerSupport = "MANAGER_SUPPORT",
  SupportFinished = "SUPPORT_FINISHED",
}

export enum ServiceTypes {
  GeneralAccounting = "GENERAL_ACCOUNTING",
  YearEnd = "YEAR_END",
  IncomeTaxReturn = "INCOME_TAX",
}

export enum FormQuestionAnswerType {
  DatePicker = "DATE_PICKER",
  Boolean = "BOOLEAN",
  Appendice = "APPENDICE_SELECTOR",
  BooleanWithText = "BOOLEAN_W_TEXT",
}

class ActivityInstanceStatus {
  status: ActivityStatus;

  created_at: Date;

  user_id?: UUID;
}

export class ServiceTypeDefinition {
  ID: number;

  Title: string;

  TranslationEn: string;

  TranslationSv: string;

  getTitleName(language?: Language) {
    if (!this.TranslationEn || !this.TranslationSv) {
      return MISSING_TRANSLATION;
    }

    switch (language?.toLowerCase()) {
      case "sv":
        return this.TranslationSv;
      case "en":
        return this.TranslationEn;

      default:
        return this.TranslationSv;
    }
  }
}

/**
 * Logical grouping of activities, which are represented by the "boxes" in the Conveyor belt UI
 */
export class CustomerActivitiesServices {
  org_number: CompanyRegistrationNumber;

  customer_id: CustomerNumber;

  fiscal_year_is_missing: boolean;

  /**
   * True if the customer is set up in the activities backend
   */
  active: boolean;

  /**
   *
   */
  @Type(() => ServiceInstance)
  services: ServiceInstance[];

  /**
   * Users that has been assigned to any of the activities on the customer
   */
  assigned_users: CustomerActivitiesServicesAssignedUsers = {
    // TODO: to be removed
    activities_users: [],
    client_user: "",
    year_end_activities_users: [],
    year_end_client_user: "",
  };

  getAssignedForPackage(p: string): PackageAssignedUsers {
    switch (p) {
      case "YEAR_END":
        return {
          activities_users: this.assigned_users.year_end_activities_users ?? [],
          client_user: this.assigned_users.year_end_client_user,
        };
      case "GENERAL_ACCOUNTING":
        return {
          activities_users: this.assigned_users.activities_users ?? [],
          client_user: this.assigned_users.client_user,
        };

      default:
        throw new Error(`Missing package ${p}`);
    }
  }
}

export class ServiceInstance {
  /**
   * The type of service, i.e. YEAR_END/GENERAL_ACCOUNTING
   */
  service_type: ServiceArea;

  /**
   * The type of service, i.e. YEAR_END/GENERAL_ACCOUNTING - improvement to take alll service type sin cosideration
   */
  cb_service_type: string;

  /**
   * The type of service, i.e. accounting monthly, accounting weekly
   */
  service_type_id: number;

  /**
   * A service instance ID. Ex. accounting monthly (September 2021) would be "1-Y2021-M9"
   */
  service_box_id: string;

  /**
   * The year in which the Service Instance should be located
   */
  year: number;

  /**
   * The month in which the Service Instance should be located
   */
  month: number;

  /**
   * The week in which the Service Instance should be located
   */
  week: number;

  /**
   * Translations of the service instance title
   */
  titles: {
    sv: string;
    en: string;
  };

  /**
   * Activities that belong to the service instance
   */
  @Type(() => ActivityInstance)
  activities: ActivityInstance[];

  /**
   * Customer recurrence, given by the activities create in activities backend
   */
  recurrence: Recurrence;

  equals(s: ServiceInstance) {
    return this.service_box_id === s.service_box_id;
  }

  isCompleted() {
    return this.getProgress() === 1;
  }

  /**
   * Get all incomplete activities, sorted by their workflow. The sorting is
   * made to correspond with the one inside ActivitiesTable.tsx
   */
  getIncompleteSorted() {
    const activityGroups = this.activities.reduce((sum, current) => {
      const groupIdx = current.group.group_idx;
      const group = sum[groupIdx] || {
        categorySortingNumber: current.getGroupSortingNum(),
        activities: [],
      };
      group.activities = [...group.activities, current];
      sum[groupIdx] = group;
      return sum;
    }, {} as ServiceActivitiesGroup);

    const sortedActivityGroups = Object.values(activityGroups).sort(
      (group1, group2) => {
        const category1 = group1.categorySortingNumber.toString();
        const category2 = group2.categorySortingNumber.toString();
        return category1.localeCompare(category2);
      }
    );

    const incompleteActivities = sortedActivityGroups
      .flatMap((group) => group.activities)
      .filter((activity) => !activity.completed_at);
    return incompleteActivities;
  }

  getPeriod(formatter: (date: Date) => string) {
    switch (this.recurrence) {
      case Recurrence.Weekly:
        return `V${this.week || 0} ${this.year}`;
      case Recurrence.Monthly:
        return formatter(new Date(`${this.year}-${this.month}`));
      case Recurrence.Quarterly:
        return `Q${Math.ceil(this.month / 3)} ${this.year}`;
      case Recurrence.Yearly:
        return `${this.year}`;

      default:
        return "";
    }
  }

  getProgress() {
    const completedCount = this.activities.filter((i) => i.completed_at).length;
    return completedCount / (this.activities.length || 1);
  }

  getTitle(language?: Language): string {
    if (!this.titles) {
      return "Missing titles";
    }

    if (!language) {
      return this.titles.en;
    }

    if (language.toLowerCase() === "sv") {
      return this.titles.sv;
    }

    if (language.toLowerCase() === "en") {
      return this.titles.en;
    }

    return MISSING_TRANSLATION;
  }

  getTitleWithWeek(language: Language): string {
    return `${this.getTitle(language)} ${this.week ? `V${this.week}` : ""}`;
  }

  getAttentionStatuses(): ActivityStatus[] {
    const hasStatuses: ActivityInstanceStatus[] = this.activities
      .map((a) => a.status)
      .filter((a) => a !== undefined && a !== null)
      .filter((a) => a.status !== ActivityStatus.SupportFinished);

    const uniqueStatuses = hasStatuses
      .map((a) => a.status)
      .filter((a, ind, arr) => a && arr.indexOf(a) === ind);

    return uniqueStatuses;
  }

  getReportFinished() {
    const report = this.activities.find((a) =>
      REPORT_ACTIVITIES_TYPES.includes(a.activity_type)
    );

    return report?.completed_at;
  }

  getReportDeadline() {
    const report = this.activities.find((a) =>
      REPORT_ACTIVITIES_TYPES.includes(a.activity_type)
    );

    return report?.deadline;
  }

  getServiceArea(): ServiceArea {
    return this.service_type;
  }

  isOverdue() {
    return this.activities.some((a) => a.isOverdue());
  }

  isYearEnd() {
    return this.service_type === "YEAR_END";
  }
}

/**
 * An activity type declaration - i.e, it doesn't represent an actual activity or task that can be completed, but the definition of the task.
 */
export class Activity extends IsLoading {
  group: {
    en: string;
    sv: string;

    /**
     * Identifier of the group which the activity belongs to
     */
    group_idx: number;

    /**
     * Sorting order number for the activity
     */
    sorting_nr: number;
  };

  /**
   * Order in which activities are expected to be completed
   */
  sorting_nr: number;

  /**
   * The activity type, i.e. identifying the specification of an activity, but not an instance of it
   */
  activity_type: string;

  /**
   * The type of service, i.e. YEAR_END/GENERAL_ACCOUNTING
   */
  service_type: ServiceArea;

  /**
   * True if the activity isn't already added to the customer
   */
  available_to_add: boolean;

  /**
   * True if the activity occurs 1 time (ignores Recurrence)
   */
  onetime: boolean;

  /*
   * If the activity comes from a tax object this is not empty
   */
  type_ext?: string;

  /*
   * If the activity is onetime sometime we can have the existing dates to show them to the user
   */
  existing_dates?: {
    start_date: string;
    deadline_date: string;
  }[];

  getGroupSortingNum() {
    return this.group.sorting_nr;
  }

  getGroupTitle(language: Language, definition: ActivityDefinition) {
    const groupName = definition.getGroupName(language.toLowerCase());

    if (!groupName) {
      return "Missing group title";
    }

    return `${groupName}${this.type_ext ? ` ${this.type_ext}` : ""}`;
  }

  getTitle(language: Language, definition: ActivityDefinition): string {
    if (language.toLowerCase() === "sv") {
      return definition.getTranslation("sv");
    }

    if (language.toLowerCase() === "en") {
      return definition.getTranslation("en");
    }

    return "Missing title";
  }

  getServiceArea(definition: ActivityDefinition): ServiceArea {
    return definition.service_area;
  }

  getRecurrence(definition: ActivityDefinition): TranslationKey {
    return definition.recurrence as TranslationKey;
  }

  getCreateItemHeader(language: Language, definition: ActivityDefinition) {
    const itemHeader = definition.getCreateItemHeader(language.toLowerCase());

    if (!itemHeader) {
      return "Missing title";
    }

    return itemHeader;
  }

  getNotRestrictedForCompleting(definition: ActivityDefinition) {
    return definition.not_restricted_for_completing;
  }
}

export enum ActivityInstanceTriggerType {
  None = "None",
  DeductionModuleDeduction = "DeductionModuleDeduction",
  DeductionModuleInvoicing = "DeductionModuleInvoicing",
}

/**
 * An activity which has been connected to a specific date and customer
 */
export class ActivityInstance extends Activity {
  /**
   * A status set by a user
   */
  @Type(() => ActivityInstanceStatus)
  status: ActivityInstanceStatus;

  /**
   * Unique ID of an activity instance
   */
  activity_id: string;

  /**
   * Unique ID of an activity instance from an external system (e.g. Fortnox Byra)
   */
  original_id: string;

  /**
   * The assigned user for this activity instance
   */
  assigned_user: UUID;

  /**
   * Date of deadline
   */
  @Type(() => Date)
  deadline: Date;

  /**
   * ID of the user that completed the activity instance
   */
  completed_by_user: UUID;

  /**
   * Teams conversation web URL for navigating to the Teams conversation
   */
  teams_conversation_weburl?: string;

  /**
   * A string indicating that a deduction module form has to be filled in before the activity can be marked done
   */
  form_id: string | null;

  /**
   * Date of completion
   */
  @Type(() => Date)
  completed_at?: Date;

  hasPassedDeadline() {
    if (!this.deadline) {
      return false;
    }

    return new Date() > this.deadline;
  }

  isCompletedWithinDeadline() {
    if (!this.completed_at) {
      return false;
    }

    return this.deadline > this.completed_at;
  }

  isOverdue() {
    return this.hasPassedDeadline() && !this.completed_at;
  }

  marked_deleted = false;

  isHidden(): boolean {
    return this.marked_deleted;
  }

  getTriggerType(definition: ActivityDefinition) {
    return definition.trigger_type;
  }
}

export class ActivityDefinition {
  activity_name: string;

  activity_name_eng: string;

  activity_sorting_nr: number;

  category_name: string;

  category_name_eng: string;

  category_sorting_nr: number;

  create_item_header: string;

  recurrence: string;

  service_area: ServiceArea;

  service_type: string;

  title: string;

  trigger_type?: string;

  not_restricted_for_completing: boolean | null;

  package_key?: string[];

  getCreateItemHeader(language: string) {
    switch (language.toLowerCase()) {
      case "sv":
        return this.create_item_header;
      case "en":
        return this.create_item_header; // there is no english translation at the moment
      default:
        return MISSING_TRANSLATION;
    }
  }

  getGroupName(language: string) {
    switch (language.toLowerCase()) {
      case "sv":
        return this.category_name;
      case "en":
        return this.category_name_eng;
      default:
        return MISSING_TRANSLATION;
    }
  }

  getTranslation(language: string) {
    switch (language.toLowerCase()) {
      case "sv":
        return this.activity_name ?? MISSING_TRANSLATION;
      case "en":
        return this.activity_name_eng ?? MISSING_TRANSLATION;
      default:
        return MISSING_TRANSLATION;
    }
  }
}

export class BooleanWithText {
  value: boolean | string;

  comment?: string;
}

export type FormQuestionAnswerAllowedTypes =
  | string
  | boolean
  | ActivityAppendix[]
  | BooleanWithText;

export class FormQuestion {
  /**
   * ID of the question from DeductionsModuleQuestions sharepoint list
   */
  question_id: string;

  question: string;

  /**
   * Denotes what type of answer is expected i.e. a date or a true/false
   */
  answer_type: FormQuestionAnswerType;

  answer?: FormQuestionAnswerAllowedTypes;

  /**
   * Used to group questions under a label
   */
  group_header: string;

  /**
   * Sort order of groups
   */
  group_sort_order: number;

  /**
   * Sort order of questions
   */
  sort_order: number;
}

export class FormAnswer {
  /**
   * ID of the question from DeductionsModuleQuestions sharepoint list
   */
  question_id: string;

  question: string;

  answer?: string | BooleanWithText;

  amount?: string;

  sort_order: number;

  /**
   * Denotes what type of answer is expected i.e. a date or a true/false
   */
  answer_type: FormQuestionAnswerType;
}

export class FormAnswerBatch {
  customer_number: string;

  fiscal_year: string;

  /**
   * Date of completion
   */
  @Type(() => Date)
  completed_at: Date;

  completed_by_user: string;

  answers: FormAnswer[];
}

export interface AssignUserToActivitiesRequest {
  customer_ids: string[];
  current_user: string;
  new_user: string;
  start_date: string;
  end_date: string;
  include_unassigned?: boolean;
}

export interface TeamServiceType {
  service_type: TranslationKey;
  default_user: string;
  assigned_users: string[];
}

export interface ActivitiesTeam {
  customer_id: string;
  org_number: string;
  service_types: TeamServiceType[];
}

export interface AssignUserToActivityRequest {
  userId: string;
  changeSingle?: boolean;
}

export interface AssignUserToActivityResponse {
  affected: { id: string }[];
}

export interface GetActivitiesPerAssignedUserRequest {
  customer_ids: string[];
  current_user: string;
  start_date: string;
  end_date: string;
  include_unassigned?: boolean;
}

export interface GetActivitiesPerAssignedUserResponse {
  activity_type: string;
  activity_uuid: string;
  assigned_user: string | null;
  customer_id: string;
  deadline_date: string;
  delivery_name: string | null;
  package_id: string | null;
  package_key: string | null;
  package_name_en: string | null;
  package_name_se: string | null;
  type_ext: string | null;
}
