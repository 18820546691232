import { rest } from "msw";
import { MOCK_DELAY } from "..";

const BASE_PATH = process.env.REACT_APP_API_URL;

export const offersHandlers = [
  rest.get(`${BASE_PATH}/services`, (req, res, { status, json, delay }) => {
    return res(
      delay(MOCK_DELAY),
      status(200),
      json({
        service_areas: [
          {
            name: "TOEK",
            service_lines: [
              {
                name: "Accounting",
                service_groups: [
                  {
                    name: "Annual accounts",
                    service_categories: [],
                  },
                ],
              },
              {
                name: "Payroll",
                service_groups: [],
              },
            ],
          },
        ],
      })
    );
  }),
];
