import { LOADING_SERVICES, SET_SERVICES, ServicesAction } from ".";
import { MyThunkAction } from "..";
import { ActivitiesAPI } from "../../api/activities";
import { authMethod } from "../../auth";
import { ServiceDescription } from "../../models/serviceDescription";
import { appendError } from "../notifications";

export function fetchServicesDescriptions(): MyThunkAction {
  return async (dispatch, getState) => {
    const state = getState();

    if (state.servicesDescriptions.data.length > 0) {
      return;
    }

    dispatch(loadingServices(true));
    try {
      const token = await authMethod.getStoredAccessToken();
      const services = await ActivitiesAPI.fetchServicesMatrix(token);
      dispatch(setServices(services));
    } catch (e) {
      dispatch(appendError("FAILED_TO_LOAD_CUSTOMERS", e as Error));
    } finally {
      dispatch(loadingServices(false));
    }
  };
}

export function loadingServices(isLoading: boolean): ServicesAction {
  return {
    type: LOADING_SERVICES,
    payload: isLoading,
  };
}

export function setServices(services: ServiceDescription[]): ServicesAction {
  return {
    type: SET_SERVICES,
    payload: services,
  };
}
