import confetti, { Options } from "canvas-confetti";

function createConfettiCanvas() {
  const myCanvas = document.createElement("canvas");
  myCanvas.style.position = "fixed";
  myCanvas.style.top = "0";
  myCanvas.style.pointerEvents = "none";
  myCanvas.width = document.body.clientWidth;
  myCanvas.height = document.body.clientHeight;
  document.body.appendChild(myCanvas);

  return confetti.create(myCanvas, {
    resize: true,
    useWorker: true,
  });
}

function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

const myConfetti = createConfettiCanvas();

export function useConfetti() {
  const d: Options = {
    angle: randomInRange(55, 125),
    spread: randomInRange(50, 170),
    particleCount: randomInRange(200, 300),
    origin: { y: 0.6 },
  };

  return { confetti: (options: Options = d) => myConfetti(options) };
}
