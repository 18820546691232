import { RouteConfig } from "routes";
import ManageCustomers from ".";
import ListOfCustomers from "./ListOfCustomers";
import UpdateInProgress from "./UpdateInProgress";

export const ManageCustomersRouteHelper = {
  getLandingPage: () => "/manage-customers",
  getListOfCustomers: () => "/manage-customers/list-of-customers",
  getUpdateInProgress: () => "/manage-customers/update-in-progress",
};

const manageCustomersRoutes: RouteConfig[] = [
  {
    path: ManageCustomersRouteHelper.getLandingPage(),
    element: <ManageCustomers />,
    subRoutes: [],
  },
  {
    path: ManageCustomersRouteHelper.getListOfCustomers(),
    element: <ListOfCustomers />,
    subRoutes: [],
  },
  {
    path: ManageCustomersRouteHelper.getUpdateInProgress(),
    element: <UpdateInProgress />,
    subRoutes: [],
  },
  {
    path: "*",
    element: <p>No such route!</p>,
    subRoutes: [],
  },
];

export default manageCustomersRoutes;
