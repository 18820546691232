import React from "react";
import "./Label.scss";

import { Label as FluentUiLabel } from "@fluentui/react-components";

export type LabelVariant =
  | "success"
  | "error"
  | "light"
  | "unknown"
  | "blue"
  | "yellow"
  | "green"
  | "lightblue"
  | "purple";

export type LabelProps = {
  selected?: boolean;
  size?: "sm" | "md" | "lg";
  variant?: LabelVariant | Uppercase<LabelVariant>;
  className?: string;
} & React.HTMLAttributes<HTMLLabelElement>;

export function Label({
  variant = "success",
  size = "md",
  selected,
  className,
  children,
  ...props
}: LabelProps) {
  return (
    <FluentUiLabel
      className={`label ${size} ${variant.toLowerCase()} ${className || ""} ${
        selected && "selected"
      }`}
      {...props}
    >
      {children}
    </FluentUiLabel>
  );
}

export function SuccessLabel() {
  return <Label variant="success" />;
}
