/**
 * Checks whether a string is a valid Swedish
 * Social Security Number (personnummer) using checksum
 *
 * Note: this is somewhat simplified because it does not take into account
 * that the date of the number is valid (e.g. '000000-0000' does return as true).
 *
 * @param ssn The string to check.
 * @param mustIncludeCentury Whether the century must be included in the SSN.
 * @returns Whether the string is a valid SSN.
 */
export function isValidSwedishSSN(ssn: string, mustIncludeCentury = true) {
  const strippedSSN = ssn.replace(/\D/g, "");

  const validLength = mustIncludeCentury
    ? strippedSSN.length === 12
    : [10, 12].includes(strippedSSN.length);
  if (!validLength) {
    return false;
  }

  const digits = strippedSSN.slice(-10).split("").reverse().map(Number);
  if (digits.length !== 10) {
    return false;
  }

  // Luhn's algorithm https://en.wikipedia.org/wiki/Luhn_algorithm
  const isValidChecksum =
    digits.reduce((sum, digit, index) => {
      let adjustedDigit = digit;
      if (index % 2 === 1) {
        adjustedDigit *= 2;
      }
      if (adjustedDigit > 9) {
        adjustedDigit -= 9;
      }
      return sum + adjustedDigit;
    }, 0) %
      10 ===
    0;
  return isValidChecksum;
}
