import { plainToClass } from "class-transformer";

export interface GenericResponse<T> {
  data: T;
}

export function plainToPartialClass<T>(
  targetClass: new () => T,
  plainObject: Partial<T>
): Partial<T> {
  const classInstance = plainToClass(targetClass, plainObject);
  const selectedProperties: Partial<T> = {};
  const properties = Object.keys(plainObject) as Array<keyof T>;

  properties.forEach((property) => {
    selectedProperties[property] = classInstance[property];
  });

  return selectedProperties;
}
