import { RingChart, RingChartLayerSegment, RingChartProps } from "./RingChart";
import "./YearChart.scss";

const TEXT_BASE_FONT_SIZE = 9.5;
const TEXT_VARIABLE_FONT_SIZE = 4;

type MonthConfiguration = {
  fill: string;
  key: string;
  label: string;
  selected: boolean;
  texts: string[];
};

type YearRingChartProps = RingChartProps & {
  monthsConfiguration: MonthConfiguration[];
  onYearMonthHover: (month: MonthConfiguration) => void;
  onYearMonthClick: (month: MonthConfiguration) => void;
  onYearMonthLeave: () => void;
};

/**
 * Linear interpolation between a and b
 */
function lerp(a: number, b: number, t: number) {
  return a + (b - a) * t;
}

export function YearRingChart({
  onMouseLeave,
  onYearMonthHover: onMonthMouseEnter,
  onYearMonthClick: onMonthClick,
  onYearMonthLeave: onMonthMouseLeave,
  monthsConfiguration = [],
  ...props
}: YearRingChartProps) {
  const monthNames: RingChartLayerSegment[] = monthsConfiguration.map(
    (segment) => {
      return {
        key: segment.key,
        fill: "var(--primary)",
        className: "inner",
        label: segment.label,
        selected: segment.selected,
        textOrientation: "end",
        texts: [segment.label],
        fontSizePixels: 12,
      };
    }
  );

  const monthValues: RingChartLayerSegment[] = monthsConfiguration.map(
    (month) => {
      const fontSizePixels = lerp(
        TEXT_BASE_FONT_SIZE,
        TEXT_BASE_FONT_SIZE + TEXT_VARIABLE_FONT_SIZE,
        1 - month.texts.length / 5
      );

      return {
        fill: month.fill,
        className: "outer",
        key: month.key,
        label: month.label,
        selected: month.selected,
        textOrientation: "start",
        texts: month.texts,
        textStyle: {
          fill: "#fff",
        },
        fontSizePixels,
        onMouseLeave: () => onMonthMouseLeave(),
        onMouseEnter: () => {
          onMonthMouseEnter(month);
        },
        onClick: () => {
          onMonthClick(month);
        },
      };
    }
  );

  return (
    <RingChart
      className="year-chart"
      onMouseLeave={onMouseLeave}
      layers={[
        {
          key: "inner",
          margin: 1,
          outerRadius: 103.5,
          innerRadius: 20,
          rotate: 0,
          segments: monthNames,
        },
        {
          key: "outer",
          margin: 0.5,
          outerRadius: 345,
          innerRadius: 105,
          rotate: 0,
          segments: monthValues,
        },
      ]}
      {...props}
    />
  );
}
