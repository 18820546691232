import { useEffect, useReducer, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ChevronLeft16Regular,
  ChevronRight16Regular,
  Edit20Regular,
} from "@fluentui/react-icons";
import { Spinner } from "@fluentui/react-components";

import { useAppDispatch } from "state/use-app-redux";

import { AppRouteHelper } from "routes";
import {
  isUserInCustomerTeam,
  isUserRestricted,
} from "libs/customer-settings-helpers";
import { getMonthName } from "libs/date/month-name";
import { renderIcon } from "libs/render-icon";
import { formatToTextOnlyString } from "libs/text-only-string";
import { useTranslation } from "hooks/use-translate";
import { RootState } from "state";
import {
  completeActivity,
  removeOnetimeActivity,
  setServiceBoxActivities,
  updateServiceBoxActivities,
} from "state/activities/actions";
import {
  Activity,
  ActivityInstance,
  ActivityInstanceTriggerType,
  Recurrence,
  ServiceInstance,
} from "models/activities/activity";
import { Customer } from "models/customer";
import ContextualMenuV9 from "components/contextualMenu/ContextualMenuV9";
import Checkbox from "components/checkbox";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { SpinnerSize } from "components/spinner";
import TooltipV9 from "components/tooltip/TooltipV9";
import { ActivityInstancesGroup, ServiceActivitiesGroup } from "..";
import {
  ActivitiesDateReducerActionEnum,
  reducerDate,
  SequentialType,
} from "../helpers/reducerDate";
import { ActivitiesNotAvailable } from "./ActivitiesNotAvailable";
import ActivityRow from "./ActivityRow";
import { noticeNextOrPrevYear } from "./utils";
import "./ActivitiesTable.scss";

type ActivitiesTableProps = {
  customer: Customer;
  service?: ServiceInstance;
  openActivityDetailsPanel: (activity: Activity) => void;
  setEditing: (isEditing: boolean) => void;
  serviceId: string;
};

export function ActivitiesTable({
  service,
  customer,
  openActivityDetailsPanel,
  setEditing,
  serviceId,
}: ActivitiesTableProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { translate, ts, language } = useTranslation();

  const { currentUser } = useSelector((state: RootState) => state.users);
  const {
    serviceBoxActivities: { data: serviceBoxActivities },
    currentServiceActivitiesForMonth,
  } = useSelector((state: RootState) => state.activities);
  const { dataMap: definitionsMap } = useSelector(
    (state: RootState) => state.activities.definitions
  );

  const [showDeletionWarningModal, setShowDeletionWarningModal] =
    useState(false);
  const [activitiesToUpdate, setActivitiesToUpdate] = useState<string[]>([]);
  const [selectedActivityInstance, setSelectedActivityInstance] = useState<
    ActivityInstance | undefined
  >();
  const [reqNewActivities, setReqNewActivities] = useState<boolean>();
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    return () => {
      // Update activities data on unmount
      dispatch(
        updateServiceBoxActivities(
          serviceBoxActivities[0],
          customer.customer_number
        )
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeActivityToUpdate = (activityId: string) => {
    setActivitiesToUpdate((prev) =>
      [...prev].filter((item) => item !== activityId)
    );
  };

  const addActivityToUpdate = (activityId: string) => {
    setActivitiesToUpdate((prev) => [...prev, activityId]);
  };

  const deleteOnetimeActivity = (
    selectedActivity: ActivityInstance | undefined
  ) => {
    if (!selectedActivity) {
      return;
    }
    dispatch(removeOnetimeActivity(customer, selectedActivity));
    setShowDeletionWarningModal(false);
  };

  const completeAll = (group: ActivityInstancesGroup) => {
    group.activities.forEach(async (activity) => {
      const definition = definitionsMap[activity.activity_type];
      if (
        !activity.completed_at &&
        (activity.form_id === "" || activity.form_id === null) &&
        activity.getTriggerType(definition) !==
          ActivityInstanceTriggerType.DeductionModuleDeduction
      ) {
        addActivityToUpdate(activity.activity_id);

        await dispatch(
          completeActivity(
            customer,
            activity,
            currentUser.graphId,
            undefined,
            service?.service_box_id
          )
        );

        removeActivityToUpdate(activity.activity_id);
      }
    });
  };

  const changeOccurred = currentServiceActivitiesForMonth[0];
  const hasService = !!(
    currentServiceActivitiesForMonth?.[0]?.services?.length > 0
  );

  const checkServiceActivities = hasService
    ? currentServiceActivitiesForMonth[0].services[0].activities
    : [];

  let activitiesOfService = service?.activities;
  if (checkServiceActivities && (changeOccurred || !activitiesOfService)) {
    activitiesOfService = checkServiceActivities;
  }

  const activityGroups = activitiesOfService?.reduce((sum, current) => {
    const definition = definitionsMap[current.activity_type];
    if (!sum[current.group.group_idx]) {
      sum[current.group.group_idx] = {
        categoryName: current.getGroupTitle(language, definition),
        categorySortingNumber: current.getGroupSortingNum(),
        activities: [],
      };
    }

    sum[current.group.group_idx].activities = [
      ...sum[current.group.group_idx].activities,
      current,
    ];

    return sum;
  }, {} as ServiceActivitiesGroup);

  const sortedActivitiesKeys =
    activityGroups &&
    Object.keys(activityGroups).sort((key1, key2) => {
      const category1 = activityGroups[key1].categorySortingNumber;
      const category2 = activityGroups[key2].categorySortingNumber;

      return category1.toString().localeCompare(category2.toString());
    });

  const currentMonthYearFromURL = () => {
    const url = window.location.href;
    const parts = url.split("/");
    const serviceBoxId = parts[parts.length - 1];
    const IdParts = serviceBoxId.split("-");
    const month = IdParts[IdParts.length - 1];
    const year = IdParts[IdParts.length - 2];

    return {
      month: parseInt(month.substring(1), 10),
      year: parseInt(year.substring(1), 10),
    };
  };

  const checkUpdatedMonth = hasService
    ? currentServiceActivitiesForMonth[0].services[0].month
    : service?.month || currentMonthYearFromURL().month;

  const checkUpdatedYear = hasService
    ? currentServiceActivitiesForMonth[0].services[0].year
    : service?.year || currentMonthYearFromURL().year;

  const initialState = {
    month: checkUpdatedMonth,
    year: checkUpdatedYear,
  };

  const [stateDate, dispatchDate] = useReducer(reducerDate, initialState);

  useEffect(() => {
    if (stateDate.month !== checkUpdatedMonth) {
      dispatchDate({
        type: ActivitiesDateReducerActionEnum.ResetState,
        payload: initialState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkUpdatedMonth]);

  useEffect(() => {
    setShowSpinner(false);
  }, [activitiesOfService]);

  useEffect(() => {
    if (!service) {
      return;
    }

    if (reqNewActivities) {
      setReqNewActivities(false);

      dispatch(setServiceBoxActivities([], ""));

      const newServiceBoxId = getNewServiceBoxId();

      navigate({
        pathname: AppRouteHelper.getAssignmentsServices(
          customer.customer_number,
          newServiceBoxId
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqNewActivities]);

  if (!service || !activityGroups) {
    return null;
  }

  const getNewServiceBoxId = () => {
    const { month, year } = stateDate;

    let serviceBoxIdParts = serviceId.split("-");
    let monthPart = serviceBoxIdParts[serviceBoxIdParts.length - 1];
    let yearPart = serviceBoxIdParts[serviceBoxIdParts.length - 2];
    serviceBoxIdParts = serviceBoxIdParts.slice(0, -2);

    monthPart = `${formatToTextOnlyString(monthPart)}${month}`;
    yearPart = `${formatToTextOnlyString(yearPart)}${year}`;
    serviceBoxIdParts = [...serviceBoxIdParts, yearPart, monthPart];
    return serviceBoxIdParts.join("-");
  };

  const handleMonthRequest = (sequential: SequentialType) => {
    dispatchDate({ type: sequential });
    setReqNewActivities(true);
    setShowSpinner(true);
  };

  const notMonthly = service.recurrence !== Recurrence.Monthly;

  return (
    <>
      <Row className="sticky top-align no-border justify-content-between contentHeader">
        <Col md={12}>
          <Row>
            <Col md={6} className="d-flex header-title">
              <h3 className="pl-sm">{service.getTitle(language)}</h3>
              <ContextualMenuV9
                menuItems={[
                  {
                    text: `${translate("EDIT_ACTIVITIES")}`,
                    icon: () => renderIcon(<Edit20Regular />),
                    onClick: () => setEditing(true),
                    disabled: isUserRestricted(customer, currentUser),
                  },
                ]}
              />
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <p className="pr-sm fw-600">{translate("SELECTED_MONTH")}</p>
              <p>
                {ts(getMonthName(stateDate.month).toUpperCase())}{" "}
                {stateDate.year}
              </p>
            </Col>
          </Row>
        </Col>
        <Col md={12} className="pt-sm">
          <Row className="d-flex justify-content-between">
            <Col md={6}>
              <ChevronLeft16Regular
                className={`${
                  !notMonthly ? "text-color-blue" : "text-gray"
                } cursor-pointer mr-md`}
                onClick={() =>
                  !notMonthly &&
                  handleMonthRequest(
                    ActivitiesDateReducerActionEnum.DecrementMonth
                  )
                }
              />
              <span className={`${notMonthly ? "text-gray" : ""}`}>
                {ts(getMonthName(stateDate.month - 1))}{" "}
                {noticeNextOrPrevYear(
                  stateDate.month,
                  stateDate.year,
                  ActivitiesDateReducerActionEnum.DecrementMonth
                )}
              </span>
            </Col>
            <Col md={6} className="text-right">
              <span className={`${notMonthly ? "text-gray" : ""}`}>
                {ts(getMonthName(stateDate.month + 1))}{" "}
                {noticeNextOrPrevYear(
                  stateDate.month,
                  stateDate.year,
                  ActivitiesDateReducerActionEnum.IncrementMonth
                )}
              </span>
              <ChevronRight16Regular
                className={`${!notMonthly ? "text-color-blue" : "text-gray"}
                cursor-pointer ml-md`}
                onClick={() =>
                  !notMonthly &&
                  handleMonthRequest(
                    ActivitiesDateReducerActionEnum.IncrementMonth
                  )
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="table-row-height-44 justify-content-center mt-sm px-xl mt-lg">
        <Row className="px-xl py-sm  align-items-center justify-content-between fw-600">
          <Col className="activity-table-header" md={3}>
            <span>{translate("ACTIVITY")}</span>
          </Col>
          <Col md={2}>
            <span>{translate("DEADLINE")}</span>
          </Col>
          <Col md={3}>
            <span>{translate("DISTRIBUTED")}</span>
          </Col>
          <Col md={1}>
            <TooltipV9 content="INFO_SUPPORT">
              <div className="d-flex align-items-center w-100">
                <span className="stringLength w-100">
                  {translate("INFO_SUPPORT")}
                </span>
              </div>
            </TooltipV9>
          </Col>
          <Col md={3}>
            <span>{translate("COMPLETED")}</span>
          </Col>
          {/* NOTE: this commented code is needed for later   */}
          {/* <Col md={1} className="text-right pr-lg">
            <span>{translate("ACTION")}</span>
          </Col> */}
        </Row>
      </Row>
      {showSpinner ? (
        <Spinner
          className="activity-table-spinner"
          size={SpinnerSize.ExtraSmall}
        />
      ) : null}
      {!showSpinner &&
      sortedActivitiesKeys &&
      sortedActivitiesKeys.length > 0 ? (
        sortedActivitiesKeys.map((key) => {
          const group: ActivityInstancesGroup = activityGroups[key];
          const groupName = group.categoryName;

          return (
            <Row
              key={key}
              className="justify-content-center align-items-center px-xl"
            >
              <Row xl={2} className="py-sm background-gray-300">
                <Col className="d-flex align-items-center">
                  <Checkbox
                    disabled={
                      group.activities.every(
                        (activity) => activity.completed_at
                      ) || !isUserInCustomerTeam(customer, currentUser)
                    }
                    onChange={() => completeAll(group)}
                    checked={group.activities.every(
                      (activity) => activity.completed_at
                    )}
                  />
                  <span className="pl-md fw-600">{groupName}</span>
                </Col>
              </Row>
              <Row className="no-padding horizontal-divider" />
              <Col>
                {group.activities
                  .filter((activity) => !activity.isHidden())
                  .sort((a, b) => (a.sorting_nr < b.sorting_nr ? -1 : 1))
                  .map((activity: ActivityInstance) => {
                    return (
                      <ActivityRow
                        key={activity.activity_id}
                        activityInstance={activity}
                        customer={customer}
                        openActivityDetailsPanel={openActivityDetailsPanel}
                        addActivityToUpdate={addActivityToUpdate}
                        removeActivityToUpdate={removeActivityToUpdate}
                        activitiesToUpdate={activitiesToUpdate}
                        service={service}
                        selectedActivityInstance={selectedActivityInstance}
                        setSelectedActivityInstance={
                          setSelectedActivityInstance
                        }
                        definition={definitionsMap[activity.activity_type]}
                      />
                    );
                  })}
              </Col>
            </Row>
          );
        })
      ) : (
        <ActivitiesNotAvailable customerId={customer.customer_number} />
      )}

      {selectedActivityInstance && (
        <Modal
          isOpen={showDeletionWarningModal}
          onDismiss={() => setShowDeletionWarningModal(false)}
          header={<ModalHeader headerTitleText="REMOVE_ASSIGNMENT" />}
          footer={
            <ModalFooter
              onSave={() => {
                deleteOnetimeActivity(selectedActivityInstance);
                setShowDeletionWarningModal(false);
              }}
              onCancel={() => setShowDeletionWarningModal(false)}
              labelSubmit="CONFIRM"
            />
          }
        >
          <p>{translate("DELETE_ONETIME_ACTIVITY_MODAL_DESCRIPTION")}</p>
        </Modal>
      )}
    </>
  );
}
