import {
  ArrowSortDown16Regular,
  ArrowSortUp16Regular,
  Info16Regular,
} from "@fluentui/react-icons";

import TooltipV9, { TooltipV9Props } from "components/tooltip/TooltipV9";

import StatusIndicatorIcon, {
  StatusIndicatorIconProps,
} from "./StatusIndicatorIcon";

type TooltipIconProps = {
  tooltip: Omit<TooltipV9Props, "children">;
  tooltipIcon?: JSX.Element;
};

export function TooltipInfoIcon({
  tooltip,
  tooltipIcon = <Info16Regular />,
}: TooltipIconProps) {
  return <TooltipV9 {...tooltip}>{tooltipIcon}</TooltipV9>;
}

type SortIconProps = {
  show: boolean;
  descending: boolean;
};

export function SortIcon({ show, descending }: SortIconProps) {
  if (!show) {
    return null;
  }
  return descending ? <ArrowSortDown16Regular /> : <ArrowSortUp16Regular />;
}

export function TooltipStatusIcon({
  tooltip,
  status,
  color,
  ...rest
}: StatusIndicatorIconProps & { tooltip: Omit<TooltipV9Props, "children"> }) {
  return (
    <TooltipV9 {...tooltip}>
      <StatusIndicatorIcon status={status} color={color} {...rest} />
    </TooltipV9>
  );
}

export function NotificationIcon({
  style,
  ...props
}: StatusIndicatorIconProps) {
  return (
    <StatusIndicatorIcon
      style={{
        fontSize: ".75em",
        cursor: "pointer",
        position: "absolute",
        top: "-5px",
        right: "-2px",
        ...style,
      }}
      {...props}
    />
  );
}
