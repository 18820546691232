import {
  Checkbox as FluentUICheckbox,
  CheckboxProps,
} from "@fluentui/react-components";

import "./Checkbox.scss";

type Props = {
  noMargin?: boolean;
  className?: string;
} & CheckboxProps;

export default function Checkbox({
  noMargin = true,
  className = "",
  ...rest
}: Props) {
  const styles = noMargin ? "checkbox-custom-styling" : "";
  return (
    <FluentUICheckbox {...rest} className={`checkbox ${className} ${styles}`} />
  );
}
