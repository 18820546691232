import Switch from "components/switch";
import { BasicInfo } from "models/customerSettings/customerSettingsBasicInfo";

type CustomerPageBasicInfoToggleProps = {
  settingName:
    | "collective_agreement"
    | "holiday_salary"
    | "lease_cars"
    | "reporting_forum";
  basicInfo: BasicInfo;
  onToggle: (arg: Partial<BasicInfo>) => void;
};

export default function CustomerPageBasicInfoToggle({
  settingName,
  basicInfo,
  onToggle,
}: CustomerPageBasicInfoToggleProps) {
  let key = "";
  let value = false;
  let label = "";
  switch (settingName) {
    case "collective_agreement":
      key = "collective_agreement";
      value = basicInfo ? basicInfo.collective_agreement : false;
      label = "Collective agreement";
      break;
    case "holiday_salary":
      key = "holiday_salary";
      value = basicInfo ? basicInfo.holiday_salary : false;
      label = "Holiday salary";
      break;
    case "lease_cars":
      key = "lease_cars";
      value = basicInfo ? basicInfo.lease_cars : false;
      label = "Lease cars";
      break;
    case "reporting_forum":
      key = "reporting_forum";
      value = basicInfo ? basicInfo.reporting_forum : false;
      label = "Reporting forum";
      break;
    default:
      break;
  }

  return (
    <Switch
      onToggleMethod={(e, { checked }) => onToggle({ [key]: checked || false })}
      checked={value}
      label={label}
    />
  );
}
