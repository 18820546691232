import { ToastIntent } from "@fluentui/react-components";

import { ApplicationError, parseApplicationError } from "errors/errors";
import { TranslationKey } from "i18n";
import { Action } from "..";

export const DISMISS_ALL_NOTIFICATIONS =
  "notifications/DISMISS_ALL_NOTIFICATIONS";
export const DISMISS_NOTIFICATION = "notifications/DISMISS_NOTIFICATION";
export const APPEND_ERROR = "notifications/APPEND_ERROR";
export const APPEND_INFO = "notifications/APPEND_INFO";

export interface DismissAllNotificationsAction {
  type: typeof DISMISS_ALL_NOTIFICATIONS;
}

export interface AppendErrorsNotificationAction {
  type: typeof APPEND_ERROR;
  error: ApplicationError;
  translationKey: TranslationKey;
  dismissTimeout: number;
}

export interface AppendInfoNotificationAction {
  type: typeof APPEND_INFO;
  translationKey: TranslationKey;
  dismissTimeout: number;
  notificationType: ToastIntent;
  translationArgs: string[];
  notificationActionText?: string;
  notificationActionLink?: string;
}

export interface DismissNotificationAction {
  type: typeof DISMISS_NOTIFICATION;
  notificationId: number;
}

export type NotificationsAction =
  | DismissAllNotificationsAction
  | AppendErrorsNotificationAction
  | DismissNotificationAction
  | AppendInfoNotificationAction;

export type NotificationEntry = {
  translationKey: TranslationKey;
  translationArgs: string[];
  error: ApplicationError;
  notificationType: ToastIntent;
  notificationId: number;
  isVisible: boolean;
  dismissTimeout: number;
  notificationActionText?: string;
  notificationActionLink?: string;
};

export type NotificationsState = {
  data: NotificationEntry[];
};

const initialNotificationsState: NotificationsState = {
  data: [],
};

export function notificationsReducer(
  { data } = initialNotificationsState,
  action: NotificationsAction
) {
  switch (action.type) {
    case DISMISS_NOTIFICATION: {
      const message = data.find(
        (d) => d.notificationId === action.notificationId
      );
      if (!message) {
        return;
      }

      return {
        data: [
          ...data.filter((d) => d.notificationId !== action.notificationId),
          { ...message, isVisible: false },
        ],
      };
    }
    case DISMISS_ALL_NOTIFICATIONS:
      data.forEach((d) => {
        d.isVisible = false;
      });
      return { data };
    case APPEND_ERROR:
      return {
        data: [
          ...data,
          {
            ...action,
            notificationId: data.length + 1,
            isVisible: true,
            notificationType: "error",
            isToast: false,
          },
        ],
      };
    case APPEND_INFO:
      return {
        data: [
          ...data,
          {
            ...action,
            notificationId: data.length + 1,
            isVisible: true,
          },
        ],
      };
    default:
      return { data };
  }
}

export function dismissMessage(message: NotificationEntry): Action {
  return {
    type: DISMISS_NOTIFICATION,
    notificationId: message.notificationId,
  };
}

export function hideErrors(): Action {
  return {
    type: DISMISS_ALL_NOTIFICATIONS,
  };
}

export function appendToastMessage(
  translationKey: TranslationKey,
  notificationType: ToastIntent = "info",
  translationArgs: string[] = [],
  notificationActionText = "",
  notificationActionLink = ""
): Action {
  return {
    type: APPEND_INFO,
    translationKey,
    dismissTimeout: 4000,
    notificationType,
    translationArgs,
    notificationActionText,
    notificationActionLink,
  };
}

export function appendError(
  translationKey: TranslationKey,
  error: Error,
  dismissTimeout = 15000
): Action {
  return {
    type: APPEND_ERROR,
    error: parseApplicationError(error),
    translationKey,
    dismissTimeout,
  };
}
