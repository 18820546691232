export function getNameFromEmail(email: string) {
  if (!email) {
    return "";
  }

  const split = email.split("@");

  if (split.length > 0) {
    return (
      split[0]
        .split(".")
        .map((s) => s[0].toUpperCase() + s.slice(1).toLowerCase())
        .join(" ") ?? " "
    );
  }

  return email;
}
