import { Calendar16Regular } from "@fluentui/react-icons";
import {
  DayOfWeek,
  FirstWeekOfYear,
  Calendar as FluentCalendar,
  CalendarProps as FluentCalendarProps,
} from "@fluentui/react-calendar-compat";
import { useState } from "react";

import Modal from "components/modal";
import { Language, TranslationKey } from "i18n";
import {
  customDatePickerStrings,
  dateLanguageFormatter,
} from "libs/date/date-format";

export type CalendarProps = {
  translate: (key: TranslationKey) => string;
  language: Language;
} & FluentCalendarProps;

export function Calendar({ translate, language, ...rest }: CalendarProps) {
  const dateFormat = dateLanguageFormatter(language);

  return (
    <FluentCalendar
      firstWeekOfYear={FirstWeekOfYear.FirstFullWeek}
      firstDayOfWeek={DayOfWeek.Monday}
      dateTimeFormatter={{
        formatDay: (date: Date) => dateFormat(date, "dd"),
        formatMonth: (date: Date) => dateFormat(date, "MMMM"),
        formatYear: (date: Date) => dateFormat(date, "yyyy"),
        formatMonthDayYear: (date: Date) => dateFormat(date, "dd-MMMM yyyy"),
        formatMonthYear: (date: Date) => dateFormat(date, "MMMM yyyy"),
      }}
      strings={customDatePickerStrings(translate)}
      {...rest}
    />
  );
}

type ModalCalendarPickerProps = CalendarProps & {
  header: JSX.Element;
  disabled?: boolean;
};

export function ModalCalendarPicker({
  translate,
  language,
  value,
  onSelectDate,
  header,
  disabled = false,
  ...rest
}: ModalCalendarPickerProps) {
  const [modalOpen, setOpenModal] = useState(false);

  return (
    <>
      <Calendar16Regular
        className={`${!disabled ? "cursor-pointer" : "text-gray"}`}
        onClick={() => !disabled && setOpenModal(true)}
      />
      <Modal
        isOpen={modalOpen}
        onDismiss={() => setOpenModal(false)}
        header={header}
      >
        <div className="d-flex justify-content-center">
          <Calendar
            translate={translate}
            language={language}
            className="d-print-none text-wrap-none position-relative py-0 me-4"
            value={value}
            onSelectDate={(date) => {
              setOpenModal(false);
              onSelectDate?.(date);
            }}
            {...rest}
          />
        </div>
      </Modal>
    </>
  );
}
