import { Col, Fade, Row } from "react-bootstrap";
import { Add20Regular } from "@fluentui/react-icons";

import "./CustomerContactsList.scss";
import { useTranslation } from "hooks";
import { DealContact } from "models/deals/deal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "state";
import ContactListItem from "./ContactListItem";
import CreateEditContact from "./CreateEditContact";

interface CustomerContactsListProps {
  contacts: DealContact[];
  setHideWizardNavigation: (hideNavigation: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
}

function CustomerContactsList({
  contacts,
  setHideWizardNavigation,
  setIsLoading,
}: CustomerContactsListProps) {
  const { translate } = useTranslation();

  const { isNewCustomer } = useSelector((state: RootState) => state.sales);

  const [showContactsList, setShowContactsList] = useState(!isNewCustomer);
  const [currentContact, setCurrentContact] = useState<DealContact>();

  useEffect(() => {
    setHideWizardNavigation(!showContactsList);
  }, [setHideWizardNavigation, showContactsList]);

  const handleAddContact = () => {
    setCurrentContact(undefined);
    setShowContactsList(false);
  };

  const renderHeader = () => (
    <div className="border-bottom py-sm">
      <Row>
        <Col md={3} className="fw-bold">
          {translate("NAME")}
        </Col>
        <Col md={3} className="fw-bold">
          {translate("EMAIL")}
        </Col>
        <Col md={3} className="fw-bold">
          {translate("CREATE.NEW.DEAL.PHONE_NUMBER")}
        </Col>
        <Col md={2} className="fw-bold ">
          {translate("BO_CONTACT")}
        </Col>
        <Col md={1} className="fw-bold d-flex justify-content-center">
          {translate("ACTIONS")}
        </Col>
      </Row>
    </div>
  );

  const onPressEditContact = (contact: DealContact) => {
    setCurrentContact(contact);
    setShowContactsList(false);
  };

  return (
    <>
      <Fade
        in={showContactsList}
        mountOnEnter
        unmountOnExit
        onExited={() => setShowContactsList(false)}
      >
        <div className="sticky-button-container h-100">
          {contacts.length > 0 ? (
            <>
              <Col md={12} className="h-100">
                {renderHeader()}
                {contacts &&
                  contacts.map((contact) => (
                    <ContactListItem
                      key={contact.id}
                      contact={contact}
                      onPressEdit={() => onPressEditContact(contact)}
                    />
                  ))}
              </Col>
              <div className="add-new-btn sticky-button-wrapper">
                <Col md={12} className="my-sm">
                  <div
                    className="p-sm cursor-pointer pog-link d-flex fit-content"
                    onClick={handleAddContact}
                  >
                    <Add20Regular />
                    <span className="ml-sm">{translate("CREATE_CONTACT")}</span>
                  </div>
                </Col>
              </div>
            </>
          ) : (
            <div className="minHeight-80 d-flex flex-column align-items-center justify-content-center">
              <span className="body-italic-light">
                {translate("NO_CONTACTS_FOUND")}
              </span>

              <div className="mt-4">
                <Col md={12} className="my-sm">
                  <div
                    className="p-sm cursor-pointer pog-link d-flex fit-content"
                    onClick={handleAddContact}
                  >
                    <Add20Regular />
                    <span className="ml-sm">{translate("CREATE_CONTACT")}</span>
                  </div>
                </Col>
              </div>
            </div>
          )}
        </div>
      </Fade>
      <Fade
        in={!showContactsList}
        appear
        mountOnEnter
        unmountOnExit
        onExited={() => {
          setCurrentContact(undefined);
          setShowContactsList(true);
        }}
      >
        <div className="h-100">
          <CreateEditContact
            setShowContactsList={setShowContactsList}
            contact={currentContact}
            customerContacts={contacts}
            setIsLoading={setIsLoading}
          />
        </div>
      </Fade>
    </>
  );
}

export default CustomerContactsList;
