import { RouteConfig } from "../../../routes";
import BussinessAreaPicker from "./businessAreaPicker";
import BussinessOpportunitiesDetails from "./businessOpportunityDetails";
import BusinessOpportunityCompletion from "./completion";
import BussinessOpportunitiesCreateCustomer from "./createCustomer";
import BusinessOpportunityCustomerContacts from "./customerContacts";
import BusinessOpportunityCustomerDetails from "./customerDetails";
import CustomerSearch from "./customerSearch";

export const BusinessOpportunityWizardRouteHelper = {
  getCustomerSearchRoute: () => "/business-opportunity",
  getCustomerDetailsRoute: () => "/business-opportunity/details",
  getCustomerContactsRoute: () => "/business-opportunity/contacts",
  getBusinessOpportunityBusinessAreaRoute: () =>
    "/business-opportunity/business-area",
  getBusinessOpportunityBusinessDetailsRoute: () =>
    "/business-opportunity/business-details",
  getBusinessOpportunityCompletionRoute: () =>
    "/business-opportunity/completion",
  getNewCustomerDetailsRoute: () => "/business-opportunity/register",
};

const businessOpportunityWizardRoutes: RouteConfig[] = [
  {
    path: BusinessOpportunityWizardRouteHelper.getCustomerSearchRoute(),
    element: <CustomerSearch />,
    subRoutes: [],
  },
  {
    path: BusinessOpportunityWizardRouteHelper.getCustomerDetailsRoute(),
    element: <BusinessOpportunityCustomerDetails />,
    subRoutes: [],
  },
  {
    path: BusinessOpportunityWizardRouteHelper.getCustomerContactsRoute(),
    element: <BusinessOpportunityCustomerContacts />,
    subRoutes: [],
  },
  {
    path: BusinessOpportunityWizardRouteHelper.getBusinessOpportunityBusinessAreaRoute(),
    element: <BussinessAreaPicker />,
    subRoutes: [],
  },
  {
    path: BusinessOpportunityWizardRouteHelper.getBusinessOpportunityBusinessDetailsRoute(),
    element: <BussinessOpportunitiesDetails />,
    subRoutes: [],
  },
  {
    path: BusinessOpportunityWizardRouteHelper.getBusinessOpportunityCompletionRoute(),
    element: <BusinessOpportunityCompletion />,
    subRoutes: [],
  },
  {
    path: BusinessOpportunityWizardRouteHelper.getNewCustomerDetailsRoute(),
    element: <BussinessOpportunitiesCreateCustomer />,
    subRoutes: [],
  },
  {
    path: "*",
    element: <p>No such route!</p>,
    subRoutes: [],
  },
];

export default businessOpportunityWizardRoutes;
