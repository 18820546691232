import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import { ActivityInstance, Recurrence } from "models/activities/activity";

export const getDateSpan = (
  timespan: Recurrence,
  selectedActivityInstance?: ActivityInstance
) => {
  const MONDAY = 1;

  if (!selectedActivityInstance) {
    return {
      minDate: new Date(),
      maxDate: new Date(),
    };
  }

  const { deadline } = selectedActivityInstance;

  switch (timespan) {
    case Recurrence.Weekly:
      return {
        minDate: startOfWeek(deadline, {
          weekStartsOn: MONDAY,
        }),
        maxDate: endOfWeek(deadline, {
          weekStartsOn: MONDAY,
        }),
      };
    case Recurrence.Monthly:
    case Recurrence.Quarterly:
      return {
        minDate: startOfMonth(deadline),
        maxDate: endOfMonth(deadline),
      };
    default:
      return {
        minDate: new Date(),
        maxDate: new Date(),
      };
  }
};
