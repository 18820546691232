import {
  sortDealsByChangedDate,
  sortOffersByChangedDate,
} from "libs/sort-deals-by-date";
import { Offer } from "models/offer/SavedOffers";
import { useSelector } from "react-redux";
import { RootState } from "state";

export function useFilterDealsAndContracts() {
  const { data: offers, createdDeals } = useSelector(
    (state: RootState) => state.offers
  );

  const filteredAndSortedContracts = sortOffersByChangedDate(
    offers.filter((o: Offer) => o.envelopes && o.envelopes?.length > 0)
  ).sort((a: Offer, b: Offer) => (!a.envelopes && !!b.envelopes ? -1 : 1));

  const filteredAndSortedOffers = sortOffersByChangedDate(
    offers.filter((o: Offer) => o.state === "offer" && !o.envelopes)
  ).sort((a: Offer, b: Offer) => (!a.envelopes && !!b.envelopes ? -1 : 1));

  const filteredDealsContracts = sortDealsByChangedDate(
    createdDeals.data.filter((d) => d.state !== "offer")
  );
  const filteredDealsOffers = sortDealsByChangedDate(
    createdDeals.data.filter((d) => d.state === "offer")
  );

  return {
    filteredAndSortedContracts,
    filteredDealsContracts,
    filteredAndSortedOffers,
    filteredDealsOffers,
    totalOffersCount:
      filteredAndSortedOffers.length + filteredDealsOffers.length,
    totalContractsCount:
      filteredAndSortedContracts.length + filteredDealsContracts.length,
  };
}
