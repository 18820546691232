import { rest } from "msw";
import { mockCheckToken, MOCK_DELAY } from "..";
import { initialCustomersWithData } from "../customers/customers";
import { generateMonths } from "../../../libs/date/generate-month";
import {
  CustomerActivitiesServices,
  ActivityInstance,
  ServiceInstance,
  Activity,
  Recurrence,
} from "../../../models/activities/activity";
import {
  getGeneralAccountingMonthlyServices,
  getGeneralAccountingQuarterlyServices,
  getGeneralAccountingYearlyServices,
  getActivitiesDefinitions,
  getYearEndServices,
} from "./activities";
import { addYears, endOfQuarter, setQuarter } from "date-fns";
import { initialUser } from "../users";
import { plainToClass } from "class-transformer";
import { MOCK_USER_EMAIL } from "../..";

const DEFAULT_CLIENT_USER = "96934a34-19d8-43e4-a998-fc05727c619e";

const months = generateMonths(new Date("2021-01-01"), new Date("2022-01-01"));
const years = [addYears(new Date(), -1), new Date(), addYears(new Date(), 1)];
const quarters = [
  endOfQuarter(setQuarter(new Date(), 1)),
  endOfQuarter(setQuarter(new Date(), 2)),
  endOfQuarter(setQuarter(new Date(), 3)),
  endOfQuarter(setQuarter(new Date(), 4)),
];

type StatusUpdate = {
  teams_conversation_weburl: string;
  teams_conversation_id: string;
  user_id: string;
  activity_id: string;
};

const initialCustomerServices = initialCustomersWithData.map(
  ({ customer: c, isFromSearch }) => {
    const monthlyServices = months
      .map((m) => getGeneralAccountingMonthlyServices(c, m, initialUser))
      .flat();

    const quarterlyServices = quarters
      .map((q) => getGeneralAccountingQuarterlyServices(c, q, initialUser))
      .flat();

    const yearlyServices = years
      .map((y) => [
        ...getGeneralAccountingYearlyServices(c, y, initialUser),
        ...getYearEndServices(c, y, initialUser),
      ])
      .flat();

    const services = [
      ...monthlyServices,
      ...quarterlyServices,
      ...yearlyServices,
    ] as any as ServiceInstance[];

    const unselectedOptionals = [
      plainToClass(Activity, {
        activity_type: `UNSELECTED-1`,
        index: {
          group_idx: 2,
          service_idx: `UNSELECTED-SERVICE-1`,
          activity_idx: 1,
        },
        recurrence: Recurrence.Weekly.toUpperCase(),
        available_to_add: true,
        titles: {
          sv: "Valbar #1",
          en: "Optional #1",
        },
        group: {
          sv: "Grupp 1",
          en: "Group 1",
        },
        mandatory: false,
        onetime: false,
      }),
      plainToClass(Activity, {
        activity_type: `UNSELECTED-2`,
        index: {
          group_idx: 2,
          service_idx: `UNSELECTED-SERVICE-1`,
          activity_idx: 1,
        },
        available_to_add: true,
        recurrence: Recurrence.Weekly.toUpperCase(),
        group: {
          sv: "Grupp 1",
          en: "Group 1",
        },
        titles: {
          sv: "Valbar #2",
          en: "Optional #2",
        },
        mandatory: false,
        onetime: false,
      }),
      plainToClass(Activity, {
        activity_type: `UNSELECTED-3`,
        index: {
          group_idx: 2,
          service_idx: `UNSELECTED-SERVICE-2`,
          activity_idx: 1,
        },
        available_to_add: true,
        recurrence: Recurrence.Weekly.toUpperCase(),
        group: {
          sv: "Grupp 2",
          en: "Group 2",
        },
        titles: {
          sv: "Valbar #3",
          en: "Optional #3",
        },
        mandatory: false,
        onetime: false,
      }),
      plainToClass(Activity, {
        activity_type: `UNSELECTED-4`,
        index: {
          group_idx: 2,
          service_idx: `UNSELECTED-SERVICE-2`,
          activity_idx: 1,
        },
        available_to_add: true,
        recurrence: Recurrence.Weekly.toUpperCase(),
        group: {
          sv: "Grupp 2",
          en: "Group 2",
        },
        titles: {
          sv: "Valbar #4",
          en: "Optional #4",
        },
        mandatory: false,
        onetime: false,
      }),
      plainToClass(Activity, {
        activity_type: `UNSELECTED-5`,
        index: {
          service_idx: `UNSELECTED-SERVICE-2`,
          activity_idx: 1,
        },
        available_to_add: true,
        recurrence: Recurrence.Weekly.toUpperCase(),
        group: {
          sv: undefined,
          en: undefined,
        },
        titles: {
          sv: "Valbar #4",
          en: "Optional #4",
        },
        mandatory: false,
        onetime: false,
      }),
    ];

    const optionalActivities = [
      ...services
        .map((s, index) => [
          ...s.activities.map((a) =>
            plainToClass(Activity, {
              ...a,
              mandatory: false,
              available_to_add: true,
            })
          ),
        ])
        .flat(),
      ...unselectedOptionals,
    ];

    const active: boolean = c.frequency !== undefined;
    const definitions = getActivitiesDefinitions();

    const customerActivities = {
      isFromSearch,
      customer_id: c.customer_number,
      activities: {
        org_number: c.company_registration_number,
        services,
        active,
        customer_id: c.customer_number,
        assigned_users: {
          client_user: active ? DEFAULT_CLIENT_USER : null,
          activities_users: [],
        },
      },
      optionalActivities: {
        activities: active ? optionalActivities : [],
      },
      definitions,
    };

    return customerActivities;
  }
);

const initialCustomerServicesWithoutSearch = initialCustomerServices.filter(
  (c) => !c.isFromSearch
);

const BASE_PATH = process.env.REACT_APP_API_URL;

export const activitiesHandlers = [
  rest.get<CustomerActivitiesServices[]>(
    `${BASE_PATH}/activities`,
    (req, res, { status, json, text, delay }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        const { message } = e as Error;
        return res(status(403), text(message));
      }

      if (!req.url.searchParams.get("start_date")) {
        return res(status(400), text("Missing parameter start_date"));
      }

      if (!req.url.searchParams.get("end_date")) {
        return res(status(400), text("Missing parameter end_date"));
      }

      const startDate: Date = new Date(
        req.url.searchParams.get("start_date") || ""
      );
      const endDate: Date = new Date(
        req.url.searchParams.get("end_date") || ""
      );

      const assignmentsPerMonth = initialCustomerServicesWithoutSearch.map(
        (c) => {
          const filteredServices = c.activities.services.filter((s) => {
            const start =
              startDate.getFullYear() * 100 + (startDate.getMonth() + 1);
            const end = endDate.getFullYear() * 100 + (endDate.getMonth() + 1);
            const serviceDate = s.year * 100 + s.month;

            return serviceDate >= start && serviceDate <= end;
          });

          return plainToClass(CustomerActivitiesServices, {
            active: c.activities.active,
            org_number: c.activities.org_number,
            fiscal_year_is_missing: false,
            customer_id: c.customer_id,
            services: c.activities.active ? filteredServices : [],
            assigned_users: c.activities.assigned_users,
          });
        }
      );

      return res(status(200), delay(MOCK_DELAY * 2), json(assignmentsPerMonth));
    }
  ),
  rest.post(
    `${BASE_PATH}/customers/:customerId/package`,
    (req, res, { status, json, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        const { message } = e as Error;
        return res(status(403), text(message));
      }

      return res(status(200), text("OK"));
    }
  ),
  rest.post<ActivityInstance>(
    `${BASE_PATH}/customers/:customerId/activities_onetime`,
    (req, res, { status, json, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        const { message } = e as Error;
        return res(status(403), text(message));
      }
      const activityId = req.body.activity_id;

      return res(
        status(200),
        text("OK"),
        json({
          activityId,
        })
      );
    }
  ),
  rest.patch(
    `${BASE_PATH}/customers/:customerId/package`,
    (req, res, { status, json, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        const { message } = e as Error;
        return res(status(403), text(message));
      }

      return res(status(200), text("OK"));
    }
  ),
  rest.get<CustomerActivitiesServices[]>(
    `${BASE_PATH}/customers/:customerId/activities`,
    (req, res, { status, json, text, delay }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        const { message } = e as Error;
        return res(status(403), text(message));
      }

      if (!req.url.searchParams.get("start_date")) {
        return res(status(400), text("Missing parameter start_date"));
      }

      if (!req.url.searchParams.get("end_date")) {
        return res(status(400), text("Missing parameter end_date"));
      }

      const customerId = req.params.customerId as string;
      const startDate = new Date(req.url.searchParams.get("start_date") || "");
      const endDate = new Date(req.url.searchParams.get("end_date") || "");

      const customerState = initialCustomersWithData.find(
        (c) => c.customer.customer_number === customerId
      );

      if (!customerState) {
        return res(status(404));
      }

      const customerActivities = initialCustomerServices.find(
        (c) =>
          c.activities.org_number ===
          customerState.customer.company_registration_number
      );

      if (!customerActivities) {
        return res(status(404), text("Could not find customer"));
      }

      const filteredServices = customerActivities.activities.services.filter(
        (s) => {
          const start =
            startDate.getFullYear() * 100 + (startDate.getMonth() + 1);
          const end = endDate.getFullYear() * 100 + (endDate.getMonth() + 1);
          const serviceDate = s.year * 100 + s.month;

          return serviceDate >= start && serviceDate <= end;
        }
      );

      return res(
        status(200),
        delay(MOCK_DELAY * 2),
        json({
          active: customerActivities.activities.active,
          org_number: customerActivities.activities.org_number,
          services: filteredServices,
          assigned_users: customerActivities.activities.assigned_users,
        })
      );
    }
  ),
  rest.patch<ActivityInstance>(
    `${BASE_PATH}/customers/:customerId/activities/deadline`,
    (req, res, { status, json, text, delay }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        const { message } = e as Error;
        return res(status(403), text(message));
      }

      const activityOriginalId = req.body.activity_id;

      const activities = initialCustomerServices
        .flatMap((c) => c.activities.services)
        .flatMap((s) => s.activities);

      const activity = activities.find(
        (a) => a.original_id === activityOriginalId
      );

      if (!activity) {
        return res(status(404));
      }

      activity.deadline = req.body.deadline;

      return res(status(200), delay(MOCK_DELAY), json(activity));
    }
  ),
  rest.put(
    `${BASE_PATH}/customers/:customerId/activity_types/:activityTypeId/assign`,
    (req, res, { status, text, json, delay }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        const { message } = e as Error;
        return res(status(403), text(message));
      }

      const jsonBody = JSON.parse(req.body?.toString() || "");

      if (jsonBody.userId.includes("error")) {
        return res(status(404), text("User not found"));
      }

      const activityTypeId = req.params.activityTypeId;

      const activities = initialCustomerServices
        .flatMap((c) => c.activities.services)
        .flatMap((s) => s.activities);

      const activity = activities.find(
        (a) => a.activity_type === activityTypeId
      );

      if (!activity) {
        return res(status(404));
      }

      activity.assigned_user = jsonBody.userId;

      return res(
        status(200),
        delay(MOCK_DELAY),
        json({
          email: MOCK_USER_EMAIL,
          firstName: "Firstname",
          lastName: "Lastname",
          id: "123",
        })
      );
    }
  ),
  rest.put<ActivityInstance>(
    `${BASE_PATH}/customers/:customerId/activities/complete`,
    (req, res, { status, text, delay }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        const { message } = e as Error;
        return res(status(403), text(message));
      }

      const { activityId } = req.params;

      const activities = initialCustomerServices
        .flatMap((c) => c.activities.services)
        .flatMap((s) => s.activities);

      const activity = activities.find((a) => a.activity_id === activityId);

      if (!activity) {
        return res(status(404));
      }

      activity.completed_at = new Date();

      return res(status(200), delay(MOCK_DELAY), text("OK"));
    }
  ),
  rest.put<ActivityInstance>(
    `${BASE_PATH}/customers/:customerId/activities/uncomplete`,
    (req, res, { status, text, delay }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        const { message } = e as Error;
        return res(status(403), text(message));
      }

      const activityId = req.params.activityId;

      const activities = initialCustomerServices
        .flatMap((c) => c.activities.services)
        .flatMap((s) => s.activities);

      const activity = activities.find((a) => a.activity_id === activityId);

      if (!activity) {
        return res(status(404));
      }

      activity.completed_at = undefined;

      return res(status(200), delay(MOCK_DELAY), text("OK"));
    }
  ),
  rest.post<StatusUpdate>(
    `${BASE_PATH}/customers/:customerId/activities/messages`,
    (req, res, { status, json, text, delay }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        const { message } = e as Error;
        return res(status(403), text(message));
      }

      const activityOriginalId = req.params.activityOriginalId;

      const activities = initialCustomerServices
        .flatMap((c) => c.activities.services)
        .flatMap((s) => s.activities);

      const activity = activities.find(
        (a) => a.original_id === activityOriginalId
      );

      if (!activity) {
        return res(status(404));
      }

      return res(
        status(200),
        delay(MOCK_DELAY),
        json({
          teams_conversation_weburl: "https://teams-url.com",
          teams_conversation_id: "conversation-id",
          user_id: "",
        })
      );
    }
  ),
  rest.post<CustomerActivitiesServices[]>(
    `${BASE_PATH}/customers/:customerId/activities`,
    (req, res, { status, text, delay }) => {
      const existing = initialCustomerServices.find(
        (c) => c.customer_id === req.params.customerId
      );

      if (!existing) {
        return res(status(404));
      }

      existing.activities.active = true;

      return res(status(200), delay(MOCK_DELAY), text("OK"));
    }
  ),
  rest.get<Activity[]>(
    `${BASE_PATH}/customers/:customerId/activities_optional`,
    (req, res, { status, json, delay }) => {
      const customerState = initialCustomersWithData.find(
        (c) => c.customer.customer_number === req.params.customerId
      );

      if (!customerState) {
        return res(status(404));
      }

      const activities = initialCustomerServices.find(
        (c) =>
          c.activities.org_number ===
          customerState.customer.company_registration_number
      );

      if (!activities) {
        return res(status(404));
      }

      return res(
        status(200),
        delay(MOCK_DELAY),
        json(activities.optionalActivities)
      );
    }
  ),
  rest.post(
    `${BASE_PATH}/customers/:customerId/activities_optional/:activityTypeId`,
    (req, res, { status, text, delay }) => {
      return res(status(200), delay(MOCK_DELAY), text("OK"));
    }
  ),
  rest.delete(
    `${BASE_PATH}/customers/:customerId/activities_optional/:activityTypeId`,
    (req, res, { status, text, delay }) => {
      return res(status(200), delay(MOCK_DELAY), text("OK"));
    }
  ),
  rest.get(
    `${BASE_PATH}/service_matrix`,
    (req, res, { status, json, delay }) => {
      return res(status(200), delay(MOCK_DELAY), json([]));
    }
  ),
  rest.get(
    `${BASE_PATH}/activities_table?version=2`,
    (req, res, { status, json, delay }) => {
      return res(
        status(200),
        delay(MOCK_DELAY),
        json(getActivitiesDefinitions())
      );
    }
  ),
];
