import React from "react";
import GitInfo from "react-git-info/macro";
import AppConfig from "./app-config";

export function AppVersion() {
    return (<p
        className="d-print-none"
        style={{
            margin: 0,
            position: "fixed",
            bottom: 5,
            right: 5,
            fontSize: "10px",
            color: "#ccc",
        }}
    >
        {AppConfig.APP_NAME} {AppConfig.STAGE} {GitInfo().commit.shortHash}
    </p>)
}