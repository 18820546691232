import {
  endOfMonth,
  endOfYear,
  endOfQuarter,
  getWeek,
  setWeek,
  endOfWeek,
} from "date-fns";
import { uuidv4 } from "../../../libs/uuid";
import {
  ActivityInstance,
  Recurrence,
  ActivityDefinition,
} from "../../../models/activities/activity";
import { Customer } from "../../../models/customer";
import { User } from "../../../models/user";
import { getRandomBool } from "../../generators/generator";
import activitiesMetadata from "./activities.json";
import activitiesDefinitions from "./definitions.json";

const CONVERSATION_WEB_URL =
  "https://teams.microsoft.com/l/message/19:P1BNcMJUHUi135E2WHC_W8b-ohlvDYC__ONuNP9JXPw1@thread.tacv2/1632759770426?tenantId=2fe1e157-416a-4c84-85de-1bc44f011521&groupId=453ed9ec-0955-436b-9646-165307f7f653&parentMessageId=1632759770426&teamName=Max%20Test&channelName=General&createdTime=1632759770426";

function getRandomActivityStatus() {
  const statuses = [
    "SUPPORT_FINISHED",
    "MANAGER_SUPPORT",
    "SUPPORT_FINISHED",
    "SUPPORT_SENT_TO_CLIENT",
  ];

  if (Math.random() < 0.9) {
    return "";
  }

  return {
    status: statuses[Math.floor(statuses.length * Math.random())],
    created_at: new Date(),
    user_id: uuidv4(),
  };
}

export function getActivitiesDefinitions(): ActivityDefinition[] {
  return activitiesDefinitions as unknown as ActivityDefinition[];
}

export function getYearEndServices(
  customer: Customer,
  startOfMonth: Date,
  user: User
) {
  if (!customer.frequency || customer.frequency === Recurrence.Yearly) {
    return [];
  }

  const year = startOfMonth.getFullYear();
  const period = `${customer.customer_number.slice(0, 4)}${year}`;
  const end = endOfYear(startOfMonth);
  const endOfYearMonth = end.getMonth() + 1;
  const serviceType = "YEAR_END";
  const serviceId = 0;

  return [
    {
      service_type: serviceType,
      service_type_id: serviceType,
      recurrence: Recurrence.Yearly,
      titles: { sv: "Bokslut", en: "Year End" },
      service_box_id: `S4-Y${year}`,
      year,
      month: endOfYearMonth,
      activities: activitiesMetadata
        .filter(
          (a) =>
            a.ServiceName === "Bokslut" &&
            a.Frequency === "Yearly" &&
            a.ShowByDefault === "Yes"
        )
        .map((a, i) => {
          return {
            original_id: uuidv4(),
            activity_id: `${period + i}${a.ActivityNr}`,
            index: {
              service_idx: serviceId,
              group_idx: parseInt(a.CategoryNr),
              activity_idx: parseInt(`${period + i}${a.ActivityNr}`),
            },
            recurrence: Recurrence.Yearly,
            status: getRandomActivityStatus(),
            deadline: end,
            teams_conversation_weburl:
              getRandomBool() &&
              customer.apps.teams?.status === "active" &&
              CONVERSATION_WEB_URL,
            assigned_user: "96934a34-19d8-43e4-a998-fc05727c619e",
            activity_type: a.Title,
            sorting_nr: parseInt(a.ActivityNr),
            group: {
              sorting_nr: parseInt(a.CategoryNr),
              group_idx: parseInt(a.CategoryNr),
              sv: a.CategoryName,
              en: a.CategoryNameENG,
            },
            titles: {
              sv: a.ActivityName,
              en: a.ActivityNameENG,
            },
            onetime: false,
          } as Partial<ActivityInstance>;
        }),
    },
  ];
}

export function getGeneralAccountingYearlyServices(
  customer: Customer,
  startOfMonth: Date,
  user: User
) {
  if (!customer.frequency || customer.frequency === Recurrence.Yearly) {
    return [];
  }

  const year = startOfMonth.getFullYear();
  const period = `${customer.customer_number.slice(0, 4)}${year}`;
  const end = endOfYear(startOfMonth);
  const endOfYearMonth = end.getMonth() + 1;
  const serviceType = "GENERAL_ACCOUNTING";
  const serviceId = 0;

  return [
    {
      service_type_id: serviceType,
      service_type: serviceType,
      recurrence: Recurrence.Yearly,
      titles: { sv: "Löpande bokföring", en: "General accounting" },
      service_box_id: `S4-Y${year}`,
      year,
      month: endOfYearMonth,
      activities: activitiesMetadata
        .filter(
          (a) =>
            a.ServiceName === "Löpande redovisning" &&
            a.Frequency === "Yearly" &&
            a.ShowByDefault === "Yes"
        )
        .map((a, i) => {
          return {
            original_id: uuidv4(),
            activity_id: `${period + i}${a.ActivityNr}`,
            index: {
              service_idx: serviceId,
              group_idx: parseInt(a.CategoryNr),
              activity_idx: parseInt(`${period + i}${a.ActivityNr}`),
            },
            recurrence: Recurrence.Yearly,
            status: getRandomActivityStatus(),
            deadline: end,
            teams_conversation_weburl:
              getRandomBool() &&
              customer.apps.teams?.status === "active" &&
              CONVERSATION_WEB_URL,
            assigned_user: "96934a34-19d8-43e4-a998-fc05727c619e",
            activity_type: a.Title,
            sorting_nr: parseInt(a.ActivityNr),
            group: {
              sorting_nr: parseInt(a.CategoryNr),
              group_idx: parseInt(a.CategoryNr),
              sv: a.CategoryName,
              en: a.CategoryNameENG,
            },
            titles: {
              sv: a.ActivityName,
              en: a.ActivityNameENG,
            },
            onetime: false,
          } as Partial<ActivityInstance>;
        }),
    },
  ];
}

export function getGeneralAccountingQuarterlyServices(
  customer: Customer,
  startOfMonth: Date,
  user: User
) {
  if (customer.frequency === Recurrence.Quarterly) {
    return [];
  }

  const year = startOfMonth.getFullYear();
  const period = `${customer.customer_number.slice(0, 4)}${year}`;
  const month = startOfMonth.getMonth() + 1;
  const end = endOfQuarter(startOfMonth);
  const serviceType = "GENERAL_ACCOUNTING";
  const serviceId = 0;

  return [
    {
      service_type_id: serviceType,
      recurrence: Recurrence.Quarterly,
      titles: { sv: "Löpande bokföring", en: "General accounting" },
      service_box_id: `S2-Y${year}-M${month}`,
      year,
      month,
      activities: activitiesMetadata
        .filter(
          (a) =>
            a.ServiceName === "Löpande redovisning" &&
            a.Frequency === "Quarterly" &&
            a.ShowByDefault === "Yes"
        )
        .map((a, i) => {
          return {
            original_id: uuidv4(),
            activity_id: `${period + i}${a.ActivityNr}`,
            index: {
              service_idx: serviceId,
              group_idx: parseInt(a.CategoryNr),
              activity_idx: parseInt(`${period + i}${a.ActivityNr}`),
            },
            recurrence: Recurrence.Quarterly,
            status: getRandomActivityStatus(),
            deadline: end,
            teams_conversation_weburl:
              getRandomBool() &&
              customer.apps.teams?.status === "active" &&
              CONVERSATION_WEB_URL,
            activity_type: a.Title,
            sorting_nr: parseInt(a.ActivityNr),
            group: {
              sorting_nr: parseInt(a.CategoryNr),
              group_idx: parseInt(a.CategoryNr),
              sv: a.CategoryName,
              en: a.CategoryNameENG,
            },
            titles: {
              sv: a.ActivityName,
              en: a.ActivityNameENG,
            },
            onetime: false,
          } as Partial<ActivityInstance>;
        }),
    },
  ];
}

export function getGeneralAccountingMonthlyServices(
  customer: Customer,
  startOfMonth: Date,
  user: User
) {
  const completed = getRandomBool();
  const year = startOfMonth.getFullYear();
  const month = startOfMonth.getMonth() + 1;
  const period = `${customer.customer_number.slice(0, 4)}${year}${month}`;

  const week = getWeek(startOfMonth);
  const services = [];

  if (customer.frequency === Recurrence.Weekly) {
    services.push(
      generateGeneralAccountingService(
        customer,
        period,
        year,
        month,
        week,
        Recurrence.Weekly,
        completed
      )
    );
    services.push(
      generateGeneralAccountingService(
        customer,
        period,
        year,
        month,
        week + 1,
        Recurrence.Weekly,
        completed
      )
    );
    services.push(
      generateGeneralAccountingService(
        customer,
        period,
        year,
        month,
        week + 2,
        Recurrence.Weekly,
        completed
      )
    );
    services.push(
      generateGeneralAccountingService(
        customer,
        period,
        year,
        month,
        week + 3,
        Recurrence.Weekly,
        completed
      )
    );
  }

  if (
    customer.frequency === Recurrence.Weekly ||
    customer.frequency === Recurrence.Monthly
  ) {
    const serviceId = 0;
    const end = endOfMonth(startOfMonth);

    services.push({
      service_type_id: "GENERAL_ACCOUNTING",
      service_type: "GENERAL_ACCOUNTING",
      titles: { sv: "Löpande bokföring", en: "General accounting" },
      service_box_id: `S2-Y${year}-M${month}`,
      year,
      week: week,
      recurrence: Recurrence.Monthly,
      month,
      activities: activitiesMetadata
        .filter(
          (a) =>
            a.ServiceName === "Löpande redovisning" &&
            a.Frequency === "Monthly" &&
            a.ShowByDefault === "Yes"
        )
        .map((a, i) => {
          return {
            original_id: uuidv4(),
            activity_id: `${period + i}${a.ActivityNr}`,
            teams_conversation_weburl:
              getRandomBool() &&
              customer.apps.teams?.status === "active" &&
              CONVERSATION_WEB_URL,
            index: {
              service_idx: serviceId,
              group_idx: parseInt(a.CategoryNr),
              activity_idx: parseInt(`${period + i}${a.ActivityNr}`),
            },
            recurrence: Recurrence.Monthly,
            status: getRandomActivityStatus(),
            deadline: end,
            activity_type: a.Title,
            sorting_nr: parseInt(a.ActivityNr),
            group: {
              sorting_nr: parseInt(a.CategoryNr),
              group_idx: parseInt(a.CategoryNr),
              sv: a.CategoryName,
              en: a.CategoryNameENG,
            },
            titles: {
              sv: a.ActivityName,
              en: a.ActivityNameENG,
            },
            onetime: false,
          } as Partial<ActivityInstance>;
        }),
    });
  }

  return services;
}

export function generateGeneralAccountingService(
  customer: Customer,
  period: string,
  year: number,
  month: number,
  week: number,
  recurrence: Recurrence,
  completed: boolean = false
) {
  const end = endOfWeek(
    setWeek(new Date(), week, {
      locale: {
        code: "sv",
      },
      firstWeekContainsDate: 3,
    })
  );

  period += week || 0;

  const serviceType = `GENERAL_ACCOUNTING`;
  const serviceId = 0;

  return {
    service_type: serviceType,
    service_type_id: serviceType,
    titles: { sv: "Redovisning", en: "Accounting" },
    service_box_id: `S1-Y${year}-M${month}-W${week}`,
    year,
    month,
    week,
    recurrence,
    activities: activitiesMetadata
      .filter(
        (a) =>
          a.ServiceName === "Löpande redovisning v" &&
          a.Frequency === "Weekly" &&
          a.ShowByDefault === "Yes"
      )
      .map((a, i) => {
        return {
          completed_at: completed ? new Date() : undefined,
          original_id: uuidv4(),
          activity_id: `${period + i}${a.ActivityNr}`,
          index: {
            service_idx: serviceId,
            group_idx: parseInt(a.CategoryNr),
            activity_idx: parseInt(`${period + i}${a.ActivityNr}`),
          },
          teams_conversation_weburl:
            getRandomBool() &&
            customer.apps.teams?.status === "active" &&
            CONVERSATION_WEB_URL,
          recurrence,
          status: getRandomActivityStatus(),
          deadline: end,
          assigned_user: uuidv4(),
          activity_type: a.Title,
          sorting_nr: parseInt(a.ActivityNr),
          group: {
            sorting_nr: parseInt(a.CategoryNr),
            group_idx: parseInt(a.CategoryNr),
            sv: a.CategoryName,
            en: a.CategoryNameENG,
          },
          titles: {
            sv: a.ActivityName,
            en: a.ActivityNameENG,
          },
          onetime: false,
        } as Partial<ActivityInstance>;
      }),
  };
}
