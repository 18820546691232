import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "state";
import { deleteDeal } from "state/offer/offersThunks";
import { appendToastMessage } from "state/notifications";
import { useTranslation } from "hooks/use-translate";
import { getSourceOptions } from "helpers/OffersHelper";
import { Offer } from "models/offer/SavedOffers";
import { CustomerDeal } from "models/offer/CustomerDeal";
import { User } from "models/user";
import { DealCustomer } from "models/offer/Customer";
import Modal  from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import OfferItem from "./offerItem";
import DealItem from "./dealItem";
import { ActionsDropdown } from "./ActionsDropdown";

type Props = {
  currentUser: User;
  filteredDeals: CustomerDeal[];
  filteredAndSortedContracts: Offer[];
  customer?: DealCustomer;
  isExpandedView?: boolean;
};

export default function OffersTable({
  currentUser,
  filteredDeals,
  filteredAndSortedContracts,
  customer,
  isExpandedView = false,
}: Props) {
  const { translate } = useTranslation();
  const dispatch = useDispatch();

  const [isDeleteWarningOpen, setDeleteWarningOpen] = useState(false);
  const [dealToDelete, setDealToDelete] = useState<Partial<CustomerDeal>>();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const dealOptions = useSelector(
    (state: RootState) => state.sales.dealOptions.data
  );

  const deleteOffer = async () => {
    setLoadingDelete(true);
    if (dealToDelete && dealToDelete.id && customer?.org_number) {
      try {
        await dispatch(
          deleteDeal({
            dealId: dealToDelete.id,
            data: {
              ...dealToDelete,
              changed_by_username: currentUser.email,
              changed_date: new Date(),
              state: "deleted",
            },
            orgNum: customer.org_number,
          })
        );
        dispatch(appendToastMessage("DELETE_OFFER_SUCCESS", "success"));
      } catch (e) {
        dispatch(appendToastMessage("DELETE_OFFER_ERROR", "error"));
      } finally {
        setDeleteWarningOpen(false);
        setLoadingDelete(false);
      }
    }
  };

  return (
    <>
      <table className="table table-hover table-fs-14">
        <thead>
          <tr>
            <th>{translate("OFFERS.TABLE_HEADER.STATUS")}</th>
            <th>{translate("OFFER_ID")}</th>
            <th>{translate("DATE")}</th>
            <th>{translate("TIME")}</th>
            <th>{translate("CONSULTANT")}</th>
            <th>{translate("DOCUMENT")}</th>
            <th>{translate("COMMENT")}</th>
            <th>{translate("TOTAL_ESTIMATE")}</th>
            <th>{translate("CONTACT_SIGNER")}</th>
            <th>{translate("SIGNING_METHOD")}</th>
            <th>{translate("ACTIONS")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredDeals.length > 0 &&
            (isExpandedView ? filteredDeals : [filteredDeals[0]]).map(
              (deal) => (
                <tr
                  key={`dealItemForID-${deal.id}-${deal.created_date}`}
                  data-testid="deal-list"
                  data-deal-id={deal.id}
                  className={`${deal.state === "offer" && "draftOfferListRow"}`}
                >
                  <DealItem
                    deal={deal}
                    actionsElement={
                      <ActionsDropdown
                        setDeleteOffer={(shouldDelete: boolean) => {
                          setDeleteWarningOpen(shouldDelete);
                          setDealToDelete(deal);
                        }}
                        deal={deal}
                        sourceOptions={getSourceOptions(dealOptions)}
                      />
                    }
                  />
                </tr>
              )
            )}
          {filteredAndSortedContracts.length > 0 &&
            (isExpandedView
              ? filteredAndSortedContracts
              : [filteredAndSortedContracts[0]]
            ).map((offer: Offer) => (
              <tr
                key={`offerItemForID-${offer.offer_id}`}
                data-testid="offer-list"
                className={`${offer.state === "draft" && "draftOfferListRow"}`}
              >
                <OfferItem offer={offer} isReducedInfo={isExpandedView} />
              </tr>
            ))}
        </tbody>
      </table>
      <Modal
        isOpen={isDeleteWarningOpen}
        onDismiss={() => !loadingDelete && setDeleteWarningOpen(false)}
        size="small"
        header={<ModalHeader headerTitleText="DELETE_OFFER" />}
        footer={
          <ModalFooter
            onCancel={() => !loadingDelete && setDeleteWarningOpen(false)}
            onSave={() => deleteOffer()}
            labelSubmit="DELETE"
            isLoading={loadingDelete}
          />
        }
      >
        <div>
          <div className="d-flex px-md pb-lg">
            <span className="p-sm">{translate("DELETE_OFFER_QUESTION")}</span>
          </div>
        </div>
      </Modal>
    </>
  );
}
