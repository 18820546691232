import {
  Deal,
  DealContact,
  DealOption,
  DynamicPropertyOption,
} from "models/deals/deal";
import { Pipeline } from "models/deals/pipeline";
import { Product } from "models/deals/product";
import { StageConfiguration } from "views/deals/configuration/stages-config";

export const SET_DEAL_PROPERTIES = "deals/SET_DEAL_PROPERTIES";
export const SET_DEALS = "deals/SET_DEALS";
export const SET_DEALS_BY_ORG_NUMBER = "deals/SET_DEALS_BY_ORG_NUMBER";
export const REMOVE_DEAL = "deals/REMOVE_DEAL";
export const APPEND_DEAL = "deals/APPEND_DEAL";
export const SET_PIPELINES = "deals/SET_PIPELINES";
export const SET_PRODUCTS = "deals/SET_PRODUCTS";
export const UPDATE_DEAL = "deals/UPDATE_DEAL";
export const SET_LOADING_DEALS = "deals/SET_LOADING_DEALS";
export const SET_LOADING_PIPELINES = "deals/SET_LOADING_PIPELINES";
export const SET_LOADING_PRODUCTS = "deals/SET_LOADING_PRODUCTS";
export const SET_LOADING_DEAL_OPTIONS = "deals/SET_LOADING_DEAL_OPTIONS";
export const SET_LOADING_DEALS_BY_ORG_NUMBER =
  "deals/SET_LOADING_DEALS_BY_ORG_NUMBER";
export const SET_HAS_FAILED = "deals/SET_HAS_FAILED";
export const SET_DEALS_BY_ORG_NUMBER_HAS_FAILED =
  "deals/SET_DEALS_BY_ORG_NUMBER_HAS_FAILED";
export const UPDATE_DEAL_CONTACT = "deals/UPDATE_DEAL_CONTACT";
export const SET_STAGE_CONFIGURATION = "deals/SET_STAGE_CONFIGURATION";
export const SET_CURRENT_DEAL = "deals/SET_CURRENT_DEAL";
export const RESET_CURRENT_DEAL = "deals/RESET_CURRENT_DEAL";
export const SET_CUSTOMER_CONTACTS = "deals/SET_CUSTOMER_CONTACTS";
export const RESET_CUSTOMER_CONTACTS = "deals/RESET_CUSTOMER_CONTACTS";
export const SET_CUSTOMER_CONTACTS_LOADING =
  "deals/SET_CUSTOMER_CONTACTS_LOADING";
export const CREATE_NEW_CONTACT = "deals/CREATE_NEW_CONTACT";
export const UPDATE_CONTACT = "deals/UPDATE_CONTACT";
export const DELETE_CONTACT = "deals/DELETE_CONTACT";
export const SET_IS_NEW_DEAL_CUSTOMER = "deals/SET_IS_NEW_DEAL_CUSTOMER";
export const SET_DYNAMIC_DEAL_PROPERTIES = "deals/SET_DYNAMIC_DEAL_PROPERTIES";

export interface SetDealsAction {
  type: typeof SET_DEALS;
  payload: Deal[];
}

export interface SetDealsByOrgNumberAction {
  type: typeof SET_DEALS_BY_ORG_NUMBER;
  payload: Deal[];
}

export interface SetPipelinesAction {
  type: typeof SET_PIPELINES;
  payload: Pipeline[];
}

export interface SetDealPropertiesAction {
  type: typeof SET_DEAL_PROPERTIES;
  payload: DealOption[];
}

export interface SetStageConfigurationAction {
  type: typeof SET_STAGE_CONFIGURATION;
  payload: StageConfiguration[];
}

export interface SetProductsAction {
  type: typeof SET_PRODUCTS;
  payload: Product[];
}

export interface RemoveDealAction {
  type: typeof REMOVE_DEAL;
  payload: Deal;
}

export interface SetLoadingDealsAction {
  type: typeof SET_LOADING_DEALS;
  payload: boolean;
}

export interface SetLoadingPipelinesAction {
  type: typeof SET_LOADING_PIPELINES;
  payload: boolean;
}

export interface SetLoadingProductsAction {
  type: typeof SET_LOADING_PRODUCTS;
  payload: boolean;
}

export interface SetLoadingDealOptionsAction {
  type: typeof SET_LOADING_DEAL_OPTIONS;
  payload: boolean;
}

export interface SetLoadingDealsByOrgNumberAction {
  type: typeof SET_LOADING_DEALS_BY_ORG_NUMBER;
  payload: boolean;
}

export interface SetHasFailed {
  type: typeof SET_HAS_FAILED;
  payload: boolean;
}

export interface SetDealsByOrgNumberHasFailed {
  type: typeof SET_DEALS_BY_ORG_NUMBER_HAS_FAILED;
  payload: boolean;
}

export interface AppendDealAction {
  type: typeof APPEND_DEAL;
  payload: Deal;
}

export interface UpdateDealAction {
  type: typeof UPDATE_DEAL;
  payload: {
    update: Partial<Deal>;
    id: string;
  };
}

export interface UpdateDealContact {
  type: typeof UPDATE_DEAL_CONTACT;
  payload: {
    update: Partial<DealContact>;
    id: string;
  };
}

export interface SetCurrentDealAction {
  type: typeof SET_CURRENT_DEAL;
  payload: {
    deal: Partial<DealContact>;
  };
}

export interface ResetCurrentDealAction {
  type: typeof RESET_CURRENT_DEAL;
}

export interface SetCustomerContacts {
  type: typeof SET_CUSTOMER_CONTACTS;
  payload: DealContact[];
}

export interface ResetCustomerContacts {
  type: typeof RESET_CUSTOMER_CONTACTS;
}

export interface SetCustomerContactsLoading {
  type: typeof SET_CUSTOMER_CONTACTS_LOADING;
  payload: boolean;
}

export interface CreateNewContact {
  type: typeof CREATE_NEW_CONTACT;
  payload: DealContact;
}

export interface UpdateContact {
  type: typeof UPDATE_CONTACT;
  payload: Partial<DealContact>;
}

export interface DeleteContact {
  type: typeof DELETE_CONTACT;
  payload: {
    contactEmail: string;
  };
}

export interface SetIsNewDealCustomer {
  type: typeof SET_IS_NEW_DEAL_CUSTOMER;
  payload: boolean;
}

export interface SetDynamicDealPropertiesAction {
  type: typeof SET_DYNAMIC_DEAL_PROPERTIES;
  payload: {
    objectName: string;
    propertyName: string;
    properties: DynamicPropertyOption[];
  };
}

export type SalesAction =
  | SetDealsAction
  | SetDealsByOrgNumberAction
  | SetLoadingDealsAction
  | SetLoadingPipelinesAction
  | SetLoadingProductsAction
  | SetLoadingDealOptionsAction
  | SetLoadingDealsByOrgNumberAction
  | UpdateDealAction
  | SetPipelinesAction
  | RemoveDealAction
  | AppendDealAction
  | SetProductsAction
  | SetDealPropertiesAction
  | SetStageConfigurationAction
  | SetHasFailed
  | SetDealsByOrgNumberHasFailed
  | UpdateDealContact
  | SetHasFailed
  | SetCurrentDealAction
  | ResetCurrentDealAction
  | SetCustomerContacts
  | ResetCustomerContacts
  | SetCustomerContactsLoading
  | CreateNewContact
  | UpdateContact
  | DeleteContact
  | SetIsNewDealCustomer
  | SetDynamicDealPropertiesAction;
