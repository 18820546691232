import { useSelector } from "react-redux";

import AppConfig from "app-config";
import { CustomerDeal } from "models/offer/CustomerDeal";
import { useAuth } from "auth/use-auth";
import { DealContact } from "models/offer/DealContact";
import { Service, ServiceCategory } from "models/offer/ServiceLine";
import { RootState } from "state";
import { SERVICE_GROUP_CURRENT_ACCOUNTING } from "views/offer/wizard/CurrentAccounting";
import { SERVICE_CATEGORY_INCOME_TAX } from "views/offer/wizard/consts/offer-contst";
import { isValidEmail } from "./is-valid-email";
import { isValidSwedishSSN } from "./is-valid-ssn";

export const useServiceMatrix = () => {
  const { user } = useAuth();

  const {
    currentOffer: { data: currentOffer },
  } = useSelector((state: RootState) => state.offers);

  const GetAllCategoriesFlattened = (offer?: CustomerDeal) => {
    const serviceAreas = (offer ?? currentOffer)?.service_areas;
    if (!serviceAreas) {
      return [];
    }

    const serviceLines = serviceAreas.flatMap((sa) => sa.service_lines);

    const serviceGroups = serviceLines.flatMap((sl) => sl.service_groups);

    const serviceCategories = serviceGroups.flatMap(
      (sg) => sg.service_categories
    );

    return serviceCategories;
  };

  const GetAllServiceGroupsFlattened = (offer?: CustomerDeal) => {
    const serviceAreas = (offer ?? currentOffer)?.service_areas;
    if (!serviceAreas) {
      return [];
    }

    const serviceLines = serviceAreas.flatMap((sa) => sa.service_lines);

    const serviceGroups = serviceLines.flatMap((sl) => sl.service_groups);

    return serviceGroups;
  };

  const GetAllServiceLinesFlattened = (offer?: CustomerDeal) => {
    const serviceAreas = (offer ?? currentOffer)?.service_areas;
    if (!serviceAreas) {
      return [];
    }

    const serviceLines = serviceAreas.flatMap((sa) => sa.service_lines);

    return serviceLines;
  };

  const GetTotalIndexedPrice = (offer?: CustomerDeal) => {
    const categories = GetAllCategoriesFlattened(offer);
    const indexedPrices = categories.flatMap((sc) => sc.indexed_prices);
    // the biggest year is always the current pricing year
    // (ex. if currently its 2023 but there are indexed prices with 2024, then 2024 is the current pricing year)
    if (indexedPrices.length > 0) {
      const currentYear = indexedPrices.reduce((a, b) =>
        a.pricing_year > b.pricing_year ? a : b
      ).pricing_year;
      const currentIndexedPrices = indexedPrices.filter(
        (indexPrice) => indexPrice.pricing_year === currentYear
      );
      return currentIndexedPrices.reduce(
        // eslint-disable-next-line camelcase
        (n, { index_price }) => n + index_price,
        0
      );
    }
    return 0;
  };

  const GetCurrentPricingYear = (offer?: CustomerDeal) => {
    const categories = GetAllCategoriesFlattened(offer);
    const indexedPrices = categories.flatMap((sc) => sc.indexed_prices);
    // the biggest year is always the current pricing year
    // (ex. if currently its 2023 but there are indexed prices with 2024, then 2024 is the current pricing year)
    if (indexedPrices.length > 0) {
      return indexedPrices.reduce((a, b) =>
        a.pricing_year > b.pricing_year ? a : b
      ).pricing_year;
    }
    return "";
  };

  type ServicesGroupedByHeaderType = { header: string; services: Service[] };
  const GetServicesGroupedByHeader = ({
    offer,
    groupByNameToo = false,
  }: {
    offer?: CustomerDeal;
    groupByNameToo?: boolean;
  }): ServicesGroupedByHeaderType[] => {
    const categories = GetAllCategoriesFlattened(offer);

    const result: ServicesGroupedByHeaderType[] = [];
    categories.forEach((serviceCategory) => {
      let tempServices: Service[] = [...serviceCategory.services];
      //
      // Group all services by name (because same service can be in different service categories)
      //
      if (groupByNameToo) {
        const tempServicesMap = new Map<string, Service>();
        serviceCategory.services.forEach((service) => {
          const prevService = tempServicesMap.get(service.name);
          const newService = { ...service };

          if (prevService) {
            tempServicesMap.set(service.name, {
              ...prevService,
              units: prevService.units + newService.units,
            });
          } else {
            tempServicesMap.set(service.name, newService);
          }
        });

        tempServices = Array.from(tempServicesMap.values());
      }

      //
      // Group all services by header
      //
      const serviceHeaders = new Map<string, Service[]>();
      tempServices.forEach((service) => {
        const prevServices = serviceHeaders.get(service.header);
        const newService = { ...service };

        if (prevServices) {
          serviceHeaders.set(service.header, [...prevServices, newService]);
        } else {
          serviceHeaders.set(service.header, [newService]);
        }
      });

      // Map serviceHeaders (type Map) to result (type array)
      serviceHeaders.forEach((services, header) => {
        result.push({ header, services });
      });
    });

    return result;
  };

  const GetFinalPrice = (
    serviceCategory?: ServiceCategory,
    offer?: CustomerDeal
  ) => {
    if (serviceCategory) {
      return serviceCategory.final_price ?? 0;
    }

    const categories = GetAllCategoriesFlattened(offer);
    return categories?.reduce((acc, sg) => acc + sg.final_price, 0) ?? 0;
  };

  const GetCalculatedPrice = (serviceCategory?: ServiceCategory) => {
    if (serviceCategory) {
      return serviceCategory?.calculated_price ?? 0;
    }

    const categories = GetAllCategoriesFlattened();
    return categories.reduce((acc, sg) => acc + sg.calculated_price, 0) ?? 0;
  };

  const GetTotalDiscount = (serviceCategory?: ServiceCategory) => {
    if (serviceCategory) {
      return serviceCategory?.discount ?? 0;
    }

    const categories = GetAllCategoriesFlattened();
    return categories?.reduce((acc, sg) => acc + sg.discount, 0) ?? 0;
  };

  const GetTotalSuggestedPrice = (serviceCategory?: ServiceCategory) => {
    if (serviceCategory) {
      return (
        serviceCategory.calculated_price - serviceCategory.price_adjustment ?? 0
      );
    }

    const categories = GetAllCategoriesFlattened();
    return (
      categories?.reduce(
        (acc, sg) => acc + sg.calculated_price - sg.price_adjustment,
        0
      ) ?? 0
    );
  };

  const GetTotalAdjustments = (serviceCategory?: ServiceCategory) => {
    if (serviceCategory) {
      return serviceCategory.price_adjustment ?? 0;
    }

    const categories = GetAllCategoriesFlattened();
    return categories?.reduce((acc, sg) => acc + sg.price_adjustment, 0) ?? 0;
  };

  const GetCategoriesWithMissingPM = () => {
    const categories = GetAllCategoriesFlattened();
    return categories.filter((sc) => !sc.project_manager);
  };

  const GetCategoriesWithMissingYear = () => {
    const categories = GetAllCategoriesFlattened();

    return categories.filter((sc) => {
      // This serviceCategory doesn't have year end specified
      if (
        sc.name === SERVICE_CATEGORY_INCOME_TAX ||
        sc.name === SERVICE_GROUP_CURRENT_ACCOUNTING
      ) {
        return false;
      }

      return !sc.year_end_year;
    });
  };

  const GetRolesThatCanApproveOffer = () =>
    AppConfig.OFFER.COMPETENT_SYSTEM_ROLES.filter(
      (role) => role.maxApprovalAmount >= GetFinalPrice()
    ).map((r) => r.systemRole);

  const IsUserAuthorizedToApprove = () => {
    const rolesThatCanApproveThisOffer = GetRolesThatCanApproveOffer();

    const result =
      user &&
      user.systemRoles &&
      user.systemRoles.some((userRole) =>
        rolesThatCanApproveThisOffer.includes(userRole)
      );

    return result;
  };

  const GetSigner = () => {
    return currentOffer?.contacts.find(
      (tempDealContact) => tempDealContact.is_signer
    );
  };

  const IsSignerValid = (dealContact?: DealContact) => {
    const signer =
      dealContact ||
      currentOffer?.contacts.find(
        (tempDealContact) => tempDealContact.is_signer
      );

    if (!signer) {
      return false;
    }

    if (!isValidSwedishSSN(signer.contact.social_security_number)) {
      return false;
    }

    if (!isValidEmail(signer.contact.email)) {
      return false;
    }

    return true;
  };

  /**
   *
   * @param options.serviceCategory If you omit options or option.serviceCategory then this will check the all serviceCategories in the offer
   * @param options.checkIfApprovedByEmail Checks if a user has approved the offer
   */
  const CanSendForSigning = (options?: {
    serviceCategory?: ServiceCategory;
    checkIfUserHasApproved?: boolean;
  }) => {
    const serviceCategory = options?.serviceCategory;
    const checkIfUserHasApproved = options?.checkIfUserHasApproved ?? true;

    if (GetFinalPrice(serviceCategory) === 0) {
      return false;
    }

    if (IsUserAuthorizedToApprove()) {
      return true;
    }

    if (checkIfUserHasApproved && serviceCategory?.approved_by_email) {
      return true;
    }

    if (
      GetFinalPrice(serviceCategory) <=
        AppConfig.OFFER.AUTO_APPROVAL_AMOUNT_LESS_THAN &&
      GetTotalDiscount(serviceCategory) === 0
    ) {
      return true;
    }

    if (GetTotalDiscount(serviceCategory) > 0) {
      return false;
    }

    return false;
  };

  const CanApproveOffer = (serviceCategory: ServiceCategory) =>
    CanSendForSigning({ serviceCategory, checkIfUserHasApproved: false });

  const CanSendCategoryForSigning = (serviceCategory: ServiceCategory) =>
    CanSendForSigning({ serviceCategory });

  const CanSendOfferForSigning = () => CanSendForSigning();

  return {
    GetAllCategoriesFlattened,
    GetSigner,
    IsSignerValid,
    CanSendCategoryForSigning,
    CanSendOfferForSigning,
    CanApproveOffer,
    GetCalculatedPrice,
    GetTotalSuggestedPrice,
    GetTotalAdjustments,
    GetFinalPrice,
    GetTotalDiscount,
    GetCategoriesWithMissingPM,
    GetCategoriesWithMissingYear,
    GetServicesGroupedByHeader,
    GetTotalIndexedPrice,
    GetCurrentPricingYear,
    GetAllServiceGroupsFlattened,
    GetAllServiceLinesFlattened,
  };
};
