import { Add20Regular } from "@fluentui/react-icons";

import { renderIcon } from "libs/render-icon";

export function TextLink({
  children,
  url,
  icon = <Add20Regular />,
}: {
  children: any;
  url: string;
  icon?: JSX.Element;
}) {
  return (
    <a
      className="d-flex text-decoration-none align-items-center"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {icon && renderIcon(icon)}
      <div className="pl-sm">{children}</div>
    </a>
  );
}
