import { ReactElement } from "react";
import {
  ArrowHookUpRight16Regular,
  ArrowRight16Regular,
  Clock16Regular,
  Dismiss16Regular,
  DocumentPdf16Regular,
  DocumentSignature16Regular,
} from "@fluentui/react-icons";

import { normalizeOrgNrAndSSN } from "libs/number-format";
import TooltipV9 from "components/tooltip/TooltipV9";
import {
  contractState,
  EnvelopState,
  Offer,
  OfferLegacy,
} from "models/offer/SavedOffers";
import { IVerifiedBody } from "state/offer/offersThunks";
import "./offers.scss";

interface Props {
  offer: Offer;
  isReducedInfo?: boolean;
}

export default function OfferItem({
  offer,
  isReducedInfo = false,
}: Props): ReactElement {
  const offerContent: OfferLegacy = JSON.parse(offer.content);

  const dateModified = new Date(offer.modified).getTime();
  const dateModifiedString = new Date(offer.modified || "").toLocaleString(
    "sv-SE"
  );
  const multipleOffers = new Date("2020-11-05").getTime() < dateModified;
  const offerdescription = `${
    offerContent.state === "offer" ? "Utkast" : "Offert"
  }`;

  const hasFinancial = offerContent.financial.selected;
  const hasBookkeeping = offerContent.bookkeeping.selected;
  const hasSalary = offerContent.salary.selected;
  const hasAdditionalServices = offerContent.additionalservices.selected;

  function mapEnvelopeState(envelopeState: EnvelopState) {
    const signer = offerContent.contacts[0];
    const tooltipContent = !!signer.mailaddress
      ? signer.mailaddress
      : "E-post hittades inte";

    switch (envelopeState) {
      case contractState.sent:
        return (
          <TooltipV9 content={tooltipContent} notTranslatable>
            <div className="d-flex align-items-center">
              <ArrowRight16Regular className="text-color-blue" />
              <span>Skickad</span>
            </div>
          </TooltipV9>
        );
      case contractState.signed:
        return (
          <TooltipV9 content={tooltipContent} notTranslatable>
            <div className="d-flex align-items-center">
              <DocumentSignature16Regular className="text-color-blue" />
              <span className="pr-md text-color-green-light">Signerad</span>
            </div>
          </TooltipV9>
        );
      case contractState.rejected:
        return (
          <TooltipV9 content={tooltipContent} notTranslatable>
            <div className="d-flex align-items-center">
              <Dismiss16Regular className="text-color-red" />
              <span className="pr-md text-color-red">Avvisad</span>
            </div>
          </TooltipV9>
        );
      case contractState.aborted:
        return (
          <TooltipV9 content={tooltipContent} notTranslatable>
            <div className="d-flex align-items-center">
              <Dismiss16Regular className="text-color-red" />
              <span className="pr-md text-color-red">Avbruten</span>
            </div>
          </TooltipV9>
        );
      case contractState.expired:
        return (
          <TooltipV9 content={tooltipContent} notTranslatable>
            <div className="d-flex align-items-center">
              <Clock16Regular className="text-color-red" />
              <span className="pr-md text-color-red">Förfallen</span>
            </div>
          </TooltipV9>
        );
      default:
        return " ";
    }
  }

  function generateOfferPostLink(offer: Offer, serviceArea: string) {
    const postForSigningHandler = () => {
      if (!offerContent.customer) {
        throw new Error("Missing customer on offer");
      }

      if (!offerContent.customer.registrationnumber) {
        throw new Error("Missing customer organization number");
      }

      if (offerContent.id) {
        postForSigning(
          normalizeOrgNrAndSSN(offerContent.customer.registrationnumber),
          offerContent.id,
          serviceArea
        );
      }
    };

    if (!offerContent.customer?.registrationnumber) {
      return <>Missing org number</>;
    }

    const signer =
      offerContent?.contacts &&
      offerContent.contacts.length > 0 &&
      offerContent.contacts[0];
    if (!signer) {
      return <>Missing signer</>;
    }

    if (offer.envelopes) {
      const envelopeState = offer.envelopes.filter(
        (e) => e.service === serviceArea
      );
      if (envelopeState.length > 0) {
        return mapEnvelopeState(envelopeState[0].state);
      }
    }

    return (
      <div className="d-flex align-items-center">
        <ArrowHookUpRight16Regular className="text-color-purple" />
        <span
          className="pr-3 cursor-pointer"
          title={`Skicka för signering ${
            signer.mailaddress ? signer.mailaddress : "E-post hittades inte"
          }`}
          onClick={(_) => postForSigningHandler()}
        >
          Skicka
        </span>
      </div>
    );
  }

  const generateOfferPdfname = function (
    prefix: string,
    orgno: string,
    companyName: string,
    id: string,
    servicearea: string
  ) {
    const servicaArea = servicearea ? `-${servicearea}` : "";
    return `${prefix} avseende ${normalizeOrgNrAndSSN(
      orgno
    )} ${companyName} (${id}${servicaArea}).pdf`;
  };

  function postForSigning(
    registrationnumber: string,
    id: string,
    serviceArea: string
  ) {
    if (!offerContent.customer) {
      throw new Error("Missing customer on offer");
    }

    const signer = offerContent.contacts[0];
    if (!signer) {
      throw new Error("Missing signer on offer");
    }

    const verifiedBody: IVerifiedBody = {
      envelope_name: `Uppdragsavtal ${offerContent.customer.name} ${offerContent.id}`,
      org_number: registrationnumber,
      company_name: offerContent.customer.name,
      offer_name: generateOfferPdfname(
        "Offert",
        registrationnumber,
        offerContent.customer.name,
        id,
        serviceArea
      ),
      consultant: {
        email: offerContent.employee.email || "N/A",
        first_name: "",
        last_name: "",
        shall_sign: false,
      },
      customer: {
        email: signer.mailaddress || "N/A",
        first_name: signer.firstname,
        last_name: signer.lastname,
        ssn: signer.socialsecuritynumber,
        signing_method: signer.signingmethod.value,
      },
    };

    if (signer.mobilephone) {
      verifiedBody.customer.phone_number = signer.mobilephone;
    }
  }

  return (
    <>
      <td>{offerdescription}</td>
      <td>{dateModifiedString.slice(0, 10)}</td>
      <td>{dateModifiedString.slice(-8)}</td>
      <td>{`${offerContent?.employee.firstname ?? " "} ${
        offerContent?.employee.lastname ?? " "
      }`}</td>
      <td>
        {offer.state !== "draft" && (
          <>
            <div
              title="Download of offers created in POG is not supported"
              className="d-flex align-items-center"
            >
              <DocumentPdf16Regular className="mr-sm" />
              <span>Sammanställning</span>
            </div>
            {!isReducedInfo && (
              <div
                title="Download of offers created in POG is not supported"
                className="d-flex align-items-center"
              >
                <DocumentPdf16Regular className="mr-sm" />
                <span>Offert</span>
              </div>
            )}
          </>
        )}
      </td>
      <td>
        {offer.state !== "draft" && multipleOffers && (
          <>
            {!isReducedInfo && <div>&nbsp;</div>}
            {isReducedInfo ? (
              <>Mixed</>
            ) : (
              <>
                {(hasBookkeeping || hasFinancial) && (
                  <div>{generateOfferPostLink(offer, "ek")}</div>
                )}
                {hasSalary && <div>{generateOfferPostLink(offer, "lon")}</div>}
                {hasAdditionalServices && (
                  <div>{generateOfferPostLink(offer, "ar")}</div>
                )}
              </>
            )}
          </>
        )}
      </td>
    </>
  );
}
