import { Recurrence } from "../../../models/activities/activity";
import {
  Customer,
  CustomerApplications,
  SieFile,
} from "../../../models/customer";
import { randomCustomerDataAndStatuses } from "../../generators/generator";

type SearchCustomer = Customer & { search: boolean };

const sieFiles = [
  {
    bucket: "bucket1",
    key: "5002011699/fortnox/20220101-20221231.se",
    last_modified: "02.02.2022 12:22",
    period: "",
    source: "capego",
    isLoading: false,
    justPrioritized: false,
  } as SieFile,
  {
    bucket: "bucket2",
    key: "5002011699/fortnox/20220101-20221231.se",
    last_modified: "02.02.2022 16:34",
    period: "",
    source: "capego2",
    isLoading: false,
    justPrioritized: false,
  } as SieFile,
];

const customers: Partial<SearchCustomer>[] = [
  {
    name: "X Errors",
    company_registration_number: "5500000000 ERROR",
    customer_number: "999000 ERROR",
    frequency: Recurrence.Weekly,
    acceptedTerms: "someone;MK;2021-10-14",
    companyForm: "ab",
    sieFiles: sieFiles,
    apps: {
      fortnox: {
        status: "active",
        created: new Date(),
        last_updated: new Date(),
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
  {
    name: "Weekly AB",
    company_registration_number: "5500000001",
    customer_number: "999001",
    frequency: Recurrence.Weekly,
    acceptedTerms: "someone;MK;2021-10-14",
    companyForm: "ab",
    sieFiles: sieFiles,
    apps: {
      fortnox: {
        status: "active",
        created: new Date(),
        last_updated: new Date(),
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
  {
    name: "Monthly AB",
    company_registration_number: "5500000002",
    customer_number: "999002",
    frequency: Recurrence.Monthly,
    acceptedTerms: "someone;MK;2021-10-14",
    companyForm: "ab",
    sieFiles: sieFiles,
    apps: {
      fortnox: {
        status: "active",
        created: new Date(),
        last_updated: new Date(),
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
  {
    name: "Quarterly AB",
    company_registration_number: "5500000003",
    customer_number: "999003",
    frequency: Recurrence.Quarterly,
    acceptedTerms: "someone;MK;2021-10-14",
    companyForm: "ab",
    sieFiles: sieFiles,
    apps: {
      fortnox: {
        status: "active",
        created: new Date(),
        last_updated: new Date(),
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
  {
    name: "Yearly AB",
    company_registration_number: "5500000004",
    customer_number: "999004",
    frequency: Recurrence.Yearly,
    acceptedTerms: "someone;MK;2021-10-14",
    companyForm: "ab",
    sieFiles: sieFiles,
    apps: {
      fortnox: {
        status: "active",
        created: new Date(),
        last_updated: new Date(),
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
  {
    name: "Not Set Up In CB AB",
    company_registration_number: "5500000005",
    customer_number: "999005",
    frequency: undefined,
    acceptedTerms: "someone;MK;2021-10-14",
    companyForm: "ab",
    sieFiles: sieFiles,
    apps: {
      fortnox: {
        status: "active",
        created: new Date(),
        last_updated: new Date(),
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
  {
    name: "Missing Activities AB",
    company_registration_number: "5500000006",
    customer_number: "999006",
    frequency: Recurrence.Monthly,
    acceptedTerms: "someone;MK;2021-10-14",
    fiscalYearMonthEnd: "05",
    fiscalYearDayEnd: "31",
    fiscalYearMonthStart: "06",
    fiscalYearDayStart: "01",
    companyForm: "ab",
    sieFiles: sieFiles,
    apps: {
      fortnox: {
        status: "active",
        created: new Date(),
        last_updated: new Date(),
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
  {
    isFromSearch: true,
    name: "Search Result 1 AB",
    company_registration_number: "5500000007",
    customer_number: "999007",
    frequency: Recurrence.Monthly,
    acceptedTerms: "someone;MK;2021-10-14",
    companyForm: "ab",
    sieFiles: sieFiles,
    apps: {
      fortnox: {
        status: "active",
        created: new Date(),
        last_updated: new Date(),
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
  {
    isFromSearch: true,
    name: "Search Result 2 AB",
    company_registration_number: "5500000008",
    customer_number: "999008",
    frequency: Recurrence.Monthly,
    acceptedTerms: "someone;MK;2021-10-14",
    companyForm: "ab",
    sieFiles: sieFiles,
    apps: {
      fortnox: {
        status: "active",
        created: new Date(),
        last_updated: new Date(),
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
  {
    name: "Not Set Up In Fortnox AB",
    company_registration_number: "5500000009",
    customer_number: "999009",
    frequency: undefined,
    acceptedTerms: "someone;MK;2021-10-14",
    companyForm: "ab",
    sieFiles: sieFiles,
    apps: {
      fortnox: {
        status: "nonexistent",
        created: new Date(),
        last_updated: new Date(),
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
  {
    name: "Not Accepted Terms AB",
    company_registration_number: "5500000010",
    customer_number: "999010",
    frequency: Recurrence.Monthly,
    acceptedTerms: "",
    companyForm: "ab",
    sieFiles: sieFiles,
    apps: {
      fortnox: {
        status: "active",
        created: new Date(),
        last_updated: new Date(),
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
  {
    name: "Missing Fiscal Year",
    company_registration_number: "5500000011",
    customer_number: "999011",
    frequency: Recurrence.Monthly,
    acceptedTerms: "someone;MK;2021-10-14",
    companyForm: "ab",
    fiscalYearDayStart: undefined,
    fiscalYearDayEnd: undefined,
    fiscalYearMonthStart: undefined,
    fiscalYearMonthEnd: undefined,
    apps: {
      fortnox: {
        status: "active",
        created: new Date(),
        last_updated: new Date(),
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
  {
    name: "Missing Team AB",
    company_registration_number: "5500000012",
    customer_number: "999012",
    frequency: Recurrence.Monthly,
    acceptedTerms: "someone;MK;2021-10-14",
    companyForm: "ab",
    apps: {
      fortnox: {
        status: "active",
        created: new Date(),
        last_updated: new Date(),
      },
      teams: {
        status: "nonexistent",
        created: undefined,
        last_updated: undefined,
      },
    } as Partial<CustomerApplications>,
  } as Partial<Customer>,
];

const initialCustomersWithData = randomCustomerDataAndStatuses(customers);

export { initialCustomersWithData };
