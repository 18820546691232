import { ArrowUpRight16Regular } from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@fluentui/react-components";

import { Button } from "components/button";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import { YearlyEvaluationKYCFormContentReadonly } from "./YearlyEvaluationKYCFormContentReadonly";

type YearlyEvaluationKYCFormLatestProps = {
  customerId: string;
};

export default function YearlyEvaluationKYCFormLatest({
  customerId,
}: YearlyEvaluationKYCFormLatestProps) {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const kycBatchData = useSelector(
    (state: RootState) => state.settings.formKYCAnswersBatch.data
  );

  const loadingStatus = useSelector(
    (state: RootState) => state.settings.formKYCAnswersBatch.status
  );

  return (
    <div>
      {loadingStatus === LoadingStatusEnum.PENDING && (
        <Spinner size={SpinnerSize.Tiny} />
      )}

      {kycBatchData.length > 0 ? (
        <div>
          <YearlyEvaluationKYCFormContentReadonly
            isLatest
            kyc={kycBatchData[0]}
          />
          <Col md={12}>
            <Row className="mt-md">
              <div className="d-flex align-items-end mb-xs px-0">
                <Button
                  variant="outline-outline"
                  className="justify-content-start primary-colored-text"
                  onClick={() =>
                    navigate(
                      AppRouteHelper.getKYCYearlyEvaluationsPage(customerId)
                    )
                  }
                >
                  <ArrowUpRight16Regular />
                  <span className="d-inline mx-sm fw-bold primary">
                    {translate("KYC_NEW_PAGE")}
                  </span>
                </Button>
              </div>
            </Row>
          </Col>
        </div>
      ) : (
        <div className="p-lg cs-content">{translate("NO_KYC")}</div>
      )}
    </div>
  );
}
