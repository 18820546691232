import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { ArrowUploadRegular } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import "./DropFileArea.scss";

type DropFileAreaProps = {
  onFileUploaded: (file: File) => void;
  onError?: () => void;
  disabled?: boolean;
};

export function DropFileArea({
  onFileUploaded,
  onError,
  disabled = false,
}: DropFileAreaProps) {
  const { translate } = useTranslation();

  const onDrop = useCallback(
    (files: File[]) => {
      if (files && files.length > 0 && !disabled) {
        onFileUploaded(files[0]);
      }
    },
    [onFileUploaded, disabled]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onError,
  });

  return (
    <div
      {...getRootProps()}
      className={`dropFileArea-container${disabled ? " disabled" : ""}`}
    >
      <input {...getInputProps()} />
      <ArrowUploadRegular className="fem-7 text-color-yellow" />

      <p className={disabled ? "text-grayed" : ""}>
        {translate(
          isDragActive ? "COMPLETION_DROP_FILES_HOVER" : "COMPLETION_DROP_FILES"
        )}
      </p>
    </div>
  );
}
