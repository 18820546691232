import { plainToClass } from "class-transformer";
import { addDays, subDays } from "date-fns";
import { Customer } from "../../models/customer";

const cities = [
  "Täby",
  "Ånge",
  "Hjo",
  "Gustavsberg",
  "Glommen",
  "Fränsta",
  "Gästrike-Hammarby",
  "Charlottenberg",
  "Beddingestrand",
  "Alnö",
  "Lur",
  "Skånes Fagerhult",
  "Skummeslövsstrand",
  "Östra Frölunda",
];

const streets = [
  "Hörnåkersvägen",
  "Järnvägsgatan",
  "Björkvägen",
  "Ringvägen",
  "Skolgatan",
  "Skogsvägen",
  "Nygatan",
  "Granvägen",
  "Idrottsvägen",
  "Storgatan",
  "Kyrkvägen",
  "Industrigatan",
  "Parkvägen",
  "Strandvägen",
  "Skolvägen",
  "Trädgårdsgatan",
];

const firstNames = [
  "Anna",
  "Eva",
  "Maria",
  "Karin",
  "Kristina",
  "Lena",
  "Sara",
  "Kerstin",
  "Emma",
  "Ingrid",
  "Lars",
  "Mikael",
  "Anders",
  "Johan",
  "Erik",
  "Per",
  "Karl",
  "Peter",
  "Thomas",
  "Jan",
];

const lastNames = [
  "Andersson",
  "Johansson",
  "Karlsson",
  "Nilsson",
  "Eriksson",
  "Larsson",
  "Olsson",
  "Persson",
  "Svensson",
  "Gustafsson",
  "Lindgren",
  "Axelsson",
  "Berg",
  "Bergström",
  "Lundberg",
  "Lind",
  "Lundgren",
  "Lundqvist",
  "Mattsson",
];

export function getDigits(digits: number) {
  return new Array(digits)
    .fill(0)
    .map(() => Math.floor(1 + Math.random() * 9))
    .join("");
}

function getRandomOrgNumber() {
  return `55${getDigits(8)}`;
}

export function getRandomDate(from: Date, to: Date) {
  const fromTime = from.getTime();
  const toTime = to.getTime();
  return new Date(fromTime + Math.random() * (toTime - fromTime));
}

export function getRandomPastDate() {
  const now = new Date();
  return subDays(now, Math.floor(Math.random() * 365));
}

export function getRandomFutureDate() {
  const now = new Date();
  return addDays(now, Math.floor(Math.random() * 365));
}

function getRandomStreet() {
  const street = streets[Math.floor(Math.random() * streets.length)];
  const randomNumber = 110 - Math.floor(Math.random() * 110);
  return `${street} ${randomNumber}`;
}

function getRandomCity() {
  return cities[Math.floor(Math.random() * cities.length)];
}

function getRandomEmail() {
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const lastName = lastNames[Math.floor(Math.random() * firstNames.length)];
  return `${firstName}.${lastName}@email.com`.toLowerCase();
}

export function getRandomContact() {
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
  return {
    name: `${firstName} ${lastName}`,
    email: `${firstName}.${lastName}@email.com`.toLowerCase(),
    firstName,
    lastName,
  };
}

export function getEmployees() {
  return [
    {
      id: getDigits(6),
      firstName: "Employee 1",
      lastName: "Lastname",
      email: "employee.1@ludvig.se",
    },
    {
      id: getDigits(6),
      firstName: "Employee 2",
      lastName: "Lastname",
      email: "employee.2@ludvig.se",
    },
  ];
}

function getRandomStatus() {
  const statuses = ["active", "active", "active", "inactive", "nonexistent"];
  return statuses[Math.floor(Math.random() * statuses.length)];
}

export function getRandomBool() {
  return Math.random() > 0.5;
}

export function randomCustomerDataAndStatuses(customers: Partial<Customer>[]) {
  const wolters_kluwer = true;
  const fortnox = true;
  const mittkontor = true;
  const datalagret = true;
  const ludvig_retriever = true;
  const ludvig_collector = true;
  const contact = getRandomContact();

  return customers.map((c) => {
    const datalagret_status = datalagret ? getRandomStatus() : "offline";
    const customer_key = datalagret_status === "active" ? getDigits(5) : 0;

    return {
      isFromSearch: (c as any).isFromSearch,
      customer: plainToClass(Customer, {
        name: c.name,
        settings: {
          ludvig_service_ara: false,
          ludvig_service_year_end_start: true,
          ludvig_service_year_end_finish: true,
          ludvig_service_ek_project: undefined,
        },
        ludvig_service: [
          {
            NeedsFeedback: true,
            ServiceType: "YEAR_END",
          },
        ],
        customer_key,
        customer_number: c.customer_number,
        company_registration_number:
          c.company_registration_number || getRandomOrgNumber(),
        address1: getRandomStreet(),
        address2: "",
        salesperson_name: contact.name,
        salesperson_email: getRandomEmail(),
        projects: getEmployees().map((e) => ({
          projectManagerNumber: e.id,
          projectManagerEmail: e.email,
          projectType: "ek",
          projectNumber: getDigits(5),
        })),
        city: getRandomCity(),
        zip: getDigits(5),
        acceptedTerms: c.acceptedTerms,
        apps: {
          teams: {
            status: getRandomStatus(),
            updated: getRandomPastDate(),
          },
          wolters_kluwer: {
            status: wolters_kluwer ? getRandomStatus() : "offline",
            updated: getRandomPastDate(),
          },
          fortnox: {
            status: fortnox ? getRandomStatus() : "offline",
            updated: getRandomPastDate(),
          },
          mittkontor: {
            status: mittkontor ? getRandomStatus() : "offline",
            updated: getRandomPastDate(),
          },
          datalagret: {
            status: datalagret_status,
            updated: getRandomPastDate(),
          },
          ludvig_retriever: {
            status: ludvig_retriever ? getRandomStatus() : "offline",
            updated: getRandomPastDate(),
          },
          ludvig_collector: {
            status: ludvig_collector ? getRandomStatus() : "offline",
            updated: getRandomPastDate(),
          },
          ...c.apps,
        },
      }),
    };
  });
}

export function getRandomAssignments(customer: Customer) {
  const projects = new Array(1 + Math.floor(Math.random() * 3)).fill(0);

  return projects.map(() => {
    const random_project_number = 8000000 + Math.floor(83584 * Math.random());

    return {
      project_number: random_project_number.toString(),
      project_type: "me",
      starting_date: getRandomPastDate(),
      project_manager_number: "45319",
      last_invoice_date: "",
      created_date: getRandomPastDate(),
      cost_center: "1955",
      closed: !!Math.round(Math.random() * 5),
      cost_center_locationname: "Stockholm",
      version_number: 6,
      changed_date: getRandomPastDate(),
      actual_ending_date: getRandomFutureDate(),
    };
  });
}
