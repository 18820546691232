import { Providers } from "@microsoft/mgt-element";
import { authentication } from "@microsoft/teams-js";
import jwtDecode from "jwt-decode";
import { isInTeams } from "../libs/teams";
import AppConfig from "../app-config";

type Decoded = {
  [propName: string]: any;
};

export interface IAuthMethod {
  getStoredAccessToken(): Promise<string>;
  getRolesFromToken(token: string): string[];
}

const RESOURCE_URI = `${AppConfig.CLIENT_RESOURCE_URI}`;
const scopes = [`${RESOURCE_URI}/access_as_user`];

const authMethod: IAuthMethod = {
  // generates a `token` to be used to request MittLudvig Ludvig BE resources
  async getStoredAccessToken(): Promise<string> {
    // get sso token from teams, you don't need to request another token
    if (isInTeams()) {
      return authentication.getAuthToken();
    }

    // if you are in a browser fall back to the regular get access token from the provider
    return Providers.globalProvider.getAccessTokenForScopes(...scopes);
  },

  getRolesFromToken(token: string): string[] {
    try {
      return jwtDecode<Decoded>(token)?.roles;
    } catch (err) {
      console.log("AccessToken, not valid token.", err);
    }
    return [];
  },
};

// Middleware function to acquire token and fetch data
export async function fetchGraphDataWithToken(
  urlPath: string,
  searchParams?: URLSearchParams
) {
  const fullURL = `https://graph.microsoft.com/v1.0/${urlPath}${
    searchParams ? `?${searchParams.toString()}` : ""
  }`;

  try {
    const accessToken = await Providers.globalProvider.getAccessToken();

    const response = await fetch(fullURL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        ConsistencyLevel: "eventual",
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText);
    }

    return await response.json();
  } catch (error) {
    console.error("Error acquiring token:", error);
    throw error;
  }
}

// TODO: remove this method when all the APIs are updated
export { authMethod };
