import { useTranslation } from "hooks/use-translate";
import { PersonPicker } from "components/people";

export type Assignee = {
  email?: string;
  name?: string;
  service_type: string;
  isDefault: boolean;
};

type Props = {
  packageName: string;
  assignedUser?: string;
  isDefaultAssignee?: boolean;
  isLoading?: boolean;
  onPackagePersonPickerChange: (
    currPackage: string,
    personEmail: string,
    isDefaultAssignee: boolean,
    assignedUser?: string
  ) => void;
};

export default function AssignedUserForPackage({
  packageName,
  onPackagePersonPickerChange,
  isDefaultAssignee = false,
  assignedUser,
  isLoading = false,
}: Props) {
  const { translate } = useTranslation();
  const emptyPlaceholderText = () => {
    if (isDefaultAssignee) {
      return assignedUser
        ? translate("CHANGE_DEFAULT_EMPLOYEE")
        : translate("ASSIGN_DEFAULT_EMPLOYEE");
    }
    return assignedUser
      ? translate("CHANGE_EMPLOYEE")
      : translate("ASSIGN_EMPLOYEE");
  };
  return (
    <PersonPicker
      className="me-3"
      selectionMode="single"
      placeholder={translate("SEARCH_PERSON")}
      isLoading={isLoading}
      emptyPlaceholder={emptyPlaceholderText()}
      selectedUsers={assignedUser ? [{ id: assignedUser, name: "" }] : []}
      onPersonChange={(_, __, person) =>
        person &&
        person.userPrincipalName &&
        onPackagePersonPickerChange(
          packageName,
          person.userPrincipalName,
          isDefaultAssignee,
          assignedUser
        )
      }
    />
  );
}
