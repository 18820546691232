import React, { useEffect, useState } from "react";
import "./Input.scss";
import { useTranslation } from "hooks/use-translate";
import { UseFormRegisterReturn, UseFormTrigger } from "react-hook-form";

export type RHFInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  disabled?: boolean;
  units?: React.ReactNode;
  errorMessage?: string | React.ReactNode;
  trigger?: UseFormTrigger<any>;
  register: UseFormRegisterReturn;
  maxChar?: number;
  value?: string;
};

/**
 * React hook form input component that takes styling of old component. Probably needs work.
 * @returns
 */
export function RHFInput({
  disabled,
  units,
  errorMessage,
  register,
  trigger,
  maxChar,
  value,
  ...rest
}: RHFInputProps) {
  const { language } = useTranslation();
  const [typedChars, setTypedChars] = useState(0);

  useEffect(() => {
    if (trigger && errorMessage) {
      trigger(register.name);
    }
    // this useEffect needs to be triggered only on language change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (value) {
      setTypedChars(value.length);
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (rest.onChange) {
      rest.onChange(e);
    }
    if (maxChar) {
      setTypedChars(e.target.value.length);
    }
  };

  return (
    <>
      <div
        className={`input${disabled ? " disabled" : ""}${
          errorMessage ? " invalid" : ""
        }`}
      >
        <input
          disabled={disabled}
          maxLength={maxChar}
          {...register}
          {...rest}
          onChange={(e) => handleChange(e)}
        />

        {units && <span className="units">{units}</span>}
      </div>
      <div className="d-flex justify-content-space-between">
        {errorMessage && (
          <div className="error-message mr-sm">
            <span className="text-color-red">
              <span>{errorMessage}</span>
            </span>
          </div>
        )}
        {maxChar && (
          <div className="ml-auto">
            <span className="text-gray">
              {typedChars}/{maxChar}
            </span>
          </div>
        )}
      </div>
    </>
  );
}
