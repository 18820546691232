import { Type } from "class-transformer";
import { IsLoading } from "../state/is-loading";

export type DeliveryRecurrence =
  | "WEEKLY"
  | "MONTHLY"
  | "QUARTERLY"
  | "YEARLY"
  | "ONEOFF";

export type CustomerPackage = {
  /**
   * Unique identifier for this specific instance of a package
   */
  uuid?: string;

  /**
   * Package belongs to this specific instance of a delivery
   */
  delivery_uuid?: string;

  /**
   * Package template identifier
   */
  package_title?: string;

  /**
   * Package template string identier
   */
  package_key_str: string;
};

export class CustomerDelivery extends IsLoading {
  constructor(
    delivery_name: string,
    packages: CustomerPackage[],
    created_by_user_uuid: string,
    start_date: Date,
    recurrence: DeliveryRecurrence,
    is_locked_in: boolean = false,
    created_using: string,
    ends_at?: Date,
    delivery_dates?: Date[],
    uuid?: string,
    delivery_name_ext?: string,
    deleted_at?: Date
  ) {
    super(false);
    this.delivery_name = delivery_name;
    this.packages = packages;
    this.created_by_user_uuid = created_by_user_uuid;
    this.start_date = start_date;
    this.is_locked_in = is_locked_in;
    this.ends_at = ends_at;
    this.recurrence = recurrence;
    if (delivery_dates) {
      this.delivery_dates = delivery_dates;
    }
    this.created_using = created_using;
    if (uuid) {
      this.uuid = uuid;
    }
    this.delivery_name_ext = delivery_name_ext;
    if (deleted_at) {
      this.deleted_at = deleted_at;
    }
  }

  /**
   * Unique identifier for this delivery instance
   */
  uuid: string;

  /**
   * User ID
   */
  created_by_user_uuid: string;

  /**
   * Customer identifier
   */
  org_number: string;

  /**
   * Delivery template identifier
   */
  delivery_name: string;

  /**
   * Has this delivery been taken into fruition in the conveyor belt? I. e. has generated activities
   */
  is_locked_in: boolean;

  /**
   * Packages that are included in this delivery
   */
  packages?: CustomerPackage[] = [];

  /**
   * Delivery recurrence. "ONEOFF" representing that it is a one-time delivery
   */
  recurrence: DeliveryRecurrence;
  /**
   * Taxable delivery unique identifier
   */
  delivery_name_ext?: string;
  /**
   * Planned start date when work on this delivery should begin
   */
  @Type(() => Date)
  start_date: Date;

  /**
   * Planned date when the delivery should be finished
   */
  @Type(() => Date)
  readonly delivery_dates: Date[];

  /**
   * Package has been deleted but has activities before deletion date
   */
  @Type(() => Date)
  ends_at?: Date;

  /**
   * Checks if the delivery has been configured
   * @returns Returns true if the delivery has been configured
   */
  isConfigured() {
    return this.packages && this.packages.length > 0;
  }

  /**
   * Used by BI to determine which system is used to create deliveries
   */
  created_using: string;

  /**
   * Used when offboarding deliveries and it is set only if the delivery is removed
   */
  deleted_at?: Date;
}

/**
 * Package fact, defines what a package contains. Can be used to create a CustomerPackage.
 */
export class PackageFact {
  /**
   * The delivery fact / definition that this package belongs to
   */
  delivery_name: string;

  /**
   * Name of the package in Swedish
   */
  package_name_se: string;

  /**
   * Name of the package in English
   */
  package_name_en: string;

  /**
   * Unique identifier of the package
   */
  package_key_str: string;

  title: string;
}

/**
 * Delivery fact, defines what a delivery contains. Can be used to create a CustomerDelivery.
 */
export class DeliveryFact {
  /**
   * Delivery identifier, ex. "General accounting", "Year end"
   */
  delivery_name: string;

  /**
   * Service area name, ex. "Economy", "Legal", "Salary"
   */
  service_area_name: string;

  /**
   * Package templates (each containing a number of activity templates)
   *
   * If packages contain only one package, then there is no need to configure the delivery
   * If packages contain more than one package then there is probably need to configure the delivery somehow
   */
  packages: PackageFact[];

  /**
   * Description of the delivery template
   */
  description: string;

  /**
   * Title/headline of the delivery template
   */
  description_headline: string;

  /**
   * Delivery image URL
   */
  image_url: string;

  /**
   * URL to Ludvig Intranet, where you can read more about the delivery template
   */
  intranet_url: string;

  /**
   * Delivery recurrence. "ONEOFF" representing no recurrence
   */
  recurrence: DeliveryRecurrence;

  /**
   * The service cannot appear more often than this limit in a single year
   */
  max_instances_per_year: number;

  /**
   * If deactivaded, then delivery no longer onboardable
   */
  deactivated: boolean;

  /**
   * If hidden, then delivery is no longer visible in the delivery planner
   * deactivated = not even creatable in backend
   * hidden = can still be created in the backend
   */
  hidden: boolean;

  /**
   * Indicates weather a delivery is a taxable objects
   */
  is_unique_tax_delivery_name?: boolean;
  /**
   * Taxable delivery unique identifier
   */
  delivery_name_ext?: string;

  /**
   * If true, disable delete button
   */
  deletion_disabled: boolean;
}

export class CustomerTaxObject {
  delivery_name_ext: string;
  name: string;
  surname?: string;
  ssn: string;
}

export class CustomerTaxObjects {
  company: CustomerTaxObject;
  persons: CustomerTaxObject[];
}

export class DeletedDelivery {
  delivery_uuid: string;

  status: string;

  delivery: CustomerDelivery;
}
