import { useContext } from "react";
import { ArrowLeft20Regular, ArrowRight20Regular } from "@fluentui/react-icons";
import { Button, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import AppConfig from "app-config";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { LoadingStatus, RootState } from "state";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import TooltipV9 from "components/tooltip/TooltipV9";
import { CreateCustomerContext } from "../CreateCustomerContext";
import { useRoutingForCreateCustomer } from "./StepperHelper";

export type WizardNavigationProps = {
  isNextDisabled?: boolean;
  isNextHidden?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  onBeforeNext?: () => Promise<boolean>;
  predefinedNextRoute?: string;
  hideNavigation?: boolean;
  nextLabel?: TranslationKey;
  backLabel?: TranslationKey;
  loadingStatus?: LoadingStatus;
  disabledBackMessage?: string | JSX.Element | JSX.Element[] | undefined;
  customBackButtonRoute?: string;
};

export function WizardNavigationButtons({
  hideNavigation,
  isNextDisabled,
  isNextHidden,
  disabledBackMessage,
  onBeforeNext,
  onNext,
  onPrevious,
  predefinedNextRoute,
  backLabel = "BACK",
  nextLabel = "NEXT",
  loadingStatus = "idle",
  customBackButtonRoute,
}: WizardNavigationProps) {
  const { translate } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { businessAreaSelected } = useContext(CreateCustomerContext);

  const {
    dealsByOrgNumber: { data: potentialDeals },
    dealOptions: { data: dealOptions },
  } = useSelector((state: RootState) => state.sales);

  const dealsBusinessAreas = dealOptions.find(
    (d) => d.name === "affarsomrade_deal_"
  );

  const filteredPotentialDeals = potentialDeals
    .filter(
      (deal) =>
        // eslint-disable-next-line no-underscore-dangle
        deal.affarsomrade_deal_ ===
        dealsBusinessAreas?.options.find(
          (area) => area.value === businessAreaSelected.value
        )?.value
    )
    .filter(
      (deal) => deal.dealstage !== AppConfig.FEATURES.DEALS.DEAL_STAGE_LOST
    );

  const routing = useRoutingForCreateCustomer(
    location.pathname,
    !!filteredPotentialDeals.length
  );

  const renderBackButton = () => {
    if (!routing.previous && !customBackButtonRoute) {
      return null;
    }

    return (
      <TooltipV9
        content={<div>{disabledBackMessage || ""}</div>}
        enabled={!!disabledBackMessage}
      >
        <Button
          variant="outline-primary"
          disabled={!!disabledBackMessage}
          onClick={() => {
            if (onPrevious) {
              onPrevious();
            }
            if (customBackButtonRoute) {
              navigate(customBackButtonRoute);
            }

            if (routing.previous?.path) {
              navigate(routing.previous.path);
            }
          }}
        >
          <span className="d-flex flex-row align-items-center">
            <ArrowLeft20Regular className="mr-sm" />
            <span className="px-sm">{translate(backLabel)}</span>
          </span>
        </Button>
      </TooltipV9>
    );
  };

  const renderForwardButton = () => {
    if (!routing.next && !predefinedNextRoute) {
      return null;
    }

    function goToNextRoute() {
      if (!predefinedNextRoute && routing.next?.path) {
        navigate(routing.next.path);
      }

      if (predefinedNextRoute) {
        navigate(predefinedNextRoute);
      }
    }
    let buttonText = translate(nextLabel);

    if (location.pathname.includes("/edit")) {
      buttonText = translate("SAVE");
    }

    return (
      <Button
        disabled={
          isNextHidden ||
          isNextDisabled ||
          loadingStatus === LoadingStatusEnum.PENDING
        }
        onClick={async () => {
          if ((onBeforeNext && !(await onBeforeNext())) || !routing.next) {
            return;
          }

          onNext?.();
          goToNextRoute();
        }}
      >
        <span className="d-flex flex-row align-items-center">
          <span className="px-sm">{buttonText}</span>
          <ArrowRight20Regular className="ml-sm" />
        </span>
      </Button>
    );
  };

  const renderNavigation = () => {
    if (hideNavigation) {
      return null;
    }

    return (
      <Row className="stepper-buttons justify-content-between">
        <Col md="auto">{renderBackButton()}</Col>
        <Col md="auto">
          <TooltipV9
            content="FORWARD_BUTTON_TOOLTIP"
            hasJSXContent
            enabled={!!isNextHidden}
          >
            {renderForwardButton()}
          </TooltipV9>
        </Col>
      </Row>
    );
  };

  return renderNavigation();
}
