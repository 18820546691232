import { Spinner } from "@fluentui/react-components";
import { HTMLAttributes, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { Setting } from "models/customerSettings/customerSetting";
import { SettingsCategory } from "models/customerSettings/settingsCategory";
import { RootState } from "state";
import { toggleCustomerSettingCallerWrapper } from "state/customerSettings/customerSettingsThunk";
import "./customerSettingsCategoriesCard.scss";
import Switch from "components/switch";

type CustomerSettingsContactCardsProps = {
  settingsCategory: SettingsCategory;
} & HTMLAttributes<HTMLElement>;

export default function CustomerSettingsCategoriesCard({
  settingsCategory,
  ...rest
}: CustomerSettingsContactCardsProps) {
  const { language } = useTranslation();
  const dispatch = useDispatch();

  const { currentUser } = useSelector((state: RootState) => state.users);
  const [updating, setUpdating] = useState<number[]>([]);

  const onToggleSetting = async (setting: Setting) => {
    setSettingUpdating(setting.id);
    await dispatch(
      toggleCustomerSettingCallerWrapper({
        id: setting.id,
        user: currentUser.graphId,
        status: !setting.status,
      })
    );
    setSettingNotUpdating(setting.id);
  };

  const getAllToggleSettings = () => {
    const toggleSettings = settingsCategory.settings.filter(
      (setting) => setting.settings_type === "boolean"
    );
    return toggleSettings.sort((a, b) => a.sorting_number - b.sorting_number);
  };

  const setSettingUpdating = (settingId: number) => {
    setUpdating((prev) => [...prev, settingId]);
  };

  const setSettingNotUpdating = (settingId: number) => {
    setUpdating((prev) => [...prev].filter((item) => item !== settingId));
  };

  return (
    <div className="settings-category-card">
      <span className="fw-bold">
        {settingsCategory.title} ({settingsCategory.settings.length})
      </span>
      {getAllToggleSettings().length > 0 && (
        <div className="toggle-container mt-md">
          {getAllToggleSettings().map((setting) =>
            updating.includes(setting.id) ? (
              <div className="d-flex py-xs">
                <Spinner size={SpinnerSize.ExtraSmall} />
                <span className="pl-lg" style={{ fontSize: "13px" }}>
                  {language === "SV" ? setting.title : setting.title_en}
                </span>
              </div>
            ) : (
              <Switch
                key={`setting-${setting.id}`}
                label={language === "SV" ? setting.title : setting.title_en}
                checked={setting.status}
                onToggleMethod={() => onToggleSetting(setting)}
              />
            )
          )}
        </div>
      )}
    </div>
  );
}
