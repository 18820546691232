import React from "react";
import { ChevronRightRegular } from "@fluentui/react-icons";

import "./Breadcrumb.scss";

export type BreadcrumbItem = {
  key: string;
  text: string | React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
};

export type BreadcrumbProps = React.HTMLProps<HTMLDivElement> & {
  items: BreadcrumbItem[];
  maxElements?: number;
  shouldTruncate?: boolean;
  className?: string;
};

// USED ONLY IN COMPONENTS THAT ARE NOT USED IN THE APP
export function Breadcrumb({
  items,
  maxElements = 2,
  shouldTruncate = false,
  className = "",
}: BreadcrumbProps) {
  const breadcrumItems =
    maxElements && shouldTruncate ? items.slice(-maxElements) : items;
  return (
    <div className={`breadcrumb ${className}`}>
      {shouldTruncate && (
        <>
          <span className="mr-sm">...</span>
          <ChevronRightRegular className="breadcrumb-separator" />
        </>
      )}
      {breadcrumItems.map((item, index) => {
        const last = index === breadcrumItems.length - 1;
        const itemClassName = [
          "breadcrumb-item",
          last ? " last fw-bold" : "",
          item.onClick ? "selectable" : "",
        ];

        return (
          <React.Fragment key={item.key}>
            <div className={itemClassName.join(" ")} onClick={item.onClick}>
              {item.text}
            </div>
            {!last && <ChevronRightRegular className="breadcrumb-separator" />}
          </React.Fragment>
        );
      })}
    </div>
  );
}
