import {
  BrowserCacheLocation,
  Configuration,
  PublicClientApplication,
} from "@azure/msal-browser";
import { Msal2Config, Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { LoginType, Providers } from "@microsoft/mgt-element";
import AppConfig from "../app-config";

const msalConfig: Configuration = {
  auth: {
    clientId: AppConfig.CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AppConfig.TENANT_ID}`,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: true,
  },
};

const msal2Config: Msal2Config = {
  clientId: AppConfig.CLIENT_ID,
  authority: `https://login.microsoftonline.com/${AppConfig.TENANT_ID}`,
  redirectUri: window.location.origin,
  scopes: ["User.Read", "People.Read"],
  loginType: LoginType.Redirect,
};
const msalInstance = new PublicClientApplication(msalConfig);

export async function browserInitialize() {
  Providers.globalProvider = new Msal2Provider(msal2Config);
  await msalInstance.initialize();
  console.log("%cBROWSER AUTH ENABLED.", "font-size: 2em; color: orange;");
}