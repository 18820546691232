import { endOfDay } from "date-fns/esm";

import { Calendar } from "components/date/Calendar";
import { Language, TranslationKey } from "i18n";
import { Recurrence } from "models/activities/activity";

type DeadlineCalendarProps = {
  value?: Date;
  defaultValue?: Date;
  minDate: Date;
  maxDate: Date;
  timespan?: Recurrence;
  language: Language;
  onSelectedDate: (date: Date) => void;
  translate: (key: TranslationKey) => string;
};

export function DeadlineCalendar({
  minDate,
  maxDate,
  value = new Date(),
  timespan,
  onSelectedDate,
  language,
  translate,
}: DeadlineCalendarProps) {
  const todayDate = new Date();

  const selectDate = (date: Date) => {
    const newDate = endOfDay(date);
    onSelectedDate(newDate);
  };

  return (
    <Calendar
      language={language}
      translate={translate}
      showWeekNumbers
      minDate={minDate}
      maxDate={maxDate}
      showGoToToday={todayDate >= minDate && todayDate <= maxDate}
      isMonthPickerVisible={timespan === Recurrence.Yearly}
      onSelectDate={selectDate}
      value={value}
      className="mb-4"
    />
  );
}
