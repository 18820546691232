import { MOCK_USER_EMAIL } from "./mocks";

/**
 * @deprecated
 */
const pilotUsers = [
  // Developers
  "anders.ahsman@ludvig.se",
  "mikael.holmstrom@ludvig.se",
  "markuss.sprogis@ludvig.se",
  "angela.gjorgjioska@ludvig.se",
  "zorica.todorova@ludvig.se",
  "ljupka.petrova@ludvig.se",
  "rasmus.harrysson@ludvig.se",
  "dalibor.peshovski@ludvig.se",
  "viktor.ristevski@ludvig.se",
  "samuel.svensater@ludvig.se",
  "max.block@ludvig.se",
  "robert.lindoff@ludvig.se",
  "christopher.rosenvall@ludvig.se",
  "benjamin.golba@ludvig.se",
  MOCK_USER_EMAIL,

  // Project leads
  "ola.froberg@ludvig.se",
  "viktoria.pettersson@ludvig.se",
  "adam.olsson@ludvig.se",
  "emma.uddenstig@ludvig.se",
  "peder.albert@ludvig.se",
  "nathalie.liljegren@ludvig.se",
  "johanna.woodbridge@ludvig.se",
  "martin.modig@ludvig.se",
  "linda.g.larsson@ludvig.se",

  // Super users
  "jesper.johansson@ludvig.se",
  "jakob.svensson@ludvig.se",

  "asa.wetterlund@ludvig.se",
  "sofie.krigh@ludvig.se",
  "emeli.westerling@ludvig.se",
  "andreas.bellgran@ludvig.se",
  "ingegerd.brannstrom@ludvig.se",
  "annika.josephsson@ludvig.se",
  "viktoria.pettersson@ludvig.se",
  "madelene.bjork@ludvig.se",
  "erica.torgersen@ludvig.se",
  "sofie.burman@ludvig.se",
  "angelica.hallberg@ludvig.se",
  "helena.ojeblad@ludvig.se",
  "sophie.cronholm@ludvig.se",
  "cecilia.mattsson@ludvig.se",
];

/**
 * @deprecated
 */
const deliveryPlannerPilotUsers = [
  "anna.eriksson@ludvig.se",
  "anneli.solin@ludvig.se",
  "cecilia.andersson@ludvig.se",
  "johanna.woodbridge@ludvig.se",
  "linda.g.larsson@ludvig.se",
  "marie.arvidsson@ludvig.se",
  "sofia.holmgren@ludvig.se",
  "marina.carlsson@ludvig.se",
  "andreas.bellgran@ludvig.se",
  "elin.elfstrand@ludvig.se",
  "ingegerd.brannstrom@ludvig.se",
  "sofie.krigh@ludvig.se",
  "anglica.hallberg@ludvig.se",
  "betina.silfverberg@ludvig.se",
  "petra.ch@ludvig.se",
  "ann-marie.paulsson@ludvig.se",
  "erica.torgersen@ludvig.se",
  "helena.ojeblad@ludvig.se",
  "maria.simic@ludvig.se",
  "sofie.burman@ludvig.se",
  "sophie.cronholm@ludvig.se",
  "cecilia.mattsson@ludvig.se",
  "petra.borrud@ludvig.se",
  "roland.sjodahl@ludvig.se",
  "sara.emanuelsson@ludvig.se",
  "jakob.svensson@ludvig.se",
  "asa.wetterlund@ludvig.se",
  "erika.bjorkkvist@ludvig.se",
  "marcus.johnson@ludvig.se",
  "maria.m.andersson@ludvig.se",
  "matilda.engelbrektson@ludvig.se",
  "sabina.hakansson@ludvig.se",
  "viktoria.pettersson@ludvig.se",
  "caroline.goransson@ludvig.se",
  "martin.odensten@ludvig.se",
  "asa.andersson@ludvig.se",
  "hakan.andersson2@ludvig.se",
  "helen.kallgard@ludvig.se",
];

/**
 * @deprecated
 */
const supportTeamUsers = [
  "deepak.bhakuni@ludvig.se",
  "arif.khan@ludvig.se",
  "suraj.rana@ludvig.se",
  "atish.singh@ludvig.se",
  "ola.froberg@ludvig.se",
  "viktoria.pettersson@ludvig.se",
  "gaurav.garg@ludvig.se",
  "raghav.pathak@ludvig.se",
  "mukesh.kumar@ludvig.se",
  "rachit.pal@ludvig.se",
  "suresh.chandra.dhal@ludvig.se",
  "nishant.kumar@ludvig.se",
  "naveen.mandal@ludvig.se",
];

// These roles come from CatalystOne
const competentSystemRoles = [
  {
    maxApprovalAmount: 40000,
    systemRole: "customer_contract_approval_ACC_50",
  },
  {
    maxApprovalAmount: 200000,
    systemRole: "customer_contract_approval_ACC_200",
  },
  {
    maxApprovalAmount: 1000000,
    systemRole: "customer_contract_approval_ACC_1000",
  },
];

const AppConfig = {
  APP_NAME: "MittLudvig",
  STAGE: process.env.REACT_APP_STAGE || "development",
  IS_DEBUGGING: process.env.REACT_APP_IS_DEBUGGING || "",
  API_URL: process.env.REACT_APP_API_URL || "",
  POG_URL: process.env.REACT_APP_POG_URL || "",
  MITT_LUDVIG_URL: process.env.REACT_APP_MITT_LUDVIG_URL || "",
  MITT_LUDVIG_CONFIG_URL:
    `${process.env.REACT_APP_MITT_LUDVIG_URL}/msteamsconfiguration` || "", // Needs to be same as specified in manifest.json configurableTabs > configurationUrl
  MITT_KONTOR_URL: process.env.REACT_APP_MITT_KONTOR_URL || "",
  HOW_TO_PLAY_URL: process.env.REACT_APP_HOW_TO_PLAY_URL || "",
  BUGSNAG_API_KEY: process.env.REACT_APP_BUGSNAG_API_KEY || "",
  LUDVIG_INTERNAL_CHAT_USER: "ludvig.intern.kundchatt@ludvig.se",
  REPORT_BASE_URL: process.env.REACT_APP_REPORT_URL_BASE || "",
  PDF_REPORT_BASE_URL: process.env.REACT_APP_PDF_REPORT_URL_BASE || "",
  BUDGET_REPORT_URL_BASE: process.env.REACT_APP_BUDGET_REPORT_URL_BASE || "",
  MONTHLY_REPORT_URL_BASE: process.env.REACT_APP_MONTHLY_REPORT_URL_BASE || "",
  REPORTER_ID: process.env.REACT_APP_REPORTER_ID || "",
  CRM_PIPELINE_STAGE_SIGNED:
    process.env.REACT_APP_CRM_PIPELINE_STAGE_SIGNED || "",
  CRM_PIPELINE_STAGE_REJECTED:
    process.env.REACT_APP_CRM_PIPELINE_STAGE_REJECTED || "",
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID || "",
  CLIENT_RESOURCE_URI: process.env.REACT_APP_CLIENT_RESOURCE_URI || "",
  TENANT_ID: process.env.REACT_APP_TENANT_ID || "",
  USE_MOCKS: process.env.REACT_APP_USE_MOCKS || false,
  USE_MOCK_AUTH: process.env.REACT_APP_USE_MOCK_AUTH || false,
  MACONOMY_URL: process.env.REACT_APP_MACONOMY_URL || "",
  POWER_BI_URL: process.env.REACT_APP_POWER_BI_URL || "",
  FEATURES: {
    DELIVERY_PLANNER_ADMINS: pilotUsers,
    DELIVERY_PLANNER: {
      PILOT_USERS: [...pilotUsers, ...deliveryPlannerPilotUsers],
      ENABLED_FOR_ALL_USERS_FROM: new Date("2022-09-05"),
    },
    LUDVIG_SERVICE: {
      PILOT_USERS: [
        ...pilotUsers,
        "matilda.engelbrektson@ludvig.se",
        "monica.isaksson.elehn@ludvig.se",
        "cecilia.mattsson@ludvig.se",
      ],
      ENABLED_FOR_ALL_USERS_FROM: new Date("2022-02-21"),
    },
    DEALS: {
      VISIBLE_PIPELINES: (
        process.env.REACT_APP_DEALS_VISIBLE_PIPELINES || ""
      ).split(","),
      DEFAULT_PIPELINE: process.env.REACT_APP_DEALS_DEFAULT_PIPELINE || "",
      DEFAULT_DEAL_STAGE: process.env.REACT_APP_DEALS_DEFAULT_DEAL_STAGE || "",
      DEFAULT_CUSTOMER_PIPELINE:
        process.env.REACT_APP_DEALS_DEFAULT_CUSTOMER_PIPELINE || "",
      DEFAULT_CUSTOMER_DEAL_STAGE:
        process.env.REACT_APP_DEALS_DEFAULT_CUSTOMER_DEAL_STAGE || "",
      DEAL_STAGE_WON: process.env.REACT_APP_DEALS_CUSTOMER_DEAL_STAGE_WON || "",
      DEAL_STAGE_LOST:
        process.env.REACT_APP_DEALS_CUSTOMER_DEAL_STAGE_LOST || "",
    },
    VISIBLE_SIE_FILES: true,
    VISIBLE_CUSTOMER_SETTINGS: {
      basicinfo: false,
      taxobjects: false,
    },
    SUPPORT_TEAM_USERS: [...supportTeamUsers],
    ADD_CONTACTS_OPTION: false,
    TAX_OBJECTS_DELIVERY: true,
  },
  OFFER: {
    VERSION: "3.0.0",
    AWS_REGION: process.env.REACT_APP_API_REGION,
    AWS_UPLOAD_BUCKET_NAME: process.env.REACT_APP_UPLOAD_BUCKET_NAME,
    API_BOI_VERIFIED_URL: process.env.REACT_APP_BOI_VERIFIED_URL || "",
    VERIFIED_ARCHIVE: process.env.REACT_APP_VERIFIED_ARCHIVE || "",
    SALARY_SECTION_SHOW: false,
    COMPETENT_SYSTEM_ROLES: competentSystemRoles,
    AUTO_APPROVAL_AMOUNT_LESS_THAN: 40000,
  },
  USER_ROLES: {
    MITT_LUDVIG_SUPERUSER: "superuser-mittludvig",
    NYK_MANAGER: "sec-dynamic-nyk-manager",
  },
  APP_TITLE: process.env.REACT_APP_TITLE ?? "MIttLudvig",
  SUPPORT_PHONE: "08-505 212",
  SUPPORT_EMAIL: "support@ludvig.se",
};

export default AppConfig;
