import React, { useEffect, useRef } from "react";
import { UseFormRegisterReturn, UseFormTrigger } from "react-hook-form";
import "./Input.scss";
import { useTranslation } from "hooks/use-translate";
import { Spinner } from "@fluentui/react-components";
import { SpinnerSize } from "components/spinner";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  units?: React.ReactNode;
  errorMessage?: string;
  formRegister?: UseFormRegisterReturn;
  trigger?: UseFormTrigger<any>;
  isLoading?: boolean;
  isFocused?: boolean;
};

export function Input({
  // eslint-disable-next-line react/prop-types
  disabled,
  // eslint-disable-next-line react/prop-types
  className = "",
  units,
  formRegister,
  trigger,
  errorMessage,
  isLoading = false,
  isFocused = false,
  ...rest
}: InputProps) {
  const { language } = useTranslation();
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (formRegister && trigger && errorMessage) {
      trigger(formRegister.name);
    }
    // this useEffect needs to be triggered only on language change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (formRegister && inputRef.current) {
      formRegister.ref(inputRef.current);
    }
  }, [formRegister]);

  useEffect(() => {
    if (isFocused) {
      inputRef.current?.focus();
    }
  }, [isFocused]);

  const renderInput = () => (
    <input
      disabled={disabled}
      className={className}
      ref={inputRef}
      {...formRegister}
      {...rest}
    />
  );

  return (
    <>
      <div
        className={`input${disabled ? " disabled" : ""}${
          errorMessage ? " invalid" : ""
        }`}
      >
        {!isLoading ? (
          renderInput()
        ) : (
          <div className="d-flex w-100">
            {renderInput()}
            <Spinner className="mr-sm" size={SpinnerSize.Tiny} />
          </div>
        )}
        {units && <span className="units">{units}</span>}
      </div>
      {errorMessage && (
        <div className="error-message">
          <span className="text-color-red">
            <span>{errorMessage}</span>
          </span>
        </div>
      )}
    </>
  );
}
