import { Step } from "components/stepper";
import { useTranslation } from "hooks/use-translate";
import { StepDefinition, SubStepDefinition } from "models/WIzardSteps";
import { CustomerTypes } from "models/customer";
import { CustomerWizardRouteHelper } from "../createCustomerWizardRoutes";

/**
 * Generate the steps for the wizard, based on whether to show potential deals and the type of the customer.
 * @param showPotentialDeals A boolean flag to indicate whether to show potential deals or not.
 * @param customerType An optional parameter that specifies the type of the customer.
 * @returns The steps for the wizard.
 */
export function getAvailableWizardStepsForCreateCustomer(
  showPotentialDeals: boolean,
  customerType?: CustomerTypes
): StepDefinition[] {
  const customerBusinessAreaStep: StepDefinition = {
    labelKey: "BUSINESS_AREA",
    steps: [
      {
        path: CustomerWizardRouteHelper.getCustomerBusinessAreaRoute(),
        nextRoute: CustomerWizardRouteHelper.getCustomerDetailsPageRoute(),
        previousRoute: undefined,
        labelKey: "BUSINESS_AREA",
        visible: false,
        validate: () => true,
        disabled: true,
      },
    ],
  };

  const customerInfoStep: StepDefinition = {
    labelKey: "OFFER_CUSTOMERINFO",
    steps: [
      {
        path: CustomerWizardRouteHelper.getCustomerDetailsPageRoute(),
        nextRoute: CustomerWizardRouteHelper.getCustomerContactDetailsRoute(),
        previousRoute: CustomerWizardRouteHelper.getCustomerBusinessAreaRoute(),
        labelKey:
          customerType === CustomerTypes.PRIVATE_PERSON
            ? "CONTACTS"
            : "OFFER_CUSTOMER",
        visible: true,
        validate: () => true,
      },
      {
        path: CustomerWizardRouteHelper.getCustomerContactDetailsRoute(),
        nextRoute: CustomerWizardRouteHelper.getCustomerPotentialDealsRoute(),
        previousRoute: CustomerWizardRouteHelper.getCustomerDetailsPageRoute(),
        labelKey:
          customerType === CustomerTypes.PRIVATE_PERSON
            ? "OFFER_CUSTOMER"
            : "CONTACTS",
        visible: true,
        validate: () => true,
      },
    ].flatMap((f) => (f ? [f] : [])),
  };

  const customerBusinessDetailsStep = (hasDeals: boolean) => {
    const step: StepDefinition = {
      labelKey: "BUSINESS_DETAILS",
      steps: [],
    };

    if (hasDeals) {
      const potentialDealsSubstep: SubStepDefinition = {
        path: CustomerWizardRouteHelper.getCustomerPotentialDealsRoute(),
        nextRoute: CustomerWizardRouteHelper.getCustomerDealInformationRoute(),
        previousRoute:
          CustomerWizardRouteHelper.getCustomerContactDetailsRoute(),
        labelKey: "POTENTIAL_DEALS_LIST",
        validate: () => true,
        visible: true,
      };
      step.steps = [potentialDealsSubstep];
    }

    const dealInformationSubstep: SubStepDefinition = {
      path: CustomerWizardRouteHelper.getCustomerDealInformationRoute(),
      nextRoute: CustomerWizardRouteHelper.getCustomerRegisteredRoute(),
      previousRoute: hasDeals
        ? CustomerWizardRouteHelper.getCustomerPotentialDealsRoute()
        : CustomerWizardRouteHelper.getCustomerContactDetailsRoute(),
      labelKey: "DEAL_INFORMATION",
      validate: () => true,
      visible: true,
    };
    step.steps = [...step.steps, dealInformationSubstep];

    return step;
  };

  const confirmationStep: StepDefinition = {
    labelKey: "REGISTRATION_COMPLETE",
    steps: [
      {
        path: CustomerWizardRouteHelper.getCustomerRegisteredRoute(),
        nextRoute: undefined,
        previousRoute: undefined,
        labelKey: "REGISTRATION_COMPLETE",
        visible: false,
        validate: () => true,
      },
    ],
  };

  return [
    customerBusinessAreaStep,
    customerInfoStep,
    customerBusinessDetailsStep(showPotentialDeals),
    confirmationStep,
  ];
}

/**
 * Generate the routes for the wizard.
 * @param currentLocationPath The current location.
 * @param showPotentialDeals A boolean flag to indicate whether to show potential deals or not.
 * @returns The routes for the wizard.
 */
export function useRoutingForCreateCustomer(
  currentLocationPath: string,
  showPotentialDeals: boolean
) {
  const availableSteps = getAvailableWizardStepsForCreateCustomer(
    showPotentialDeals,
    undefined
  );
  const stepsWithState = availableSteps
    .flatMap((a) => a.steps)
    .map((s) => ({ ...s, valid: s.validate() }));

  // strip the current path from the url params if there are any
  const currentPathParamsIndex = currentLocationPath.search(/\/[0-9]/);
  const currentBasePath =
    currentPathParamsIndex > 0
      ? currentLocationPath.slice(0, currentPathParamsIndex)
      : currentLocationPath;

  const isQuickEdit = currentLocationPath.includes("/edit");
  const activeStepIndex = stepsWithState.findIndex((s) =>
    isQuickEdit ? `${s.path}/edit` : s.path === currentBasePath
  );

  const nextStep = () => {
    if (!stepsWithState?.[activeStepIndex]?.nextRoute) {
      return;
    }

    return isQuickEdit
      ? stepsWithState[stepsWithState.length - 1]
      : stepsWithState[activeStepIndex + 1];
  };

  const previousStep = () => {
    if (!stepsWithState?.[activeStepIndex]?.previousRoute) {
      return;
    }

    return stepsWithState[activeStepIndex - 1];
  };

  return {
    previous: previousStep(),
    next: nextStep(),
    current: stepsWithState[activeStepIndex],
    steps: stepsWithState,
  };
}

/**
 * Generate stepper items for the wizard, based on the current location and available steps.
 * @param currentLocationPath The current location.
 * @param availableSteps An array of available steps definitions.
 * @returns The stepper items for the wizard.
 */
export function useStepperItemsForCreateCustomer(
  currentLocationPath: string,
  availableSteps: StepDefinition[]
): Step[] {
  const { ts } = useTranslation();

  // strip the current path from the url params if there are any
  const currentPathParamsIndex = currentLocationPath.search(/\/[0-9]/);
  const currentBasePath =
    currentPathParamsIndex > 0
      ? currentLocationPath.slice(0, currentPathParamsIndex)
      : currentLocationPath;

  const currentActiveIndex = availableSteps.findIndex((step) =>
    step.steps.some(
      (ss) =>
        ss.path === currentBasePath || `${ss.path}/edit` === currentBasePath
    )
  );

  if (currentActiveIndex === -1) {
    return [];
  }

  return availableSteps.map((s, index) => {
    const passed = currentActiveIndex > index;
    const activeChildIndex = s.steps.findIndex(
      (subS) =>
        subS.path === currentBasePath || `${subS.path}/edit` === currentBasePath
    );

    return {
      key: index.toString(),
      label: ts(s.labelKey),
      subSteps: s.steps.map(
        (ss, subIndex) =>
          ({
            ...ss,
            subSteps: [],
            key: ss.labelKey,
            label: ss.labelKey ? ts(ss.labelKey) : "",
            state: {
              visible: ss.visible,
              passed: passed || activeChildIndex >= subIndex,
              valid: ss.validate(),
              active: activeChildIndex === subIndex,
              disabled: ss.disabled,
            },
          } as Step)
      ),
      state: {
        active: currentActiveIndex === index,
        disabled: s.disabled,
        valid: s.steps.every((ss) => ss.validate()),
        passed,
        visible: true,
      },
    } as Step;
  });
}

export const sortedCustomerWizardSteps = (stepperSteps: Step[]) => {
  const sortedSteps = stepperSteps
    .filter((step) => !step.state?.disabled)
    .map((step) => {
      if (step.subSteps) {
        const tempStep = {
          ...step,
          subSteps: step.subSteps.filter((subStep) => !subStep.state?.disabled),
        };

        return tempStep;
      }

      return step;
    })
    .sort((a, b) => a.key.localeCompare(b.key));
  return sortedSteps;
};
