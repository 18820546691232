import {
  Dropdown,
  Option,
  makeStyles,
  shorthands,
  DropdownProps,
  OptionOnSelectData,
  SelectionEvents,
  Combobox,
  ComboboxProps,
  mergeClasses,
} from "@fluentui/react-components";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { UseFormRegisterReturn, UseFormTrigger } from "react-hook-form";

import StatusIndicatorIcon from "components/icon/StatusIndicatorIcon";
import { useTranslation } from "hooks/use-translate";

const dropdownOptionIcons = {
  MANAGER_SUPPORT: <StatusIndicatorIcon status="yellow" className="mr-xs" />,
  SUPPORT_SENT_TO_CLIENT: (
    <StatusIndicatorIcon status="blue" className="mr-xs" />
  ),
  SUPPORT_NEEDED: <StatusIndicatorIcon status="red" className="mr-xs" />,
  SUPPORT_FINISHED: <StatusIndicatorIcon status="green" className="mr-xs" />,
};

export type OptionItem = {
  value: string | number | Date;
  text?: string;
  content?: JSX.Element;
  disabled?: boolean;
  data?: any;
};

export type DropdownV9Props = Omit<Partial<DropdownProps>, "onOptionSelect"> &
  Omit<Partial<ComboboxProps>, "onOptionSelect"> & {
    options: OptionItem[];
    label?: string;
    className?: string;
    dropdownClassName?: string;
    selectedOptions?: string[];
    onOptionSelect: (
      event: SelectionEvents,
      data: OptionOnSelectData,
      isSelected: boolean
    ) => void;
    multiselect?: boolean;
    placeholder?: string;
    errorMessage?: string;
    formRegister?: UseFormRegisterReturn;
    trigger?: UseFormTrigger<any>;
    optionsWithIcons?: boolean;
    optionsWithJSXContent?: boolean;
    isSearchable?: boolean;
  };

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    alignItems: "center",
    justifyItems: "start",
    ...shorthands.gap("2px"),
  },
  dropdown: {
    width: "100%",
    minWidth: "100%",
    ...shorthands.borderWidth("1px"),
  },
  dropdownItemsWrapper: { maxHeight: "450px" },
  truncateText: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

export function DropdownV9({
  options,
  onOptionSelect,
  placeholder = "",
  label,
  selectedOptions = [],
  className = "w-100",
  dropdownClassName = "",
  multiselect = false,
  errorMessage,
  formRegister,
  trigger,
  optionsWithIcons = false,
  optionsWithJSXContent,
  isSearchable = false,
  ...rest
}: DropdownV9Props) {
  const styles = useStyles();
  const { language } = useTranslation();

  const [selectedKeys, setSelectedKeys] = useState<string[]>(selectedOptions);
  const [comboboxOptions, setComBoxOptions] = useState(options);

  const defaultSelectedValues =
    selectedOptions.length > 0
      ? options
          .filter(
            (option) =>
              option.value && selectedOptions.includes(option.value.toString())
          )
          .map((option) => option.text)
          .join(", ")
      : placeholder;

  const [selectedValues, setSelectedValues] = useState<string>(
    defaultSelectedValues
  );

  useEffect(() => {
    if (formRegister && trigger && errorMessage) {
      trigger(formRegister.name);
    }
  }, [language, formRegister, trigger, errorMessage]);

  useEffect(() => {
    setSelectedKeys(selectedOptions);
    if (selectedOptions.length > 0 && !isSearchable) {
      const values = options
        .filter(
          (option) =>
            option.value && selectedOptions.includes(option.value.toString())
        )
        .map((selectedOption) => selectedOption.text)
        .join(", ");
      setSelectedValues(values);
    } else {
      const defaultVal = isSearchable ? "" : placeholder;
      setSelectedValues(defaultVal);
    }
  }, [selectedOptions, options, placeholder, isSearchable]);

  const handleOptionChangeDropdown = (
    e: SelectionEvents,
    data: OptionOnSelectData
  ) => {
    setSelectedKeys(data.selectedOptions);
    let isSelected;
    if (data.optionValue) {
      isSelected = data.selectedOptions.includes(data.optionValue);
    } else {
      isSelected = false;
    }

    onOptionSelect(e, data, isSelected);
  };

  const handleOptionChangeCombobox: ComboboxProps["onOptionSelect"] = (
    e: SelectionEvents,
    data: OptionOnSelectData
  ) => {
    setSelectedKeys(data.selectedOptions);
    setSelectedValues("");
    let isSelected;
    if (data.optionValue) {
      isSelected = data.selectedOptions.includes(data.optionValue);
    } else {
      isSelected = false;
    }

    onOptionSelect(e, data, isSelected);
  };

  // clear value on focus
  const onFocus = () => {
    setSelectedValues("");
  };

  // update value to selected options on blur
  const onBlur = () => {
    setSelectedValues(defaultSelectedValues);
    setComBoxOptions(options);
  };

  // update value on input change
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase();
    const filteredOptions = options.filter((option) =>
      option.text?.toLowerCase().includes(inputValue)
    );

    if (inputValue === "") {
      setComBoxOptions(options);
    } else {
      setComBoxOptions(filteredOptions);
    }
    setSelectedValues(inputValue);
  };

  const renderOptionIcon = (key: string): JSX.Element | null => {
    return dropdownOptionIcons[key as keyof typeof dropdownOptionIcons] || null;
  };

  const dropdownButton = (
    <span
      className={mergeClasses(
        styles.truncateText,
        !selectedValues && !optionsWithJSXContent ? "text-gray" : ""
      )}
    >
      {optionsWithIcons &&
        selectedKeys.length > 0 &&
        selectedKeys.map((val) => (
          <Fragment key={val}>
            {renderOptionIcon(val)}
            <span>{options.find((option) => option.value === val)?.text}</span>
          </Fragment>
        ))}

      {optionsWithJSXContent &&
        selectedKeys.length > 0 &&
        selectedKeys.map((val) => (
          <span key={val}>
            {options.find((option) => option.value === val)?.content}
          </span>
        ))}

      {(!optionsWithIcons && !optionsWithJSXContent && selectedValues) ||
        placeholder}
    </span>
  );

  const renderDropdownOption = (option: OptionItem) => (
    <Option
      key={option.value.toString()}
      value={option.value ? option.value.toString() : ""}
      disabled={option.disabled}
      text={option.text ?? ""}
    >
      {optionsWithIcons &&
        option.value &&
        renderOptionIcon(option.value.toString())}
      {optionsWithJSXContent && option.content}
      {option.text || ""}
    </Option>
  );

  const sharedProps = {
    className: mergeClasses(styles.dropdown, dropdownClassName),
    ...rest,
    ...formRegister,
    multiselect,
  };

  const error = errorMessage ? (
    <div className="error-message">
      <span className="text-color-red">
        <span>{errorMessage}</span>
      </span>
    </div>
  ) : undefined;

  return (
    <div className={mergeClasses(styles.root, className)}>
      {label && <label>{label}</label>}

      {isSearchable ? (
        <Combobox
          {...sharedProps}
          value={selectedValues}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          selectedOptions={selectedOptions}
          onOptionSelect={(e, data) => handleOptionChangeCombobox(e, data)}
        >
          <span className={`${styles.dropdownItemsWrapper}`}>
            {comboboxOptions.map((option) => renderDropdownOption(option))}
          </span>
        </Combobox>
      ) : (
        <Dropdown
          {...sharedProps}
          button={dropdownButton}
          selectedOptions={selectedKeys}
          onOptionSelect={(e, data) => handleOptionChangeDropdown(e, data)}
        >
          <span className={`${styles.dropdownItemsWrapper}`}>
            {options.map((option) => renderDropdownOption(option))}
          </span>
        </Dropdown>
      )}
      {error}
    </div>
  );
}
