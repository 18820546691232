export class CapegoFortnoxConnection {
  capegoId: string; // "3",

  fortnoxDbId: string; // "22267",

  orgno: string; // "920527-0185",

  username: string; // "samuel.svensater@ludvig.se"

  constructor(data?: Partial<CapegoFortnoxConnection>) {
    this.capegoId = data?.capegoId ?? "";
    this.fortnoxDbId = data?.fortnoxDbId ?? "";
    this.orgno = data?.orgno ?? "";
    this.username = data?.username ?? "";
  }
}

export type CapegoFortnoxConnectionResponse = any;
