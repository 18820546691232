import { Spinner } from "@fluentui/react-components";
import { SpinnerSize } from "components/spinner";
import { ReactNode, useContext } from "react";
import { Collapse } from "react-bootstrap";

import { LoadingStatusEnum } from "../../../models/enums/LoadingStatus.enum";
import "../accordion.scss";
import { AccordionContext } from "../AccordionContextProvider";

interface IAccordionContent {
  children: ReactNode;
  loadingStatus?: string;
}

export function AccordionContent({
  children,
  loadingStatus,
}: IAccordionContent) {
  const { isOpen } = useContext(AccordionContext);

  return (
    <Collapse in={isOpen} mountOnEnter unmountOnExit>
      <div>
        <div className="accordion-item-content">
          {loadingStatus && loadingStatus === LoadingStatusEnum.PENDING ? (
            <Spinner size={SpinnerSize.Tiny} />
          ) : (
            children
          )}
        </div>
      </div>
    </Collapse>
  );
}
