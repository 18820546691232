import { createAsyncThunk } from "@reduxjs/toolkit";
import { OffersAPI } from "../../api/offers";
import { authMethod } from "../../auth";
import { appendError } from "../notifications";

export const fetchServiceAreaCallerWrapper = createAsyncThunk(
  "serviceArea/FETCH_SERVICE_AREA_CALLER_WRAPPER",
  async (_, { dispatch }) => {
    try {
      await dispatch(fetchServiceAreaCaller()).unwrap();
    } catch (e) {
      dispatch(appendError("UNKNOWN_ERROR", e as Error));
    }
  }
);

export const fetchServiceAreaCaller = createAsyncThunk(
  "serviceArea/FETCH_SERVICE_AREA_CALLER",
  async () => {
    const token = await authMethod.getStoredAccessToken();
    const response = await OffersAPI.fetchOfferTemplate(token);
    const serviceAreas = response.service_areas;
    return serviceAreas;
  }
);
