import { rest } from "msw";
import { mockCheckToken } from "..";
import { CostCenter, Office } from "../../../models/organization";

const BASE_PATH = process.env.REACT_APP_API_URL;

export const organizationHandlers = [
  rest.get<CostCenter[]>(
    `${BASE_PATH}/cost_centers`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      const costCenters: CostCenter[] = [
        {
          id: "1000",
          name: "Sandviken",
          officeId: "100",
          kecId: "K500",
          regionId: "R40",
          organizationId: "M20",
          ZipCode: "",
          PostalDistrict: "",
          Address1: "",
          Phone: "",
        },
        {
          id: "1001",
          name: "Sandviken",
          officeId: "100",
          kecId: "K500",
          regionId: "R40",
          organizationId: "M20",
          ZipCode: "",
          PostalDistrict: "",
          Address1: "",
          Phone: "",
        },
        {
          id: "1002",
          name: "Sandviken",
          officeId: "100",
          kecId: "K500",
          regionId: "R40",
          organizationId: "M20",
          ZipCode: "",
          PostalDistrict: "",
          Address1: "",
          Phone: "",
        },
        {
          id: "1003",
          name: "Sandviken",
          officeId: "100",
          kecId: "K500",
          regionId: "R40",
          organizationId: "M20",
          ZipCode: "",
          PostalDistrict: "",
          Address1: "",
          Phone: "",
        },
      ];

      return res(delay(500), status(200), json(costCenters));
    }
  ),
  rest.get<Office[]>(
    `${BASE_PATH}/offices`,
    (req, res, { status, json, delay, text }) => {
      try {
        mockCheckToken(req);
      } catch (e) {
        return res(status(403), text(e as string));
      }

      const offices: Office[] = [
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Skellefteå",
          kecId: "K619",
          name: "Skellefteå",
          nameInC1: null,
          officeId: "972",
          officeType: "Fullservicekontor",
          organizationId: "M20",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R40",
          regionName: "Norr",
          salesManagerEmail: "maximilian.paju@ludvig.se",
          zip: null,
        },
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Umeå",
          kecId: "K624",
          name: "Södra Norr",
          nameInC1: null,
          officeId: "203",
          officeType: "Stab",
          organizationId: "M20",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R40",
          regionName: "Norr",
          zip: null,
        },
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Umeå",
          kecId: "K624",
          name: "Norra Norr",
          nameInC1: null,
          officeId: "204",
          officeType: "Stab",
          organizationId: "M20",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R40",
          regionName: "Norr",
          zip: null,
        },
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Umeå",
          kecId: "K624",
          name: "Örnsköldsvik",
          nameInC1: null,
          officeId: "953",
          officeType: "Kontor",
          organizationId: "M20",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R40",
          regionName: "Norr",
          zip: null,
        },
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Umeå",
          kecId: "K624",
          name: "Umeå",
          nameInC1: null,
          officeId: "970",
          officeType: "Fullservicekontor",
          organizationId: "M20",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R40",
          regionName: "Norr",
          zip: null,
        },
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Skaraborg",
          kecId: "K618",
          name: "Skara",
          nameInC1: null,
          officeId: "810",
          officeType: "Fullservicekontor",
          organizationId: "M20",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R42",
          regionName: "Väst",
          zip: null,
        },
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Göteborg",
          kecId: "K605",
          name: "Göteborg",
          nameInC1: null,
          officeId: "750",
          officeType: "Fullservicekontor",
          organizationId: "M20",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R47",
          regionName: "Storstad och Specialistenheter",
          zip: null,
        },
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Göteborg",
          kecId: "K605",
          name: "Kungälv",
          nameInC1: null,
          officeId: "751",
          officeType: "Inlämningskontor",
          organizationId: "M20",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R47",
          regionName: "Storstad och Specialistenheter",
          zip: null,
        },
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Göteborg",
          kecId: "K605",
          name: "Sisjön",
          nameInC1: null,
          officeId: "755",
          officeType: "Inlämningskontor",
          organizationId: "M20",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R47",
          regionName: "Storstad och Specialistenheter",
          zip: null,
        },
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Lön",
          kecId: "K615",
          name: "Specialistenhet Lön",
          nameInC1: null,
          officeId: "228",
          officeType: "Kontor",
          organizationId: "M20",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R47",
          regionName: "Storstad och Specialistenheter",
          zip: null,
        },
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Stockholm",
          kecId: "K620",
          name: "Norrtälje",
          nameInC1: null,
          officeId: "275",
          officeType: "Inlämningskontor",
          organizationId: "M20",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R47",
          regionName: "Storstad och Specialistenheter",
          zip: null,
        },
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Stockholm",
          kecId: "K620",
          name: "Stockholm",
          nameInC1: null,
          officeId: "285",
          officeType: "Fullservicekontor",
          organizationId: "M20",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R47",
          regionName: "Storstad och Specialistenheter",
          zip: null,
        },
        {
          active: true,
          address: "Adressgatan 1",
          city: null,
          clusterName: "Stängt",
          kecId: "K999",
          name: "Stängt",
          nameInC1: null,
          officeId: "999",
          officeType: "Stab",
          organizationId: "M99",
          publicEmail: null,
          publicName: null,
          publicPhone: null,
          regionId: "R99",
          regionName: "Stängt",
          zip: null,
        },
      ];

      return res(delay(500), status(200), json(offices));
    }
  ),
];
