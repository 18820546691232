import { combineReducers } from "redux";

import { ludvigApiService } from "services/ludvigApi";
import { activitiesApiService } from "services/activitiesApi";
import { customerManagementApiService } from "services/customerManagementApi";
import { activitiesReducer } from "./activities/reducer";
import { assignmentsReducer } from "./assignments/reducer";
import { customersReducer } from "./customers/reducer";
import { salesReducer } from "./sales/reducer";
import { notificationsReducer } from "./notifications";
import { i18nReducer } from "./i18n/reducer";
import { servicesDescriptionsReducer } from "./servicesDescriptions/reducer";
import { offersSlice } from "./offer/offersSlice";
import kycSlice from "./offer/kycSlice";
import settingsSlice from "./customerSettings/customerSettingsSlice";
import serviceAreaSlice from "./serviceAreas/serviceAreaSlice";
import { userSlice } from "./users/userSlice";
import { syncActivitiesSlice } from "./activities/syncActivities.state";

/*
  TODO: Move away from standard redux and use redux-toolkit and/or RTK Query instead.
*/

export default combineReducers({
  // Redux
  customers: customersReducer,
  assignments: assignmentsReducer,
  activities: activitiesReducer,
  syncActivities: syncActivitiesSlice.reducer,
  i18n: i18nReducer,
  servicesDescriptions: servicesDescriptionsReducer,
  notifications: notificationsReducer,
  sales: salesReducer,
  kyc: kycSlice,
  settings: settingsSlice,
  serviceArea: serviceAreaSlice,

  // RTK
  users: userSlice.reducer,
  offers: offersSlice.reducer,

  // RTK Query
  [ludvigApiService.reducerPath]: ludvigApiService.reducer,
  [activitiesApiService.reducerPath]: activitiesApiService.reducer,
  [customerManagementApiService.reducerPath]:
    customerManagementApiService.reducer,
});
