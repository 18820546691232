import "./verticalStepper.scss";

export type Step = {
  key: string;
  label: string;
  circleLabel?: string;
  state?: StepState;
  component: React.ReactNode;
};

export type StepState = {
  active: boolean;
  disabled: boolean;
  highlighted?: boolean;
};

interface IStepperProps {
  steps?: Step[];
}

export default function VerticalStepper({ steps = [] }: IStepperProps) {
  const renderStepCircleLabel = (
    { key, label, state, circleLabel }: Step,
    index: number
  ) => {
    const stepState = state
      ? [state.active && "active", state.disabled && "disabled"]
      : [];
    const stepStateClasses = stepState.filter((s) => s).join(" ");

    return (
      <div>
        <span className={`stepNumber ${stepStateClasses}`}>
          <span>{circleLabel ?? index + 1}</span>
        </span>
        <span
          className={`stepLabel ${stepStateClasses} ms-2 ${
            state?.highlighted ? "fw-bold" : ""
          } `}
        >
          {label}
        </span>
      </div>
    );
  };

  return (
    <div className="vertical-wizard-stepper">
      {steps.map((step, index) => {
        return (
          <div key={step.key}>
            {renderStepCircleLabel(step, index)}
            <div
              className={`mt-sm mb-lg ${
                steps[index + 1] ? "vertical-wizard-divider px-lg" : "px-xl"
              }`}
            >
              {" "}
              {step.component}
            </div>
          </div>
        );
      })}
    </div>
  );
}
