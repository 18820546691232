import { Spinner } from "@fluentui/react-components";
import {
  Calendar20Regular,
  Info20Regular,
  Warning20Regular,
} from "@fluentui/react-icons";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "components/checkbox";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { PersonPicker, Persona } from "components/people";
import TooltipV9 from "components/tooltip/TooltipV9";
import { SpinnerSize } from "components/spinner";
import StatusIndicatorIcon from "components/icon/StatusIndicatorIcon";
import { useTranslation } from "hooks";
import {
  isUserInCustomerTeam,
  isUserRestricted,
} from "libs/customer-settings-helpers";
import { dateLanguageFormatter } from "libs/date/date-format";
import {
  Activity,
  ActivityDefinition,
  ActivityInstance,
  ActivityInstanceTriggerType,
  Recurrence,
  ServiceInstance,
} from "models/activities/activity";
import { Customer } from "models/customer";
import { ActivityFormEnum } from "models/enums/activityForms.enum";
import { RootState } from "state";
import {
  completeActivity,
  unCompleteActivity,
  updateActivityTypeAssignedUser,
} from "state/activities/actions";
import { ViewType } from "models/mgt";
import { ActivityStatusMap } from "..";
import { checkIfIncompleteActivitiesExist } from "../helpers/checkIfIncompleteActivitiesExist";
import ActivityTooltip from "./ActivityTooltip";
import CalendarModal from "./CalendarModal";
import { DeductionModuleForm } from "./DeductionModuleForm";
import MessageButton from "./MessageButton";
import MessageModal from "./MessageModal";
import { YearlyEvaluationKYCForm } from "./YearlyEvaluationKYCForm";

type Props = {
  activityInstance: ActivityInstance;
  customer: Customer;
  openActivityDetailsPanel: (activity: Activity) => void;
  activitiesToUpdate: string[];
  addActivityToUpdate: (activityId: string) => void;
  removeActivityToUpdate: (activityId: string) => void;
  service?: ServiceInstance;
  selectedActivityInstance?: ActivityInstance;
  setSelectedActivityInstance: (
    selectedActivityInstance?: ActivityInstance
  ) => void;
  definition: ActivityDefinition;
};

export default function ActivityRow({
  activityInstance,
  customer,
  openActivityDetailsPanel,
  activitiesToUpdate,
  addActivityToUpdate,
  removeActivityToUpdate,
  service,
  selectedActivityInstance,
  setSelectedActivityInstance,
  definition,
}: Props) {
  const { translate, language } = useTranslation();
  const formatter = dateLanguageFormatter(language);
  const dispatch = useDispatch();

  const { currentUser } = useSelector((state: RootState) => state.users);
  const currentServiceActivitiesForMonth = useSelector(
    (state: RootState) => state.activities.currentServiceActivitiesForMonth
  );
  const { dataMap: definitionsMap } = useSelector(
    (state: RootState) => state.activities.definitions
  );
  const [showKYCYearlyEvaluationForm, setShowKYCYearlyEvaluationForm] =
    useState(false);
  const [showDeductionForm, setShowDeductionForm] = useState(false);
  const [showDeductionVerificationModal, setShowDeductionVerificationModal] =
    useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [selectedChangeDeadlineDate, setChangeDeadlineDate] = useState(
    new Date()
  );
  const [showCalendarModal, setShowCalendarModal] = useState(false);

  const handleCheckboxChange = async (
    newActivityInstance: ActivityInstance
  ) => {
    addActivityToUpdate(newActivityInstance.activity_id);
    if (
      newActivityInstance.getTriggerType(definition) ===
      ActivityInstanceTriggerType.DeductionModuleDeduction
    ) {
      // If all checkboxes are checked, open deduction verification modal
      if (
        !checkIfIncompleteActivitiesExist(
          definitionsMap,
          newActivityInstance,
          language,
          currentServiceActivitiesForMonth,
          service
        )
      ) {
        setShowDeductionVerificationModal(true);
        setSelectedActivityInstance(newActivityInstance);
      }
    } else if (
      newActivityInstance.form_id &&
      newActivityInstance.form_id !== ActivityFormEnum.FORM_5_KYC_YEARLY &&
      !newActivityInstance.completed_at
    ) {
      setShowDeductionForm(true);
      setSelectedActivityInstance(newActivityInstance);
    } else if (
      newActivityInstance.form_id &&
      newActivityInstance.form_id === ActivityFormEnum.FORM_5_KYC_YEARLY &&
      !newActivityInstance.completed_at
    ) {
      setShowKYCYearlyEvaluationForm(true);
      setSelectedActivityInstance(newActivityInstance);
    } else if (newActivityInstance.completed_at) {
      await dispatch(unCompleteActivity(customer, newActivityInstance));
    } else {
      await dispatch(
        completeActivity(
          customer,
          newActivityInstance,
          currentUser.graphId,
          undefined,
          service?.service_box_id
        )
      );
    }
    removeActivityToUpdate(newActivityInstance.activity_id);
  };

  const handleOnDeductionSubmit = async () => {
    if (
      selectedActivityInstance &&
      selectedActivityInstance.getTriggerType(definition) ===
        ActivityInstanceTriggerType.DeductionModuleDeduction
    ) {
      await dispatch(
        completeActivity(
          customer,
          selectedActivityInstance,
          currentUser.graphId,
          undefined,
          service?.service_box_id
        )
      );

      removeActivityToUpdate(selectedActivityInstance.activity_id);
      setSelectedActivityInstance(undefined);
    }
  };

  const shouldDisableActivityForChecking = (activity: ActivityInstance) => {
    const activityDefinition = definitionsMap[activity.activity_type];
    const isNotRestrictedForCompletion =
      activity.getNotRestrictedForCompleting(activityDefinition);
    const isCompletedWithForm =
      !!activityInstance.completed_at && !!activityInstance.form_id;
    const isRestrictedUser = !isUserInCustomerTeam(customer, currentUser);

    return (
      !isNotRestrictedForCompletion && (isCompletedWithForm || isRestrictedUser)
    );
  };

  return (
    <>
      <Row
        xl={12}
        key={activityInstance.original_id}
        className={`px-lg py-xs table-row-height-44 align-items-center 
          ${activityInstance.completed_at ? "bg-blueLightest" : ""}
          ${activityInstance.isOverdue() ? "bg-redLightest overdue" : ""}
          `}
      >
        <Col md={3} className="d-flex pl-xl align-items-center">
          {activitiesToUpdate.includes(activityInstance.activity_id) ? (
            <Spinner size={SpinnerSize.ExtraSmall} />
          ) : (
            <Checkbox
              checked={!!activityInstance.completed_at}
              disabled={shouldDisableActivityForChecking(activityInstance)}
              onChange={() => handleCheckboxChange(activityInstance)}
            />
          )}
          {activityInstance.getTriggerType(definition) ===
          ActivityInstanceTriggerType.DeductionModuleDeduction ? (
            <ActivityTooltip
              activityInstance={activityInstance}
              isEndOfYearTooltip
            />
          ) : null}
          {!!activityInstance.form_id &&
          activityInstance.getTriggerType(definition) !==
            ActivityInstanceTriggerType.DeductionModuleDeduction ? (
            <ActivityTooltip activityInstance={activityInstance} />
          ) : null}
          <Row className="d-flex pl-md align-items-center w-100">
            <Col md={10}>
              <TooltipV9
                notTranslatable
                content={activityInstance.getTitle(language, definition)}
              >
                <span className="text-max-char-100">
                  {activityInstance.getTitle(language, definition)}
                </span>
              </TooltipV9>
            </Col>
            <Col md={2}>
              <Info20Regular
                className="cursor-pointer"
                onClick={() => openActivityDetailsPanel(activityInstance)}
              />
            </Col>
          </Row>
        </Col>
        <Col
          md={2}
          className="d-flex text-align-start pl-md align-items-center"
        >
          {activityInstance.deadline &&
            formatter(activityInstance.deadline, "yyyy-MM-dd")}

          {activityInstance.hasPassedDeadline() &&
            !activityInstance.isCompletedWithinDeadline() && (
              <Warning20Regular color="var(--red)" className="ml-md" />
            )}

          {activityInstance.getRecurrence(definition) !== Recurrence.Yearly &&
            isUserInCustomerTeam(customer, currentUser) && (
              <Calendar20Regular
                className=" ml-md cursor-pointer on-hover"
                onClick={() => {
                  setSelectedActivityInstance(activityInstance);
                  setChangeDeadlineDate(activityInstance.deadline);
                  setShowCalendarModal(true);
                }}
              />
            )}
        </Col>
        <Col md={3} className="d-flex align-items-center">
          <PersonPicker
            className="mr-sm"
            selectionMode="single"
            placeholder={translate("SEARCH_PERSON")}
            disabled={
              isUserRestricted(customer, currentUser) ||
              !currentUser.isEmployee()
            }
            selectedUsers={
              activityInstance.assigned_user
                ? [{ id: activityInstance.assigned_user, name: "" }]
                : []
            }
            onPersonChange={(email, _, person) =>
              dispatch(
                updateActivityTypeAssignedUser(
                  customer,
                  activityInstance.original_id,
                  person?.id ?? email
                )
              )
            }
          />
        </Col>
        <Col md={1} className="d-flex">
          <MessageButton
            activityInstance={activityInstance}
            customer={customer}
            currentUser={currentUser}
            setShowMessageModal={setShowMessageModal}
            setSelectedActivityInstance={setSelectedActivityInstance}
          />
          {activityInstance.status && (
            <TooltipV9 content={activityInstance.status.status}>
              <StatusIndicatorIcon
                status={ActivityStatusMap.get(activityInstance.status.status)}
              />
            </TooltipV9>
          )}
        </Col>
        <Col md={3}>
          <Row
            xl={12}
            className="d-flex align-items-center justify-content-start"
          >
            <Col md={6}>
              {activityInstance.completed_by_user && (
                <Persona
                  className="mr-md"
                  userId={activityInstance.completed_by_user}
                  view={ViewType.oneline}
                />
              )}
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-end align-items-center"
            >
              <span className="mx-sm text-wrap-none">
                {activityInstance.completed_at &&
                  formatter(activityInstance.completed_at, "yyyy-MM-dd")}
              </span>
              {activityInstance.isLoading && (
                <Spinner className="ms-2" size={SpinnerSize.ExtraSmall} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="horizontal-divider" />
      {selectedActivityInstance && showKYCYearlyEvaluationForm && (
        <YearlyEvaluationKYCForm
          setShowQuestionsModal={setShowKYCYearlyEvaluationForm}
          customer={customer}
          activityInstance={selectedActivityInstance}
          currentUser={currentUser}
          showQuestionsModal={showKYCYearlyEvaluationForm}
          service={service}
        />
      )}
      {selectedActivityInstance && showDeductionForm && (
        <DeductionModuleForm
          setShowQuestionsModal={setShowDeductionForm}
          showQuestionsModal={showDeductionForm}
          customer={customer}
          activityInstance={selectedActivityInstance}
          currentUser={currentUser}
          service={service}
          definition={definition}
        />
      )}
      {service && (
        <CalendarModal
          open={showCalendarModal}
          onClose={() => setShowCalendarModal(false)}
          service={service}
          customer={customer}
          selectedChangeDeadlineDate={selectedChangeDeadlineDate}
          setChangeDeadlineDate={setChangeDeadlineDate}
          selectedActivityInstance={selectedActivityInstance}
        />
      )}
      {showDeductionVerificationModal && (
        <Modal
          isOpen
          onDismiss={() => setShowDeductionVerificationModal(false)}
          header={
            <ModalHeader headerTitleText="DEDUCTION_VERIFICATION_MODAL_TITLE" />
          }
          size="medium"
          footer={
            <ModalFooter
              onSave={() => {
                setShowDeductionVerificationModal(false);
                handleOnDeductionSubmit();
              }}
              onCancel={() => setShowDeductionVerificationModal(false)}
              labelSubmit="MARK_COMPLETED"
            />
          }
        >
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: translate("DEDUCTION_VERIFICATION_MODAL_TEXT"),
            }}
          />
        </Modal>
      )}
      {selectedActivityInstance && (
        <MessageModal
          open={showMessageModal}
          onClose={() => setShowMessageModal(false)}
          customer={customer}
          selectedActivityInstance={selectedActivityInstance}
          definition={definition}
        />
      )}
    </>
  );
}
