import {
  createContext,
  ReactNode,
  useCallback,
  useState,
  useMemo,
} from "react";
import { TableRowId } from "@fluentui/react-components";

import { PersonDetails } from "models/profile";
import {
  CustomerManagement,
  CustomerManagementRoles,
  CustomerManagementWithIndex,
} from "models/customerManagement";

interface ManageCustomersContextProps {
  resetContext: () => void;

  customers: CustomerManagement[];
  setCustomers: (data: CustomerManagement[]) => void;
  customerFilter: string;
  setCustomerFilter: (customerFilter: string) => void;

  assignee?: PersonDetails;
  setAssignee: (assignee?: PersonDetails) => void;
  assigneeFirstName: string;
  assigneeFullName: string;
  newAssigneeId: string;
  setNewAssigneeId: (newAssigneeId: string) => void;

  affectedCustomersCounter: number;
  setAffectedCustomersCounter: (affectedCustomersCounter: number) => void;
  affectedProjectsCounter: number;
  setAffectedProjectsCounter: (affectedProjectsCounter: number) => void;
  affectedRoles: CustomerManagementRoles;
  setAffectedRoles: (affectedRoles: CustomerManagementRoles) => void;

  selectedRows: Set<TableRowId>;
  setSelectedRows: (selectedRows: Set<TableRowId>) => void;
  lockedRows: Set<TableRowId>;
  unlockedCustomers: CustomerManagementWithIndex[];
  selectedCustomers: CustomerManagementWithIndex[];
}

const defaultContext: ManageCustomersContextProps = {
  resetContext: () => {},

  customers: [],
  setCustomers: () => {},
  customerFilter: "",
  setCustomerFilter: () => {},

  assignee: undefined,
  setAssignee: () => {},
  assigneeFirstName: "",
  assigneeFullName: "",
  newAssigneeId: "",
  setNewAssigneeId: () => {},

  affectedCustomersCounter: 0,
  setAffectedCustomersCounter: () => {},
  affectedProjectsCounter: 0,
  setAffectedProjectsCounter: () => {},
  affectedRoles: CustomerManagementRoles.None,
  setAffectedRoles: () => {},

  selectedRows: new Set<TableRowId>(),
  setSelectedRows: () => {},
  lockedRows: new Set<TableRowId>(),
  unlockedCustomers: [],
  selectedCustomers: [],
};

export const ManageCustomersContext =
  createContext<ManageCustomersContextProps>(defaultContext);

interface IRegistrationProvider {
  children: ReactNode;
}

function useManageCustomersContextState() {
  const [customers, setCustomers] = useState<CustomerManagement[]>([]);
  const [customerFilter, setCustomerFilter] = useState("");
  const [assignee, setAssignee] = useState<PersonDetails>();
  const [newAssigneeId, setNewAssigneeId] = useState("");
  const [affectedCustomersCounter, setAffectedCustomersCounter] = useState(0);
  const [affectedProjectsCounter, setAffectedProjectsCounter] = useState(0);
  const [affectedRoles, setAffectedRoles] = useState<CustomerManagementRoles>(
    CustomerManagementRoles.None
  );
  const [selectedRows, setSelectedRows] = useState(() => new Set<TableRowId>());

  const resetContext = useCallback(() => {
    setCustomers([]);
    setCustomerFilter("");
    setAssignee(undefined);
    setNewAssigneeId("");
    setAffectedCustomersCounter(0);
    setAffectedProjectsCounter(0);
    setAffectedRoles(CustomerManagementRoles.None);
    setSelectedRows(new Set<TableRowId>());
  }, []);

  return {
    customers,
    setCustomers,
    customerFilter,
    setCustomerFilter,
    assignee,
    setAssignee,
    newAssigneeId,
    setNewAssigneeId,
    affectedCustomersCounter,
    setAffectedCustomersCounter,
    affectedProjectsCounter,
    setAffectedProjectsCounter,
    affectedRoles,
    setAffectedRoles,
    selectedRows,
    setSelectedRows,
    resetContext,
  };
}

export function ManageCustomersContextProvider({
  children,
}: IRegistrationProvider) {
  const state = useManageCustomersContextState();
  const { customers, assignee, selectedRows } = state;

  const assigneeFirstName = assignee?.firstName || assignee?.givenName || "";
  const assigneeFullName =
    assignee?.displayName ||
    `${assigneeFirstName} ${assignee?.lastName || ""}`.trim();

  const lockedRowsAndUnlockedCustomers = useMemo(() => {
    let lockedRows = new Set<TableRowId>();
    let unlockedCustomers: CustomerManagementWithIndex[] = [];

    const result = customers.reduce(
      (initialAccumulator, customer, index) => {
        const customerWithIndex = { index, customer };

        if (customer.status) {
          initialAccumulator.lockedRows.add(index);
        } else {
          initialAccumulator.unlockedCustomers.push(customerWithIndex);
        }

        return initialAccumulator;
      },
      {
        lockedRows: new Set<TableRowId>(),
        unlockedCustomers: [] as CustomerManagementWithIndex[],
      }
    );

    lockedRows = result.lockedRows;
    unlockedCustomers = result.unlockedCustomers;

    return { lockedRows, unlockedCustomers };
  }, [customers]);
  const { lockedRows, unlockedCustomers } = lockedRowsAndUnlockedCustomers;

  const selectedCustomers = unlockedCustomers.filter(({ index }) =>
    selectedRows.has(index)
  );

  const initContextState = useMemo<ManageCustomersContextProps>(
    () => ({
      ...state,
      assigneeFirstName,
      assigneeFullName,
      lockedRows,
      unlockedCustomers,
      selectedCustomers,
    }),
    [
      state,
      assigneeFirstName,
      assigneeFullName,
      lockedRows,
      unlockedCustomers,
      selectedCustomers,
    ]
  );

  return (
    <ManageCustomersContext.Provider value={initContextState}>
      {children}
    </ManageCustomersContext.Provider>
  );
}
