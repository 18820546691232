export type UUID = string;
export const EMPTY_UUID = "00000000-0000-0000-0000-000000000000";

/**
 * Creates a new randomly generated UUID
 * @returns A new UUID
 */
export function uuidv4(): UUID {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * Checks if a string is a UUID
 * @param str The string to check
 * @returns True if the string is a UUID, false otherwise
 */
export function isUUID(str: UUID): boolean {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    str
  );
}

/**
 * Checks if a UUID is "empty", i.e. matches "00000000-0000-0000-0000-000000000000"
 * @param uuid The UUID to check
 * @returns True if the UUID is empty, false otherwise
 */
export function isEmptyUUID(uuid: UUID): boolean {
  return uuid === EMPTY_UUID;
}
