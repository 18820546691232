import { Type } from "class-transformer";
import { Recurrence } from "./activities/activity";
import { PersonDetails } from "./profile";

export class Assignment {
  id: string;
  closed: boolean;
  cost_center: string;
  cost_center_locationname: string;
  customer_number: string;
  project_number: string;
  project_manager_number: string;
  project_manager_name: string;
  project_manager_email: string;
  project_type: string;
  version_number: number;
  project_manager?: PersonDetails;
  recurrence: Recurrence;

  value_sek: number;

  @Type(() => Date)
  starting_date: Date;

  @Type(() => Date)
  created_date: Date;

  @Type(() => Date)
  last_invoice_date: Date;

  @Type(() => Date)
  changed_date: Date;

  @Type(() => Date)
  actual_ending_date: Date;
}
