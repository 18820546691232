import { ArrowUpRight48Regular } from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";
import "./RedirectScreen.scss";
import { useCallback, useEffect } from "react";

export type RedirectScreenProps = {
  title: string;
  description: string;
  urlsArray?: string[];
  loadingStatus?: boolean;
  setLoadingStatus?: (loading: boolean) => void;
};

export function RedirectScreen({
  title,
  description,
  urlsArray = [],
  loadingStatus = false,
  setLoadingStatus,
}: RedirectScreenProps) {
  /**
   * Open multiple urls each in a new tab, every url is delayed 500ms than the previous one
   */
  const openMultipleTabs = useCallback(() => {
    urlsArray.forEach((url: string, key: number) => {
      if (key === 0) {
        window.open(url, `_blank_first_${key.toString()}`);
      } else {
        setTimeout(() => {
          window.open(url, `_blank_${key.toString()}`);
        }, 500 * key);
      }
    });
  }, [urlsArray]);

  useEffect(() => {
    if (loadingStatus) {
      setTimeout(() => {
        setLoadingStatus?.(false);
        openMultipleTabs();
      }, 5000);
    }
  }, [loadingStatus, openMultipleTabs, setLoadingStatus]);

  return (
    <Row className="minHeight-100">
      <div className="d-flex align-items-center pb-lg">
        <div className="w-100">
          <Row className="text-center m-auto search-customer w-100 pt-lg">
            <Col md={3} className="pt-lg m-auto">
              <div className="horizontal-divider" />
            </Col>
            <Col md={6}>
              <ArrowUpRight48Regular style={{ color: "var(--primary)" }} />
              <div>
                <h4 className="py-lg">{title}</h4>
              </div>
              <div
                className="redirect-description"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </Col>
            <Col md={3} className="pt-lg m-auto">
              <div className="horizontal-divider" />
            </Col>
          </Row>
        </div>
      </div>
    </Row>
  );
}
