import { Warning16Filled } from "@fluentui/react-icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { hasResponseError } from "api/libs/responseError";
import { useTranslation } from "hooks/use-translate";
import { isValidOrganizationNumberOrSSN } from "libs/is-valid-organization-number-or-ssn";
import { normalizeOrgNrAndSSN } from "libs/number-format";
import { AppRouteHelper } from "routes";
import { CustomerSearchComponent } from "shared/CustomerSearch";
import { RootState } from "state";
import { SearchBy, fetchCompanyInfoFromSales } from "state/offer/companyThunks";
import { resetCompanyInfo } from "state/offer/offersSlice";
import { resetOffer } from "state/offer/offersThunks";
import {
  fetchCustomerContactsByOrgNr,
  fetchDealsByOrgNumber,
  resetCustomerContacts,
  setIsNewDealCustomer,
} from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import { appendError } from "state/notifications";
import { BusinessOpportunityWizardRouteHelper } from "../BusinessOpportunityWizardRoutes";
import { CreateBusinessOpportunityContext } from "../CreateBusinessOpportinityContext";
import WizardSectionBusinessOpportunity from "../WizardSectionBusinessOpportinity";

export default function CustomerSearch() {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { resetContext } = useContext(CreateBusinessOpportunityContext);

  const [searchBarParams] = useSearchParams();
  const customerNumber = searchBarParams.get("customer");

  const { data: companyInfo, status: companyStatus } = useSelector(
    (state: RootState) => state.offers.companyInfo
  );

  const [customerId, setCustomerId] = useState(customerNumber || "");
  const [searchCustomerBy, setSearchCustomerBy] = useState<SearchBy>(
    customerId.length > 8 ? SearchBy.OrgNumber : SearchBy.CustomerNumber
  );
  const [searchErrorHasOccurred, setSearchErrorHasOccurred] = useState(false);

  const isInvalidOrgNumber =
    searchCustomerBy === SearchBy.OrgNumber &&
    !isValidOrganizationNumberOrSSN(customerId);

  useEffect(() => {
    if (!customerNumber) {
      setCustomerId("");
    }
    dispatch(resetCustomerContacts());
    dispatch(resetCompanyInfo());
  }, [customerNumber, dispatch]);

  useEffect(() => {
    const init = async () => {
      resetContext();
      dispatch(setIsNewDealCustomer(false));
      dispatch(resetCompanyInfo());
      dispatch(resetOffer());
    };

    init();
  }, [dispatch, resetContext]);

  const fetchCompanyData = async () => {
    const normalizedNumber = normalizeOrgNrAndSSN(customerId);
    if (isInvalidOrgNumber || !normalizedNumber) {
      return;
    }

    try {
      const tempCustomerResult = await dispatch(
        fetchCompanyInfoFromSales({
          customerId: normalizedNumber,
          searchByParam: searchCustomerBy,
        })
      );

      if (tempCustomerResult.meta.requestStatus === "rejected") {
        throw tempCustomerResult.payload;
      }

      const tempCustomer = unwrapResult(tempCustomerResult);

      if (tempCustomer.org_number) {
        dispatch(fetchDealsByOrgNumber(tempCustomer.org_number));
        dispatch(fetchCustomerContactsByOrgNr(tempCustomer.org_number));
      }
      navigate(BusinessOpportunityWizardRouteHelper.getCustomerDetailsRoute());
    } catch (error) {
      if (hasResponseError(404, error)) {
        setSearchErrorHasOccurred(true);
      } else {
        dispatch(
          appendError("FAILED_TO_FETCH_CUSTOMER", {
            cause: error,
          } as Error)
        );
      }
    }
  };

  const goToRegisterCustomer = () => {
    dispatch(setIsNewDealCustomer(true));
    navigate(BusinessOpportunityWizardRouteHelper.getNewCustomerDetailsRoute());
  };

  function formatErrorMessage(message: string) {
    return (
      <span style={{ color: "var(--red)" }}>
        <Warning16Filled className="mr-xs" />
        {message}
      </span>
    );
  }

  const searchCustomerErrorMessage = () => {
    if (customerId === "") {
      return "";
    }

    if (isInvalidOrgNumber) {
      return formatErrorMessage(translate("INVALID_ORGANIZATION_NUMBER"));
    }

    if (searchErrorHasOccurred) {
      if (searchCustomerBy === SearchBy.CustomerNumber) {
        return formatErrorMessage(translate("INVALID_CUSTOMER_NUMBER"));
      }

      return formatErrorMessage(translate("ORGANIZATION_NUMBER_NOT_FOUND"));
    }

    return "";
  };

  const clearInputErrorMsg = () => {
    setSearchErrorHasOccurred(false);
  };

  return (
    <WizardSectionBusinessOpportunity
      loadingStatus={companyStatus}
      isNextDisabled={!companyInfo}
      subtitles={[translate("BUSINESS_DESCRIPTION")]}
      backLabel="GO_TO_CREATE_NEW_SCREEN"
      customBackButtonRoute={AppRouteHelper.getCreateNew()}
    >
      <CustomerSearchComponent
        fetchCompanyData={fetchCompanyData}
        goToRegisterCustomer={goToRegisterCustomer}
        searchErrorHasOccurred={searchErrorHasOccurred}
        searchCustomerErrorMessage={searchCustomerErrorMessage()}
        clearInputErrorMsg={clearInputErrorMsg}
        setSearchCustomerBy={setSearchCustomerBy}
        searchCustomerBy={searchCustomerBy}
        setCustomerIdValue={setCustomerId}
      />
    </WizardSectionBusinessOpportunity>
  );
}
