import { createAsyncThunk } from "@reduxjs/toolkit";
import { UsersAPI } from "../../api/users";
import { authMethod } from "../../auth";

export const fetchUser = createAsyncThunk("users/FETCH_USER", async () => {
  const token = await authMethod.getStoredAccessToken();
  const roles = authMethod.getRolesFromToken(token);
  return UsersAPI.fetchCurrentUser(token, roles);
});

export const fetchUserByIdOrEmail = createAsyncThunk(
  "users/FETCH_USER_BY_ID",
  async (userId: string) => {
    const token = await authMethod.getStoredAccessToken();
    const user = await UsersAPI.fetchUser(token, userId);

    if (!user.id) {
      return undefined;
    }

    return user;
  }
);

export const fetchCurrentUserAzureGroups = createAsyncThunk(
  "users/CURRENT_USER_MEMBER_OF",
  async (userId: string) => {
    const token = await authMethod.getStoredAccessToken();
    const response = await UsersAPI.fetchCurrentUserMemberOf(token, userId);
    return response;
  }
);
