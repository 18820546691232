import { isNumeric } from "../../../libs/is-numeric";
import { Deal } from "../../../models/deals/deal";

export const hasValidOfferURL = (deal: Deal) => {
  if (deal.pog_quote_url) {
    const urlParts = deal.pog_quote_url.split("/");
    const offerId = urlParts[urlParts.length - 1];
    return isNumeric(offerId);
  }
  return false;
};
