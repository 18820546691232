import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowLeft20Regular,
  Checkmark20Regular,
  Warning20Regular,
} from "@fluentui/react-icons";
import { Spinner } from "@fluentui/react-components";

import { Dropdown } from "components/dropdown";
import { RHFInput } from "components/input/RHFInput";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import Switch from "components/switch";
import { useTranslation } from "hooks/use-translate";
import {
  generatePlaceholderEmail,
  isPlaceholderEmail,
} from "libs/generate-placeholder-email";
import { isValidEmail } from "libs/is-valid-email";
import { isValidPhone } from "libs/is-valid-phone";
import { isValidSwedishSSN } from "libs/is-valid-ssn";
import { getDigitsFromString } from "libs/number-format";
import {
  MAX_CHAR_FIRST_NAME,
  MAX_CHAR_LAST_NAME,
} from "constants/maxCharConsts";
import {
  DealContact,
  SigningMethod,
  SigningMethodToPrettyString,
} from "models/offer/DealContact";
import DealContactDetails from "models/offer/DealContactDetails";
import { RootState } from "state";
import { appendToastMessage } from "state/notifications";
import { createOrUpdateCompanyContact } from "state/offer/companyThunks";
import { updateOfferContact } from "state/offer/offersSlice";
import { sendOfferForCalculation } from "state/offer/offersThunks";
import { searchContact } from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import VerticalStepper from "components/verticalStepper";
import { SearchContact } from "models/deals/deal";
import { deleteCustomerContactCallerWrapper } from "state/customerSettings/customerSettingsThunk";
import { SpinnerSize } from "components/spinner";

type ContactsEditModalProps = {
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
  contactDetails?: DealContactDetails;
  isInOffer?: boolean;
};
export default function ContactsEditModal({
  isOpen,
  onToggle,
  contactDetails,
  isInOffer = false,
}: ContactsEditModalProps) {
  const { translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const initContactFormState = {
    isLoading: false,
    isInitialDataLoaded: false,
    emailVerifyLoading: false,
    isEnteringEmail: false,
    contactExists: false,
    existingContactError: "",
  };

  const [contactFormState, setContactFormState] =
    useState(initContactFormState);

  const {
    isLoading,
    isInitialDataLoaded,
    emailVerifyLoading,
    isEnteringEmail,
    contactExists,
    existingContactError,
  } = contactFormState;

  const { currentUser } = useSelector((state: RootState) => state.users);
  const offers = useSelector((state: RootState) => state.offers);
  const currentOfferCustomer = offers.currentOffer.data?.customer;
  const currentDealContact = offers.currentOffer.data?.contacts?.find(
    (currentOfferContact) =>
      currentOfferContact.contact.id === contactDetails?.id
  );
  const { data: customerContacts } = useSelector(
    (state: RootState) => state.offers.availableCompanyContacts
  );

  const isNewContact = !contactDetails?.id;

  const {
    register,
    formState: { errors, isValid },
    setValue,
    reset,
    control,
    watch,
    trigger,
    getValues,
  } = useForm<DealContact>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: new DealContact(),
  });

  const contactEmail = watch("contact.email");
  const isSigner = watch("is_signer");

  const socialSecurityNumberOptions = register(
    "contact.social_security_number",
    {
      validate: {
        isValid: (value) => {
          const tempIsSigner = getValues("is_signer");
          if (!tempIsSigner && !value) {
            return true;
          }

          return value.length < 12
            ? translate("INVALID_FULLSSN_MESSAGE")
            : isValidSwedishSSN(value) || translate("INVALID_SSN_MESSAGE");
        },
      },
    }
  );

  const firstNameOptions = register("contact.first_name", {
    required: translate("FIRSTNAME_REQUIRED"),
  });

  const lastNameOptions = register("contact.last_name", {
    required: translate("LASTNAME_REQUIRED"),
  });

  const emailOptions = register("contact.email", {
    required: translate("EMAIL_REQUIRED"),
    validate: {
      isValid: (value) =>
        isValidEmail(value) || translate("EMAIL_VALIDATION_MESSAGE"),
    },
  });

  const phoneOptions = register("contact.phone_number", {
    validate: {
      isValid: (value) => {
        if (!value) {
          return true;
        }

        return isValidPhone(value) || translate("PHONE_VALIDATION_MESSAGE");
      },
    },
  });

  const mobilePhoneOptions = register("contact.mobile_phone", {
    required: translate("MOBILE_PHONE_REQUIRED"),
    validate: {
      isValid: (value) => {
        return (
          isValidPhone(value) || translate("MOBILE_PHONE_VALIDATION_MESSAGE")
        );
      },
    },
  });

  const mergeContactAndDetails = (
    contact?: DealContact,
    details?: DealContactDetails
  ) => {
    return new DealContact({
      ...contact,
      contact: details,
    });
  };

  const handleCloseModal = () => {
    setContactFormState((prev) => ({
      ...prev,
      contactExists: false,
      existingContactError: "",
      isInitialDataLoaded: false,
    }));
    reset();
    onToggle(false);
  };

  async function updateOrSaveContact(data: DealContact): Promise<void> {
    if (isValid) {
      setContactFormState((prev) => ({
        ...prev,
        isLoading: true,
      }));
      if (!currentOfferCustomer?.id && isInOffer) {
        dispatch(updateOfferContact(data));
        await dispatch(sendOfferForCalculation());
        dispatch(appendToastMessage("DRAFT_OFFER_CREATED", "success"));
      }
      const customerNumber = currentOfferCustomer?.customer_number;
      if (
        contactDetails &&
        customerNumber &&
        data.contact.id !== contactDetails.id
      ) {
        await dispatch(
          deleteCustomerContactCallerWrapper({
            customerNumber,
            contactEmail: contactDetails.email,
            showToastMessage: false,
          })
        );
      }

      try {
        const refreshedContact = await dispatch(
          createOrUpdateCompanyContact({
            contactData: data.contact,
            orgNum: currentOfferCustomer?.org_number ?? "",
          })
        ).unwrap();
        if (refreshedContact) {
          const refreshedDealContact = new DealContact({
            ...data,
            contact: refreshedContact,
          });

          if (isInOffer) {
            dispatch(updateOfferContact(refreshedDealContact));
          }
        }
        dispatch(
          appendToastMessage(
            isNewContact
              ? "CREATE_NEW.CONTRACT.CONTACT_PERSON_MODAL.CREATE_CONTACT_SUCCESS"
              : "CREATE_NEW.CONTRACT.CONTACT_PERSON_MODAL.UPDATE_CONTACT_SUCCESS",
            "success"
          )
        );
      } catch (error) {
        dispatch(appendToastMessage("SOMETHING_WENT_WRONG", "error"));
      } finally {
        setContactFormState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
    }
  }

  const resetContactDetails = (contactFound: SearchContact | undefined) => {
    if (contactFound) {
      const fetchedContact = {
        email: contactEmail,
        first_name: contactFound.firstName,
        last_name: contactFound.lastName,
        mobile_phone: contactFound.mobile,
        social_security_number: contactFound.social_security_number,
        phone_number: contactFound.phone,
      };

      reset(
        new DealContact({ contact: fetchedContact } as Partial<DealContact>)
      );
      trigger();
    } else {
      reset(
        new DealContact({
          contact: { email: contactEmail },
        } as Partial<DealContact>)
      );
      trigger();
    }
  };

  const setContactExistsError = (
    contactFound: SearchContact,
    isCustomerContact?: DealContactDetails
  ) => {
    if (isCustomerContact) {
      setContactFormState((prev) => ({
        ...prev,
        existingContactError: translate(
          "CREATE_NEW.CONTRACT.CONTACT_PERSON_MODAL.CONTACT_EXISTS_CUSTOMER"
        ),
        contactExists: true,
      }));
    } else {
      setValue("contact.id", contactFound.id);
    }
  };

  const fetchContact = async () => {
    if (!isValidEmail(contactEmail)) {
      return;
    }
    setContactFormState((prev) => ({
      ...prev,
      isEnteringEmail: false,
    }));
    if (contactDetails && contactDetails.email === contactEmail) {
      return;
    }
    if (!isPlaceholderEmail(contactEmail)) {
      setContactFormState((prev) => ({
        ...prev,
        emailVerifyLoading: true,
      }));
      const contacts = await dispatch(searchContact(contactEmail));
      setContactFormState((prev) => ({
        ...prev,
        emailVerifyLoading: false,
      }));
      const contactFound = contacts && contacts[0];

      if (contactFound) {
        const isCustomerContact = customerContacts.find(
          (customerContact) => customerContact.id === contactFound.id
        );
        setContactExistsError(contactFound, isCustomerContact);
      } else {
        setContactFormState((prev) => ({
          ...prev,
          contactExists: false,
        }));
      }
      if (isNewContact) {
        resetContactDetails(contactFound);
      }
    }
  };

  const handleOnSave = async () => {
    const tempContact = getValues();
    tempContact.contact.created_by_username = tempContact.contact
      .created_by_username
      ? tempContact.contact.created_by_username
      : currentUser.email;
    tempContact.contact.changed_by_username = currentUser.email;

    if (isPlaceholderEmail(contactEmail)) {
      tempContact.signing_method = SigningMethod.PHYSICAL;
    }
    await updateOrSaveContact(tempContact);
    handleCloseModal();
  };

  useEffect(() => {
    reset(mergeContactAndDetails(currentDealContact, contactDetails));
    setContactFormState((prev) => ({
      ...prev,
      isInitialDataLoaded: true,
    }));
  }, [contactDetails, currentDealContact, reset]);

  const isDisabled =
    isLoading ||
    emailVerifyLoading ||
    contactExists ||
    isEnteringEmail ||
    !isValidEmail(contactEmail);

  const getNextButtonLabel = () => {
    if (isNewContact) {
      return translate(
        "CREATE_NEW.CONTRACT.CONTACT_PERSON_MODAL.CREATE_CONTACT"
      );
    }
    return translate("CREATE_NEW.CONTRACT.CONTACT_PERSON_MODAL.UPDATE_CONTACT");
  };

  const getSigningOptions = () => {
    const bankIdOption = {
      value: SigningMethod.BANKID,
      label: SigningMethodToPrettyString(SigningMethod.BANKID),
    };

    const physicalOption = {
      value: SigningMethod.PHYSICAL,
      label: SigningMethodToPrettyString(SigningMethod.PHYSICAL),
    };

    return [
      ...(isPlaceholderEmail(contactEmail) ? [] : [bankIdOption]),
      physicalOption,
    ];
  };

  const handleOnToggleEmail = (isChecked?: boolean) => {
    const email = isChecked ? generatePlaceholderEmail() : "";
    setValue("contact.email", email, { shouldValidate: true });
    trigger();
  };

  const renderEmailForm = () => {
    return (
      <>
        <Row className="pr-xl">
          <Col lg={4}>
            <label className="fw-semibold">{translate("EMAIL")} *</label>
            <RHFInput
              type="email"
              required
              placeholder="email@example.com"
              errorMessage={
                contactExists ? (
                  <>
                    <Warning20Regular className="mr-xs" />
                    {existingContactError}
                  </>
                ) : (
                  errors.contact?.email?.message
                )
              }
              disabled={isLoading || isPlaceholderEmail(contactEmail ?? "")}
              register={emailOptions}
              onChange={({ target }) => {
                setContactFormState((prev) => ({
                  ...prev,
                  contactExists: false,
                }));
                setValue("contact.email", target.value, {
                  shouldValidate: true,
                });
              }}
              onBlur={() => {
                fetchContact();
              }}
              onKeyDown={() => {
                setContactFormState((prev) => ({
                  ...prev,
                  isEnteringEmail: true,
                }));
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="mt-sm">
            <Switch
              disabled={isLoading}
              checked={isPlaceholderEmail(contactEmail ?? "")}
              label={translate("CONTACT_MISSING_EMAIL")}
              onToggleMethod={(e, { checked }) => handleOnToggleEmail(checked)}
            />
          </Col>
        </Row>
      </>
    );
  };

  const renderContactDetailsForm = () => {
    return (
      <>
        <Row className="mb-md pr-xl">
          <Col lg={4}>
            <label>{translate("FIRST_NAME")} *</label>
            <RHFInput
              placeholder={translate("FIRST_NAME")}
              errorMessage={errors.contact?.first_name?.message}
              disabled={isDisabled}
              register={firstNameOptions}
              maxChar={MAX_CHAR_FIRST_NAME}
              value={getValues("contact.first_name")}
              onChange={({ target }) => {
                setValue("contact.first_name", target.value, {
                  shouldValidate: true,
                });
              }}
            />
          </Col>
          <Col lg={4}>
            <label>{translate("LAST_NAME")} *</label>
            <RHFInput
              placeholder={translate("LAST_NAME")}
              errorMessage={errors.contact?.last_name?.message}
              disabled={isDisabled}
              register={lastNameOptions}
              maxChar={MAX_CHAR_LAST_NAME}
              value={getValues("contact.last_name")}
              onChange={({ target }) => {
                setValue("contact.last_name", target.value, {
                  shouldValidate: true,
                });
              }}
            />
          </Col>
          <Col lg={4}>
            <label>{`${translate("SSN")}${isSigner ? " * " : ""}`}</label>
            <RHFInput
              placeholder={translate("SSN_PLACEHOLDER")}
              errorMessage={errors.contact?.social_security_number?.message}
              disabled={isDisabled}
              register={socialSecurityNumberOptions}
              onChange={({ target }) => {
                setValue(
                  "contact.social_security_number",
                  getDigitsFromString(target.value),
                  { shouldValidate: true }
                );
              }}
            />
          </Col>
        </Row>
        <Row className="mb-md pr-xl">
          <Col lg={4}>
            <label>{translate("PHONE_NUM")}</label>
            <RHFInput
              type="text"
              placeholder={translate("PHONE_NUM")}
              errorMessage={errors.contact?.phone_number?.message}
              disabled={isDisabled}
              register={phoneOptions}
              onChange={({ target }) => {
                setValue(
                  "contact.phone_number",
                  getDigitsFromString(target.value),
                  {
                    shouldValidate: true,
                  }
                );
              }}
            />
          </Col>
          <Col lg={4}>
            <label>{translate("MOBILE_PHONE")} *</label>
            <RHFInput
              type="text"
              placeholder={translate("MOBILE_PHONE")}
              errorMessage={errors.contact?.mobile_phone?.message}
              disabled={isDisabled}
              register={mobilePhoneOptions}
              onChange={({ target }) => {
                setValue(
                  "contact.mobile_phone",
                  getDigitsFromString(target.value),
                  { shouldValidate: true }
                );
              }}
            />
          </Col>
          {isInOffer && (
            <Col lg={4}>
              <div>
                <div>
                  <label>{translate("SIGNING")} *</label>
                  <Controller
                    name="signing_method"
                    control={control}
                    render={({ field }) => (
                      <Dropdown<SigningMethod>
                        disabled={isDisabled}
                        options={getSigningOptions()}
                        value={
                          isPlaceholderEmail(getValues("contact.email"))
                            ? SigningMethod.PHYSICAL
                            : field.value
                        }
                        placeholder={translate(
                          "PLACEHOLDER_SELECT_SIGNING_METHOD"
                        )}
                        onChange={(signingMethod) => {
                          field.onChange(signingMethod);
                          field.onBlur();
                        }}
                        errorMessage={errors.signing_method?.message}
                      />
                    )}
                  />
                </div>
                <div className="mt-sm">
                  <Controller
                    name="is_signer"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <Switch
                        disabled={isDisabled}
                        checked={field.value}
                        label={translate("LABEL_MAKE_CONTACT_SIGNER")}
                        onToggleMethod={(_, { checked }) => {
                          field.onChange(checked);
                          field.onBlur();

                          trigger("contact.social_security_number");
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </Col>
          )}
        </Row>
      </>
    );
  };

  const contactPersonSteps = [
    {
      key: "1",
      label: translate("EMAIL_VERIFICATION"),
      circleLabel: "A",
      component: renderEmailForm(),
      state: {
        highlighted: !contactEmail || !isValidEmail(contactEmail),
        active: true,
        disabled: emailVerifyLoading,
      },
    },
    {
      key: "2",
      label: translate("CONTACT_PERSON_DETAILS"),
      circleLabel: "B",
      component:
        (!isValidEmail(contactEmail) ||
          isEnteringEmail ||
          emailVerifyLoading) &&
        isNewContact ? (
          <span className="text-gray fs-italic">
            {translate("EMAIL_VERIFY_INFO")}
          </span>
        ) : (
          renderContactDetailsForm()
        ),
      state: {
        highlighted: !!contactEmail,
        active: !contactEmail || !isValidEmail(contactEmail),
        disabled: emailVerifyLoading,
      },
    },
  ];

  return (
    <Modal
      onDismiss={handleCloseModal}
      isOpen={isOpen}
      size="large"
      isLoading={emailVerifyLoading || isLoading}
      footer={
        <ModalFooter
          isDisabled={isDisabled || !isValid}
          onCancel={() => handleCloseModal()}
          onSave={() => handleOnSave()}
          labelSubmit={getNextButtonLabel()}
          labelCancel="CANCEL"
          cancelButtonIcon={<ArrowLeft20Regular />}
          sendButtonIcon={<Checkmark20Regular />}
        />
      }
      header={
        <ModalHeader
          isLoading={emailVerifyLoading || isLoading}
          subheaderText={
            isNewContact
              ? "CREATE_NEW.CONTRACT.CONTACT_PERSON_MODAL.CREATE_NEW_CONTACT.SUBHEADER"
              : "CREATE_NEW.CONTRACT.CONTACT_PERSON_MODAL.EDIT_CONTACT.SUBHEADER"
          }
          headerTitleText={
            isNewContact
              ? "CREATE_NEW.CONTRACT.CONTACT_PERSON_MODAL.CREATE_NEW_CONTACT.HEADER"
              : "CREATE_NEW.CONTRACT.CONTACT_PERSON_MODAL.EDIT_CONTACT.HEADER"
          }
        />
      }
    >
      {isInitialDataLoaded ? (
        <VerticalStepper steps={contactPersonSteps} />
      ) : (
        <Spinner size={SpinnerSize.ExtraSmall} />
      )}
    </Modal>
  );
}
