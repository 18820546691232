import {
  Circle16Filled,
  SpinnerIos16Regular,
  Warning16Regular,
} from "@fluentui/react-icons";

export type StatusIconColor =
  | "active"
  | "inactive"
  | "nonexistent"
  | "offline"
  | "loading"
  | "busy"
  | "online"
  | "away"
  | "undefined"
  | "red"
  | "blue"
  | "green"
  | "yellow";

export type StatusIndicatorIconProps = React.SVGAttributes<SVGElement> & {
  /**
   * status "active" will show a green circle, "inactive" red, "nonexistent" gray
   */
  status?: StatusIconColor;
};

export default function StatusIndicatorIcon({
  status = "red",
  color,
  style,
  ...rest
}: StatusIndicatorIconProps) {
  const statusColor =
    (status === "active" && "blue") ||
    (status === "inactive" && "yellow") ||
    (status === "nonexistent" && "gray-40") ||
    (status === "red" && "red") ||
    (status === "blue" && "blue") ||
    (status === "green" && "green") ||
    (status === "yellow" && "yellow");

  if (status === "undefined" || !status) {
    return null;
  }

  if (status === "offline") {
    return (
      <Warning16Regular
        {...rest}
        role="status"
        data-testid="status-icon"
        style={{ color: `var(--red)`, ...style }}
      />
    );
  }

  if (status === "loading") {
    return (
      <SpinnerIos16Regular {...rest} role="status" data-testid="status-icon" />
    );
  }

  return (
    <Circle16Filled
      {...rest}
      role="status"
      data-testid="status-icon"
      style={{ color: color || `var(--${statusColor})`, ...style }}
    />
  );
}
