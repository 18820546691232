import { useMemo } from "react";
import { useEventCallback } from "@fluentui/react-utilities";
import type { TableRowId } from "@fluentui/react-components";
import type { ExpandingHookParams, TableExpandingState } from "./types";
import { noop } from "../utils";
import { TableFeaturesExtendedState } from "../useTableFeaturesExtended";
import { useExpandingRows } from "./useExpandingRows";

export const defaultTableExpandingState: TableExpandingState = {
  expandedRows: new Set(),
  foldAllRows: noop,
  expandRow: noop,
  foldRow: noop,
  toggleRow: noop,
  toggleAllRows: noop,
  allRowsExpanded: false,
  someRowsExpanded: false,
  isRowExpanded: () => false,
};

export function useTableExpandingRows<TItem>(options: ExpandingHookParams) {
  // https://github.com/microsoft/fluentui/blob/710e528eb20755a81ebd60ddb4d99e4dad26f7d6/packages/react-components/react-table/src/hooks/useTableSelection.ts#L20
  // False positive, these plugin hooks are intended to be run on every render
  return (tableState: TableFeaturesExtendedState<TItem>) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTableExpandingRowsState(tableState, options);
}

export function useTableExpandingRowsState<TItem>(
  tableState: TableFeaturesExtendedState<TItem>,
  options: ExpandingHookParams
): TableFeaturesExtendedState<TItem> {
  const { items, getRowId } = tableState;
  const {
    expandingMode,
    defaultExpandedItems,
    expandedItems,
    onExpandingChange,
  } = options;

  const [expanded, expandingMethods] = useExpandingRows({
    expandingMode,
    defaultExpandedItems,
    expandedItems,
    onExpandingChange,
  });

  const expandableRowIds = useMemo(() => {
    const rowIds = new Set<TableRowId>();
    for (let i = 0; i < items.length; i++) {
      rowIds.add(getRowId?.(items[i]) ?? i);
    }
    return rowIds;
  }, [items, getRowId]);

  const allRowsExpanded = useMemo(() => {
    if (expanded.size < expandableRowIds.size) {
      return false;
    }

    let res = true;
    expandableRowIds.forEach((expandableRowId) => {
      if (!expanded.has(expandableRowId)) {
        res = false;
      }
    });

    return res;
  }, [expandableRowIds, expanded]);

  const someRowsExpanded = useMemo(() => {
    if (expanded.size <= 0) {
      return false;
    }

    let res = false;
    expandableRowIds.forEach((expandableRowId) => {
      if (expanded.has(expandableRowId)) {
        res = true;
      }
    });

    return res;
  }, [expandableRowIds, expanded]);

  const toggleAllRows: TableExpandingState["toggleAllRows"] = useEventCallback(
    (e) => {
      expandingMethods.toggleAllItems(
        e,
        items.map((item, i) => getRowId?.(item) ?? i)
      );
    }
  );

  const toggleRow: TableExpandingState["toggleRow"] = useEventCallback(
    (e, rowId: TableRowId) => expandingMethods.toggleItem(e, rowId)
  );

  const foldRow: TableExpandingState["foldRow"] = useEventCallback(
    (e, rowId: TableRowId) => expandingMethods.foldItem(e, rowId)
  );

  const expandRow: TableExpandingState["expandRow"] = useEventCallback(
    (e, rowId: TableRowId) => expandingMethods.expandItem(e, rowId)
  );

  const isRowExpanded: TableExpandingState["isRowExpanded"] = (
    rowId: TableRowId
  ) => expandingMethods.isExpanded(rowId);

  const foldAllRows: TableExpandingState["foldAllRows"] = useEventCallback(
    (e) => expandingMethods.foldItems(e)
  );

  return {
    ...tableState,
    expandingRows: {
      allRowsExpanded,
      someRowsExpanded,
      expandedRows: expanded,
      toggleAllRows,
      toggleRow,
      foldRow,
      expandRow,
      isRowExpanded,
      foldAllRows,
    },
  };
}
