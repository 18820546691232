import { plainToClass } from "class-transformer";
import { addQuarters } from "date-fns";
import { rest } from "msw";
import { MOCK_DELAY } from "..";
import { uuidv4 } from "../../../libs/uuid";
import {
  CustomerDelivery,
  DeliveryFact,
  DeliveryRecurrence,
} from "../../../models/deliveryPlan";
import {
  GENERAL_ACCOUNTING_DELIVERY,
  YEAR_END_DELIVERY,
} from "../../../state/customers/actions";

const BASE_PATH = process.env.REACT_APP_API_URL;
const deliveries: CustomerDelivery[] = [
  plainToClass(CustomerDelivery, {
    uuid: "f4f3c00e-7030-4218-8860-63ee7dedb89b",
    org_number: "5500000006",
    delivery_name: GENERAL_ACCOUNTING_DELIVERY,
    delivery_dates: [],
    recurrence: "MONTHLY",
    start_date: new Date("2022-03-01"),
    is_locked_in: true,
    packages: [
      {
        uuid: "6fd2e426-c899-4973-a2e4-04228dd21eb5",
        delivery_uuid: "f4f3c00e-7030-4218-8860-63ee7dedb89b",
        package_title: "1",
        package_key_str: "AccPackMstd",
      },
      {
        uuid: "5a1d5045-68b7-40de-8031-047a28adcdbc",
        delivery_uuid: "f4f3c00e-7030-4218-8860-63ee7dedb89b",
        package_title: "6",
        package_key_str: "VatM",
      },
    ],
  }),
];

function getDeliveryDatesFromRecurrence(
  startDate: Date,
  recurrence: DeliveryRecurrence
) {
  return {
    ONEOFF: [startDate],
    WEEKLY: [],
    MONTHLY: [],
    QUARTERLY: [
      startDate,
      addQuarters(startDate, 1),
      addQuarters(startDate, 2),
      addQuarters(startDate, 3),
    ],
    YEARLY: [startDate],
  }[recurrence];
}

export const deliveryHandlers = [
  rest.post<string, CustomerDelivery>(
    `${BASE_PATH}/customers/:id/deliveries`,
    (req, res, { status, json, delay }) => {
      const delivery = JSON.parse(req.body);

      const deliveryDates = getDeliveryDatesFromRecurrence(
        new Date(delivery.start_date),
        delivery.recurrence
      );

      return res(
        status(200),
        json({
          ...delivery,
          delivery_dates: deliveryDates,
          uuid: uuidv4(),
        })
      );
    }
  ),
  rest.delete(
    `${BASE_PATH}/customers/:id/deliveries/:deliveryId`,
    (req, res, { status, text, delay }) => {
      return res(status(200), text("OK"));
    }
  ),
  rest.patch<string, CustomerDelivery>(
    `${BASE_PATH}/customers/:id/deliveries/:deliveryId`,
    (req, res, { status, json, delay }) => {
      const delivery = JSON.parse(req.body);
      const existingDelivery = deliveries.find(
        (d) => d.uuid === req.params.deliveryId
      );
      const deliveryDates = getDeliveryDatesFromRecurrence(
        new Date(delivery.start_date),
        delivery.recurrence
      );
      delivery.delivery_dates = deliveryDates;
      return res(
        status(200),
        json({
          ...existingDelivery,
          ...delivery,
        })
      );
    }
  ),
  rest.get<CustomerDelivery[]>(
    `${BASE_PATH}/customers/:id/deliveries`,
    (req, res, { status, json, delay }) => {
      return res(
        status(200),
        delay(MOCK_DELAY),
        json(deliveries.filter((d) => d.org_number === req.params.id))
      );
    }
  ),
  rest.get<DeliveryFact[]>(
    `${BASE_PATH}/customers/:id/available_deliveries`,
    (req, res, { status, json, delay }) => {
      return res(
        delay(MOCK_DELAY),
        status(200),
        json([
          {
            service_area_name: "Ekonomi",
            description_headline: "Bokslut- och deklarationsgenomgång",
            delivery_name: YEAR_END_DELIVERY,
            description: "Vi genomför ditt bokslut.",
            image_url: "https://via.placeholder.com/250",
            intranet_url: undefined,
            packages: [],
            recurrence: "ONEOFF",
            max_instances_per_year: 1,
            deactivated: false,
            hidden: false,
            deletion_disabled: false,
          },
          {
            service_area_name: "Ekonomi",
            description_headline: "Tjänst #1",
            delivery_name: "Service 1",
            description: "Beskrivning av tjänsten.",
            image_url: "https://via.placeholder.com/250",
            intranet_url: undefined,
            packages: [],
            recurrence: "ONEOFF",
            max_instances_per_year: 1,
            deactivated: false,
            hidden: false,
            deletion_disabled: false,
          },
          {
            service_area_name: "Ekonomi",
            description_headline: "Tjänst #2",
            delivery_name: "Service 2",
            description: "Beskrivning av tjänsten.",
            image_url: "https://via.placeholder.com/250",
            intranet_url: undefined,
            packages: [],
            recurrence: "ONEOFF",
            max_instances_per_year: 1,
            deactivated: false,
            hidden: false,
            deletion_disabled: false,
          },
          {
            service_area_name: "Ekonomi",
            description_headline: "Avaktiverad Tjänst #3",
            delivery_name: "Service 3",
            description: "Beskrivning av tjänsten.",
            image_url: "https://via.placeholder.com/250",
            intranet_url: undefined,
            packages: [],
            recurrence: "ONEOFF",
            max_instances_per_year: 1,
            deactivated: true,
            hidden: false,
            deletion_disabled: false,
          },
          {
            service_area_name: "Ekonomi",
            description_headline: "Löpande redovisning",
            delivery_name: GENERAL_ACCOUNTING_DELIVERY,
            description:
              "Vi utför löpande redovisning i enlighet med lagar, regler och allmänna rekommendationer",
            image_url: "https://via.placeholder.com/250",
            intranet_url: "/intranät/Tjänster/beskrivning",
            packages: [],
            recurrence: "MONTHLY",
            max_instances_per_year: 1,
            deactivated: false,
            hidden: false,
            deletion_disabled: false,
          },
          {
            service_area_name: "Ekonomi",
            description_headline: "Lön",
            delivery_name: "Salary",
            description:
              "Vi betalar löner till dina anställda och redovisar till Skatteverket",
            image_url: "https://via.placeholder.com/250",
            intranet_url: "/intranät/Tjänster/beskrivning",
            packages: [],
            recurrence: "MONTHLY",
            max_instances_per_year: 1,
            deactivated: false,
            hidden: false,
            deletion_disabled: false,
          },
          {
            service_area_name: "Affärsrådgivning",
            description_headline: "Affärsrådgivning",
            delivery_name: "Advisory 1",
            description: "Beskrivning av affärsrådgivning.",
            image_url: "https://via.placeholder.com/250",
            intranet_url: "https://intranät/Tjänster/beskrivning",
            packages: [],
            recurrence: "ONEOFF",
            max_instances_per_year: 1,
            deactivated: false,
            hidden: false,
            deletion_disabled: false,
          },
          {
            service_area_name: "Affärsrådgivning",
            description_headline: "Affärsrådgivning #2",
            delivery_name: "Advisory 2",
            description: "Beskrivning av affärsrådgivning.",
            image_url: "https://via.placeholder.com/250",
            intranet_url: "https://intranät/Tjänster/beskrivning",
            packages: [],
            recurrence: "ONEOFF",
            max_instances_per_year: 1,
            deactivated: false,
            hidden: false,
            deletion_disabled: false,
          },
        ] as DeliveryFact[])
      );
    }
  ),
];
