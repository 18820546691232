import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbDivider,
  BreadcrumbButton,
  BreadcrumbProps,
} from "@fluentui/react-components";

import TooltipV9 from "components/tooltip/TooltipV9";

export type BreadcrumbDefinitionItem = {
  text: string | JSX.Element;
  onClick?: () => void;
};

type Props = {
  items: BreadcrumbDefinitionItem[];
  maxElements?: number;
  shouldTruncate?: boolean;
  className?: string;
} & BreadcrumbProps;

export default function BreadcrumbV9({
  items,
  maxElements = 2,
  shouldTruncate = false,
  className = "",
  size,
}: Props) {
  const breadcrumbItems = shouldTruncate
    ? items.slice(0, maxElements).concat(items.slice(-1))
    : items;
  const truncatedBreadcrumbs = items
    .slice(maxElements, items.length - 1)
    .map((item) => item.text)
    .join(" > ");

  const generateKey = (
    text: BreadcrumbDefinitionItem["text"],
    index: number
  ) => {
    return `breadcrumb-${typeof text === "string" ? text : index}`;
  };

  return (
    <Breadcrumb size={size} className={className}>
      {breadcrumbItems.map((breadcrumb, index) => (
        <React.Fragment key={generateKey(breadcrumb.text, index)}>
          {shouldTruncate && index === breadcrumbItems.length - 1 && (
            <>
              <BreadcrumbItem>
                <TooltipV9 content={truncatedBreadcrumbs} notTranslatable>
                  <BreadcrumbButton>...</BreadcrumbButton>
                </TooltipV9>
              </BreadcrumbItem>
              <BreadcrumbDivider />
            </>
          )}
          <BreadcrumbItem>
            <BreadcrumbButton
              className={index === 0 ? "pl-0" : ""}
              onClick={breadcrumb.onClick}
              current={index === breadcrumbItems.length - 1}
            >
              {breadcrumb.text}
            </BreadcrumbButton>
          </BreadcrumbItem>
          {index !== breadcrumbItems.length - 1 && <BreadcrumbDivider />}
        </React.Fragment>
      ))}
    </Breadcrumb>
  );
}
