/* eslint-disable no-underscore-dangle */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Spinner } from "@fluentui/react-components";
import { Dismiss16Regular, Info16Regular } from "@fluentui/react-icons";

import { AppDispatch } from "state/use-app-redux";
import { useTranslation } from "hooks/use-translate";
import { Deal, DealContact, SearchContact } from "models/deals/deal";
import VerticalStepper from "components/verticalStepper";
import { RHFInput } from "components/input/RHFInput";
import { UseFormReset, useFormContext } from "react-hook-form";
import { isValidEmail } from "libs/is-valid-email";
import { searchContact } from "state/sales/actions";
import { MAX_CHAR_EMAIL, MAX_CHAR_FIRST_NAME } from "constants/maxCharConsts";
import { isValidPhone } from "libs/is-valid-phone";
import { getDigitsFromString } from "libs/number-format";
import { Row, Col } from "react-bootstrap";
import { SpinnerSize } from "components/spinner";

type StageDealContactsProps = {
  formReset: UseFormReset<Partial<Deal>>;
};

export function StageDealContacts({ formReset }: StageDealContactsProps) {
  const dispatch: AppDispatch = useDispatch();

  const { translate } = useTranslation();

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useFormContext<Partial<Deal>>();

  const [isLoadingContact, setIsLoadingContact] = useState(false);
  const [isEmailFilled, setIsEmailFilled] = useState(
    !!getValues("contact.email")
  );
  const [isExistingContact, setIsExistingContact] = useState(false);

  const populateFetchedContactValues = (contact: SearchContact) => ({
    id: contact.id,
    firstname: contact.firstName,
    lastname: contact.lastName,
    email: contact.email,
    mobilephone: contact.mobile,
    organization: contact.organization,
    registrationNumber: contact.registrationNumber,
    roles: contact.roles,
    phone: contact.phone,
    address: contact.address,
    city: contact.city,
    country: contact.country,
    zip: contact.zip,
    aterkommande_aktiviteter: contact.aterkommande_aktiviteter,
    aktivitet_event: contact.aktivitet_event,
  });

  const fetchContact = async (email: string) => {
    if (!isValidEmail(email)) {
      return;
    }
    setIsEmailFilled(true);
    setIsLoadingContact(true);
    try {
      const contacts = await dispatch(searchContact(email));
      const contactsFound = contacts && contacts.length !== 0;
      if (contactsFound) {
        const contact: SearchContact = contacts[0];

        formReset(
          new Deal({
            contact: new DealContact(populateFetchedContactValues(contact)),
          }),
          { keepDirtyValues: true, keepDirty: true, keepIsValid: true }
        );
        setIsExistingContact(true);
      } else {
        setIsExistingContact(false);
      }
    } finally {
      setIsLoadingContact(false);
    }
  };

  const emailOption = register("contact.email", {
    required: translate("ENTER_EMAIL_EXAMPLE"),
    validate: {
      isValid: (value) => {
        if (!value) {
          return true;
        }

        return isValidEmail(value) || translate("VALID_EMAIL");
      },
    },
  });

  const firstNameOptions = register("contact.firstname", {
    required: translate("FIRSTNAME_REQUIRED"),
  });

  const lastNameOptions = register("contact.lastname", {
    required: translate("LASTNAME_REQUIRED"),
  });

  const phoneOptions = register("contact.phone", {
    required: translate("PHONE_REQUIRED"),
    validate: {
      isValid: (value) => {
        if (value) {
          return isValidPhone(value) || translate("PHONE_VALIDATION_MESSAGE");
        }
      },
    },
  });

  const resetFormOnClearEmail = () => {
    setValue("contact.email", "", { shouldValidate: true, shouldDirty: true });
    setValue("contact.lastname", "", {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("contact.lastname", "", {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue("contact.phone", "", {
      shouldValidate: true,
      shouldDirty: true,
    });
    trigger();
    setIsEmailFilled(false);
    setIsExistingContact(false);
  };

  const renderEmailForm = () => (
    <div className="pr-md">
      <div className="d-flex my-md">
        <div>
          <Info16Regular />
        </div>
        <div className="ml-sm">
          {translate("MY_BUSINESS_OPPORTUNITIES.PANEL.EMAIL_INFO")}
        </div>
      </div>
      <Row className="bottom-border py-md">
        <Col lg={6}>
          <label className="fw-semibold">{translate("EMAIL")}</label>
        </Col>
        <Col lg={6}>
          {isEmailFilled ? (
            <div className="w-100 border border-dark d-flex justify-content-between pl-sm py-xs align-items-center ">
              <div className="text-grayed stringLength">
                {getValues("contact.email")}
              </div>
              <Dismiss16Regular
                onClick={resetFormOnClearEmail}
                className="mr-sm"
              />
            </div>
          ) : (
            <RHFInput
              type="email"
              register={emailOption}
              disabled={isLoadingContact}
              required
              placeholder="email@example.com"
              maxChar={MAX_CHAR_EMAIL}
              value={getValues("contact.email")}
              errorMessage={errors.contact?.email?.message}
              onBlur={() => {
                fetchContact(getValues("contact.email"));
              }}
              onChange={({ target }) => {
                const { value } = target;
                setValue("contact.email", value, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );

  const renderContactDeatilsForm = () => (
    <>
      {isExistingContact && (
        <div className="d-flex my-md">
          <div>
            <Info16Regular />
          </div>
          <div className="ml-sm">
            {translate("MY_BUSINESS_OPPORTUNITIES.PANEL.CONTACT_DETAILS_INFO")}
          </div>
        </div>
      )}
      <Row className="bottom-border py-md">
        <Col lg={6}>
          <label className="fw-semibold">{translate("FIRST_NAME")}</label>
        </Col>
        <Col lg={6}>
          <RHFInput
            disabled={isLoadingContact}
            placeholder={translate("FIRST_NAME")}
            errorMessage={errors.contact?.firstname?.message}
            register={firstNameOptions}
            maxChar={MAX_CHAR_FIRST_NAME}
            value={getValues("contact.firstname")}
            onChange={({ target }) => {
              const { value } = target;
              setValue("contact.firstname", value, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            onBlur={() => trigger()}
          />
        </Col>
      </Row>
      <Row className="bottom-border py-md">
        <Col lg={6}>
          <label className="fw-semibold">{translate("LAST_NAME")}</label>
        </Col>
        <Col lg={6}>
          <RHFInput
            placeholder={translate("LAST_NAME")}
            disabled={isLoadingContact}
            errorMessage={errors.contact?.lastname?.message}
            register={lastNameOptions}
            maxChar={MAX_CHAR_FIRST_NAME}
            value={getValues("contact.lastname")}
            onChange={({ target }) => {
              const { value } = target;
              setValue("contact.lastname", value, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            onBlur={() => trigger()}
          />
        </Col>
      </Row>
      <Row className="bottom-border py-md">
        <Col lg={6}>
          <label className="fw-semibold">{translate("PHONE_NUM")}</label>
        </Col>
        <Col lg={6}>
          <RHFInput
            type="text"
            disabled={isLoadingContact}
            placeholder={translate("PHONE_NUM")}
            errorMessage={errors.contact?.phone?.message}
            register={phoneOptions}
            onChange={({ target }) => {
              const { value } = target;
              setValue("contact.phone", getDigitsFromString(value), {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            onBlur={() => trigger()}
          />
        </Col>
      </Row>
    </>
  );

  const customerSteps = [
    {
      key: "1",
      label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.EMAIL_VERIFICATION"),
      circleLabel: "A",
      component: renderEmailForm(),
      state: {
        highlighted: !isEmailFilled,
        active: !isEmailFilled,
        disabled: false,
      },
    },
    {
      key: "2",
      label: translate("MY_BUSINESS_OPPORTUNITIES.PANEL.OTHER_DETAILS"),
      circleLabel: "B",
      component:
        isEmailFilled && !isLoadingContact ? (
          renderContactDeatilsForm()
        ) : (
          <div>
            {isLoadingContact ? (
              <Spinner size={SpinnerSize.ExtraSmall} />
            ) : (
              <span className="text-gray fs-italic p-sm">
                {translate("EMAIL_VERIFY_INFO")}
              </span>
            )}
          </div>
        ),
      state: {
        highlighted: isEmailFilled && !isLoadingContact,
        active: isEmailFilled && !isLoadingContact,
        disabled: !isEmailFilled || isLoadingContact,
      },
    },
  ];

  return (
    <div>
      <VerticalStepper steps={customerSteps} />
    </div>
  );
}
