import { Language } from "../i18n";
import { Activity } from "./activities/activity";

export class ServiceDescription {
  Code: string;

  Instruktioner?: {
    Description: string;
    Url: string;
  };

  /**
   * Category (English)
   */
  Category: string;

  /**
   * Category (Swedish)
   */
  Kategori: string;

  /**
   * Service (English)
   */
  Service: string;

  /**
   * Service (English)
   */
  Tjänst: string;

  /**
   * Describes the process (Swedish)
   */
  Processbeskrivning: string;

  /**
   * Describes the process (Swedish)
   */
  Description: string;

  /**
   * Process name (Swedish)
   */
  "Process SE": string;

  /**
   * Process name (English)
   */
  "Process SSC": string;

  /**
   * Mapping between the CB and the service matrix
   */
  "CB aktivitetsID": string;

  ServiceArea: string;

  ServiceAreaSE: string;

  ServiceAreaGrouping: string;

  ServiceLineGrouping: string;

  getCategory(language: Language) {
    return language === "SV" ? this.Kategori : this.Category;
  }

  getServiceName(language: Language) {
    return language === "SV" ? this.Tjänst : this.Service;
  }

  getProcessName(language: Language) {
    return language === "SV" ? this["Process SE"] : this["Process SSC"];
  }

  getDescription(language: Language) {
    return language === "SV" ? this.Processbeskrivning : this.Description;
  }

  getUrl() {
    return this.Instruktioner && this.Instruktioner.Url;
  }

  isMatchingActivity(activity: Activity) {
    const types = (this["CB aktivitetsID"] || "").split(",");
    return types.indexOf(activity.activity_type) > -1;
  }
}
