import { Toaster, useId, useToastController } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "state";
import { dismissMessage, NotificationEntry } from "state/notifications";
import { NotificationToast } from "./NotificationToast";
import "./ToasterNotification.scss";

export function ToasterNotification() {
  const { data } = useSelector((state: RootState) => state.notifications);
  const dispatch = useDispatch();
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [displayedToastIds, setDisplayedToastIds] = useState<number[]>([]);

  useEffect(() => {
    const latestError = data[data.length - 1];
    if (latestError?.isVisible && latestError?.dismissTimeout) {
      const timeoutId = setTimeout(() => {
        dispatch(dismissMessage(latestError));
      }, latestError.dismissTimeout);
      return () => clearTimeout(timeoutId);
    }
  }, [data, dispatch]);

  useEffect(() => {
    data.forEach((message) => {
      if (
        !message.isVisible ||
        displayedToastIds.includes(message.notificationId)
      ) {
        return null;
      }

      const { notificationType: intent } = message;
      const position = "top-end";

      dispatchToast(
        <NotificationToast
          key={message.notificationId}
          message={message}
          onDismiss={(dismissToast: NotificationEntry) =>
            dispatch(dismissMessage(dismissToast))
          }
          notificationActionText={message.notificationActionText}
          notificationActionLink={message.notificationActionLink}
        />,
        { position, intent }
      );

      setDisplayedToastIds((prev) => [...prev, message.notificationId]);
    });
  }, [data, dispatch, dispatchToast, displayedToastIds]);

  return <Toaster toasterId={toasterId} className="toaster" />;
}
