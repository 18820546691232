import {
  ArrowRight16Regular,
  Edit20Regular,
  ToggleLeft20Regular,
} from "@fluentui/react-icons";
import { HTMLAttributes } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@fluentui/react-components";

import { renderIcon } from "libs/render-icon";
import ContextualMenuV9 from "components/contextualMenu/ContextualMenuV9";
import { useTranslation } from "hooks/use-translate";
import { SpinnerSize } from "components/spinner";
import { DetailedInfo } from "models/customerSettings/customerSettingsDetailedInfo";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { RootState } from "state";
import { resetOpenCreateModalFor } from "state/customerSettings/customerSettingsSlice";
import { updateDetailedInfoCallerWrapper } from "state/customerSettings/customerSettingsThunk";
import "./customerPageDetailedInfo.scss";

type CustomerPageInfoContentProps = {
  maxElements?: number;
  maxText?: number;
  onSelectItem?: (arg: DetailedInfo) => void;
  setOpenModal?: (arg: boolean) => void;
  onEditInfo?: (arg: DetailedInfo) => void;
  data?: DetailedInfo[];
  isDetailedPage?: boolean;
  customerManagerId: string;
} & HTMLAttributes<HTMLElement>;

export default function CustomerPageDetailedInfoContent({
  maxElements,
  maxText,
  onSelectItem,
  setOpenModal,
  className,
  data,
  isDetailedPage = false,
  onEditInfo,
  customerManagerId,
  ...rest
}: CustomerPageInfoContentProps) {
  const { translate, language } = useTranslation();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.users);

  const { info } = useSelector(
    (state: RootState) => state.settings.detailedInfo.data
  );
  const { status } = useSelector(
    (state: RootState) => state.settings.detailedInfo
  );
  const searchTerm = useSelector(
    (state: RootState) => state.settings.searchTerm
  );

  const infoToShowFrom = searchTerm
    ? info.filter(
        (infoEl) =>
          infoEl.title.toLowerCase().includes(searchTerm) ||
          infoEl.content.toLowerCase().includes(searchTerm) ||
          (language === "SV"
            ? infoEl.customerdetailssubcategory.subcategory_se
                .toLowerCase()
                .includes(searchTerm)
            : infoEl.customerdetailssubcategory.subcategory_en
                .toLowerCase()
                .includes(searchTerm)) ||
          (language === "SV"
            ? infoEl.customerdetailssubcategory.customerdetailscategory.category_se
                .toLowerCase()
                .includes(searchTerm)
            : infoEl.customerdetailssubcategory.customerdetailscategory.category_en
                .toLowerCase()
                .includes(searchTerm))
      )
    : info;

  const infoToShow = maxElements
    ? infoToShowFrom.slice(0, maxElements)
    : infoToShowFrom;

  const getUniqueCategories = () => {
    const categories = infoToShow.map(
      (element) => element.customerdetailssubcategory.customerdetailscategory
    );
    return categories.filter((value, index, self) => {
      return self.findIndex((v) => v.id === value.id) === index;
    });
  };

  const getUniqueSubcategoriesByCategoryId = (categoryId: number) => {
    const subcategories = infoToShow.filter(
      (detailedInfo) =>
        detailedInfo.customerdetailssubcategory.customerdetailscategory.id ===
        categoryId
    );
    return subcategories.filter((value, index, self) => {
      return (
        self.findIndex(
          (v) =>
            v.customerdetailssubcategory.id ===
            value.customerdetailssubcategory.id
        ) === index
      );
    });
  };

  const getUniqueInfoDataByCategoryAndSubcategoryId = (
    categoryId: number,
    subcategoryId: number
  ) => {
    return infoToShow.filter(
      (detailedInfo) =>
        detailedInfo.customerdetailssubcategory.customerdetailscategory.id ===
          categoryId &&
        detailedInfo.customerdetailssubcategory.id === subcategoryId
    );
  };

  function truncateContent(source: string) {
    return maxText && source.length > maxText
      ? `${source.slice(0, maxText)} …`
      : source;
  }

  const showMoreButton = maxText && onSelectItem && setOpenModal;

  const disableSpecificInfo = (detailedInfo: DetailedInfo) => {
    const disabledInfo = {
      ...detailedInfo,
      enabled: false,
    } as DetailedInfo;
    dispatch(updateDetailedInfoCallerWrapper(disabledInfo));
    dispatch(resetOpenCreateModalFor());
  };
  return (
    <div>
      {status && status === LoadingStatusEnum.PENDING && (
        <Spinner size={SpinnerSize.Tiny} />
      )}
      {searchTerm && infoToShow.length === 0 && (
        <Row>{translate("NO_DATA_SEARCH")}</Row>
      )}
      {!searchTerm && infoToShow.length === 0 && (
        <Row className="pl-sm">
          {isDetailedPage
            ? translate("NO_CSI_DATA_PAGE")
            : translate("NO_CSI_DATA_SECTION")}
        </Row>
      )}
      {getUniqueCategories().map((category, index) => (
        <div key={`wrapper-${category.id}`}>
          {index !== 0 && (
            <div
              key={`divider-${category.id}`}
              className="horizontal-divider my-md"
            />
          )}
          <Row
            key={`category-${category.id}`}
            className="detailed-info-category pb-sm"
          >
            <Col md={12}>
              <h6 className="category-text pb-md">
                {language === "SV"
                  ? category.category_se
                  : category.category_en}
              </h6>
              {getUniqueSubcategoriesByCategoryId(category.id).map(
                (subcategory) => (
                  <Row key={subcategory.id}>
                    <Col md={12} className="subcategory-text pt-sm">
                      {language === "SV"
                        ? subcategory.customerdetailssubcategory.subcategory_se
                        : subcategory.customerdetailssubcategory.subcategory_en}
                    </Col>
                    {getUniqueInfoDataByCategoryAndSubcategoryId(
                      category.id,
                      subcategory.customerdetailssubcategory.id
                    ).map((infoEl) => (
                      <Row key={infoEl.id}>
                        <Col md={12}>
                          <div
                            key={`header-${infoEl.title}`}
                            style={{ fontSize: "15px" }}
                            className="d-flex align-items-center"
                          >
                            <div className="title py-md pr-sm">
                              {infoEl.title ? infoEl.title : "No title"}
                            </div>
                            {isDetailedPage &&
                              onEditInfo &&
                              (infoEl.created_by === currentUser.graphId ||
                                customerManagerId === currentUser.id) && (
                                <div>
                                  <ContextualMenuV9
                                    menuItems={[
                                      {
                                        text: `${translate(
                                          "CUSTOMER_INFORMATION_EDIT"
                                        )}`,
                                        icon: () =>
                                          renderIcon(<Edit20Regular />),
                                        onClick: () => {
                                          onEditInfo(infoEl);
                                        },
                                      },
                                      {
                                        text: `${translate(
                                          "CUSTOMER_INFORMATION_DISABLE"
                                        )}`,
                                        icon: () =>
                                          renderIcon(<ToggleLeft20Regular />),
                                        onClick: () => {
                                          disableSpecificInfo(infoEl);
                                        },
                                      },
                                    ]}
                                  />
                                </div>
                              )}
                          </div>
                          <Row key={`info-text-${infoEl.id}`} className="pb-sm">
                            <Col className="content">
                              {maxText
                                ? truncateContent(infoEl.content)
                                : infoEl.content}
                            </Col>
                          </Row>
                        </Col>
                        {showMoreButton && infoEl.content.length > maxText - 1 && (
                          <Row className="pb-md pl-2 d-flex justify-content-end align-items-end">
                            <Col
                              md={4}
                              className="d-flex justify-content-end align-items-end"
                            >
                              <Button
                                variant="outline-link"
                                onClick={() => {
                                  onSelectItem(infoEl);
                                  setOpenModal(true);
                                }}
                              >
                                <span className="px-sm">
                                  {translate("READ_MORE")}
                                </span>
                                <ArrowRight16Regular />
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Row>
                    ))}
                  </Row>
                )
              )}
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
}
