import { Language, TranslationKey } from ".";

type LanguageTranslation =
  | string
  | {
      SINGLE: string;
      PLURAL: string;
    };

export type Translate<T = TranslationKey> = (
  key: T,
  args?: string[],
  count?: number
) => string;

export type Dictionary = {
  [index: string]: {
    SV: LanguageTranslation;
    EN: LanguageTranslation;
  };
};

export class Translator<T extends string> {
  private dict: Dictionary;

  constructor() {
    this.dict = {};
  }

  missing(key: T) {
    console.warn(`Translation key not defined ${key}`);
    return `T:${key}`;
  }

  notFound(key: T) {
    console.warn(`Missing translation key ${key}`);
    return `T:${key}`;
  }

  addWord(
    key: T,
    translation: { SV: LanguageTranslation; EN: LanguageTranslation }
  ) {
    if (this.dict[key]) {
      throw new Error("Translation already exists.");
    }

    this.dict[key] = translation;
  }

  getText(entry: LanguageTranslation, count = 0) {
    const isString = typeof entry === "string";
    if (isString) {
      return entry;
    }

    if (count === 1) {
      return entry.SINGLE;
    }

    return entry.PLURAL;
  }

  getTranslation(key: T, language: Language, args: string[] = [], count = 0) {
    if (!key) {
      return this.missing(key);
    }

    const upper = key.toUpperCase();
    const entry = this.dict[upper];

    if (!entry) {
      return this.notFound(key);
    }

    const text = this.getText(entry[language], count);

    if (!args) {
      return text;
    }

    return args.reduce(
      (str, arg, index) => str.replace(`$${index + 1}`, arg),
      text
    );
  }

  /**
   * @param language
   * @returns Object<{
   *     byKey: Translate<T>;         // Used for all constant keys
   *     byString: Translate<string>; // Used for all dynamic keys (e.g. data from backend such as service.name)
   * }>
   */
  getTranslator(language: Language): {
    byKey: Translate<T>;
    byString: Translate<string>;
  } {
    return {
      byKey: (key: T, args: string[] = [], count = 0) =>
        this.getTranslation(key, language, args, count),
      /**
       * testar
       * @param key asd
       * @param args asd
       * @param count
       * @returns
       */
      byString: (key: string, args: string[] = [], count = 0) =>
        this.getTranslation(key as T, language, args, count),
    };
  }

  getCounting(language: Language) {
    return (n: number) => {
      const digit = n % 10;
      switch (language) {
        case "SV":
          if (digit === 1 || digit === 2) {
            return `${digit}a`;
          }
          return `${digit}e`;
        case "EN":
          if (digit === 1) {
            return `${digit}st`;
          }
          if (digit === 2) {
            return `${digit}nd`;
          }
          if (digit === 3) {
            return `${digit}rd`;
          }
          return `${digit}th`;

        default:
          throw new Error(`Unknown language ${language}`);
      }
    };
  }
}
