export interface IKycQuestion {
  id: number;
  key:
    | "has_beneficial_owner"
    | "person_is_on_sanctions_list"
    | "person_is_on_pep_list"
    | "residence_is_high_risk_country";
  question: string;
  tooltip: string;
  block?: number;
  order?: number;
  required: boolean;
  weighting: {
    yes: number;
    no: number;
  };
}

export const kycQuestions: IKycQuestion[] = [
  {
    id: 0,
    key: "has_beneficial_owner",
    question: "Har vi kontrollerat verklig huvudman?",
    tooltip:
      "Verklig huvudman är den person som ytterst äger (mer än 25 procent) eller kontrollerar exempelvis ett företag eller en förening (kan utse eller avsätta mer än hälften av styrelseledamöter eller befattningshavare). Aktiebolag måste ha anmält en verklig huvudman. Enskilda firmor behöver inte anmäla verklig huvudman.",
    order: 0,
    required: true,
    weighting: {
      yes: 0,
      no: 0,
    },
  },
  {
    id: 5,
    key: "residence_is_high_risk_country",
    question: "Är kund eller koncernbolag registrerat i högriskland?",
    tooltip:
      "Med högriskländer avses länder det föreligger stor risk för penningtvätt eller finansiering av terrorism, hög korruptionsrisk och länder som utmärks av obefintlig reglering av finansmarknaden, låg eller ingen beskattning samt möjlighet till anonymitet.",
    block: 1,
    required: true,
    weighting: {
      yes: 3,
      no: 0,
    },
  },
  {
    id: 1,
    key: "person_is_on_sanctions_list",
    question: "Finns någon verklig huvudman eller ägare på sanktionslista?",
    tooltip:
      "Om kunden finns på sanktionslista över EU, UN eller OFAC ska Compliancegruppen konsulteras före bekräftelse av uppdrag.",
    order: 1,
    required: true,
    weighting: {
      yes: 3,
      no: 0,
    },
  },
  {
    id: 4,
    key: "person_is_on_pep_list",
    question: "Finns verklig huvudman eller ägare på PEP-lista?",
    tooltip:
      "Politically Exposed Person (person med viktig offentlig funktion) som t.ex. stats- eller regeringschefer, ministrar, riksdagsledamöter, ledamöter i styrelse för politiska partier, domare i högsta domstol, högre tjänstemän vid revisionsmyndigheter, ambassadörer, höga officerare, personer i statsägda företags ledningsorgan samt familjemedlem eller känd medarbetare till person med viktig offentlig funktion.",
    block: 1,
    order: 2,
    required: true,
    weighting: {
      yes: 2,
      no: 0,
    },
  },
];
