import { format, startOfMonth } from "date-fns";
import { enGB, sv } from "date-fns/locale";
import { defaultDatePickerStrings } from "@fluentui/react-datepicker-compat";

import { DropdownOption } from "components/dropdown";
import { capitalize, capitalizeSentence } from "libs/capitalize";
import { Translate } from "i18n/translator";
import { Language, TranslationKey } from "i18n";

export const DateFormat = {
  YearDayMonth: "yyyy-MM-dd",
  MonthLongYear: "MMMM yyyy",
};

/**
 * Format a date with YYYY-MM
 */
export function yearMonthDateFormat(date: Date) {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);

  return `${year}-${month}`;
}

export function dateLanguageFormatter(
  language: Language,
  defaultDateFormat: string = DateFormat.MonthLongYear
) {
  return (date: Date, dateFormat = defaultDateFormat) =>
    capitalize(
      format(date, dateFormat, {
        locale: language === "SV" ? sv : enGB,
      })
    );
}

export function getYearsOptions(): DropdownOption<string>[] {
  const startYear = new Date().getFullYear();
  return [startYear - 1, startYear, startYear + 1, startYear + 2].map(
    (year) => ({
      value: year.toString(),
      label: year.toString(),
    })
  );
}

export function formatDate(dateString: Date): string {
  const dateFormatted = capitalizeSentence(
    new Date(dateString).toLocaleDateString("sv", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
  );
  return dateFormatted;
}

export function formatMonth(language: Language, monthNum: number | string) {
  const monthNumber =
    typeof monthNum === "string" ? parseInt(monthNum, 10) : monthNum;

  if (Number.isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12) {
    throw new Error("monthNum must be a number between 1 and 12");
  }

  let month = new Date(new Date().getFullYear(), monthNumber - 1)
    .toLocaleString(language, { month: "short" })
    .toUpperCase();

  if (month.endsWith(".")) {
    month = month.slice(0, -1);
  }

  return month;
}

export const isCurrentMonth = (date?: Date) => {
  if (!date) {
    return false;
  }

  const currentMonthStart = startOfMonth(new Date());
  return date.getMonth() === currentMonthStart.getMonth();
};

export const customDatePickerStrings = (
  translate: Translate<TranslationKey>
) => {
  return {
    ...defaultDatePickerStrings,
    goToToday: translate("GO_TO_TODAY"),
    days: [
      translate("SUNDAY"),
      translate("MONDAY"),
      translate("TUESDAY"),
      translate("WEDNESDAY"),
      translate("THURSDAY"),
      translate("FRIDAY"),
      translate("SATURDAY"),
    ],
    shortDays: [
      translate("SUNDAY_SHORT"),
      translate("MONDAY_SHORT"),
      translate("TUESDAY_SHORT"),
      translate("WEDNESDAY_SHORT"),
      translate("THURSDAY_SHORT"),
      translate("FRIDAY_SHORT"),
      translate("SATURDAY_SHORT"),
    ],
    months: [
      translate("JANUARY"),
      translate("FEBRUARY"),
      translate("MARCH"),
      translate("APRIL"),
      translate("MAY"),
      translate("JUNE"),
      translate("JULY"),
      translate("AUGUST"),
      translate("SEPTEMBER"),
      translate("OCTOBER"),
      translate("NOVEMBER"),
      translate("DECEMBER"),
    ],
    shortMonths: [
      translate("JANUARY_SHORT"),
      translate("FEBRUARY_SHORT"),
      translate("MARCH_SHORT"),
      translate("APRIL_SHORT"),
      translate("MAY_SHORT"),
      translate("JUNE_SHORT"),
      translate("JULY_SHORT"),
      translate("AUGUST_SHORT"),
      translate("SEPTEMBER_SHORT"),
      translate("OCTOBER_SHORT"),
      translate("NOVEMBER_SHORT"),
      translate("DECEMBER_SHORT"),
    ],
  };
};
