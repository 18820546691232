import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useTranslation } from "hooks/use-translate";
import { CustomerDetailsParams } from "routes";
import { RootState } from "state";
import CustomerPagePowerOfAttorney from "./customerPagePowerOfAttorney";

export default function CustomerPagePowerOfAttorneyPage() {
  const { translate } = useTranslation();
  const { customerId } = useParams<CustomerDetailsParams>();

  const powerOfAttorneyData = useSelector(
    (state: RootState) => state.settings.powerOfAttorney.data
  );

  return (
    <div className="new-page-content">
      <div className="header-title px-lg d-flex header-sticky contentHeader">
        <h3 className="pl-sm stringLength">{translate("POWER_OF_ATTORNEY")}</h3>
        <span className="accordion-subtitle align-self-center ml-sm">
          ({powerOfAttorneyData.length})
        </span>
      </div>
      {customerId ? (
        <CustomerPagePowerOfAttorney customerId={customerId} />
      ) : (
        <div>{translate("NO_CUSTOMER_ID")}</div>
      )}
    </div>
  );
}
