export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeSentence(str: string) {
  const sentenceParts = str.split(" ");
  const sentence = sentenceParts
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return sentence;
}
