import { ArrowLeft20Regular, ArrowRight20Regular } from "@fluentui/react-icons";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { LoadingStatus, RootState } from "state";
import { useRoutingForCreateBusinessOpportunity } from "./StepperHelper";

export type WizardNavigationProps = {
  isNextDisabled?: boolean;
  isNextHidden?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  onBeforeNext?: () => Promise<boolean>;
  predefinedNextRoute?: string;
  hideNavigation?: boolean;
  nextLabel?: TranslationKey;
  backLabel?: TranslationKey;
  loadingStatus?: LoadingStatus;
  disabledBackMessage?: string | JSX.Element | JSX.Element[] | undefined;
  customBackButtonRoute?: string;
  backMessageTooltip?: string;
};

export function WizardNavigationButtons({
  hideNavigation,
  isNextDisabled,
  isNextHidden,
  disabledBackMessage,
  onBeforeNext,
  onNext,
  onPrevious,
  predefinedNextRoute,
  backLabel = "BACK",
  nextLabel = "NEXT",
  loadingStatus = "idle",
  customBackButtonRoute,
  backMessageTooltip,
}: WizardNavigationProps) {
  const { translate } = useTranslation();
  const location = useLocation();

  const { isNewCustomer } = useSelector((state: RootState) => state.sales);

  const navigate = useNavigate();
  const routing = useRoutingForCreateBusinessOpportunity(
    location.pathname,
    isNewCustomer
  );

  const renderBackButton = () => {
    if (!routing.previous && !customBackButtonRoute) {
      return null;
    }

    return (
      <TooltipV9
        content={<div>{disabledBackMessage || backMessageTooltip || ""}</div>}
        hasJSXContent
        enabled={!!disabledBackMessage && !!backMessageTooltip}
      >
        <Button
          variant="outline-primary"
          disabled={!!disabledBackMessage}
          onClick={() => {
            if (onPrevious) {
              onPrevious();
            }
            if (customBackButtonRoute) {
              navigate(customBackButtonRoute);
            }

            if (routing.previous?.path) {
              navigate(routing.previous.path);
            }
          }}
        >
          <span className="d-flex flex-row">
            <ArrowLeft20Regular />
            <span className="px-sm">{translate(backLabel)}</span>
          </span>
        </Button>
      </TooltipV9>
    );
  };

  const renderForwardButton = () => {
    if (!routing.next && !predefinedNextRoute) {
      return null;
    }

    function goToNextRoute() {
      if (!predefinedNextRoute && routing.next?.path) {
        navigate(routing.next.path);
      }

      if (predefinedNextRoute) {
        navigate(predefinedNextRoute);
      }
    }
    let buttonText = translate(nextLabel);

    if (location.pathname.includes("/edit")) {
      buttonText = translate("SAVE");
    }

    return (
      <Button
        disabled={isNextDisabled || loadingStatus === LoadingStatusEnum.PENDING}
        onClick={async () => {
          if ((onBeforeNext && !(await onBeforeNext())) || !routing.next) {
            return;
          }

          onNext?.();
          goToNextRoute();
        }}
      >
        <span className="d-flex flex-row">
          <span className="px-sm">{buttonText}</span>
          <ArrowRight20Regular />
        </span>
      </Button>
    );
  };

  const renderNavigation = () => {
    if (hideNavigation) {
      return null;
    }

    return (
      <Row className="stepper-buttons justify-content-between">
        <Col md="auto">{renderBackButton()}</Col>

        {!isNextHidden && <Col md="auto">{renderForwardButton()}</Col>}
      </Row>
    );
  };

  return renderNavigation();
}
