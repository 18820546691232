/* eslint-disable no-useless-constructor */
/* eslint-disable max-classes-per-file */

import { useMemo } from "react";
import { useSelector } from "react-redux";
import { differenceInBusinessDays } from "date-fns";

import { RootState } from "state";
import {
  ActivityStatus,
  ActivityDefinition,
  Recurrence,
  ActivityInstance,
  ServiceInstance,
  CustomerActivitiesServices,
} from "models/activities/activity";
import { UserSnapshot } from "models/user";
import {
  ActivityInstancesGroup,
  ServiceActivitiesGroup,
} from "views/activities/serviceActivities";

const filterActivitiesReducer = (
  sum: ActivityInstance[],
  parent: ServiceInstance,
  filter: ServiceActivityFilter
) =>
  sum.filter((activityInstance) =>
    filter.check.bind(filter)(activityInstance, parent)
  );

interface ServiceActivityFilter {
  check(activity: ActivityInstance, parent: ServiceInstance): boolean;
}

/**
 * Builds a filter function which passes any service instance where any its activities are overdue
 * @returns
 */
export class OverdueFilter implements ServiceActivityFilter {
  check(activity: ActivityInstance) {
    return activity.isOverdue();
  }
}

/**
 * Builds a filter function which passes any service instance where any of its activities matches any of the filter's activity statuses
 * @param statuses Any number of activity statuses
 * @returns
 */
export class StatusFilter implements ServiceActivityFilter {
  constructor(private statuses: ActivityStatus[] | string[]) {}

  check(activity: ActivityInstance) {
    return activity.status && this.statuses.includes(activity.status.status);
  }
}

/**
 * Builds a filter function which passes any service instance where its service type matches any of the filter's service types
 * @param statuses Any number of service types
 * @returns
 */
export class ServiceTypeFilter implements ServiceActivityFilter {
  constructor(private serviceTypes: string[]) {}

  check(_: ActivityInstance, service: ServiceInstance) {
    return this.serviceTypes.includes(service.cb_service_type);
  }
}

/**
 * Builds a filter function which passes any service instance where any of its activities are assigned to any of the filter's users
 * @param userIds
 * @returns
 */
export class UsersFilter implements ServiceActivityFilter {
  constructor(private selectedUsers: UserSnapshot[]) {}

  check(activity: ActivityInstance) {
    return this.selectedUsers.some(
      (user) => activity.assigned_user === user.id
    );
  }
}

/**
 * Builds a filter function which passes any service instance where the next activity that is not completed is  assigned to any of the filter's users
 * @param userIds
 * @returns
 */
export class NextUsersFilter implements ServiceActivityFilter {
  constructor(private nextSelectedUsers: UserSnapshot[]) {}

  check(_: ActivityInstance, service: ServiceInstance) {
    /* NOTE: the next code is needed to be able to group and sort activities is one  serviceBox the same way they are presented in the Activities table */
    const activityGroups = service.activities
      .filter((activity) => activity.completed_at === null)
      .reduce((sum, current) => {
        if (!sum[current.group.group_idx]) {
          sum[current.group.group_idx] = {
            categorySortingNumber: current.getGroupSortingNum(),
            activities: [],
          };
        }

        sum[current.group.group_idx].activities = [
          ...sum[current.group.group_idx].activities,
          current,
        ];
        return sum;
      }, {} as ServiceActivitiesGroup);

    const sortedActivitiesKeys = Object.keys(activityGroups).sort(
      (key1, key2) => {
        const category1 = activityGroups[key1].categorySortingNumber;
        const category2 = activityGroups[key2].categorySortingNumber;

        return category1.toString().localeCompare(category2.toString());
      }
    );

    const firstSortedGroup: ActivityInstancesGroup =
      activityGroups[sortedActivitiesKeys[0]];

    const assignedNextUserId = firstSortedGroup?.activities.sort((a, b) =>
      a.sorting_nr < b.sorting_nr ? -1 : 1
    )[0].assigned_user;

    return this.nextSelectedUsers.some(
      (user) => assignedNextUserId === user.id
    );
  }
}

/**
 * Builds a filter function which passes any service instance where any of its activities matches any of the filter's activity definitions
 * @param definitions
 * @returns
 */
export class DefinitionsFilter implements ServiceActivityFilter {
  constructor(
    private definitions: string[],
    private definitionsMap: { [key: string]: ActivityDefinition }
  ) {}

  check(activity: ActivityInstance) {
    const definition = this.definitionsMap[activity.activity_type];

    if (!definition) {
      return false;
    }

    return this.definitions.some((activityType) => {
      const activityName = this.definitionsMap[activityType]?.activity_name;
      return activityName === definition.activity_name;
    });
  }
}

/**
 * Builds a filter function which passes any service instance which period is within the given timespan
 * @param timespan
 * @returns
 */
export class TimespanFilter implements ServiceActivityFilter {
  constructor(private timespan: Date[]) {}

  check(_: ActivityInstance, service: ServiceInstance) {
    const servicePeriod = new Date(`${service.year}-${service.month}-01 00:00`);

    return (
      servicePeriod >= this.timespan[0] &&
      servicePeriod <= this.timespan[this.timespan.length - 1]
    );
  }
}

/**
 * Builds a filter function which passes any service instance which matches any of the filter's frequencies
 * @param frequencies
 * @returns
 */
export class RecurrenceFilter implements ServiceActivityFilter {
  constructor(
    private recurrence: Recurrence[],
    private definitionsMap: { [key: string]: ActivityDefinition }
  ) {}

  check(activity: ActivityInstance) {
    const definition = this.definitionsMap[activity.activity_type];
    return this.recurrence.some(
      (recurrence) =>
        activity.getRecurrence(definition).toLowerCase() ===
        recurrence.toLowerCase()
    );
  }
}

/**
 * Builds a filter function which passes any service instance where any of its activities are unfinished
 * @returns
 */
export class ActiveFilter implements ServiceActivityFilter {
  check(activity: ActivityInstance) {
    return !activity.completed_at;
  }
}

export class PassFilter implements ServiceActivityFilter {
  isEnabled() {
    return false;
  }

  check(_: ActivityInstance) {
    return true;
  }
}

export class MonthFilter implements ServiceActivityFilter {
  constructor(private selectedDate: Date) {}

  check(_: ActivityInstance, service: ServiceInstance) {
    const selectedMonth = this.selectedDate.getMonth() + 1;
    const selectedYear = this.selectedDate.getFullYear();

    return service.month === selectedMonth && service.year === selectedYear;
  }
}

export class DeadlineFilter implements ServiceActivityFilter {
  constructor(private deadline: string) {}

  check(activity: ActivityInstance) {
    const dateFrom = new Date();
    const dateTo = new Date(activity.deadline);

    const workingDaysLeft = differenceInBusinessDays(dateTo, dateFrom);

    return !activity.completed_at && workingDaysLeft <= Number(this.deadline);
  }
}

/**
 * Builds a filter function for filtering customers service instances, based on the given parameters.
 * @param filterOverdue Filter service instances based on activity instances overdue status
 * @param filterActive Filter service instances based on activity instances completed status
 * @param filterStatuses Filter service instances based on activity instances status (help needed / manager support, etc.)
 * @param filterRecurrence Filter service instances based on recurrence
 * @param filterDefinitions Filter service instances based on activity instances definitions
 * @returns A service instance filter function
 */
export function useCustomerServiceFilter(params: {
  filterOverdue: boolean;
  filterActive: boolean;
  filterStatuses: ActivityStatus[];
  filterRecurrence: Recurrence[];
  filterDefinitions: ActivityDefinition[];
  filterUserIds: UserSnapshot[];
  filterServiceTypes: string[];
  filterByMonth: Date | undefined;
  filterByDeadline: string | null;
  filterNextUserIds: UserSnapshot[];
  definitionsMap: { [key: string]: ActivityDefinition };
}) {
  const {
    filterOverdue,
    filterActive,
    filterServiceTypes,
    filterStatuses,
    filterRecurrence,
    filterDefinitions,
    filterUserIds,
    filterByMonth,
    filterByDeadline,
    filterNextUserIds,
    definitionsMap,
  } = params;

  const filters: ServiceActivityFilter[] = useMemo(
    () => [
      filterOverdue ? new OverdueFilter() : new PassFilter(),
      filterActive ? new ActiveFilter() : new PassFilter(),
      filterServiceTypes.length > 0
        ? new ServiceTypeFilter(filterServiceTypes)
        : new PassFilter(),
      filterStatuses.length > 0
        ? new StatusFilter(filterStatuses)
        : new PassFilter(),
      filterRecurrence.length > 0
        ? new RecurrenceFilter(filterRecurrence, definitionsMap)
        : new PassFilter(),
      filterDefinitions.length > 0
        ? new DefinitionsFilter(
            filterDefinitions.map((definition) => definition.title),
            definitionsMap
          )
        : new PassFilter(),
      filterUserIds.length > 0
        ? new UsersFilter(filterUserIds)
        : new PassFilter(),
      filterByMonth ? new MonthFilter(filterByMonth) : new PassFilter(),
      filterByDeadline
        ? new DeadlineFilter(filterByDeadline)
        : new PassFilter(),
      filterNextUserIds.length > 0
        ? new NextUsersFilter(filterNextUserIds)
        : new PassFilter(),
    ],
    [
      filterOverdue,
      filterActive,
      filterStatuses,
      filterRecurrence,
      filterDefinitions,
      filterUserIds,
      filterServiceTypes,
      filterByMonth,
      filterByDeadline,
      filterNextUserIds,
      definitionsMap,
    ]
  );

  const activities = useSelector((state: RootState) => state.activities.data);

  return {
    servicesFilter: (
      customerNumber: string,
      activitiesToFilter?: CustomerActivitiesServices[]
    ) => {
      const customerServices = (activitiesToFilter ?? activities).find(
        (activity) => activity.customer_id === customerNumber
      );

      if (!customerServices) {
        return [];
      }

      return customerServices.services.filter(
        (service) =>
          // Take one filter at a time and apply it to the service's activities
          filters.reduce((activityInstances, filter) => {
            return filterActivitiesReducer(activityInstances, service, filter);
          }, service.activities).length > 0
      );
    },
    isFiltering: !filters.every(
      (f) => f instanceof PassFilter || f instanceof TimespanFilter
    ),
  };
}
