/**
 * Wraps a function, so that it can only called any number of times, but will only fire once `timeout` milliseconds has passed since it was called.
 */
export async function createDebounce<T extends unknown[], U>(timeout: number) {
  return (callback: (...args: T) => PromiseLike<U> | U) => {
    let timer: ReturnType<typeof setTimeout>;

    return (...args: T): Promise<U> => {
      clearTimeout(timer);
      return new Promise((resolve) => {
        timer = setTimeout(() => resolve(callback(...args)), timeout);
      });
    };
  };
}

export class Debounce<T> {
  timeout: NodeJS.Timeout;
  delay: number;

  constructor(delay: number) {
    this.delay = delay;
  }

  fire(callback: () => Promise<T>) {
    clearTimeout(this.timeout);

    return new Promise((resolve) => {
      this.timeout = setTimeout(() => {
        return resolve(callback());
      }, this.delay);
    });
  }
}
