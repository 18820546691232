import { Type } from "class-transformer";
import { Stage } from "./stage";

export class Pipeline {
  id: string;

  label: string;

  @Type(() => Stage)
  stages: Stage[];
}
