import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { useTranslation } from "hooks/use-translate";
import { ActivityDefinition } from "models/activities/activity";
import { DeliveryFact } from "models/deliveryPlan";

type IncludedActivitiesDialogProps = {
  isOpen: boolean;
  activitiesDefinitions: ActivityDefinition[];
  deliveryFact: DeliveryFact;
  handleOnDismiss: () => void;
};

export function IncludedActivitiesDialog({
  isOpen,
  activitiesDefinitions,
  deliveryFact,
  handleOnDismiss,
}: IncludedActivitiesDialogProps) {
  const { translate, language } = useTranslation();

  const groupDefinitions = () => {
    const m = new Map();
    activitiesDefinitions.forEach(definition => {
      if (!m.has(definition.recurrence)) {
        m.set(definition.recurrence, [])
      }

      const values = m.get(definition.recurrence);
      values.push(definition);
      m.set(definition.recurrence, values);
    });

    return m;
  }

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={handleOnDismiss}
      size="small-to-mid"
      header={
        <ModalHeader
          headerTitleText={translate(
            "DP.SELECTED_DELIVERY_ACTIVITIES_DIALOG.TITLE",
            [deliveryFact.description_headline]
          )}
        />
      }
      footer={
        <ModalFooter
          labelCancel="CANCEL"
          onCancel={handleOnDismiss}
          hideSubmit
          labelSubmit=""
          onSave={() => {}}
        />
      }
    >
      {
        Array.from(groupDefinitions().keys()).map(group => (
            <ul key={`ul-${group}`}>
              <span key={`title-${group}`}>{group}</span>
              {
                groupDefinitions().get(group)?.map((item: any) => (
                    <li key={item.title}>
                      {item.title} - {item.getTranslation(language)}
                    </li>
                ))
              }
            </ul>
        ))
      }
    </Modal>
  );
}
