import React from "react";
import "./Button.scss";

type ButtonElementProps = React.HTMLProps<HTMLButtonElement>;

type Outline = "outline-" | "";
type Color =
  | "primary"
  | "secondary"
  | "outline"
  | "dark"
  | "warning"
  | "danger"
  | "link";

export type Variant = `${Outline}${Color}`;

type ButtonProps = Omit<ButtonElementProps, "size" | "type"> & {
  variant?: Variant;
  className?: string;
  size?: "md" | "sm" | "lg";
};

export function Button({
  variant = "primary",
  className = "",
  size = "md",
  ...rest
}: ButtonProps) {
  const c = `btn btn-${variant} btn-${size} ${className}`;
  return <button {...rest} className={c} data-testid={"button"}></button>;
}

export function PrimaryButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) {
  return <Button {...props} variant="primary"></Button>;
}
