type RetryOptions = {
  attemptDelay: (attempt: number) => number;
  attemptsLimit: number;
};

type RetryFunction<T> = (attempt: number) => Promise<T>;

/**
 * Wrap an async function in a retry mechanism. When called, it retries the inner function until it succeeds or the number of tries has been reached
 * @param fn An async function
 * @param options Retry options
 * @returns A promise which resolves if any of the calls succeed before the attempt limit is reached, otherwise rejects
 */
export function wrapRetryAsync<T>(
  fn: (attempt?: number) => Promise<T>,
  { attemptDelay: delay, attemptsLimit }: RetryOptions = {
    attemptDelay: (attempt: number) => attempt * 400,
    attemptsLimit: 3,
  }
) {
  return () => {
    const retryCall: RetryFunction<T> = async (attempt: number) => {
      try {
        return await fn(attempt);
      } catch (error) {
        if (attempt >= attemptsLimit) throw error;

        // Keep retrying until attempt limit has been reached
        return new Promise<T>((resolve) =>
          setTimeout(() => resolve(retryCall(attempt + 1)), delay(attempt))
        );
      }
    };

    return retryCall(1);
  };
}
