import { useMemo } from "react";
import { useControllableState } from "@fluentui/react-utilities";
import type {
  ExpandedItemId,
  ExpandingHookParams,
  ExpandingMethods,
} from "./types";
import { createSetFromIterable } from "../utils";

function useExpandingRowsState(
  params: Omit<ExpandingHookParams, "expandingMode">
) {
  const [expanded, setExpanded] = useControllableState<Set<ExpandedItemId>>({
    initialState: new Set(),
    defaultState: useMemo(
      () =>
        params.defaultExpandedItems &&
        createSetFromIterable(params.defaultExpandedItems),
      [params.defaultExpandedItems]
    ),
    state: useMemo(
      () => params.expandedItems && createSetFromIterable(params.expandedItems),
      [params.expandedItems]
    ),
  });

  const changeExpandedItems = (
    event: React.SyntheticEvent,
    nextExpandedItems: Set<ExpandedItemId>
  ) => {
    params.onExpandingChange?.(event, { expandedItems: nextExpandedItems });
    setExpanded(nextExpandedItems);
  };
  return [expanded, changeExpandedItems] as const;
}

export function useSingleExpandingRows(
  params: Omit<ExpandingHookParams, "expandingMode">
) {
  const [expanded, changeExpandingItems] = useExpandingRowsState(params);

  const methods: ExpandingMethods = {
    foldItem: (event) => changeExpandingItems(event, new Set()),
    expandItem: (event, itemId) =>
      changeExpandingItems(event, new Set([itemId])),
    toggleAllItems: () => {
      throw new Error(
        "[table expanding rows]: `toggleAllItems` should not be used in single expanding mode"
      );
    },
    toggleItem: (event, itemId) => {
      if (expanded.has(itemId)) {
        changeExpandingItems(event, new Set());
      } else {
        changeExpandingItems(event, new Set([itemId]));
      }
    },
    foldItems: (event) => changeExpandingItems(event, new Set()),
    isExpanded: (itemId) => expanded.has(itemId),
  };

  return [expanded, methods] as const;
}

export function useMultipleExpandingRows(
  params: Omit<ExpandingHookParams, "expandingMode">
) {
  const [expanded, changeExpandingItems] = useExpandingRowsState(params);

  const methods: ExpandingMethods = {
    foldItem: (event, itemId) => {
      const nextExpandedItems = new Set(expanded);
      nextExpandedItems.delete(itemId);
      changeExpandingItems(event, nextExpandedItems);
    },
    expandItem: (event, itemId) => {
      const nextExpandedItems = new Set(expanded);
      nextExpandedItems.add(itemId);
      changeExpandingItems(event, nextExpandedItems);
    },
    toggleAllItems: (event, itemIds) => {
      const allItemsExpanded = itemIds.every((itemId) => expanded.has(itemId));
      const nextExpandedItems = new Set(expanded);
      if (allItemsExpanded) {
        nextExpandedItems.clear();
      } else {
        itemIds.forEach((itemId) => nextExpandedItems.add(itemId));
      }
      changeExpandingItems(event, nextExpandedItems);
    },
    foldItems: (event) => changeExpandingItems(event, new Set()),
    toggleItem: (event, itemId) => {
      const nextExpandedItems = new Set(expanded);
      if (expanded.has(itemId)) {
        nextExpandedItems.delete(itemId);
      } else {
        nextExpandedItems.add(itemId);
      }
      changeExpandingItems(event, nextExpandedItems);
    },
    isExpanded: (itemId) => expanded.has(itemId),
  };
  return [expanded, methods] as const;
}

export function useExpandingRows(params: ExpandingHookParams) {
  if (params.expandingMode === "multiple") {
    // expandingMode is a static value, so we can safely ignore rules-of-hooks
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMultipleExpandingRows(params);
  }

  // expandingMode is a static value, so we can safely ignore rules-of-hooks
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSingleExpandingRows(params);
}
