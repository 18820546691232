import { useState } from "react";
import { Tag } from "@fluentui/react-components";
import {
  Info20Regular,
  People16Regular,
  Search20Regular,
  Warning20Regular,
} from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks";
import { ActivityInstance, Recurrence } from "models/activities/activity";
import { Customer } from "models/customer";
import { RootState } from "state";
import {
  reassignPackageAssignedUser,
  updatePackageDefaultAssignedUser,
} from "state/activities/actions";
import { useAppDispatch } from "state/use-app-redux";
import AssignmentTeam from "./AssignmentTeam";
import CustomerDetailsMenu from "./CustomerDetailsMenu";
import TeamModal from "./TeamModal";

export interface Assignees {
  assigned_user: ActivityInstance;
  cb_service_type: string;
}

export interface ProjectManagerForCustomer {
  email: string;
  name: string;
  projectType: string;
}

type Props = {
  customer: Customer;
  isExpanded: boolean;
  toggleInfo: () => void;
  isCollapsedView: boolean;
};

export default function CustomerDetails({
  customer,
  isExpanded,
  toggleInfo,
  isCollapsedView,
}: Props) {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();

  const customerState = useSelector(
    (state: RootState) => state.customers.data
  ).find((data) => data.customer.customer_number === customer.customer_number);
  const {
    data: activities,
    activitiesTeams: { data: activitiesTeams },
  } = useSelector((state: RootState) => state.activities);

  const [showTeamModal, setShowTeamModal] = useState(false);

  const customerActivities = activities.find(
    (activity) => activity.customer_id === customer.customer_number
  );

  const assigneesData = activitiesTeams.filter(
    (activitiesTeam) => activitiesTeam.customer_id === customer.customer_number
  );

  const assigneeArray =
    assigneesData && assigneesData.length > 0
      ? assigneesData[0].service_types.flatMap(
          (assignees) => assignees.assigned_users
        )
      : [];

  const allAssignees = assigneeArray.filter(
    (item, index) => assigneeArray.indexOf(item) === index
  );

  const customerIsFromSearch = customerState?.isFromSearch;

  const customerProject: ProjectManagerForCustomer[] = customer.projects.map(
    (project) => {
      return {
        email: project.projectManagerUsername,
        name: project.projectManagerName,
        projectType: project.projectType,
      };
    }
  );

  function customerFrequencyRecurrence(customerObj: Customer) {
    if (customerObj.frequency && customerObj.frequency !== Recurrence.Unknown) {
      const freq = customerObj.frequency;
      return translate(`FREQUENCY_PILL.${freq}`);
    }
  }

  const emptyActivitiesReason = [
    {
      visible: !customer.fiscalYearMonthEnd,
      message: translate("CUSTOMER_MISSING_FISCAL_YEARS"),
    },
    {
      visible: !customer.hasAcceptedTerms(),
      message: translate("CUSTOMER_HAS_NOT_ACCEPTED_GENERAL_TERMS"),
    },
  ];

  const hasWarning = emptyActivitiesReason.some((reason) => reason.visible);

  function distinctProjectManagerEmails(
    projectManagers: ProjectManagerForCustomer[]
  ) {
    const map = new Map();

    projectManagers.forEach(({ email, name, projectType }) => {
      if (map.has(email)) {
        const existing = map.get(email);

        if (projectType) {
          existing.projectType += existing.projectType
            ? `, ${projectType}`
            : projectType;
        }
      } else {
        map.set(email, { email, name, projectType: projectType || "" });
      }
    });

    return Array.from(map.values());
  }

  const projectManagersForCustomer =
    distinctProjectManagerEmails(customerProject);

  const onPackagePersonPickerChange = async (
    packageName: string,
    userEmail: string,
    isDefaultAssignee: boolean,
    assignedUser?: string
  ) => {
    if (isDefaultAssignee) {
      const response = await dispatch(
        updatePackageDefaultAssignedUser(customer, packageName, userEmail)
      );
      return response;
    }
    if (!isDefaultAssignee && assignedUser) {
      const response = await dispatch(
        reassignPackageAssignedUser(
          customer,
          userEmail,
          assignedUser,
          packageName
        )
      );
      return response;
    }
  };

  return (
    <div className="w-100">
      <Row>
        <Col xs={8} className="d-flex">
          {customerIsFromSearch && (
            <TooltipV9 content="CUSTOMER_IS_FROM_SEARCH">
              <span className="pr-xs">
                <Search20Regular color="red" />
              </span>
            </TooltipV9>
          )}
          <TooltipV9 content={customer.name} notTranslatable>
            <div className="text-max-char pt-xs min-width-170 customer-details-name">
              <span className="align-items-center fpx-14 fw-semibold">
                {customer.name}
              </span>
            </div>
          </TooltipV9>
        </Col>
        <Col xs={4} className="d-flex align-items-center justify-content-end">
          <div className="vertical-divider pr-sm ml-sm" />
          {hasWarning && (
            <div>
              <TooltipV9
                hasJSXContent
                content={
                  <div className="flex-column-tooltip-content align-items-start">
                    {emptyActivitiesReason.map((reason) => (
                      <span key={reason.message}>
                        {reason.visible && <li> {reason.message} </li>}
                      </span>
                    ))}
                  </div>
                }
              >
                <Warning20Regular className="color-red mr-sm" />
              </TooltipV9>
            </div>
          )}
          <TooltipV9 content="VIEW_CLIENT_INFO">
            <Info20Regular
              className="cursor-pointer"
              onClick={() => toggleInfo()}
            />
          </TooltipV9>
          <TooltipV9 content="MORE_OPTIONS">
            <CustomerDetailsMenu customer={customer} />
          </TooltipV9>
        </Col>
      </Row>
      {!isCollapsedView && (
        <div className="d-flex align-items-center mt-sm">
          <Tag
            className={`customer-details-pill ${
              !customer.fiscalYearMonthEnd ? "bg-redLightest" : ""
            }`}
          >
            {customer.fiscalYearMonthEnd ? (
              <TooltipV9 content="FISCAL_YEAR">
                <span className="fw-bold fpx-12">{`${customer.fiscalYearMonthEnd}-${customer.fiscalYearDayEnd}`}</span>
              </TooltipV9>
            ) : (
              <TooltipV9 content="CUSTOMER_DETAILS.NO_FISCAL_YEAR">
                <span className="fs-italic fpx-12 text-gray">
                  {translate("CUSTOMER_DETAILS.MISSING_FISCAL_YEAR")}
                </span>
              </TooltipV9>
            )}
          </Tag>
          <Tag className="customer-details-pill mx-md">
            {customerFrequencyRecurrence(customer) ? (
              <TooltipV9 content="FREQUENCY">
                <span className="fw-bold fpx-12">
                  {customerFrequencyRecurrence(customer)}
                </span>
              </TooltipV9>
            ) : (
              <TooltipV9 content="CUSTOMER_DETAILS.NO_FREQUENCY">
                <span className="fs-italic fpx-12 text-gray">
                  {translate("CUSTOMER_DETAILS.MISSING_FREQUENCY")}
                </span>
              </TooltipV9>
            )}
          </Tag>
          {!isExpanded && (
            <TooltipV9 content="TEAM_MODAL">
              <Tag
                className="customer-details-pill cursor-pointer"
                onClick={() => setShowTeamModal(!showTeamModal)}
              >
                <div className="d-flex align-items-center">
                  <People16Regular className="mr-xs" />
                  <span className="fw-bold fpx-12">
                    {projectManagersForCustomer.length +
                      allAssignees.length +
                      (customer.salesperson_username ? 1 : 0)}
                  </span>
                </div>
              </Tag>
            </TooltipV9>
          )}
        </div>
      )}
      {isExpanded && !isCollapsedView && (
        <div className="d-flex mt-xs">
          <AssignmentTeam
            customer={customer}
            assignees={allAssignees.length > 0 ? allAssignees : []}
            projectManagersForCustomer={projectManagersForCustomer}
            customerActivities={customerActivities}
            setShowTeamModal={setShowTeamModal}
          />
        </div>
      )}
      {showTeamModal && (
        <TeamModal
          customer={customer}
          assignees={assigneesData[0].service_types}
          projectManagers={projectManagersForCustomer}
          onPackagePersonPickerChange={onPackagePersonPickerChange}
          isOpen
          onDismiss={(open: boolean) => {
            setShowTeamModal(open);
          }}
        />
      )}
    </div>
  );
}
