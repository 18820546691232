import { Transform, Type } from "class-transformer";

export class Product {
  id: string;

  @Transform(({ value }) => parseInt(value))
  price: number;

  name: string;

  description?: string;

  activity_category_label?: string;

  @Type(() => Date)
  createdAt: Date;

  @Type(() => Date)
  updatedAt?: Date;
}
