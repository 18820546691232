import { Col, Row } from "react-bootstrap";

import Checkbox from "components/checkbox";
import { CustomerTaxObject } from "models/deliveryPlan";

type CustomerTaxObjectRowProps = {
  taxObject: CustomerTaxObject;
  onChange: (arg: CustomerTaxObject, arg1: boolean) => void;
  disabled: boolean;
  className?: string;
};

export function CustomerTaxObjectRow({
  taxObject,
  onChange,
  disabled,
  className = "",
}: CustomerTaxObjectRowProps) {
  return (
    <Row className={className}>
      <Col md={1}>
        <Checkbox
          onChange={(_, checked) => onChange(taxObject, !!checked)}
          disabled={disabled}
          defaultChecked={disabled}
        />
      </Col>
      <Col md={11}>
        <span className="pe-3">
          {taxObject.name} {taxObject.surname}
        </span>
        <span className="ps-3">{taxObject.ssn}</span>
      </Col>
    </Row>
  );
}
