import { Assignment } from "../../models/assignment";

export const FETCH_ASSIGNMENTS = "assignments/FETCH_ASSIGNMENTS";
export const LOADING_ASSIGNMENTS = "assignments/LOADING_ASSIGNMENTS";
export const LOADING_ASSIGNMENTS_FAILED =
  "assignments/LOADING_ASSIGNMENTS_FAILED";
export const UPDATE_ASSIGNMENT = "assignments/UPDATE_ASSIGNMENT";

export interface LoadAssignments {
  type: typeof FETCH_ASSIGNMENTS;
  payload: Assignment[];
}

export interface LoadingAssignments {
  type: typeof LOADING_ASSIGNMENTS;
}

export interface LoadingAssignmentsFailed {
  type: typeof LOADING_ASSIGNMENTS_FAILED;
}

export interface UpdateAssignment {
  type: typeof UPDATE_ASSIGNMENT;
  payload: Assignment;
}

export type AssignmentsAction =
  | LoadAssignments
  | LoadingAssignments
  | LoadingAssignmentsFailed
  | UpdateAssignment;
