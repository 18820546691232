import React, {useEffect} from "react";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagLib from "@bugsnag/js";
import {app} from "@microsoft/teams-js";
import AppConfig from "./app-config";
import {useAuth} from "./auth/use-auth";

function bugsnagShouldStart() {
    return ["production", "test"].includes(AppConfig.STAGE);
}

export class Bugsnag {
    static initialize() {
        if (bugsnagShouldStart()) {
            BugsnagLib.start({
                apiKey: AppConfig.BUGSNAG_API_KEY,
                plugins: [new BugsnagPluginReact()],
                releaseStage: AppConfig.STAGE,
                enabledReleaseStages: ["production", "test"],
                collectUserIp: false,
                onSession: (event) => {
                    console.log('[Bugsnag] Session started', event);
                }
            });
        }
    }
}

export function getBugsnagErrorBoundary() {
    if (!bugsnagShouldStart()) {
        return React.Fragment;
    }

    const BugsnagReact = BugsnagLib.getPlugin("react");
    if (!BugsnagReact) {
        throw new Error("Missing Bugsnag React plugin");
    }

    return BugsnagReact.createErrorBoundary(React);
}

export function useBugsnagSetUser(logActive?: boolean) {
    const {user} = useAuth();

    useEffect(() => {
        // the log is only temporary to help out with testing
        const log = (...params: any) => {
            if (logActive) {
                console.log(...params);
            }
        };

        if (!bugsnagShouldStart()) {
            return;
        }

        log('[Bugsnag] Effect started: ', user);
        const existingBugsnagUser = BugsnagLib.getUser();
        // no need to set it again
        if (existingBugsnagUser && existingBugsnagUser.id) {
            log('[Bugsnag] There is an existing bugsnag user: ', existingBugsnagUser);
            return;
        }

        if (user && user.id) {
            log('[Bugsnag] Setting the signedin user from auth: ', user);
            BugsnagLib.setUser(user.id);
            return;
        }

        const setUserPrincipalName = async () => {
            const context = await app.getContext();
            log('[Bugsnag] Setting the user from the context: ', context.user?.userPrincipalName);
            BugsnagLib.setUser(context.user?.userPrincipalName);
        }

        // If no user set, use the email address from Teams instead
        if (!existingBugsnagUser) {
            setUserPrincipalName();
        }

    }, [user, logActive]);
}