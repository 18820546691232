import AppConfig from "app-config";
import { AppRouteHelper } from "routes";
import { useTranslation } from "hooks";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutWrapper,
  LayoutFooter,
} from "components/layout/Layout";
import { Content, ContentBody } from "components/content/Content";
import { ContentHeader } from "components/content/ContentHeader";
import { Picker } from "components/picker";
import "./index.scss";
import PickerCarousel from "components/picker/PickerCarousel ";

export default function OtherApplications() {
  const { translate } = useTranslation();

  const otherApplicationsList = [
    {
      name: "MittKontor",
      description: translate("OTHER_APPLICATIONS.SELECT_APP", ["MittKontor"]),
      url: AppRouteHelper.getMittKontor(),
    },
    {
      name: "Intranät",
      description: translate("OTHER_APPLICATIONS.SELECT_APP", ["Intranät"]),
      url: AppRouteHelper.getIntranat(),
    },
    {
      name: "Fortnox",
      description: translate("OTHER_APPLICATIONS.SELECT_APP", ["Fortnox"]),
      url: AppRouteHelper.getFortnox(),
    },
    {
      name: translate("OTHER_APPLICATIONS.FEATURE_VOTING"),
      description: translate("OTHER_APPLICATIONS.SELECT_APP", [
        translate("OTHER_APPLICATIONS.FEATURE_VOTING"),
      ]),
      url: AppRouteHelper.getFeatureVoting(),
    },
  ];

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <h1>{translate("OTHER_APPLICATIONS.MENU_LINK")}</h1>
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutWrapper>
        <Content>
          <ContentHeader
            title={translate("OTHER_APPLICATIONS.HEADER")}
            subtitles={[translate("OTHER_APPLICATIONS.SUBHEADER")]}
            counter={otherApplicationsList.length}
          />
          <ContentBody>
            <PickerCarousel
              prevTooltipContent={translate(
                "OTHER_APPLICATIONS.PICKER_CAROUSEL_PREVIOUS"
              )}
              nextTooltipContent={translate(
                "OTHER_APPLICATIONS.PICKER_CAROUSEL_NEXT"
              )}
            >
              {otherApplicationsList.map((appItem) => (
                <Picker
                  name={appItem.name}
                  description={appItem.description}
                  className="mx-sm"
                  checked={false}
                  onToggle={() =>
                    window.open(appItem.url, "_blank", "noopener,noreferrer")
                  }
                  toggles={[]}
                />
              ))}
            </PickerCarousel>
          </ContentBody>
        </Content>
      </LayoutWrapper>
      <LayoutFooter>
        <div className="footer pl-lg d-flex align-items-center">
          <div className="title">{translate("FOOTER.SUPPORT")}</div>
          <div className="footer-vertical-divider ml-md p-sm" />
          <div>
            <span className="support-label mr-xs">
              {translate("PHONE_SHORT")}:
            </span>
            <span className="support-value">{AppConfig.SUPPORT_PHONE}</span>
          </div>
          <div className="footer-vertical-divider ml-md p-sm" />
          <div>
            <span className="support-label mr-xs">
              {translate("EMAIL_SHORT")}:
            </span>
            <span className="support-value">{AppConfig.SUPPORT_EMAIL}</span>
          </div>
        </div>
      </LayoutFooter>
    </>
  );
}
