/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-prototype-builtins */
import { useEffect, useRef } from "react";

/**

USAGE:

If you have a useEffect somewhere and you'd like to visualize what has changed,
simply rename it to useEffectDebug and pass in the dependencyNames (optional but
suggested) array that represent the name of each depdenceny. Now check the console.

  useEffectDebug(
    () => {
      // code
    },
    [dependency1, dependency2],
    ["dependency1", "dependency2"]
  );

*/

const usePrevious = (value: any, initialValue: any) => {
  const ref = useRef(initialValue);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const getObjectPropertyDiff = (key: string, prev?: any, current?: any) => {
  // Check if previous property has the value, it could be that its state
  // starts as undefined and then gets a value. Defaults to undefined.
  let tempPrev;
  if (prev && prev.hasOwnProperty(key)) {
    tempPrev = prev[key];
  }

  // Check if current property has a value, it could be that its state
  // goes from having a value to being undefined. Defaults to undefined.
  let tempCurrent;
  if (current && current.hasOwnProperty(key)) {
    tempCurrent = current[key];
  }

  const hasDiff = tempPrev !== tempCurrent;
  if (!hasDiff) {
    return null;
  }

  return { [key]: `${tempPrev} => ${tempCurrent}` };
};

const getPrimitiveDiff = (prev: any, current: any) => {
  const hasDiff = prev !== current;
  if (!hasDiff) {
    return null;
  }

  return `${prev} => ${current}`;
};

export const useEffectDebug = (
  effectHook: () => unknown,
  dependencies: unknown[],
  dependencyNames: string[] = [],
  useEffectName = "useEffectDebug"
) => {
  const previousDeps = usePrevious(dependencies, []);

  const calculate = () => {
    const result: unknown[] = [];

    if (dependencies && previousDeps) {
      for (let i = 0; i < dependencies.length; i++) {
        const currentDepdendency = dependencies[i];
        const previousDependency = previousDeps[i];

        // If a dependency goes from undefined to a value and vice versa we want
        // to make sure we're making it possible to fetch data from the depdendency
        // that has values. Use depdendencyModel to determine the keys that this dependency
        // object has so that we can look at each individual key from prev -> current.
        let dependencyModel;
        if (
          currentDepdendency === undefined &&
          previousDependency !== undefined
        ) {
          dependencyModel = previousDependency;
        } else if (
          currentDepdendency !== undefined &&
          previousDependency === undefined
        ) {
          dependencyModel = currentDepdendency;
        } else {
          dependencyModel = currentDepdendency;
        }

        let diff: any = null;
        if (typeof dependencyModel === "object") {
          // If depdendency is a object, we compare all the properties in the
          // shallow depth of 1. This could be further improved to recurse down
          // throughout the entire depth of the object in the future.
          const dependencyPropertyNames = Object.keys(dependencyModel);

          // Check diff of each object property
          dependencyPropertyNames.forEach((depdendencyPropertyName) => {
            const prevAndCurrentPropertyDiff = getObjectPropertyDiff(
              depdendencyPropertyName,
              previousDependency,
              currentDepdendency
            );

            if (prevAndCurrentPropertyDiff !== null) {
              diff = { ...diff, ...prevAndCurrentPropertyDiff };
            }
          });
        } else {
          const prevAndCurrentPropertyDiff = getPrimitiveDiff(
            previousDependency,
            currentDepdendency
          );

          if (prevAndCurrentPropertyDiff) {
            diff = prevAndCurrentPropertyDiff;
          }
        }

        // Summarise findings in a easy to read object
        // that is later outputted to the console.
        if (
          typeof dependencyModel === "object" ||
          typeof dependencyModel === "undefined"
        ) {
          // Objects
          result.push({
            name: dependencyNames[i] ?? "",
            diff,
            type: typeof dependencyModel,
            hasObjectReferenceChanged: previousDeps[i] !== currentDepdendency,
            before: previousDeps[i],
            after: currentDepdendency,
          });
        } else if (typeof currentDepdendency !== "function") {
          // Primitives
          result.push({
            name: dependencyNames[i] ?? "",
            diff,
            type: typeof dependencyModel,
            hasObjectReferenceChanged: previousDeps[i] !== currentDepdendency,
          });
        }
      }
    }

    return result;
  };

  useEffect(() => {
    console.log(`Running ${useEffectName}`);

    const result = calculate();

    if (Object.keys(result).length) {
      result.map((changedDep: any) => {
        if (changedDep.diff !== null) {
          console.log(changedDep);
        }

        return null;
      });
    }

    effectHook();
  }, dependencies);
};
