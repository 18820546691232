import {
  DialogModalType,
  OverlayDrawer as FluentOverlayDrawer,
} from "@fluentui/react-components";
import { ReactNode } from "react";
import "./overlayDrawer.scss";

type OverlayDrawerProps = {
  size:
    | "small"
    | "medium"
    | "large"
    | "extra-large"
    | "extra-extra-large"
    | "full";
  showPanel: boolean;
  modalType?: DialogModalType | undefined;
  children: ReactNode;
  onOpenChange?: (_: any, { open }: { open: any }) => void;
};

export default function OverlayDrawer({
  size,
  showPanel,
  modalType,
  children,
  onOpenChange,
}: OverlayDrawerProps) {
  const isAllowedSize = ["small", "medium", "large", "full"].includes(size);

  return (
    <FluentOverlayDrawer
      open={showPanel}
      position="end"
      modalType={modalType}
      size={
        isAllowedSize
          ? (size as "small" | "medium" | "large" | "full")
          : undefined
      }
      className={`overlay-drawer-${size}`}
      onOpenChange={onOpenChange}
    >
      {children}
    </FluentOverlayDrawer>
  );
}
