import { Spinner } from "@fluentui/react-components";
import { SpinnerSize } from "components/spinner";
import { useContext } from "react";
import {
  TriangleDown12Filled,
  TriangleRight12Filled,
} from "@fluentui/react-icons";

import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { AccordionContext } from "components/accordion/AccordionContextProvider";
import "../accordion.scss";

interface IAccordionContent {
  title: string | React.ReactNode;
  loadingStatus?: string;
  subtitles?: string[];
  limitSubtitles?: number;
}

export function AccordionHeader({
  title,
  loadingStatus,
  subtitles,
  limitSubtitles = 3,
}: IAccordionContent) {
  const { toggle, isOpen } = useContext(AccordionContext);

  return (
    <div className="accordion-item-header" onClick={toggle}>
      <div className="pb-xs pr-sm">
        {isOpen ? <TriangleDown12Filled /> : <TriangleRight12Filled />}
      </div>
      <div className="d-flex">
        <div className="fw-semibold sub-h1 mr-md">{title}</div>
        {subtitles && subtitles.length > 0 && (
          <div className="disabled">
            {subtitles.slice(0, limitSubtitles).map((subtitle) => (
              <span className="mr-md" key={subtitle}>
                {subtitle}
              </span>
            ))}
            {subtitles.length > limitSubtitles && (
              <span>+{subtitles.length - limitSubtitles}</span>
            )}
          </div>
        )}
      </div>
      {loadingStatus && loadingStatus === LoadingStatusEnum.PENDING && (
        <span className="px-sm mt-xs">
          <Spinner size={SpinnerSize.Tiny} />
        </span>
      )}
    </div>
  );
}