type DateRange = {
  from: Date;
  to: Date;
};

/**
 * Checks if two date ranges overlap.
 * @param {DateRange} firstRange - The first date range to compare.
 * @param {DateRange} secondRange - The second date range to compare.
 * @returns {boolean} True if the date ranges overlap, false otherwise.
 */
export function checkDateRangeOverlap(
  firstRange: DateRange,
  secondRange: DateRange
): boolean {
  return firstRange.to >= secondRange.from && firstRange.from <= secondRange.to;
}
