import { useContext, useState } from "react";
import { useSelector } from "react-redux";

import Paginator from "components/Pagination";
import { useTranslation } from "hooks/use-translate";
import { CompanyTypeOptions } from "models/offer/Company";
import { RootState } from "state";
import { defaultPaginatorOptions } from "components/Pagination/consts";
import { CreateBusinessOpportunityContext } from "../CreateBusinessOpportinityContext";
import WizardSectionBusinessOpportunity from "../WizardSectionBusinessOpportinity";
import CreateEditContact from "./CreateEditContact";
import CustomerContactsList from "./CustomerContactsList";

export default function BusinessOpportunityCustomerContacts() {
  const { translate } = useTranslation();

  const { newDeal, newCustomer } = useContext(CreateBusinessOpportunityContext);
  const contacts = useSelector(
    (state: RootState) => state.sales.customerContacts.data
  );
  const { isNewCustomer } = useSelector((state: RootState) => state.sales);

  const [activePage, setActivePage] = useState<number>(1);
  const [hideWizardNavigation, setHideWizardNavigation] = useState(false);
  const [itemsShownPerPage, setItemsShownPerPage] = useState<number>(10);
  const [isLoading, setIsLoading] = useState(false);

  const activePageObjects = contacts.slice(
    (activePage - 1) * itemsShownPerPage,
    activePage * itemsShownPerPage
  );

  const contactsSubtitle = hideWizardNavigation
    ? [translate("BO_CREATE_CONTACT_SUBHEADER")]
    : [translate("BO_CONTACTS_SUBHEADER")];

  return (
    <WizardSectionBusinessOpportunity
      titleBreadCrumb={
        hideWizardNavigation ? translate("CREATE_UPDATE_CONTACT") : ""
      }
      numberBreadCrumb={contacts.length}
      isNextDisabled={!newDeal.contact}
      subtitles={
        isNewCustomer
          ? [
              translate("CUSTOMER_TYPE_DETAILS", [
                newCustomer.CompanyForm !== ""
                  ? CompanyTypeOptions[newCustomer.CompanyForm].label
                  : "",
              ]),
              translate("CREATE.NEW.DEAL.REGISTER_CONTACT_INFO"),
            ]
          : contactsSubtitle
      }
      nextLabel="CHOOSE_BUSINESS_AREA"
      hideNavigation={hideWizardNavigation || isNewCustomer}
      content={
        !hideWizardNavigation && !isNewCustomer ? (
          <Paginator
            presetActivePage={activePage}
            itemsLength={contacts.length}
            defaultItemsShownPerPage={itemsShownPerPage}
            onChangeShownItems={(v) => setItemsShownPerPage(v)}
            onSelectPage={setActivePage}
            className="w-100 h-100 align-items-end"
            options={defaultPaginatorOptions}
          />
        ) : null
      }
      loadingStatus={isLoading ? "pending" : "idle"}
    >
      {isNewCustomer ? (
        <div className="h-100">
          <CreateEditContact
            setIsLoading={setIsLoading}
            customerContacts={contacts}
          />
        </div>
      ) : (
        <CustomerContactsList
          contacts={activePageObjects}
          setHideWizardNavigation={setHideWizardNavigation}
          setIsLoading={setIsLoading}
        />
      )}
    </WizardSectionBusinessOpportunity>
  );
}
