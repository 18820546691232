import { HTMLProps } from "react";
import { CheckmarkRegular } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import TooltipV9 from "components/tooltip/TooltipV9";
import Switch from "components/switch";
import "./Picker.scss";

export type PickerToggle = {
  label: string;
  value: string;
  group: number;
  checked: boolean;
  onToggle: () => void;
  disabled?: boolean; // add logic for disabled toggles in the future
};

type PickerProps = {
  name: string;
  checked: boolean;
  onToggle: () => void;
  toggles: PickerToggle[];
  description?: string;
  useHTMLDescription?: boolean;
  isDisabled?: boolean;
  excludingGroups?: boolean;
} & HTMLProps<HTMLDivElement>;

export function Picker({
  name,
  checked,
  toggles,
  description,
  useHTMLDescription = false,
  className = "",
  excludingGroups = false,
  onToggle,
  ...rest
}: PickerProps) {
  const { ts } = useTranslation();

  const shouldDisableToggle = (toggle: PickerToggle) => {
    if (excludingGroups) {
      const activeGroup = toggles.find(
        (activeToggle) => activeToggle.checked
      )?.group;
      if (activeGroup) {
        return toggle.group !== activeGroup;
      }
      return false;
    }
    return false;
  };

  const expandedText = checked ? "CLICK_TO_COLLAPSE" : "CLICK_TO_EXPAND";
  const singleOption = checked ? "CLICK_TO_UNSELECT" : "CLICK_TO_SELECT";

  return (
    <TooltipV9 content={toggles.length > 0 ? expandedText : singleOption}>
      <div
        className={`picker-wrapper m-auto ${className} ${
          checked ? "selected" : ""
        }`}
        {...rest}
        onClick={(e) => {
          if ((e.target as HTMLElement).closest(".picker-toggle") === null) {
            onToggle();
          }
        }}
      >
        <div className="picker-selected">
          <CheckmarkRegular />
        </div>

        <div className={`col picker${!description ? " no-description" : ""}`}>
          <h5 className="text-center picker-heading">{name}</h5>
          {!!description && (
            <>
              <hr className="my-sm mx-auto" />
              <div className="text-center picker-description p-sm">
                {useHTMLDescription ? (
                  <span dangerouslySetInnerHTML={{ __html: description }} />
                ) : (
                  description
                )}
              </div>
            </>
          )}
        </div>

        {toggles.length > 0 && (
          <div className="picker-toggles py-xs px-sm">
            {toggles.map((toggle, index) => {
              const showHrDivider =
                toggles[index - 1] &&
                toggles[index - 1].group !== toggle.group ? (
                  <div className="horizontal-divider m-sm" />
                ) : null;
              return (
                <div key={toggle.value}>
                  {showHrDivider}
                  <Switch
                    key={toggle.value}
                    className="picker-toggle ml-xxs"
                    label={ts(toggle.label)}
                    labelPosition="after"
                    checked={toggle.checked}
                    disabled={shouldDisableToggle(toggle) || toggle.disabled}
                    onToggleMethod={(e) => {
                      e.stopPropagation();
                      toggle.onToggle();
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </TooltipV9>
  );
}
