import { useTranslation } from "../../hooks/use-translate";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutBody,
} from "../../components/layout/Layout";

export function AccessDeniedPage() {
  const { translate } = useTranslation();

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <h1>{translate("ACCESS_DENIED")}</h1>
        </LayoutHeaderLeft>
      </LayoutHeader>

      <LayoutBody>{translate("ACCESS_DENIED_INFO")}</LayoutBody>
    </>
  );
}
