import {
  TableFeaturePlugin,
  TableFeaturesState,
  useTableFeatures,
  UseTableFeaturesOptions,
} from "@fluentui/react-components";
import {
  defaultTableExpandingState,
  TableExpandingState,
} from "./expanding-rows";
import { defaultTableLockingState, TableLockingState } from "./locked-rows";

export interface TableFeaturesExtendedState<TItem>
  extends TableFeaturesState<TItem> {
  expandingRows: TableExpandingState;
  lockingRows: TableLockingState;
}

export type TableFeatureExtendedPlugin = <TItem>(
  tableState: TableFeaturesExtendedState<TItem>
) => TableFeaturesExtendedState<TItem>;

export function useTableFeaturesExtended<TItem>(
  options: UseTableFeaturesOptions<TItem>,
  plugins: TableFeaturePlugin[] = [],
  extendedPlugins: TableFeatureExtendedPlugin[] = []
): TableFeaturesExtendedState<TItem> {
  const baseState = useTableFeatures<TItem>(options, plugins);

  const extendedState: TableFeaturesExtendedState<TItem> = {
    ...baseState,
    expandingRows: defaultTableExpandingState,
    lockingRows: defaultTableLockingState,
  };

  return extendedPlugins.reduce(
    (state, plugin) => plugin(state),
    extendedState
  );
}
