import { ModalHeader } from "components/modal/ModalHeader";
import { useTranslation } from "hooks/use-translate";
import Modal from ".";
import { ModalFooter } from "./ModalFooter";

type PendingChangesModalProps = {
  isOpen: boolean;
  isValid: boolean;
  isLoading?: boolean;
  onCancel: () => void;
  onDiscard: () => void;
  onSave: () => void;
};

export default function PendingChangesModal({
  isOpen,
  isValid,
  isLoading = false,
  onCancel,
  onDiscard,
  onSave,
}: PendingChangesModalProps) {
  const { translate } = useTranslation();

  const handleCloseModal = () => {
    onCancel();
  };

  const handleOnSave = () => {
    onSave();
  };

  const handleOnDiscard = () => {
    onDiscard();
  };

  return (
    <Modal
      className="top-modal"
      onDismiss={handleCloseModal}
      isOpen={isOpen}
      footer={
        <ModalFooter
          onCancel={handleOnDiscard}
          onSave={handleOnSave}
          isLoading={isLoading}
          labelSubmit="SAVE_CHANGES"
          labelCancel="DISCARD_CHANGES"
          isDisabled={!isValid}
        />
      }
      header={<ModalHeader headerTitleText="UNSAVED_CHANGES" />}
    >
      <div>
        <div className={`d-flex px-md pb-lg ${!isValid && "text-color-red"}`}>
          <span className="p-sm">
            {isValid
              ? translate("PENDING_CHANGES_MESSAGE")
              : translate("INVALID_CHANGES_MESSAGE")}
          </span>
        </div>
      </div>
    </Modal>
  );
}
