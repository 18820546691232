import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "state";
import { Customer } from "models/customer";
import { uuidv4 } from "libs/uuid";
import SkeletonServiceRow from "./SkeletonServiceRow";
import ServiceTableCustomerRow from "./ServiceTableCustomerRow";

type Props = {
  filteredMonths: Date[];
  customers: Customer[];
  selectedCustomerNumbers: string[];
  setSelectedCustomerNumbers: (customers: string[]) => void;
  isFullLoading: boolean;
  isCollapsedView: boolean;
  isExpanded: boolean;
  openPanel: (customer: Customer) => void;
  showCompleted?: boolean;
  showCheckbox?: boolean;
};

export default function ServiceTableContent({
  filteredMonths,
  customers,
  selectedCustomerNumbers,
  setSelectedCustomerNumbers,
  isFullLoading,
  isCollapsedView = false,
  isExpanded,
  openPanel,
  showCompleted = false,
  showCheckbox = false,
}: Props) {
  const { isLoading: isActivitiesLoading, timelineWindow } = useSelector(
    (state: RootState) => state.activities
  );
  const [timelineLoadingMonthIndexes, setTimelineLoadingMonthIndexes] =
    useState<number[]>([]);

  const isFilteredByMonth = filteredMonths.length === 1;

  const handleCheckbox = (customerNumber: string) => {
    if (selectedCustomerNumbers.includes(customerNumber)) {
      const updatedCustomers = selectedCustomerNumbers.filter(
        (cn) => cn !== customerNumber
      );
      setSelectedCustomerNumbers(updatedCustomers);
    } else {
      setSelectedCustomerNumbers([...selectedCustomerNumbers, customerNumber]);
    }
  };

  useEffect(() => {
    if (isActivitiesLoading) {
      const oldTimeline = localStorage.getItem("oldTimeline") ?? "";
      const newTimeline = timelineWindow.toString();

      const oldDates = oldTimeline.split(",").map((dateStr) => dateStr.trim());
      const newDates = newTimeline.split(",").map((dateStr) => dateStr.trim());

      // Identify indexes of months that are in the new timeline but not in the old timeline
      const newMonths = newDates.reduce((result, date, index) => {
        if (!oldDates.includes(date)) {
          result.push(index);
        }
        return result;
      }, [] as number[]);

      setTimelineLoadingMonthIndexes(newMonths);
    } else {
      setTimelineLoadingMonthIndexes([]);
    }
  }, [isActivitiesLoading, timelineWindow]);

  return (
    <div className="pb-md px-md">
      {isFullLoading &&
        [...Array(10)].map(() => (
          <SkeletonServiceRow
            key={uuidv4()}
            isFull={isFullLoading}
            isExpanded={isExpanded}
            openPanel={openPanel}
            isCollapsedView={isCollapsedView}
            isFilteredByMonth={isFilteredByMonth}
          />
        ))}
      {!isFullLoading &&
        customers.map((customer, index) => (
          <ServiceTableCustomerRow
            key={customer.customer_number}
            customer={customer}
            isCollapsedView={isCollapsedView}
            isExpanded={isExpanded}
            openPanel={openPanel}
            filteredMonths={filteredMonths}
            showCompleted={showCompleted}
            checkbox={
              showCheckbox
                ? selectedCustomerNumbers.includes(customer.customer_number)
                : undefined
            }
            handleCheckbox={() => handleCheckbox(customer.customer_number)}
            showDivider={index !== customers.length - 1}
            timelineLoadingMonthIndexes={timelineLoadingMonthIndexes}
          />
        ))}
    </div>
  );
}
