import "./contentV2.scss";

/*

  These components are used to create a structure for the content of a layout. Current setup is:
   _______
  |___2___|
  |   3   |
  |       |
  |_______|
  |___4___|

  0. Content               // This is the wrapper for everything Content
  2. ContentHeader
  3. ContentBody
  4. ContentFooter         // Sticked to bottom of Content

  These components should only be used once for every Layout. If you want to split the content
  into several sections, use the component Section.
  
*/

export function ContentV2({ children }: any) {
  return <div className="content">{children}</div>;
}

export function ContentBodyV2({ children }: any) {
  return <div className="contentBody2">{children}</div>;
}

export function ContentBodyCenterV2({ children }: any) {
  return (
    <div className="h-100 w-100 d-flex align-items-center">
      <div className="w-100">{children}</div>
    </div>
  );
}

export function ContentFooterV2({ children }: any) {
  return <div className="contentFooter">{children}</div>;
}
