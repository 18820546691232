import { TableRowId } from "@fluentui/react-components";
import { LockingHookParams, TableLockingState } from "./types";
import { TableFeaturesExtendedState } from "../useTableFeaturesExtended";
import { useLockingRows } from "./useLockingRows";

export function useTableLockingRows<TItem>(options: LockingHookParams) {
  // https://github.com/microsoft/fluentui/blob/710e528eb20755a81ebd60ddb4d99e4dad26f7d6/packages/react-components/react-table/src/hooks/useTableSelection.ts#L20
  // False positive, these plugin hooks are intended to be run on every render
  return (tableState: TableFeaturesExtendedState<TItem>) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTableLockingRowsState(tableState, options);
}

export function useTableLockingRowsState<TItem>(
  tableState: TableFeaturesExtendedState<TItem>,
  options: LockingHookParams
): TableFeaturesExtendedState<TItem> {
  const { defaultLockedItems, lockedItems, onLockingChange } = options;

  const [locked, lockingMethods] = useLockingRows({
    defaultLockedItems,
    lockedItems,
    onLockingChange,
  });

  const isRowLocked: TableLockingState["isRowLocked"] = (rowId: TableRowId) =>
    lockingMethods.isLocked(rowId);

  return {
    ...tableState,
    lockingRows: {
      isRowLocked,
      lockedRows: locked,
    },
  };
}
