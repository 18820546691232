import { createApi } from "@reduxjs/toolkit/query/react";

import { ActivityAppendix } from "models/activities/ActivityAppendix";
import { getBaseQueryConfig } from "./utils";

const baseQuery = getBaseQueryConfig();

export const activitiesApiService = createApi({
  reducerPath: "activitiesApi",
  baseQuery,
  tagTypes: ["activities"],
  endpoints: (build) => ({
    getAppendicesFacts: build.query<ActivityAppendix[], void>({
      query: () => "/activities/appendices_facts",
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetAppendicesFactsQuery } = activitiesApiService;
