import { Col, Row } from "react-bootstrap";
import { Warning20Regular } from "@fluentui/react-icons";

import { isCurrentMonth } from "libs/date/date-format";
import { useTranslation } from "hooks";
import "./ServicesPerMonthColumn.scss";
import "./ServiceBox.scss";

type Props = {
  yearMonth: Date;
  allEmpty?: boolean;
  completedServices: number;
  allServices: number;
  hasOverdueServices?: boolean;
  showCompleted: boolean;
};

export default function ServicesPerMonthCollapsedColumn({
  yearMonth,
  allEmpty = false,
  completedServices,
  allServices,
  hasOverdueServices,
  showCompleted,
}: Props) {
  const { translate } = useTranslation();
  const backgroundColorClass = isCurrentMonth(yearMonth)
    ? "bg-currentMonthColumn"
    : "bg-monthColumn";

  return (
    <Col className={`${backgroundColorClass} p-0`}>
      <Row
        className={`wrapper w-100 h-100 min-height-45 d-flex align-items-center ${backgroundColorClass}`}
      >
        <Col>
          {!allEmpty ? (
            <>
              <label className=" d-flex justify-content-center cursor-pointer">
                {`${completedServices} / ${allServices}`}
                {hasOverdueServices && (
                  <Warning20Regular className="d-flex pl-xs justify-content-center color-red" />
                )}
              </label>
              <progress
                className="service-box-progress-bar"
                value={completedServices / allServices}
              />
            </>
          ) : (
            showCompleted && (
              <Row>
                <Col md={12}>
                  <label className="d-flex justify-content-center">
                    {translate("NO_ACTIVITIES_FOR_MONTH")}
                  </label>
                </Col>
              </Row>
            )
          )}
        </Col>
      </Row>
    </Col>
  );
}
