import { plainToClass } from "class-transformer";
import { format } from "date-fns";
import AppConfig from "../app-config";
import { Customer } from "../models/customer";
import {
  CustomerDelivery,
  CustomerTaxObjects,
  DeletedDelivery,
  DeliveryFact,
} from "../models/deliveryPlan";
import { getAuthorizationHeader } from "./libs/auth-header";
import { BatchResponse, ParseBatchResponseArray } from "./libs/batchResponse";

export class DeliveryPlanAPI {
  static async fetchCustomerDeliveryPlan(token: string, customer: Customer) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customer.customer_number}/deliveries`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const jsonArray = (await response.json()) as CustomerDelivery[];
    return jsonArray.map((json) => plainToClass(CustomerDelivery, json));
  }

  static async fetchCustomerDeliveryPlanBatch(
    token: string,
    customers: Customer[]
  ) {
    const customerIds = customers.map((customer) => customer.customer_number);

    const response = await fetch(`${AppConfig.API_URL}/deliveries`, {
      method: "POST",
      headers: {
        ...getAuthorizationHeader(token),
      },
      body: JSON.stringify({ customer_ids: customerIds }),
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const batchResponse: BatchResponse<CustomerDelivery[]> =
      await response.json();
    const result = ParseBatchResponseArray(batchResponse, {
      cls: CustomerDelivery,
    });

    return result;
  }

  static async createCustomerDelivery(
    token: string,
    customer: Customer,
    delivery: CustomerDelivery
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customer.company_registration_number}/deliveries`,
      {
        method: "POST",
        body: JSON.stringify({
          ...delivery,
          customer_number: customer.customer_number,
        }),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as CustomerDelivery;
    return plainToClass(CustomerDelivery, json);
  }

  static async updateCustomerDelivery(
    token: string,
    customer: Customer,
    deliveryId: string,
    delivery: Partial<CustomerDelivery>
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customer.company_registration_number}/deliveries/${deliveryId}`,
      {
        method: "PATCH",
        body: JSON.stringify(delivery),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as CustomerDelivery;
    return plainToClass(CustomerDelivery, json);
  }

  static async deleteCustomerDelivery(
    token: string,
    customer: Customer,
    deliveryId: string,
    endDate?: Date
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customer.company_registration_number}/deliveries/${deliveryId}`,
      {
        method: "DELETE",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({ end_date: endDate }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async deleteDelivery(
    token: string,
    orgNumber: string,
    deliveryId: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/v2/customers/${orgNumber}/deliveries/${deliveryId}`,
      {
        method: "DELETE",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = await response.json();
    const data = json.data as CustomerDelivery;
    return plainToClass(CustomerDelivery, data);
  }

  static async endDelivery(
    token: string,
    orgNumber: string,
    deliveryId: string,
    endDate: Date
  ) {
    const query = new URLSearchParams();
    query.set("end_date", format(endDate, "yyyy-MM-dd"));

    const response = await fetch(
      `${AppConfig.API_URL}/v2/customers/${orgNumber}/deliveries/${deliveryId}?${query}`,
      {
        method: "DELETE",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = await response.json();
    const data = json.data as CustomerDelivery;
    return plainToClass(CustomerDelivery, data);
  }

  static async deleteCustomerDeliveriesBatch(
    token: string,
    customer: Customer,
    deliveryIds: string[],
    endDate?: Date
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customer.company_registration_number}/deliveries/batch`,
      {
        method: "DELETE",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify({
          end_date: endDate,
          delivery_uuids: deliveryIds,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    const jsonArray = (await response.json()) as DeletedDelivery[];
    return jsonArray.map((json) =>
      plainToClass(DeletedDelivery, json, { exposeDefaultValues: true })
    );
  }

  static async fetchAvailableDeliveries(token: string, customer: Customer) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customer.company_registration_number}/available_deliveries`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const jsonArray = (await response.json()) as DeliveryFact[];
    return jsonArray.map((json) => plainToClass(DeliveryFact, json));
  }

  static async fetchCustomerTaxObjects(token: string, customerNumber: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/tax-objects`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    const json = (await response.json()) as CustomerTaxObjects;
    return plainToClass(CustomerTaxObjects, json);
  }
}
