/* eslint-disable @typescript-eslint/no-unused-vars */

import {MockedRequest, RequestHandler, setupWorker} from "msw";
import {assignmentsHandlers} from "./assignments";
import {customerHandlers} from "./customers";
import {activitiesHandlers} from "./activities";
import {usersHandlers} from "./users";
import {dealsHandlers} from "./deals";
import {organizationHandlers} from "./organization";
import {graphTokenHandler} from "./graph-token";
import {deliveryHandlers} from "./deliveryPlan";
import {offersHandlers} from "./offers";

export const MOCK_DELAY = 1000;

export function mockCheckToken(req: MockedRequest) {
    const authorization = req.headers.get("Authorization");

    if (!authorization) {
        throw new Error("Missing 'Authorization' header");
    }

    return authorization.replace("Bearer ", "");
}

const apiMocks: RequestHandler[] = [
    ...customerHandlers,
    ...assignmentsHandlers,
    ...activitiesHandlers,
    ...usersHandlers,
    ...dealsHandlers,
    ...organizationHandlers,
    ...graphTokenHandler,
    ...deliveryHandlers,
    ...offersHandlers,
] as any;

export const mockWorker = () => {
    return setupWorker(...apiMocks);
}