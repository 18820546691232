import { addMonths, differenceInMonths, startOfMonth } from "date-fns";

export function generateMonths(startMonth: Date, endMonth: Date) {
  return Array(differenceInMonths(endMonth, startMonth) + 1)
    .fill(0)
    .map((_, i) => startOfMonth(addMonths(startMonth, i)));
}

export const getMonthsOfOneYear = (startDate = new Date()): string[] => {
  const theMonths = [];
  const year = startDate.getFullYear();
  const month = startDate.getMonth();

  for (let i = 1; i < 13; i++) {
    theMonths.push(new Date(year, month + i).toISOString().substr(0, 8) + "01");
  }
  return theMonths;
};
