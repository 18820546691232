export function numberFormat(n: number) {
  return n ? n.toLocaleString().replace(/,/g, " ") : "";
}

export function numberFormatOrZero(n: number) {
  if (n === 0) {
    return "0";
  }

  return n ? n.toLocaleString() : "";
}

/**
 * Returns number rounded to nearest integer and formatted as Swedish currency.
 */
export function numberAsSwedishCurrency(n: number, acceptZero?: boolean) {
  if (n === 0) {
    return acceptZero ? "0 SEK" : "-";
  }

  const rounded = Math.round(n);
  return `${numberFormat(rounded)} SEK`;
}

export function removeDashFromString(str: string) {
  if (str.includes("-")) return str.replace(/\D/g, "");
  return str;
}

export function normalizeOrgNrAndSSN(orgNum?: string) {
  if (!orgNum) {
    return "";
  }

  let tempOrgNum = orgNum.replace(/\D/g, "");

  if (tempOrgNum.length > 10) {
    tempOrgNum = tempOrgNum.slice(2);
  }

  return tempOrgNum;
}

export function getDigitsFromString(value: string): string {
  return value.replace(/[^0-9]/g, "");
}

export function getOnlyNumbers(value: string): number | string {
  return value === "" || getDigitsFromString(value) === ""
    ? ""
    : parseInt(getDigitsFromString(value), 10) ?? 0;
}
