import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";

import AppConfig from "app-config";
import { authMethod } from "auth";

// Create baseQuery instance
export const getBaseQueryConfig = () => {
  return fetchBaseQuery({
    baseUrl: AppConfig.API_URL,
    prepareHeaders: async (headers) => {
      const token = await authMethod.getStoredAccessToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });
};
