import { plainToClass, Type } from "class-transformer";

import { User } from "../user";
import { CompanyTypeList, CompanyTypeOptions, CompanyTypes } from "./Company";
import { DealCustomer } from "./Customer";

export enum TaxObjectState {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export class TaxObjectDetails {
  /**
   * Identification of the tax object in pog database
   */
  id?: number;

  /**
   * tax object owner details
   */
  first_name: string;

  last_name?: string;

  /**
   * SSN or ORG Number depending if it's a company tax object then org, or non company tax object then ssn
   */
  social_security_number: string;

  /**
   * Company type
   */
  type: CompanyTypes;

  /**
   * Created by consultant email
   */
  created_by_username: string;

  /**
   * Created date
   */
  @Type(() => Date)
  created_date: Date;

  /**
   * Update date
   */
  @Type(() => Date)
  changed_date: Date;

  /**
   * updated by user
   */
  changed_by_username: string;

  /**
   * Is the tax object active or has been disabled
   */
  state: TaxObjectState;

  static getNewTaxObjectDetails(user: User): TaxObjectDetails {
    return {
      first_name: "",
      last_name: "",
      social_security_number: "",
      state: TaxObjectState.ACTIVE,
      type: CompanyTypes.SE_PrivatePerson,
      changed_date: new Date(),
      created_by_username: user.email,
      changed_by_username: user.email,
      created_date: new Date(),
    };
  }

  static getUpdatedTaxDetailsObject(
    taxObjectData: Partial<TaxObjectDetails>,
    user: User
  ): TaxObjectDetails {
    const taxDetails = plainToClass(TaxObjectDetails, taxObjectData);
    return {
      ...taxDetails,
      changed_by_username: user.email,
      changed_date: new Date(),
    };
  }

  static getTaxObjectFromCustomerData(
    customer: DealCustomer,
    user: User
  ): TaxObjectDetails {
    const customerTaxType = (CompanyTypeOptions as CompanyTypeList)[
      customer.legal_form ?? "ef"
    ].value;
    return {
      ...this.getNewTaxObjectDetails(user),
      social_security_number: customer.org_number,
      first_name: customer.customer_name,
      type: customerTaxType,
    };
  }
}
