import { cloneElement, ReactElement, ReactNode } from "react";

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: ReactElement;
  children: ReactNode;
}

export default function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps): ReactElement {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return condition ? cloneElement(wrapper, {}, children) : <>{children}</>;
}
