import { createApi } from "@reduxjs/toolkit/query/react";

import {
  CustomerManagement,
  CustomerManagementUpdateResponse,
} from "models/customerManagement";
import { GenericResponse } from "models/utils";
import { getBaseQueryConfig } from "./utils";

const baseQuery = getBaseQueryConfig();

export const customerManagementApiService = createApi({
  reducerPath: "customerManagementApi",
  baseQuery,
  tagTypes: ["customerManagement"],
  endpoints: (build) => ({
    getCustomersForManagement: build.query<
      GenericResponse<CustomerManagement[]>,
      string
    >({
      query: (assigneeEmail) => ({
        url: "/customers/management",
        params: { assignee_id: assigneeEmail },
      }),
      providesTags: ["customerManagement"],
      keepUnusedDataFor: 0,
    }),
    postCustomersForManagement: build.mutation<
      GenericResponse<CustomerManagementUpdateResponse[]>,
      { body: GenericResponse<CustomerManagement[]>; assigneeEmail: string }
    >({
      query: ({ body, assigneeEmail }) => ({
        url: "/customers/management",
        params: { assignee_id: assigneeEmail },
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetCustomersForManagementQuery,
  usePostCustomersForManagementMutation,
} = customerManagementApiService;
