import React from "react";

import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import Modal from ".";

interface NotificationModalProps {
  header: string;
  body: React.ReactNode;
  isOpen: boolean;
  onCancel: () => void;
}

function NotificationModal({
  header,
  body,
  isOpen,
  onCancel,
}: NotificationModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onCancel}
      size="medium"
      header={<ModalHeader headerTitleText={header} />}
      footer={<ModalFooter onSave={onCancel} labelSubmit="CLOSE_MODAL" />}
    >
      <div className="px-md">{body}</div>
    </Modal>
  );
}

export default NotificationModal;
