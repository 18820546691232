import WarningModal from "components/modal/WarningModal";
import { useTranslation } from "hooks/use-translate";

interface ModalProps {
  missingPMforServices: string[];
  onCancel?: () => void;
}

function MissingProjectManagerModal({
  onCancel,
  missingPMforServices,
}: ModalProps) {
  const { translate } = useTranslation();

  const onDismiss = () => {
    onCancel?.();
  };

  return (
    <WarningModal
      header={translate("WARNING")}
      onCancel={() => onDismiss()}
      warningMessage={translate("MISSING_PROJECT_MANAGER_INFO", [
        missingPMforServices.toString(),
      ])}
    />
  );
}

export default MissingProjectManagerModal;
