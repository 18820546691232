import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingStatus } from "..";
import {
  getLatestKYC,
  KYC,
  KYCCached,
  getWeightPoints,
} from "../../models/offer/kyc";
import { fetchKycCache, queryKycData } from "./kycThunks";

export interface KycState {
  status: LoadingStatus;
  selected: boolean;
  data: Partial<KYC>;
  existingKycData: Partial<KYC>;
  error: Object;
}

const initialState: KycState = {
  status: "idle",
  selected: true,
  data: {},
  existingKycData: {},
  error: {},
};

export const kycSlice = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    resetKYC: (state) => {
      Object.assign(state, initialState);
    },
    addKyc: (state, action: PayloadAction<Partial<KYC>>) => {
      const weightPoints = getWeightPoints({
        ...state.data,
        ...action.payload,
      });
      const kycData = {
        ...{ ...state.data, ...action.payload },
        ...{ accept_risk_level: weightPoints === 0 },
      };
      state.data = kycData;
      state.status = "idle";
    },
    approveRisk: (state, action: PayloadAction<boolean>) => {
      state.data.accept_risk_level = action.payload;
      state.status = "idle";
    },
    setKycSelected: (state, action: PayloadAction<boolean>) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchKycCache.pending, (state: KycState) => {
      state.status = "pending";
    });
    builder.addCase(
      fetchKycCache.fulfilled,
      (state: KycState, action: PayloadAction<KYCCached[]>) => {
        const weightPoits = getWeightPoints(action.payload[0]);
        const kycData = {
          ...getLatestKYC(action.payload),
          ...{ accept_risk_level: weightPoits === 0 },
        };
        state.status = "idle";
        state.data = kycData;
        state.existingKycData = kycData;
        state.error = {};
      }
    );
    builder.addCase(fetchKycCache.rejected, (state: KycState, { error }) => {
      console.log(error);
      state.data = {};
      state.status = "failed";
      state.error = error;
    });

    builder.addCase(queryKycData.pending, (state: KycState) => {
      state.status = "pending";
    });
    builder.addCase(queryKycData.fulfilled, (state: KycState, { payload }) => {
      const weightPoits = getWeightPoints(payload);
      const kycData = {
        ...payload,
        ...{ accept_risk_level: weightPoits === 0 },
      };
      state.status = "idle";
      state.data = kycData;
      state.existingKycData = kycData;
      state.error = {};
    });
    builder.addCase(queryKycData.rejected, (state: KycState, { error }) => {
      console.log(error);
      state.data = {};
      state.status = "failed";
      state.error = error;
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetKYC, addKyc, approveRisk, setKycSelected } =
  kycSlice.actions;

export default kycSlice.reducer;
