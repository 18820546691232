import {
  AssignmentsAction,
  FETCH_ASSIGNMENTS,
  LOADING_ASSIGNMENTS_FAILED,
  LOADING_ASSIGNMENTS,
} from ".";
import { Assignment } from "../../models/assignment";

export type CustomersAssignmentsState = {
  data: Assignment[];
  isLoading: boolean;
  hasFailed: boolean;
};

const initialCustomersAssignmentsState: CustomersAssignmentsState = {
  data: [],
  isLoading: false,
  hasFailed: false,
};

export function assignmentsReducer(
  { data, isLoading, hasFailed } = initialCustomersAssignmentsState,
  action: AssignmentsAction
) {
  switch (action.type) {
    case FETCH_ASSIGNMENTS: {
      return { data: action.payload, isLoading: false, hasFailed: false };
    }
    case LOADING_ASSIGNMENTS_FAILED: {
      return { data, isLoading: false, hasFailed: true };
    }
    case LOADING_ASSIGNMENTS: {
      return { data, isLoading: true };
    }
    default: {
      return { data, isLoading, hasFailed };
    }
  }
}
