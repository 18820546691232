import { useNavigate } from "react-router-dom";
import { createContext, useEffect } from "react";
import { app, authentication, pages } from "@microsoft/teams-js";
import jwtDecode, { JwtPayload } from "jwt-decode";
import {
  TeamsUserCredential,
  TeamsUserCredentialAuthConfig,
} from "@microsoft/teamsfx";
import { Providers, SimpleProvider } from "@microsoft/mgt-element";
import AppConfig from "../app-config";
import { isInTeams } from "../libs/teams";
import { getAuthorizationHeader } from "../api/libs/auth-header";
import { ResponseError } from "../api/libs/responseError";

const STORAGE_TOKEN_KEY = "teams-token";

const getCachedToken = () => {
  const localToken = localStorage.getItem(STORAGE_TOKEN_KEY);

  if (localToken) {
    const decoded = jwtDecode<JwtPayload>(localToken);

    if (decoded?.exp) {
      const now = new Date();
      if (now.getTime() < decoded?.exp * 1000) {
        return localToken;
      }
    }
  }
};

async function getAccessTokenHandler(scopes: string[]): Promise<string> {
  const apiToken = await authentication.getAuthToken();

  const cachedToken = getCachedToken();
  if (cachedToken) {
    return cachedToken;
  }

  // request OBO token from BE and store to local storage
  try {
    localStorage.removeItem(STORAGE_TOKEN_KEY);
    const response = await fetch(`${AppConfig.API_URL}/graph-token`, {
      headers: { ...getAuthorizationHeader(apiToken) },
    });

    if (!response.ok) {
      throw ResponseError(response);
    }

    const res = await response.json();
    localStorage.setItem(STORAGE_TOKEN_KEY, res.access_token);
    return res.access_token;
  } catch (error) {
    console.error("Error getting graph token:", error);
  }

  return apiToken;
}

export function useNavigateFromTeamsUrl() {
  const navigate = useNavigate();

  useEffect(() => {
    const navigateToPage = async () => {
      const ctx = await app.getContext();
      if (ctx.page.subPageId && ctx.page.subPageId !== "") {
        navigate(ctx.page.subPageId);
      }
    };

    if (isInTeams()) {
      navigateToPage();
    }
  }, [navigate]);
}

// if this is a new install in teams we need to show the config window first and save the settings
async function teamsShowConfigWindow() {
  const showConfigWindow = window.location.href.includes(
    AppConfig.MITT_LUDVIG_CONFIG_URL
  );

  if (showConfigWindow) {
    pages.config.registerOnSaveHandler((saveEvent) => {
      const displayName =
        AppConfig.APP_NAME +
        (AppConfig.STAGE !== "production" ? ` ${AppConfig.STAGE}` : "");

      const configPromise = pages.config.setConfig({
        entityId: `${AppConfig.APP_NAME}Team`,
        suggestedDisplayName: displayName,
        contentUrl: AppConfig.MITT_LUDVIG_URL,
        websiteUrl: AppConfig.MITT_LUDVIG_URL,
      });

      configPromise
        .then((result) => {
          saveEvent.notifySuccess();
        })
        .catch((error) => {
          saveEvent.notifyFailure("failure message");
        });
    });

    pages.config.setValidityState(true);
  }
}

export const teamsCredentialsConfig: TeamsUserCredentialAuthConfig = {
  clientId: AppConfig.CLIENT_ID,
  initiateLoginEndpoint: `${AppConfig.MITT_LUDVIG_URL}/login`,
};

export const TeamsFxContext = createContext<{
  teamsUserCredential?: TeamsUserCredential;
}>({
  teamsUserCredential: undefined,
});

class TeamsCustomProvider extends SimpleProvider {}

// the initialization is now async and we need to wait for it to finish
export async function teamsInitialize() {
  Providers.globalProvider = new TeamsCustomProvider(getAccessTokenHandler);
  console.log("%cTEAMS AUTH ENABLED.", "font-size: 2em; color: blue;");
  await app.initialize();
  await teamsShowConfigWindow();
  await app.notifyAppLoaded();
  await app.notifySuccess();
}
