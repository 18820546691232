import { Col, Row } from "react-bootstrap";
import { Checkmark20Regular } from "@fluentui/react-icons";

import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import { capitalize } from "libs/capitalize";
import { getMonthName } from "libs/date/month-name";
import { ServiceInstance } from "models/activities/activity";
import "./ServiceBox.scss";

type Props = {
  servicesList: ServiceInstance[];
};

export function ServiceBoxCompleted({ servicesList }: Props) {
  const { translate, language } = useTranslation();

  return (
    <div className="service-box completed-collapsed pr-xxl">
      <Row className="service-box-content h-100">
        <Col className="my-xxs mx-0 py-xs">
          <Row>
            <div className="d-flex align-items-center">
              <TooltipV9
                hasJSXContent
                content={
                  <div className="flex-column-tooltip-content">
                    {servicesList.map((service) => (
                      <span key={service.service_box_id}>
                        {service.getTitleWithWeek(language)}
                      </span>
                    ))}
                  </div>
                }
              >
                <p className="mr-sm m-0 fw-600 services-number">
                  {servicesList.length}
                </p>
              </TooltipV9>
              <p className="me-auto m-0 fw-600 pl-sm">
                {translate("COMPLETED_SERVICES_CURRENT_MONTH")}
                {capitalize(getMonthName(servicesList[0].month, language))}
              </p>
              <p className="m-0 p-0 fw-semibold">
                <Checkmark20Regular
                  data-testid="completed-check"
                  className="ml-sm"
                />
              </p>
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
