import { createContext, ReactNode, useContext, useMemo } from "react";

import {
  CustomerActivitiesServices,
  ServiceInstance,
} from "models/activities/activity";

interface AssignmentManagementContextValue {
  servicesFilter: (
    customerNumber: string,
    activitiesToFilter?: CustomerActivitiesServices[]
  ) => ServiceInstance[];
  isCacheDisabled: boolean;
  setIsCacheDisabled: (isCacheDisabled: boolean) => void;
  isFiltering: boolean;
  setSearchToMyCustomers: () => void;
}

interface AssignmentManagementContextProps
  extends AssignmentManagementContextValue {
  children: ReactNode;
}

const AssignmentManagementContext = createContext<
  AssignmentManagementContextValue | undefined
>(undefined);

export function AssignmentManagementContextProvider({
  children,
  servicesFilter,
  isCacheDisabled,
  setIsCacheDisabled,
  isFiltering,
  setSearchToMyCustomers,
}: AssignmentManagementContextProps) {
  const value = useMemo(
    () => ({
      servicesFilter,
      isCacheDisabled,
      setIsCacheDisabled,
      isFiltering,
      setSearchToMyCustomers,
    }),
    [
      servicesFilter,
      isCacheDisabled,
      setIsCacheDisabled,
      isFiltering,
      setSearchToMyCustomers,
    ]
  );

  return (
    <AssignmentManagementContext.Provider value={value}>
      {children}
    </AssignmentManagementContext.Provider>
  );
}

export const useAssignmentManagementContext = () => {
  const context = useContext(AssignmentManagementContext);
  if (!context) {
    throw new Error(
      "useAssignmentManagementContext must be used within an AssignmentManagementContextProvider"
    );
  }
  return context;
};
