import { useDispatch, useSelector } from "react-redux";

import Modal from "components/modal";
import { useTranslation } from "hooks/use-translate";
import { BasicInfo } from "models/customerSettings/customerSettingsBasicInfo";
import { RootState } from "state";
import { updateBasicInfoSettingCallerWrapper } from "state/customerSettings/customerSettingsThunk";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";

type CustomerPageBasicInfoModalProps = {
  openModal: boolean;
  setOpenModal: (arg: boolean) => void;
  currSetting: Partial<BasicInfo>;
  customerId: string;
};

export default function CustomerPageBasicInfoModal({
  openModal,
  setOpenModal,
  currSetting,
  customerId,
}: CustomerPageBasicInfoModalProps) {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.users.currentUser);
  const basicInfo = useSelector(
    (state: RootState) => state.settings.basicInfo.data
  );

  const setSettingOption = (setting: Partial<BasicInfo>) => {
    let existingBasicInfoObj: Partial<BasicInfo> = basicInfo;
    if (Object.keys(existingBasicInfoObj).length === 0) {
      existingBasicInfoObj = {
        lease_cars: false,
        collective_agreement: false,
        holiday_salary: false,
        reporting_forum: false,
        created_by: user.graphId,
        modified_by: user.graphId,
        customer_number: customerId,
      };
    }
    const updatedSetting: Partial<BasicInfo> = {
      ...existingBasicInfoObj,
      ...setting,
    };
    dispatch(updateBasicInfoSettingCallerWrapper(updatedSetting));
  };

  return (
    <Modal
      onDismiss={() => {
        setOpenModal(false);
      }}
      size="medium"
      isOpen={openModal}
      footer={
        <ModalFooter
          onCancel={() => setOpenModal(false)}
          onSave={() => {
            if (currSetting) {
              setSettingOption(currSetting);
            }
            setOpenModal(false);
          }}
          labelSubmit="YES"
          labelCancel="NO"
        />
      }
      header={<ModalHeader headerTitleText="CONFIRM" />}
    >
      <div className="p-xl">
        <div className="d-flex justify-content-center">
          {currSetting &&
            Object.entries(currSetting).map((k, v) => (
              <h3 key={`current-change-${k}`}>
                {translate("CONFIRMATION_QUESTION_PREFIX")}{" "}
                {v ? translate("ENABLE") : translate("DISABLE")}{" "}
                {`${translate("SETTING")} "${k}"?`}
              </h3>
            ))}
        </div>
      </div>
    </Modal>
  );
}
