import { CheckboxProps } from "@fluentui/react-components";

import { TranslationKey } from "i18n";
import { CompanyType, Deal } from "models/deals/deal";
import { Stage } from "models/deals/stage";
import { RHFInputProps } from "components/input/RHFInput";
import { TextAreaProps } from "components/textarea";
import { FieldConfiguration } from "views/deals/configuration/fields-config";
import { DropdownV9Props } from "components/dropdown/DropdownV9";

export const companyTypes: { label: TranslationKey; value: CompanyType }[] = [
  {
    label: "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_PRIVATE_PERSON",
    value: "Privatperson",
  },
  {
    label: "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_LIMITED_COMPANY",
    value: "Aktiebolag",
  },
  {
    label: "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_TRADING_COMPANY",
    value: "Handelsbolag",
  },
  {
    label: "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_PRIVATE_BUSINESS",
    value: "Enskildnäringsverksamhet",
  },
  {
    label: "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_LIMITED_PARTNERSHIP",
    value: "Kommanditbolag",
  },
  {
    label:
      "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_NON_PROFIT_ASSOCIATION",
    value: "Ideell förening",
  },
  {
    label:
      "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_COMMUNITY_ASSOCIATION",
    value: "Samfällighetsförening",
  },
  {
    label: "MY_BUSINESS_OPPORTUNITIES.PANEL.COMPANY_TYPES_OTHER",
    value: "Annat",
  },
];

export type DealFieldProps = {
  /**
   * The current deal
   */
  deal: Deal;
  configuration: FieldConfiguration;
  stage?: Stage;
};
export type ContactGroupForm = {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
};
export type DealInputProps = DealFieldProps & Partial<RHFInputProps>;
export type DealTextAreaProps = DealFieldProps & TextAreaProps;
export type DealDropdownProps = DealFieldProps & Partial<DropdownV9Props>;
export type DealMultiSelectDropdownProps = DealFieldProps &
  Partial<DropdownV9Props>;
export type DealCheckboxProps = { option: number } & DealFieldProps & CheckboxProps;
