import { plainToClass } from "class-transformer";
import { rest } from "msw";
import { mockCheckToken } from "..";
import { MOCK_USER_EMAIL } from "../..";
import { User } from "../../../models/user";

const KEY = "user-key-mock";
const BASE_PATH = process.env.REACT_APP_API_URL;

export const initialUser: User = plainToClass(User, {
  email: MOCK_USER_EMAIL,
  id: "123",
  firstName: "Firstname",
  lastName: "Lastname",
  title: "office_manager",
  graphId: "12345678-123a-123a-123a-12345678",
});

export const usersHandlers = [
  rest.get<User>(`${BASE_PATH}/me`, (req, res, { status, json, text }) => {
    try {
      mockCheckToken(req);
    } catch (e) {
      return res(status(403), text(e as string));
    }

    const user: User = JSON.parse(
      localStorage.getItem(KEY) ?? JSON.stringify(initialUser)
    );
    return res(status(200), json(user));
  }),
];
