import { Spinner } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Important16Filled, Checkmark16Regular } from "@fluentui/react-icons";

import { Button } from "components/button";
import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { SpinnerSize } from "components/spinner";
import { Customer, SieFile } from "models/customer";
import {
  fetchCustomerSieFiles,
  updateCustomerSieFilePriority,
} from "state/customers/actions";

type SIEFilesModalProps = {
  selectedCustomer?: Customer;
  showSIEFilesModal: boolean;
  onDismiss: () => void;
};

export function SIEFilesModal({
  selectedCustomer,
  showSIEFilesModal,
  onDismiss,
}: SIEFilesModalProps) {
  const dispatch = useDispatch();
  const [displayLoader, setDisplayLoader] = useState(false);

  useEffect(() => {
    (async () => {
      if (!selectedCustomer) {
        return;
      }
      setDisplayLoader(true);
      await dispatch(fetchCustomerSieFiles(selectedCustomer));
      setDisplayLoader(false);
    })();
  }, [selectedCustomer, dispatch]);

  const prioritizeFile = (sieFile: SieFile) => {
    if (selectedCustomer) {
      dispatch(updateCustomerSieFilePriority(selectedCustomer, sieFile));
    }
  };

  const onModalDismiss = () => {
    setDisplayLoader(false);
    onDismiss();
  };

  return (
    <Modal
      header={
        <ModalHeader
          headerTitleText={`SIE-filer ${
            selectedCustomer && selectedCustomer.name
          }`}
        />
      }
      isOpen={showSIEFilesModal}
      onDismiss={() => onModalDismiss()}
    >
      {selectedCustomer && displayLoader && (
        <Spinner
          label="Loading SIE files..."
          className="p-3"
          size={SpinnerSize.ExtraSmall}
        />
      )}

      {(selectedCustomer?.sieFiles === undefined ||
        selectedCustomer?.sieFiles.length === 0) &&
        !displayLoader && (
          <div>
            <b>No SIE files found.</b>
          </div>
        )}

      {selectedCustomer &&
        selectedCustomer.sieFiles &&
        selectedCustomer.sieFiles.length !== 0 && (
          <table>
            <thead>
              <tr>
                <th>Key</th>
                <th>Source</th>
                <th>Last modified</th>
                <th>Prioritize</th>
              </tr>
            </thead>
            <tbody>
              {selectedCustomer.sieFiles?.map((sieFile) => (
                <tr key={sieFile.key}>
                  <td>{sieFile.key}</td>
                  <td>{sieFile.source}</td>
                  <td>{sieFile.last_modified}</td>
                  {!sieFile.isLoading ? (
                    <td>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        disabled={sieFile.justPrioritized !== false}
                        onClick={(e) => prioritizeFile(sieFile)}
                      >
                        {sieFile.justPrioritized === false ? (
                          <Important16Filled />
                        ) : (
                          <Checkmark16Regular />
                        )}
                      </Button>
                    </td>
                  ) : (
                    <Spinner className="p-1" size={SpinnerSize.ExtraSmall} />
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </Modal>
  );
}
