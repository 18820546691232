import AppConfig from "../app-config";
import { getAuthorizationHeader } from "./libs/auth-header";
import { authMethod } from "../auth";
import { VolumeAnalysisData } from "../models/VolumeAnalysisData";

export class SieFilesAPI {
  static async getVolumeAnalysis(
    organisationNumber: string,
    onLoaded: (x: VolumeAnalysisData[]) => void
  ) {
    const token = await authMethod.getStoredAccessToken();
    const response = await fetch(
      `${AppConfig.API_URL}/sie-files/volumeanalysis/${organisationNumber}`,
      {
        method: "GET",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    onLoaded(await response.json());
  }
}
