import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Content, ContentBody } from "components/content/Content";
import { ContentHeader } from "components/content/ContentHeader";
import { RedirectScreen } from "components/redirectScreen";
import { useTranslation } from "hooks/use-translate";
import { SelectedPickerOption } from "models/offer/Picker";
import { RootState } from "state";
import {
  resetSelectedServiceOptions,
  resetSelectedServiceType,
} from "state/offer/offersSlice";
import { AppDispatch } from "state/use-app-redux";
import { availableServiceTypes } from "../configuration/service-type-config";

export function OffersRedirectScreen() {
  const { ts, translate } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const selectedServiceType = useSelector(
    (state: RootState) => state.offers.selectedServiceType
  );
  const selectedOptions = useSelector(
    (state: RootState) => state.offers.selectedServiceOptions
  );

  const serviceType = useRef(selectedServiceType);
  const serviceOptions = useRef(selectedOptions);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedServiceType());
      dispatch(resetSelectedServiceOptions());
    };
  }, [dispatch]);

  const urlsArray = Array.from(
    new Set(serviceOptions.current.map((option) => option.externalURL))
  ) as string[];

  let mappedOptions = "";
  // check if the current service type is accounting or else other
  if (serviceType.current === availableServiceTypes[0].name) {
    // mapping the selected options so the description displays whats selected
    mappedOptions = serviceOptions.current
      .map((op: SelectedPickerOption) => `'${ts(`${op.name}_FREQUENCY`)}'`)
      .join(", ");
  } else if (serviceType.current === availableServiceTypes[1].name) {
    // mapping the selected options so the description displays whats selected
    mappedOptions = serviceOptions.current
      .map((op: SelectedPickerOption) => `'${ts(op.name)}'`)
      .join(", ");
  }

  const getRedirectScreenContent = () => {
    if (
      selectedOptions.length === 1 &&
      selectedOptions[0].name === "Real_estate"
    ) {
      return {
        title: ts("REDIRECT_INTRANET_TITLE"),
        description: ts("REDIRECT_INTRANET_DESCRIPTION", [mappedOptions]),
      };
    }

    return {
      title: ts("REDIRECT_VERIFIED_TITLE"),
      description: ts("REDIRECT_VERIFIED_DESCRIPTION", [mappedOptions]),
    };
  };

  return (
    <Content>
      <ContentHeader
        title={translate("REDIRECTION_SCREEN")}
        loadingStatus={loading ? "pending" : "idle"}
      />
      <ContentBody>
        <RedirectScreen
          title={getRedirectScreenContent().title}
          description={getRedirectScreenContent().description}
          urlsArray={urlsArray}
          loadingStatus={loading}
          setLoadingStatus={setLoading}
        />
      </ContentBody>
    </Content>
  );
}
