import { CustomerDeal } from "../models/offer/CustomerDeal";
import { SigningMethod } from "../models/offer/DealContact";

export const canUploadPhysicalDeal = (deal?: CustomerDeal) => {
  if (!deal) {
    return false;
  }

  const isSigningMethodPhysical = deal.contacts
    ? deal.contacts.some(
        (contact) => contact.signing_method === SigningMethod.PHYSICAL
      )
    : false;

  if (deal.state === "contract" && isSigningMethodPhysical) {
    return true;
  }

  return false;
};

export const isDealEditable = (deal?: CustomerDeal) => {
  if (!deal) {
    return false;
  }

  if (deal.state === "offer") {
    return true;
  }

  return false;
};
